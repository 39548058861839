function customStyles(theme){
  return {
    breadCrumbs:{},
    formButtons:theme.formButtons,
    submitButton:theme.submitButton,
    textFieldErr:theme.textFieldErr,
    textField:theme.textField,
    tableTextFieldErr:theme.tableTextFieldErr,
    tableTextField:theme.tableTextField,
    rowActionButton:theme.rowActionButton,
    rowActionButtonSattelite:theme.rowActionButtonSattelite,
    rowActionButtonRed: theme.rowActionButtonRed,
    columnAction: theme.columnAction,
    submitButtonDanger:theme.submitButtonDanger,
    submitButtonSattelite: theme.submitButtonSattelite,
    crmXTable: theme.crmXTable,
    listSelectFieldErr:theme.listSelectFieldErr,
    listSelectField:theme.listSelectField,
    listSelectOption: theme.listSelectOption,
    btnGroup:{
       display:"inline-block",
       whiteSpace:"nowrap",
    },
    pageTitle:theme.pageTitle,
    topButtons:theme.topButtons,
    btnBlock:theme.btnBlock,
  };
}
export default customStyles;
