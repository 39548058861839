import React, {Component}  from "react";
import {
  Grid,
  Link,
  Select,
  MenuItem,
  Button,
  TextField,
  // Popover
} from "@material-ui/core";
// import moment from 'moment';

// components
// import { Typography } from "../../../../components/Wrappers";
import MUIDataTable from "mui-datatables";

// context
import { AppContext } from "../../../../context/AppContext";
import ApiModel from "../../../../utils/ApiModel";
// import validators   from '../../../../utils/validators';
import MessageBlock from '../../../../utils/MessageBlock';
import {CrmButton} from "../../../../components/CrmButton/CrmButton";
// styles
import { withStyles } from "@material-ui/core/styles";
import Themes from "../../../../themes";
import customStyles from "./styles";
// import { Alarm } from "@material-ui/icons";
const theme = Themes.default;
const styles = customStyles(theme);

class ShipmentDetail extends Component {

  static contextType = AppContext;

  constructor(props) {
    super(props);
    var self = this;

    self.columns=[
      // { name: "product_id", label: "Товар", options: { filter: false, sort: false, } },
      { name: "input_product_id", label: "Товар", options: { filter: false, sort: false, } },

      // { name: "unit_id", label: "Одиниці",  options: { filter: false, sort: false, } },
      { name: "input_unit_id", label: "Одиниці",  options: { filter: false, sort: false, } },

      // { name: "count_to_ship", label: "Кількість", options: { filter: false, sort: false, } },
      { name: "input_count_to_ship", label: "Кількість", options: { filter: false, sort: false, } },

      // { name: "ship_price_for_one", label: "Ціна за одиницю", options: { filter: false, sort: false, } },
      { name: "input_ship_price_for_one", label: "Ціна за одиницю", options: { filter: false, sort: false, } },

      { name: "total_ship_price", label: "Всього", options: { filter: false, sort: false, } },

      // { name: "provider_id", label: "Постачальник", options: { filter: false, sort: false, } },
      { name: "input_provider_id", label: "Постачальник", options: { filter: false, sort: false, } },

      // price_one_by_provider  float   (NULL)     NO              (NULL)                   select,insert,update,references           
      { name: "input_price_one_by_provider", label: "Ціна постачальника за одиницю", options: { filter: false, sort: false, } },

      // total_price_provider   float   (NULL)     NO              (NULL)                   select,insert,update,references  
      { name: "total_price_provider", label: "Всього", options: { filter: false, sort: false, } },

      { name: "action", label: "Дії", options: { filter: false, sort: false, }  },

    ];
    if(!self.state) {
      self.state = {
          consumerId:0,
          consumer:{},

          consumerContractId:0,
          consumerContract:{},

          shipmentId:0,
          shipment:{},

          detail:[],

          message:"",
          messageType:"",

          products:[],
          providers:[],
          units:[],
      };
    }

    self.validator={
      shipments_id:ApiModel.atomicValidator.isPositiveInteger,
      product_id:ApiModel.atomicValidator.isPositiveInteger,
      unit_id:ApiModel.atomicValidator.isPositiveInteger,
      count_to_ship: ApiModel.atomicValidator.isPositiveFloat,
      ship_price_for_one: ApiModel.atomicValidator.isPositiveFloat,
      total_ship_price: ApiModel.atomicValidator.isPositiveFloat,
      provider_id:ApiModel.atomicValidator.isPositiveInteger,
      price_one_by_provider: ApiModel.atomicValidator.isPositiveFloat,
      total_price_provider: ApiModel.atomicValidator.isPositiveFloat,
    };

    self.mapTable = self.mapTable.bind(self);

    self.validate = self.validate.bind(self);
    self.onError = ApiModel.onError(self).bind(self);

    self.loadOneConsumer = ApiModel.loadOneConsumer(self, "consumer", self.onError);
    self.loadOneConsumerContract = ApiModel.loadOneConsumerContract(self, "consumerContract", self.onError);
    this.loadOneShipment = ApiModel.loadOneShipment(this, "shipment", this.onError);
    this.loadOneShipmentDetail = ApiModel.loadOneShipmentDetail(this, "detail", this.onError);

    self.loadProviders  = ApiModel.loadProviders(self, "providers", self.onError);
    self.loadProducts  = ApiModel.loadProducts(self, "products", self.onError);
    self.loadUnits  = ApiModel.loadUnits(self, "units", self.onError);

  }

  componentDidMount(){
    // var app = this.context;
    var self = this;
    this.setState({
      consumerId: this.props.consumerId,
      consumerContractId: this.props.consumerContractId,
      shipmentId: this.props.shipmentId,
    });
    self.loadOneConsumer(self.props.consumerId).finally(function(){
      self.loadOneConsumerContract(self.props.consumerContractId).finally(function(){
        self.loadOneShipment(self.props.shipmentId).finally(function(){
          self.loadOneShipmentDetail(self.props.shipmentId).finally(function(){
            self.loadUnits().finally(function(){
              self.loadProducts().finally(function(){
                self.loadProviders({});
              });        
            });
          });
        });
      });
    });
  }

  componentDidUpdate(){
    if(this.props.consumerId !==  this.state.consumerId 
       || this.props.consumerContractId !==  this.state.consumerContractId
       || this.props.shipmentId !==  this.state.shipmentId
       ) {
      this.setState({
        consumerId: this.props.consumerId,
        consumerContractId: this.props.consumerContractId,
        shipmentId: this.props.shipmentId,
      });
      var self = this;
      self.loadOneConsumer(self.props.consumerId).finally(function(){
        self.loadOneConsumerContract(self.props.consumerContractId).finally(function(){
          self.loadOneShipment(self.props.shipmentId).finally(function(){
            self.loadOneShipmentDetail(self.props.shipmentId);
          });
        });
      });
    }    
  }

  mapTable(){
    var self=this;
    const { classes } = self.props;
    return function(row, ind){
      var elm =  JSON.parse(JSON.stringify(row));


      elm['input_product_id'] = (
          <Select
                value={self.state.detail[ind]["product_id"]}
                onChange={self.handleChangeFactory("product_id", ind)}
                className={elm.error_product_id ? classes.listSelectFieldErr : classes.listSelectField}
              >
                <MenuItem value="" key={'product_id_X'} >-</MenuItem>
                {self.state.products.map(function(elm2, ind2){
                    return <MenuItem key={'product_id_' + ind + '_' + ind2} value={elm2.id}  className={classes.listSelectOption}>{elm2.name}</MenuItem>;
                })}
          </Select>
      );

      elm['input_unit_id'] = (
          <Select
                value={self.state.detail[ind]["unit_id"]}
                onChange={self.handleChangeFactory("unit_id", ind)}
                className={elm.error_unit_id ? classes.listSelectFieldErr : classes.listSelectField}
              >
                <MenuItem value="" key={'unit_id_X_'+ind} >-</MenuItem>
                {self.state.units.map(function(elm2, ind2){
                    return <MenuItem key={'unit_id_' + ind + '_' + ind2} value={elm2.id}  className={classes.listSelectOption}>{elm2.name}</MenuItem>;
                })}
          </Select>
      );

      elm['input_count_to_ship'] = (
          <TextField
            value={self.state.detail[ind]["count_to_ship"]}
            onChange={self.handleChangeFactory("count_to_ship", ind)}
            margin="normal"
            placeholder="Кількість"
            type="text"
            fullWidth
            variant="standard" 
            className={elm.error_count_to_ship ? classes.tableTextFieldErr : classes.tableTextField}
            />
      );

      elm['input_ship_price_for_one'] = (
          <TextField
            value={self.state.detail[ind]["ship_price_for_one"]}
            onChange={self.handleChangeFactory("ship_price_for_one", ind)}
            margin="normal"
            placeholder="Ціна за одиницю"
            type="text"
            fullWidth
            variant="standard" 
            className={elm.error_ship_price_for_one ? classes.tableTextFieldErr : classes.tableTextField}
            />
      );

      elm['input_provider_id'] = (
          <Select
                value={self.state.detail[ind]["provider_id"]}
                onChange={self.handleChangeFactory("provider_id", ind)}
                className={elm.error_provider_id ? classes.listSelectFieldErr : classes.listSelectField}
              >
                <MenuItem value="" key={'provider_id_X_'+ind} >-</MenuItem>
                {self.state.providers.map(function(elm2, ind2){
                  return <MenuItem key={'provider_id_' + ind + '_' + ind2} value={elm2.id}  className={classes.listSelectOption}>{elm2.name}</MenuItem>;
                })}
          </Select>
      );

      elm['input_price_one_by_provider'] = (
          <TextField
            value={self.state.detail[ind]["price_one_by_provider"]}
            onChange={self.handleChangeFactory("price_one_by_provider", ind)}
            margin="normal"
            placeholder="Ціна за одиницю"
            type="text"
            fullWidth
            variant="standard" 
            className={elm.error_price_one_by_provider ? classes.tableTextFieldErr : classes.tableTextField}
            />
      );

      elm['action'] = (
        <div className={classes.btnGroup}>
          <CrmButton
            classNameMain={classes.rowActionButton}
            classNameSattelite={classes.rowActionButtonSattelite}
            size="small"
            onClick={self.handleChangeFactory("all", -1)}
            >
            Видалити
          </CrmButton>
        </div>
      );
      return  elm;
    }
  }

  validate(record){
    var self = this;
    var errorExists = false;
    Object.keys(record).map(function(elm) {
      var error;
      if(self.validator[elm]) {
        error  = self.validator[elm](record[elm]);
        if (error){
          errorExists = true;
        }
      } else {
        error = "";
      }
      record["error_" + elm] = error;
      return elm;
    });

    return errorExists;
  }

  handleChangeFactory(attributeName, ind) {
      var self = this;
      return function(event) {
        event.preventDefault();
        // deep copy of the data
        var detail = JSON.parse(JSON.stringify(self.state.detail));

        if(ind<0) {
          // add new row to end
          // console.log(moment(), moment().format("YYYY-MM-DD"));
          var newRow = ApiModel.defaultShipmentDetailRecord();
          newRow.shipments_id = self.props.shipmentId;

          detail.push(newRow);
          self.setState({
              "detail": detail
          });
        } else {
          // update price if validated
          var p = detail[ind];
          
          p[attributeName] = event.target.value;

          try{
            p.total_ship_price = p.ship_price_for_one * p.count_to_ship;
          } catch(e){
          }
          try{
            p.total_price_provider = p.price_one_by_provider * p.count_to_ship;
          } catch(e){
          }
          
          let shipmentToPay = 0;
          let shipmentToGet = 0;
          for(let i=0; i<detail.length; i++){
            shipmentToPay += parseFloat(detail[i].total_price_provider) || 0;
            shipmentToGet += parseFloat(detail[i].total_ship_price) || 0;
          }

          var errorExists = self.validate(p);
          
          self.setState({
            detail: detail,
            shipmentToPay:shipmentToPay,
            shipmentToGet:shipmentToGet
          });

          // post the updated data
          if(!errorExists) {
            if(self.saveTimeout) {
              window.clearTimeout(self.saveTimeout);
            }
            self.saveTimeout = window.setTimeout(function(){
              self.saveOneRow(p);
            }, 2000);
          }
        }
      }
  }

  saveOneRow(p){
      var self = this;
      var app = self.context;
      
      let postData = {
        id: parseInt(p["id"]),
        shipments_id: parseInt(p["shipments_id"]),
        product_id:parseInt(p["product_id"]),
        unit_id: parseInt(p["unit_id"]),
        count_to_ship: parseFloat(p["count_to_ship"]),
        ship_price_for_one: parseFloat(p["ship_price_for_one"]),
        total_ship_price: parseFloat(p["total_ship_price"]),
        provider_id: parseInt(p["provider_id"]),
        price_one_by_provider: parseFloat(p["price_one_by_provider"]),
        total_price_provider: parseFloat(p["total_price_provider"]),
      };

      if (postData.id) {
          // post to UPDATE
          app.postData(
              '/consumers/contracts/shipments/detail/edit',
              [postData]
          ).then(function(responseJson){
          if(responseJson["status"] === "OK") {
              self.setState({
                  "message": "Дані оновлено",
                  "messageType": "success"
              });
              self.loadOneShipmentDetail(self.props.shipmentId);
          } else if(responseJson["status"] === "EXPECTATION_FAILED") {
              self.onError(responseJson["comment"] || "Не вдалося зберегти дані");
          } else {
              self.onError(responseJson["comment"] || "Не вдалося зберегти дані");
          }
          }).catch((reason) => self.onError( reason || "Не вдалося зберегти дані") );
      } else {
          // post to ADD
          app.postData(
              '/consumers/contracts/shipments/detail/add',
              [postData]
          ).then(function(responseJson){
              if(responseJson["status"] === "OK") {
                self.setState({
                  "message": "Рядок додано",
                  "messageType": "success"
                });
                self.loadOneShipmentDetail(self.props.shipmentId);
              } else if(responseJson["status"] === "EXPECTATION_FAILED") {
                  self.onError(responseJson["comment"] || "Не вдалося зберегти дані");
              } else {
                  self.onError(responseJson["comment"] || "Не вдалося зберегти дані");
              }
          }).catch((reason) => self.onError( reason || "Не вдалося зберегти дані") );
      }
  }

  handleDeleteFactory(ind) {
      var self = this;
      var app = this.context;
      return function(event) {
          event.preventDefault();

          var id = self.state.detail[ind].id;

          if(id) {
              var onError = function(errorMessage) {
                  self.setState({
                      "message":errorMessage,
                      "messageType":"error",
                  });
              };
              var postData = [id];
              // post the DELETE command

              app.postData(
                    '/consumers/contracts/shipments/delete',
                    postData
                  ).then(function(responseJson){
                      if(responseJson["status"] === "OK") {
                          self.setState({
                              "message": "Дані оновлено",
                              "messageType": "success"
                          });
                          self.loadOneShipmentDetail(self.props.shipmentId);
                      } else if(responseJson["status"] === "EXPECTATION_FAILED") {
                          onError("Не вдалося зберегти дані");
                      } else {
                          onError(responseJson["comment"]);
                      }
                  }).catch(() => onError("Невідома помилка"));
          } else {
              var detail = JSON.parse(JSON.stringify(self.state.detail));
              detail.splice(ind, 1);
              self.setState({ "detail": detail });
          }
      }
  }

  render(){
    // global
    var app = this.context;
    var self = this;
    const { classes } = self.props;

    // console.log(self.state.provider);
    return (
      <>

        <div>
          <Grid container spacing={4}>
              {self.props.breadcrumbs &&
                  <Grid item xs={12}>
                  <div className={classes.breadCrumbs}>
                    <Link 
                      onClick={() => app.setState({cmd_consumer:[
                            {
                              action: 'consumers',
                            },
                          ]})
                      }>Клієнти</Link>
                    /
                    <Link 
                      onClick={() => app.setState({cmd_consumer:[
                            {
                              action: 'consumer_info', 
                              consumerId: self.state.consumer.id,
                            },
                          ]})
                      }>{self.state.consumer.name}</Link>
                    /
                    <Link 
                      onClick={() => app.setState({cmd_consumer:[
                            {
                              action: 'contract_info', 
                              consumerId: self.state.consumer.id || 0,
                              consumerContractId: self.state.consumerContractId || 0,
                            },
                          ]})
                      }>Договір {self.state.consumerContract.contract_number}</Link>
                    / Відвантаження
                  </div>
                </Grid>
              }
              {self.props.header &&
                <>
                  <Grid item xs={7}>
                    <h1 className={classes.pageTitle}>Відвантаження договору {self.state.consumerContract.contract_number}</h1>
                  </Grid>
                  <Grid item xs={5}>
                    <div className={classes.topButtons}>
                        <CrmButton
                            classNameMain={classes.submitButton}
                            classNameSattelite={classes.submitButtonSattelite}
                            size="small"
                            onClick={self.handleChangeFactory("all", -1)}
                            >
                            Додати рядок
                        </CrmButton>
                    </div>
                  </Grid>
                </>
              }

          





            <Grid item xs={12}>
              <MessageBlock message={self.state.message} messageType={self.state.messageType}/>
            </Grid>
            <Grid item xs={12}>
              <MUIDataTable
                data={this.state.detail.map(self.mapTable() )}
                columns={self.columns}
                options={{
                  filterType: "checkbox",
                  download:false,
                  print:false,
                  search:false,
                  filter:false,
                  viewColumns:false,
                  selectableRows: "none",
                  textLabels: ApiModel.textLabels
                }}
                className={classes.crmXTable + ' ' + classes.crmXColWidth}
              />
            </Grid>
            {!!self.props.header &&
              <Grid item xs={6}>&nbsp;</Grid>
            }
            {!self.props.header &&
              <Grid item xs={6}>
                <div className={classes.topButtons} style={{"textAlign": "left"}}>
                    <CrmButton
                        classNameMain={classes.submitButton}
                        classNameSattelite={classes.submitButtonSattelite}
                        size="small"
                        onClick={self.handleChangeFactory("all", -1)}
                        >
                        Додати рядок
                    </CrmButton>

                </div>
              </Grid>
            }
            <Grid item xs={6}>
              <div className={classes.shipmentSummary}>
                <div>Борг перед постачальниками за відвантаження: {self.state.shipmentToPay} грн.</div>
                <div>Клієнт винен сплати: {self.state.shipmentToGet} грн.</div>
                <div>Різниця: {self.state.shipmentToGet-self.state.shipmentToPay} грн.</div>
              </div>
            </Grid>



                        

          </Grid>
        </div>


      </>
    );
  }
}
export default withStyles(styles)(ShipmentDetail);

