import React, { Component } from "react";
import {
  // Collapse,
  // Divider,
  List,
  ListItem,
  // ListItemIcon,
  // ListItemText,
  TextField,
  // FormLabel,  
  // FormControlLabel,
  // FormControl,
  Select,
  // Typography,
  Box,
  Button,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import {
  Search as SearchIcon,
} from "@material-ui/icons";
import * as Icons from "@material-ui/icons";
// import { Inbox as InboxIcon } from "@material-ui/icons";
// import { Link } from "react-router-dom";
// import classnames from "classnames";
import {CrmDatePicker} from '../../../../components/CrmDateTimePicker/CrmDateTimePicker';

import { AppContext } from "../../../../context/AppContext";
import ApiModel from "../../../../utils/ApiModel";

// styles
import { withStyles } from "@material-ui/core/styles";
import Themes from "../../../../themes";
import customStyles from "./styles";
var theme = Themes.default;
var styles = customStyles(theme);

class AuctionFilter extends Component {

  static contextType = AppContext;

  constructor(props) {
    super(props);
    if(!this.state) {
      this.state = {
        idProzoro: "",
        edrpou:"",
        auctionUrl:"",
        dateMin:"",
        dateMax:"",
        typePaymentsId:"",
        typeProductId:"",
        companyId:"",
        type_product:[],
        type_payments:[],
        company:[],
        result:"",
        auctionResults:[],
        err_dateMin:false,
        err_dateMax:false,
      };
    }
    // this.setStateProperty = this.setStateProperty.bind(this);

     this.loadCompany = ApiModel.loadCompany(this, "company");
     this.loadAuctionResult = ApiModel.loadAuctionResult(this, "auctionResults");
     this.loadTypeProduct = ApiModel.loadTypeProduct(this, "type_product");
     this.loadTypePayments = ApiModel.loadTypePayments(this, "type_payments");
  }


  updateFactory(attr) {
    var self = this;
    return function(e) {

      var updates=Object.assign({}, self.state);

      if(attr==='dateMin'){
        // console.log('dateMin', e);
        updates[attr] = e.format('YYYY-MM-DD');
      } else if (attr==='dateMax') {
        // console.log('dateMax', e);
        updates[attr] = e.format('YYYY-MM-DD');
      } else {
        updates[attr] = e.target.value
      }
      

      if(!!updates['dateMin'] && !!updates['dateMax']) {
         // both dates are set
         updates['err_dateMin'] = false;
         updates['err_dateMax'] = false;
      } else if( !updates['dateMin'] && !updates['dateMax']) {
         // both dates are NOT set
         updates['err_dateMin'] = false;
         updates['err_dateMax'] = false;
      } else if( !!updates['dateMin'] && !updates['dateMax']) {
         // dateMin is set and dateMax is NOT set
         updates['err_dateMin'] = false;
         updates['err_dateMax'] = true;
      } else if( !updates['dateMin'] && !!updates['dateMax']) {
         // dateMin is NOT set and dateMax is set
         updates['err_dateMin'] = true;
         updates['err_dateMax'] = false;
      }

      self.setState(updates);
    };
  }

  setFilter(upd) { 
    var app = this.context;
    var filter_auction=JSON.parse(JSON.stringify(app.state.filter_auction || {})) || {};
    filter_auction = filter_auction || {};
    for(var k in upd){
      filter_auction[k]=upd[k];
    }
    app.setState({filter_auction:filter_auction});

    this.setState(upd);
  }

  componentDidMount(){
    var self = this;
    var app = this.context;
    self.loadAuctionResult();
    self.loadCompany().finally(function(){
      self.loadTypePayments().finally(function(){
        self.loadTypeProduct().finally(function(){        
          var filter_auction=JSON.parse(JSON.stringify(app.state.filter_auction || {})) || {};
          filter_auction = filter_auction || {};
          self.setState(filter_auction)
        });
      });
    });
  }

  render(){
    var {classes} = this.props;
    // global
    var app = this.context;
    var self= this;


    return (
        <>
          <List className={classes.sidebarList}>

            <ListItem
                className={classes.sidebarListItem}
              >
              <Box  className={classes.sidebarFilterEdrpouRow} >
                <div className={classes.sidebarFilterEdrpou}>
                  <TextField
                    value={self.state.edrpou}
                    onChange={self.updateFactory("edrpou")}
                    margin="normal"
                    placeholder="Найменування / ЄДРПОУ"
                    type="text"
                    fullWidth
                    variant="standard" 
                    className={classes.sidebarFilterText}
                    />
                  </div>
                  <div className={classes.sidebarEdrpouBtn}><SearchIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} /></div>
              </Box>
            </ListItem>

            { app.state.userType === "admin" &&
              <ListItem
                className={classes.sidebarListItem + " button"}
              >
                <Button
                  variant="contained"
                  color="success"
                  size="large"
                  className={classes.greenButtonAdd}
                  onClick={()=> app.setState({cmd_auction:[
                    {action: 'add'},
                  ]})}
                >
                <Icons.AddBox />
                &nbsp;
                Додати аукціон
              </Button>
              </ListItem>
            }
            
            <ListItem
              className={classes.sidebarListItem + " filter"}
            >
              <InputLabel className={classes.sidebarLabel}>ID аукціону</InputLabel>
              <TextField
                value={self.state.idProzoro}
                onChange={self.updateFactory("idProzoro")}
                margin="normal"
                placeholder=""
                type="text"
                fullWidth
                className={classes.sidebarFilterText}
                        />
            </ListItem>

            <ListItem
              className={classes.sidebarListItem + " filter"}
            >
              <InputLabel 
                className={classes.sidebarLabel}
                >URL аукціону</InputLabel>
              <TextField
                value={self.state.auctionUrl}
                onChange={self.updateFactory("auctionUrl")}
                margin="normal"
                placeholder=""
                type="text"
                fullWidth
                className={classes.sidebarFilterText}
                />
            </ListItem>

            <ListItem
              className={classes.sidebarListItem + " filter"}
            >
              <InputLabel className={classes.sidebarLabel}>За результатом</InputLabel>
              <Select
                value={self.state.result}
                onChange={self.updateFactory("result")}
                className={classes.sidebarFilterSelect}
                multiple={false}
              >
                <MenuItem value="" key={'result_X'} >-</MenuItem>
                {self.state.auctionResults.map(function(elm, ind){
                    return <MenuItem key={'result_' + ind} value={elm.id}>{elm.name}</MenuItem>;
                })}
              </Select>
            </ListItem>

            <ListItem
              className={classes.sidebarListItem + " filter"}
            >
              <InputLabel className={classes.sidebarLabel}>За компаніями</InputLabel>
              <Select
                value={self.state.companyId}
                onChange={self.updateFactory("companyId")}
                className={classes.sidebarFilterSelect}
                multiple={false}
              >
                <MenuItem value="" key={'company_id_X'} >-</MenuItem>
                {self.state.company.map(function(elm, ind){
                    return <MenuItem key={'company_id_' + ind} value={elm.id}>{elm.name}</MenuItem>;
                })}
              </Select>
            </ListItem>

            <ListItem
              className={classes.sidebarListItem + " filter"}
            >
              <InputLabel className={classes.sidebarLabel}>Тип платежу</InputLabel>
              <Select
                value={self.state.typePaymentsId}
                onChange={self.updateFactory("typePaymentsId")}
                className={classes.sidebarFilterSelect}
                multiple={false}
              >
                <MenuItem value="" key={'type_payments_id_X'} >-</MenuItem>
                {self.state.type_payments.map(function(elm, ind){
                    return <MenuItem key={'type_paymentstype_payments_id_' + ind} value={elm.id}>{elm.name}</MenuItem>;
                })}
              </Select>
            </ListItem>
            <ListItem
              className={classes.sidebarListItem + " filter"}
            >
              <InputLabel className={classes.sidebarLabel}>Тип товару</InputLabel>
              <Select
                value={self.state.typeProductId}
                onChange={self.updateFactory("typeProductId")}
                className={classes.sidebarFilterSelect}
              >
                <MenuItem value="" key={'type_products_id_X'} >-</MenuItem>
                {self.state.type_product.map(function(elm, ind){
                    return <MenuItem key={'type_products_id_' + ind} value={elm.id}  className={classes.selectOption}>{elm.name}</MenuItem>;
                })}
              </Select>
            </ListItem>

            <ListItem
              className={classes.sidebarListItem + " filter"}
            >
              <InputLabel 
                className={classes.sidebarLabel}
                >За період</InputLabel>

              <CrmDatePicker
                renderInput={(props) => <TextField {...props} />}
                label=""
                value={self.state.dateMin}
                onChange={self.updateFactory("dateMin")}
                margin="dense"
                placeholder="Від дати"
                variant="standard" 
                className={self.state.err_dateMin ? classes.sidebarFilterTextError : classes.sidebarFilterText}
              />
              <br/>
              <CrmDatePicker
                renderInput={(props) => <TextField {...props} />}
                label=""
                value={self.state.dateMax}
                onChange={self.updateFactory("dateMax")}
                margin="dense"
                placeholder="До дати"
                variant="standard" 
                className={self.state.err_dateMax ? classes.sidebarFilterTextError : classes.sidebarFilterText}
              />
            </ListItem>

            <ListItem
              className={classes.sidebarListItem + " button"}
            >
              <Button
                variant="contained"
                className={classes.sidebarFilterButton}
                onClick={e => self.setFilter({
                    idProzoro: self.state.idProzoro,
                    edrpou:self.state.edrpou,
                    auctionUrl:self.state.auctionUrl,
                    companyId:self.state.companyId,
                    dateMin:self.state.dateMin,
                    dateMax:self.state.dateMax,
                    result:self.state.result,
                    typePaymentsId:self.state.typePaymentsId,
                    typeProductId:self.state.typeProductId,
                })}
                >Фільтрувати</Button>
            </ListItem>
            <ListItem
              className={classes.sidebarListItem + " button"}
            >
              <Button 
                variant="contained"
                className={classes.sidebarFilterButton}
                onClick={e => self.setFilter({
                    idProzoro: "",
                    edrpou:"",
                    auctionUrl:"",
                    companyId:"",
                    dateMin:null,
                    dateMax:null,
                    typePaymentsId:"",
                    typeProductId:"",
                    result:"",
                    err_dateMin:false,
                    err_dateMax:false,
                })}
                >Очистити фільтр</Button>
            </ListItem>

          </List>
        </>
        );

  }

}

export default withStyles(styles)(AuctionFilter);

