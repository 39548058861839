import React, {Component}  from "react";
import {
  Grid,
  Popover,
  // LinearProgress,
  // Select,
  // OutlinedInput,
  // MenuItem,
  Button,
} from "@material-ui/core";
// import * as Icons from "@material-ui/icons";
import moment from 'moment';
// components
// import Widget from "../../components/Widget";
// import PageTitle from "../../components/PageTitle";
// import { Typography } from "../../components/Wrappers";
// import Dot from "../../components/Sidebar/components/Dot";
import AuctionInfo from "./components/AuctionInfo/AuctionInfo";
import AuctionResult from "./components/AuctionResult/AuctionResult";
import MUIDataTable from "mui-datatables";

import { AppContext } from "../../context/AppContext";
import ApiModel from "../../utils/ApiModel";
import MessageBlock from '../../utils/MessageBlock';
import {CrmButton} from "../../components/CrmButton/CrmButton";

// styles
import { withStyles } from "@material-ui/core/styles";
import Themes from "../../themes";
import customStyles from "./styles";
const theme = Themes.default;
const styles = customStyles(theme);



class Auction extends Component {

  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.columns = [
      { name: "id",          label: "ID",  options: { filter: false, sort: false, } },
      { name: "id_prozoro",  label: "ID в prozoro", options: { filter: false, sort: false, } },
      // { name: "url_prozoro", label: "url в prozoro",  options: { filter: false, sort: false, } },
      // { name: "company_id",   label: "Компанія",  options: { filter: false, sort: false, } },
      { name: "company_id_view", label: "Компанія",  options: { filter: false, sort: false, } },
      // { name: "type_auction",    label: "Тип аукціону",  options: { filter: false, sort: false, } },
      { name: "type_auction_view",    label: "Тип аукціону",  options: { filter: false, sort: false, } },
      // { name: "type_product",  label: "Тип товару",  options: { filter: false, sort: false, } },
      { name: "type_product_view",  label: "Тип товару",  options: { filter: false, sort: false, } },
      { name: "waiting_total_deal",  label: "Очікується сума",  options: { filter: false, sort: false, } },
      { name: "region",  label: "Регіон",  options: { filter: false, sort: false, } },
      // { name: "propositions_up_to",  label: "Прийом пропозицій до",  options: { filter: false, sort: false, } },
      { name: "propositions_up_to_view",  label: "Прийом пропозицій до",  options: { filter: false, sort: false, } },
      // { name: "date_auction",  label: "Дата аукціону",  options: { filter: false, sort: false, } },
      { name: "date_auction_view",  label: "Дата аукціону",  options: { filter: false, sort: false, } },
      { name: "member_price",  label: "Вартість участі",  options: { filter: false, sort: false, } },
      // { name: "personal_id",  label: "Завдання для співробітника",  options: { filter: false, sort: false, } },
      { name: "personal_id_view",  label: "Завдання для співробітника",  options: { filter: false, sort: false, } },
      // { name: "comments",  label: "Коментар до завдання",  options: { filter: false, sort: false, } },
      // { name: "type_task",  label: "Тип завдання",  options: { filter: false, sort: false, } },
      { name: "type_task_view",  label: "Тип завдання",  options: { filter: false, sort: false, } },
      //{ name: "deadline",  label: "Термін виконання завдання",  options: { filter: false, sort: false, } },
      { name: "deadline_view",  label: "Термін виконання завдання",  options: { filter: false, sort: false, } },
      // { name: "status_play",  label: "Приймаємо участь",  options: { filter: false, sort: false, } },
      { name: "status_play_view",  label: "Приймаємо участь",  options: { filter: false, sort: false, } },
      { name: "comment_not_play",  label: "Причина не участі",  options: { filter: false, sort: false, } },
      { name: "contractor_name",  label: "Контрагент", options: { filter: false, sort: false, } },
      { name: "contractor_okpo",  label: "ЄДРПОУ", options: { filter: false, sort: false, } },
      // { name: "result",  label: "Результат",  options: { filter: false, sort: false, } },
      { name: "result_view",  label: "Результат",  options: { filter: false, sort: false, } },
      { name: "action",  label: "Дії",  options: { filter: false, sort: false, } },
    ];


    if(!this.state) {
      this.state = {
        auctionId: 0,
        auctionProzoroId: 0,
        auction: [],
        forceResult:'',
        popupFormVisible:false,

        company_map:{},
        type_payments_map:{},
        type_product_map:{},
        personal_map:{},
        type_task_map:{},
        auction_result_map:{},

        message: "",
        messageType: "",
      };
    }
    this.filterHash = JSON.stringify(this.props.filter);
    this.closePopupForm = this.closePopupForm.bind(this);
    this.loadAuction = this.loadAuction.bind(this);
    

    this.loadCompanyMap  = ApiModel.loadCompanyMap(this, "company_map");
    this.loadTypePaymentsMap  = ApiModel.loadTypePaymentsMap(this, "type_payments_map");
    this.loadTypeProductMap  = ApiModel.loadTypeProductMap(this, "type_product_map");
    this.loadPersonalMap  = ApiModel.loadPersonalMap(this, "personal_map");
    this.loadTaskTypeMap  = ApiModel.loadTaskTypeMap(this,"type_task_map");
    this.loadAuctionResultMap  = ApiModel.loadAuctionResultMap(this, "auction_result_map");
  }

  componentDidUpdate(){
    // var app = this.context;
    // var filter = this.props.filter ;
    // console.log("Auction componentDidUpdate")
    // console.log("receiver filter", filter);

    this.processCmd();

    var newFilterHash = JSON.stringify(this.props.filter);
    if(newFilterHash !==  this.filterHash) {
      this.filterHash = newFilterHash;
      this.loadAuction();
    }
  }

  componentDidMount(){
    var app = this.context;
    var self = this;
    self.loadAuctionResultMap();
    self.loadAuction().finally(function(){
      self.loadCompanyMap().finally(function(){
        self.loadTypePaymentsMap().finally(function(){
          self.loadTypeProductMap().finally(function(){
            self.loadPersonalMap().finally(function(){
              self.loadTaskTypeMap().finally(function(){
                // var popupFormVisible = self.props.cmd && self.props.cmd[0]==='add';
                app.setState({
                  sidebarMenuId:"auction",
                  //   popupFormVisible:popupFormVisible,
                });
              });
            });
          });
        });
      });
    });
  }

  processCmd(){
    var self =this;
    var app = self.context;
    var onError = ApiModel.onError(self);
    if(self.props.cmd.length > 0){
       for(let i=0; i<self.props.cmd.length; i++) {
          let cmd = self.props.cmd[i];

          switch(cmd.action) {
            case "add":
              self.setState({
                popupFormVisible:true,
                auctionId:0,
                auctionProzoroId: "",
              });
            break;

            case "lost":
              self.setState({
                resultFormVisible:true,
                auctionId: cmd.auctionId,
                auctionProzoroId: cmd.auctionProzoroId,
              });
            break;

            case "not_play":
              self.setState({
                resultFormVisible:true,
                auctionId: cmd.auctionId,
                auctionProzoroId: cmd.auctionProzoroId,
                forceResult: "not_play",
              });
            break;


            case "won":
              let postData = {
                    id:parseInt(cmd.auctionId) || 0, //  bigint(20)
                    id_prozoro: cmd.auctionProzoroId || "",
                    result:'won',             //  text
              };
              // console.log('updating', postData);
              app.postData(
                  '/auction/result',
                  [postData]
              ).then(function(responseJson){
                // console.log("responseJson", responseJson, responseJson["status"]);
                if(responseJson["status"] === "OK") {
                  self.setState({
                    "message": "Дані оновлено",
                    "messageType": "success"
                  });
                } else if(responseJson["status"] === "FORBIDDEN") {
                  var errorMsg = [];
                  errorMsg.push("За даним аукціоном завершені не всі завдання !");
                  if(responseJson["unclosed_tasks"] && responseJson["unclosed_tasks"].length) {
                    errorMsg.push("Будь ласка завершите завдання за номерами:") ;
                    for(let i=0; i<responseJson["unclosed_tasks"].length; i++){
                      errorMsg.push(responseJson["unclosed_tasks"][i] + ";");
                    }
                  }
                  // self.setState({
                  //   "message": errorMsg,
                  //   "messageType": "error"
                  // });
                  throw errorMsg;
                } else if(responseJson["status"] === "EXPECTATION_FAILED") {
                  // onError("Не вдалося зберегти дані");
                  throw "Не вдалося зберегти дані";
                } else {
                  // onError(responseJson["comment"]);
                  throw responseJson["comment"] || "Не вдалося зберегти дані";
                }
              }).then(
                ()=>self.loadAuction()
              ).then(function(){
                app.setState({cmd_consumer:[
                  {
                    action: 'from_auction', 
                    "auctionId": cmd.auctionId, 
                    "auctionProzoroId": cmd.auctionProzoroId,
                  },
                ]});
                console.log({
                    action: 'from_auction', 
                    auctionId: cmd.auctionId, 
                    auctionProzoroId: cmd.auctionProzoroId,
                });
                window.location.hash="/app/consumer";
              }).catch((reason) => onError( ("" + reason) ||"Невідома помилка"));
            break;

            case "refresh":
            case "reload":
              self.loadAuction();
            break;

            case "refreshDelayed":
            case "reloadDelayed":
              if(self.reloadTimeout) {
                window.clearTimeout(self.reloadTimeout);
              }
              self.reloadTimeout = window.setTimeout(self.loadAuction, 1000);
            break;


            case "closePopup":
              self.closePopupForm();
            break;

            default:
            break;
          }
       }
       app.setState( { cmd_auction:[] });
    }
  }


  loadAuction(){
    var app = this.context;
    var self = this;
    var onError = ApiModel.onError(self);

    var filter = this.props.filter ;
    var param = {};
    if(filter.edrpou) param["edrpou"] = filter.edrpou;
    if(filter.idProzoro) param["id_prozoro"] = filter.idProzoro;
    if(filter.auctionUrl) param["auction_url"] = filter.auctionUrl;
    if(filter.result) param["result"] = filter.result;
    if(filter.companyId) param["company_id"] = filter.companyId;
    if(filter.typePaymentsId) param["type_auction"] = filter.typePaymentsId;
    if(filter.typeProductId) param["type_product"] = filter.typeProductId;

    // if(filter.dateMin) param["date_min"] = filter.dateMin;
    // if(filter.dateMax) param["date_max"] = filter.dateMax;
    if(!!filter.dateMin && !!filter.dateMax) {
      param["period"]=[filter.dateMin, filter.dateMax];
    }

    if(app.state.userType !== "admin") {
      param["personal_id"] = app.state.personal_id;
    }

    if(Object.keys(param).length > 0) {
        param = [param];
    } else {
        param = [];
    }
    return app.postData(
        '/auction/get',
        {"param": param}
    ).then(function(responseJson){
      // console.log("responseJson", responseJson, responseJson["status"]);
      if(responseJson["status"] === "OK") {
        self.setState({
          "auction": responseJson["detail"], 
          "message": "",
          "messageType": "success" 
        });
      } else if(responseJson["status"] === "NOT_FOUND") {
        self.setState({
          "auction": [], 
          "message": "Дані не знайдено, очистіть фільтр та спробуйте ще раз",
          "messageType": "error"
        });
      } else {
        onError(responseJson["comment"]);
      }
    }).catch(() => onError("Не вдалося завантажити аукціон"));
  }

  closePopupForm(){
    // var app = this.context;
    this.setState({
      popupFormVisible:false,
      resultFormVisible:false,
      auctionId: 0,
      auctionProzoroId: 0,
      forceResult:'',
    });
  }

  mapTable(){
    var self=this;
    var app = self.context;
    const { classes } = self.props;
    return function(row, ind){
      var elm =  JSON.parse(JSON.stringify(row));

      elm.id = elm.id || elm.auction_id;

      if(self.state.company_map[elm['company_id']]){
        let c =  self.state.company_map[elm['company_id']];
        elm['company_id_view'] =  c.name;
      }else{
        elm['company_id_view'] =  elm['company_id'];
      }

      if(self.state.type_payments_map[elm['type_auction']]) {
        elm['type_auction_view'] =  self.state.type_payments_map[elm['type_auction']].name;
      } else {
        elm['type_auction_view'] =  elm['type_auction'];
      }
      
      if(self.state.type_product_map[elm['type_product']]){
        elm['type_product_view'] =  self.state.type_product_map[elm['type_product']].name;
      } else {
        elm['type_product_view'] =  elm['type_product'];
      }

      // if(self.state.personal_map[elm['personal_id']]){
      //   let p = self.state.personal_map[elm['personal_id']];
      //   elm['personal_id_view'] = (<div>{p.fname} {p.sname} {p.lname}</div>)
      // } else {
      //   elm['personal_id_view'] = elm['personal_id'];
      // }
      
      elm['personal_id_view'] = [];
      for(let ix = 0; ix < elm['task_to_aukcion'].length; ix++){
        let personal_id = elm['task_to_aukcion'][ix].personal_id;
        // console.log("personal_id", personal_id);
        if(self.state.personal_map[personal_id]){
          let p = self.state.personal_map[personal_id];
          elm['personal_id_view'].push(<div key={"auction_" + elm.id + "_personal_" + personal_id}>&minus;&nbsp;{p.fname} {p.sname} {p.lname}</div>)
        } else {
          elm['personal_id_view'].push(<div key={"auction_" + elm.id + "_personal_" + personal_id}>&minus;&nbsp;{personal_id}</div>);
        }
      }

      // if(self.state.type_task_map[elm['type_task']]){
      //   elm['type_task_view'] =  self.state.type_task_map[elm['type_task']].name;
      // } else{
      //   elm['type_task_view'] =  elm['type_task'];
      // }
      elm['type_task_view'] = [];
      for(let ix = 0; ix < elm['task_to_aukcion'].length; ix++){
        let type_task = elm['task_to_aukcion'][ix].type_task;
        let personal_id = elm['task_to_aukcion'][ix].personal_id;
        //console.log("type_task", type_task);
        if(self.state.type_task_map[type_task]){
          let p = self.state.type_task_map[type_task];
          elm['type_task_view'].push(<div key={"auction_" + elm.id + "_personal_" + personal_id+"_task"}>&minus;&nbsp;{p.name}</div>)
        } else {
          elm['type_task_view'].push(<div key={"auction_" + elm.id + "_personal_" + personal_id+"_task"}>&minus;&nbsp;{type_task}</div>);
        }
      }

      if(self.state.auction_result_map[elm['result']]){
        elm['result_view'] =  self.state.auction_result_map[elm['result']];
      } else{
        elm['result_view'] =  elm['result'];
      }

      try{
        elm['propositions_up_to_view'] = moment(elm['propositions_up_to']).format('DD.MM.YYYY  HH:mm');
      } catch (e) {
        elm['propositions_up_to_view'] = elm['propositions_up_to'];
      }
      
      try{
        elm['date_auction_view'] = moment(elm['date_auction']).format('DD.MM.YYYY  HH:mm');
      } catch (e) {
        elm['date_auction_view'] = elm['date_auction'];
      }
      try{
        elm['deadline_view'] = moment(elm['deadline']).format('DD.MM.YYYY  HH:mm');
      } catch (e) {
        elm['deadline_view'] = elm['deadline'];
      }

      elm['status_play_view'] =  elm['status_play'] ? "Так" : "Ні";
      // console.log(elm);

      elm['action'] = (<>
        <div className={classes.btnGroup}>
          <CrmButton
              classNameMain={classes.rowActionButton}
              classNameSattelite={classes.rowActionButtonSattelite}
              size="small"
              onClick={() => self.setState({
                auctionId: elm.id || elm.auction_id,
                auctionProzoroId: elm.id_prozoro,
                popupFormVisible:true,
            })}
            >
            Переглянути
          </CrmButton>
        </div>
        { !elm.result &&
          (<div className={classes.btnGroup}>
            <CrmButton
                classNameMain={classes.rowActionButton}
                classNameSattelite={classes.rowActionButtonSattelite}
                size="small"
                onClick={() =>  app.setState( { cmd_auction:[
                  {action: "won", auctionId: elm.id, auctionProzoroId: elm.id_prozoro}
                ] })}
              >
              Перемога
            </CrmButton>
          </div>)
        }
        { !elm.result &&
            (<div className={classes.btnGroup}>
            <CrmButton
                classNameMain={classes.rowActionButton}
                classNameSattelite={classes.rowActionButtonSattelite}
                size="small"
                onClick={() =>  app.setState( { cmd_auction:[
                  {action: "lost", auctionId: elm.id, auctionProzoroId: elm.id_prozoro}
                ] })}
              >
              Поразка
            </CrmButton>
            </div>)
        }
        { !elm.result &&
            (<div className={classes.btnGroup}>
            <CrmButton
                classNameMain={classes.rowActionButton}
                classNameSattelite={classes.rowActionButtonSattelite}
                size="small"
                onClick={() =>  app.setState( { cmd_auction:[
                  {action: "not_play", auctionId: elm.id, auctionProzoroId: elm.id_prozoro}
                ] })}
              >
              Не приймаємо участі
            </CrmButton>
            </div>)
        }
          <div className={classes.btnGroup}>
            <CrmButton
                classNameMain={classes.rowActionButton}
                classNameSattelite={classes.rowActionButtonSattelite}
                size="small"
                onClick={() =>  {
                  app.setState( { cmd_task:[
                    {action: "add", auction_id: elm.id, contractor_okpo: elm.contractor_okpo}
                  ] });
                  window.location.hash="/app/task";
                }}
              >
              Завдання для юриста
            </CrmButton>
          </div>
      </>);
      return  elm;
    }
  }

  render(){
    // global
    // var app = this.context;
    // var filter = this.props.filter ;
    // console.log("receiver filter", filter);
    var self = this;
    const { classes } = self.props;
    return (
        <>
        <Grid container spacing={4}>
          {self.state.message && (<Grid item xs={12}>
            <MessageBlock message={self.state.message} messageType={self.state.messageType}/>
          </Grid>)}
          <Grid item xs={12}>
              <MUIDataTable
                data={this.state.auction.map( self.mapTable() )}
                columns={self.columns}
                options={{
                  filterType: "checkbox",
                  download:false,
                  print:false,
                  search:false,
                  filter:false,
                  viewColumns:true,
                  selectableRows: "none",
                  textLabels: ApiModel.textLabels
                }}
                className={classes.crmXTable + ' ' + classes.crmXColWidth}
              />
          </Grid>
        </Grid>
        {self.state.popupFormVisible && (
          <Popover
            id="popover"
            open={self.state.popupFormVisible}
            anchorEl={document.getElementById('root')}
            // onClose={self.closePopupForm}
            className={classes.popOver}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <AuctionInfo auctionId={self.state.auctionId} 
                        auctionProzoroId={self.state.auctionProzoroId}
                        parent={this}/>
          </Popover>
        )}
        {self.state.resultFormVisible && (
          <Popover
            id="popover"
            open={self.state.resultFormVisible}
            anchorEl={document.getElementById('root')}
            // onClose={self.closePopupForm}
            className={classes.popOverMini}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <AuctionResult auctionId={self.state.auctionId} 
                        auctionProzoroId={self.state.auctionProzoroId}
                        forceResult={self.state.forceResult}
                        parent={this}/>
          </Popover>
        )}

        

        </>
      );

  }

}
export default withStyles(styles)(Auction);

