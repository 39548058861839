import React, {Component}  from "react";
import {
  Grid,
  Link,
  Select,
  MenuItem,
  Button,
  TextField,
  // Popover
} from "@material-ui/core";
// import moment from 'moment';

// components
// import { Typography } from "../../../../components/Wrappers";
import MUIDataTable from "mui-datatables";

// context
import { AppContext } from "../../../../context/AppContext";
import ApiModel from "../../../../utils/ApiModel";
// import validators   from '../../../../utils/validators';
import MessageBlock from '../../../../utils/MessageBlock';
import {CrmButton} from "../../../../components/CrmButton/CrmButton";
// styles
import { withStyles } from "@material-ui/core/styles";
import Themes from "../../../../themes";
import customStyles from "./styles";
// import { Alarm } from "@material-ui/icons";
const theme = Themes.default;
const styles = customStyles(theme);

class ConsumerContractSpecs extends Component {

  static contextType = AppContext;

  constructor(props) {
    super(props);
    var self = this;

    self.columns=[
      //  { name: "id", label: "ID", options: { filter: false, sort: false, } },
      // { name: "consumer_contract_id", label: "Договір", options: { filter: false, sort: true, } },
      // { name: "product_id", label: "Товар", options: { filter: false, sort: false, } },
      { name: "input_product_id", label: "Товар", options: { filter: false, sort: false, } },
      // { name: "unit_id", label: "Одиниці",  options: { filter: false, sort: false, } },
      { name: "input_unit_id", label: "Одиниці",  options: { filter: false, sort: false, } },
      // { name: "count", label: "Кількість", options: { filter: false, sort: false, } },
      { name: "input_count", label: "Кількість", options: { filter: false, sort: false, } },
      // { name: "price_one", label: "Ціна за одиницю", options: { filter: false, sort: false, } },
      { name: "input_price_one", label: "Ціна за одиницю", options: { filter: false, sort: false, } },
      { name: "total", label: "Всього", options: { filter: false, sort: false, } },
      { name: "action", label: "Дії", options: { filter: false, sort: false, }  },

    ];
    if(!self.state) {
      self.state = {
          consumerId:"",
          consumer:{},

          consumerContractId:"",
          consumerContract:{},

          specification:[],

          message:"",
          messageType:"",

          products:[],
          units:[],
      };
    }

    self.validator={
      "consumer_contract_id":ApiModel.atomicValidator.isPositiveInteger,
      "product_id":ApiModel.atomicValidator.isPositiveInteger,
      "unit_id":ApiModel.atomicValidator.isPositiveInteger,
      "count": ApiModel.atomicValidator.isPositiveFloat,
      "price_one": ApiModel.atomicValidator.isPositiveFloat,
      "total": ApiModel.atomicValidator.isPositiveInteger,
    };
    self.mapTable = self.mapTable.bind(self);

    self.validate = ApiModel.validate(self).bind(self);
    self.onError = ApiModel.onError(self).bind(self);
    self.loadOneConsumer = ApiModel.loadOneConsumer(self, "consumer", self.onError);
    self.loadOneConsumerContract = ApiModel.loadOneConsumerContract(self, "consumerContract", self.onError);
    self.loadProducts  = ApiModel.loadProducts(self, "products", self.onError);
    self.loadUnits  = ApiModel.loadUnits(self, "units", self.onError);
  }

  componentDidMount(){
    // var app = this.context;
    var self = this;
    this.setState({
      consumerId: this.props.consumerId,
      consumerContractId: this.props.consumerContractId,
    });
    self.loadOneConsumer(this.props.consumerId).finally(function(){
      self.loadOneConsumerContract(this.props.consumerContractId).finally(function(){
        self.loadSpecification().finally(function(){
          self.loadUnits().finally(function(){
            self.loadProducts();        
          });
        });
      });
    });
  }

  componentDidUpdate(){
    console.log("componentDidUpdate()");
    if(this.props.consumerId !==  this.state.consumerId 
       || this.props.consumerContractId !==  this.state.consumerContractId) {
      this.setState({
        consumerId: this.props.consumerId,
        consumerContractId: this.props.consumerContractId,
      });
      var self = this;
      self.loadOneConsumer(this.props.consumerId).finally(function(){
        self.loadOneConsumerContract(this.props.consumerContractId).finally(function(){
          self.loadSpecification();
        });
      });
    }    
  }

  loadSpecification(){
    var self = this;
    var app = self.context;
    var data = {"param": [{"consumer_contract_id": self.props.consumerContractId}]};
    return app.postData(
      '/consumers/contracts/specification/get',
      data
    ).then(function(responseJson){
      // console.log("responseJson", responseJson, responseJson["status"]);
      if(responseJson["status"] === "OK") {
        let data = responseJson["detail"];            
        self.setState({
          "specification": data,
        });
      } else if(responseJson["status"] === "NOT_FOUND") {
        self.props.app.setState({
          "message": responseJson["comment"],
          "messageType": "error"
        });
      } else {
        self.onError(responseJson["comment"]);
      }
    }).catch(() => self.onError("Не вдалося завантажити спеціфікацію"));
  }

  mapTable(){
    var self=this;
    const { classes } = self.props;
    return function(row, ind){
      var elm =  JSON.parse(JSON.stringify(row));
      elm['input_product_id'] = (
          <Select
                value={self.state.specification[ind]["product_id"]}
                onChange={self.handleChangeFactory("product_id", ind)}
                className={elm.error_product_id ? classes.listSelectFieldErr : classes.listSelectField}
              >
                <MenuItem value="" key={'product_id_X'} >-</MenuItem>
                {self.state.products.map(function(elm, ind){
                    return <MenuItem key={'product_id_' + ind} value={elm.id}  className={classes.listSelectOption}>{elm.name}</MenuItem>;
                })}
          </Select>
      );
      elm['input_unit_id'] = (
          <Select
                value={self.state.specification[ind]["unit_id"]}
                onChange={self.handleChangeFactory("unit_id", ind)}
                className={elm.error_product_id ? classes.listSelectFieldErr : classes.listSelectField}
              >
                <MenuItem value="" key={'unit_id_X'} >-</MenuItem>
                {self.state.units.map(function(elm, ind){
                    return <MenuItem key={'unit_id_' + ind} value={elm.id}  className={classes.listSelectOption}>{elm.name}</MenuItem>;
                })}
          </Select>
      );
      elm['input_count'] = (
          <TextField
            value={self.state.specification[ind]["count"]}
            onChange={self.handleChangeFactory("count", ind)}
            margin="normal"
            placeholder="Кількість"
            type="text"
            fullWidth
            variant="standard" 
            className={elm.error_count ? classes.tableTextFieldErr : classes.tableTextField}
            />
      );
      elm['input_price_one'] = (
          <TextField
            value={self.state.specification[ind]["price_one"]}
            onChange={self.handleChangeFactory("price_one", ind)}
            margin="normal"
            placeholder="Кількість"
            type="text"
            fullWidth
            variant="standard" 
            className={elm.error_price_one ? classes.tableTextFieldErr : classes.tableTextField}
            />
      );
      elm['action'] = (<div className={classes.btnGroup}>
                          <Button
                            variant="contained"
                            color="success"
                            size="large"
                            className={classes.rowActionButton}
                            onClick={self.handleDeleteFactory(ind)}
                          >
                          Видалити
                        </Button>
                          <Button
                            variant="contained"
                            color="success"
                            size="large"
                            className={classes.rowActionButtonSattelite}
                            onClick={self.handleDeleteFactory(ind)}
                          >
                          &gt;
                        </Button>
                        </div>);
      return  elm;
    }
  }

  handleChangeFactory(attributeName, ind) {
      var self = this;
      return function(event) {
        event.preventDefault();
        // deep copy of the data
        var specification = JSON.parse(JSON.stringify(self.state.specification));

        if(ind<0) {
            // add new row to end
            // console.log(moment(), moment().format("YYYY-MM-DD"));
            var newRow = {
                id:0,
                consumer_contract_id: self.props.consumerContractId,
                count: "",
                product_id: "",
                unit_id: "",
                price_one: "",
                total: "",
                error_count:1,
                error_product_id:1,
                error_unit_id:1,
                error_price_one:1
            };
            specification.push(newRow);
            self.setState({
                "specification": specification
            });
        } else {
            // update price if validated
            var p = specification[ind];
            
            p[attributeName] = event.target.value;

            try{
              p.total = p.price_one * p.count;
            } catch(e){
            }
            
            var errorExists = self.validate(p);
            
            self.setState({
                "specification": specification
            });

            // post the updated data
            if(!errorExists) {
                if(self.saveTimeout) {
                    window.clearTimeout(self.saveTimeout);
                }
                self.saveTimeout = window.setTimeout(function(){
                    self.saveOneRow(p);
                }, 2000);
            }
        }
      }
  }

  validate(record){
    var self = this;
    var errorExists = false;
    [
      "count",
      "price_one",
      "product_id",
      "total",
      "unit_id"
    ].map(function(elm) {
          var error;
          if(self.validator[elm]) {
            error  = self.validator[elm](record[elm]);
            if (error){
              errorExists = true;
            }
          } else {
            error = "";
          }
          record["error_" + elm] = error;
          return elm;
    });

    return errorExists;
  }

  saveOneRow(p){
      var self=this;
      var app = this.context;
      var onError = function(errorMessage) {
          self.setState({
          "message":errorMessage,
          "messageType":"error",
          });
      };
      let postData = {
          id: parseInt(p["id"]),
          contract_id: p["contract_id"],
          product_id: parseInt(p["product_id"]),
          unit_id: parseInt(p["unit_id"]),
          count: parseInt(p["count"]),
          price_one: parseFloat(p["price_one"]),
          total: parseFloat(p["total"]),
      };
      if (postData.id) {
          // post the UPDATE

          app.postData(
              '/consumers/contracts/specification/edit',
              [postData]
          ).then(function(responseJson){
          if(responseJson["status"] === "OK") {
              self.setState({
                  "message": "Дані оновлено",
                  "messageType": "success"
              });
              self.loadSpecification(false);
          } else if(responseJson["status"] === "EXPECTATION_FAILED") {
              onError("Не вдалося зберегти дані");
          } else {
              onError(responseJson["comment"]);
          }
          }).catch(() => onError("Невідома помилка"));
      } else {
          // post the ADD
          app.postData(
              '/consumers/contracts/specification/add',
              [postData]
          ).then(function(responseJson){
              if(responseJson["status"] === "OK") {
                  self.setState({
                      "message": "Рядок додано",
                      "messageType": "success"
                  });
                  self.loadSpecification(false);
              } else if(responseJson["status"] === "EXPECTATION_FAILED") {
                  onError("Не вдалося зберегти дані");
              } else {
                  onError(responseJson["comment"]);
              }
          }).catch(() => onError("Невідома помилка"));
      }
  }

  handleDeleteFactory(ind) {
      var self = this;
      var app = this.context;
      return function(event) {
          event.preventDefault();

          var id = self.state.specification[ind].id;

          if(id) {
              var onError = function(errorMessage) {
                  self.setState({
                      "message":errorMessage,
                      "messageType":"error",
                  });
              };
              var postData = [id];
              // post the DELETE command
              app.postData(
                    '/provider_contract_specification/delete',
                    postData
                  ).then(function(responseJson){
                      if(responseJson["status"] === "OK") {
                          self.setState({
                              "message": "Дані оновлено",
                              "messageType": "success"
                          });
                          self.loadSpecification(false);
                      } else if(responseJson["status"] === "EXPECTATION_FAILED") {
                          onError("Не вдалося зберегти дані");
                      } else {
                          onError(responseJson["comment"]);
                      }
                  }).catch(() => onError("Невідома помилка"));
          } else {
              var specification = JSON.parse(JSON.stringify(self.state.specification));
              specification.splice(ind, 1);
              self.setState({
                  "specification": specification
              });
          }
      }
  }

  render(){
    // global
    var app = this.context;
    var self = this;
    const { classes } = self.props;

    // console.log(self.state.provider);
    return (
      <>

      <div>
        <Grid container spacing={4}>
            <Grid item xs={12}>
              <div className={classes.breadCrumbs}>
                <Link href="javascript:void(1)"
                  onClick={() => app.setState({cmd_consumer:[
                        {
                          action: 'consumers',
                        },
                      ]})
                  }>Клієнти</Link>
                /
                <Link href="javascript:void(1)"
                  onClick={() => app.setState({cmd_consumer:[
                        {
                          action: 'consumer_info', 
                          "consumerId": self.state.consumer.id,
                        },
                      ]})
                  }>{self.state.consumer.name}</Link>
                /
                <Link href="javascript:void(1)"
                  onClick={() => app.setState({cmd_consumer:[
                        {
                          action: 'contracts', 
                          "consumerId": self.state.consumer.id || 0,
                        },
                      ]})
                  }>Договори</Link>
                /
                <Link href="javascript:void(1)"
                  onClick={() => app.setState({cmd_consumer:[
                        {
                          action: 'contract_info', 
                          "consumerId": self.state.consumer.id || 0,
                          consumerContractId: self.state.consumerContractId || 0,
                        },
                      ]})
                  }>Договір {self.state.consumerContract.contract_number}</Link>
                / Специфікація
              </div>
            </Grid>
         
          <Grid item xs={7}>
            <h1 className={classes.pageTitle}>Спеціфікація договору {self.state.consumerContract.contract_number}</h1>
          </Grid>
          <Grid item xs={5}>
            <div className={classes.topButtons}>
                <CrmButton
                    classNameMain={classes.submitButton}
                    classNameSattelite={classes.submitButtonSattelite}
                    size="small"
                    onClick={self.handleChangeFactory("all", -1)}
                    >
                    Додати рядок
                </CrmButton>
            </div>
          </Grid>
          <Grid item xs={12}>
            <MessageBlock message={self.state.message} messageType={self.state.messageType}/>
          </Grid>
          <Grid item xs={12}>
            <MUIDataTable
              data={this.state.specification.map( self.mapTable() )}
              columns={self.columns}
              options={{
                filterType: "checkbox",
                download:false,
                print:false,
                search:false,
                filter:false,
                viewColumns:false,
                selectableRows: "none",
                textLabels: ApiModel.textLabels
              }}
              className={classes.crmXTable + ' ' + classes.crmXColWidth}
            />
          </Grid>
        </Grid>
      </div>


      </>
    );
  }
}
export default withStyles(styles)(ConsumerContractSpecs);

