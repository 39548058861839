import React, {Component}  from "react";
import {
  Grid,
  // Button,
  // Popover,
  Link
} from "@material-ui/core";

import moment from 'moment';

// components
import MUIDataTable from "mui-datatables";


import { AppContext } from "../../../../context/AppContext";
import ApiModel from "../../../../utils/ApiModel";

import {CrmButton} from "../../../../components/CrmButton/CrmButton";

// styles
import { withStyles } from "@material-ui/core/styles";
import Themes from "../../../../themes";
import customStyles from "./styles";
const theme = Themes.default;
const styles = customStyles(theme);



class Shipments extends Component {

  static contextType = AppContext;

  constructor(props) {
    super(props);

    // id                    bigint      (NULL)     NO      PRI     (NULL)   auto_increment  select,insert,update,references           
    // consumer_id           bigint      (NULL)     NO      MUL     (NULL)                   select,insert,update,references           
    // consumer_contract_id  bigint      (NULL)     NO      MUL     (NULL)                   select,insert,update,references           
    // emplyoes_id           bigint      (NULL)     NO      MUL     (NULL)                   select,insert,update,references           
    // summary               float       (NULL)     NO              (NULL)                   select,insert,update,references           
    // create_day            datetime    (NULL)     NO              (NULL)                   select,insert,update,references           
    // approved              tinyint(1)  (NULL)     YES             (NULL)                   select,insert,update,references           

    this.columns=[
      { name: "id",      label: "id",      options: { filter: false, sort: false, }   },
      // { name: "consumer_id",  label: "Відповідальний",     options: { filter: false, sort: false, }   },
      // { name: "consumer_contract_id",  label: "Відповідальний",     options: { filter: false, sort: false, }   },
      // { name: "emplyoes_id",  label: "Відповідальний",     options: { filter: false, sort: false, }   },
      { name: "emplyoes_id_view",  label: "Відповідальний",     options: { filter: false, sort: false, }   },
      { name: "summary", label: "Сума", options: { filter: false, sort: false, }   },
      //  { name: "create_day",      label: "Дата", options: { filter: false, sort: false, }   },
      { name: "create_day_view",      label: "Дата створення", options: { filter: false, sort: false, }   },
      //  { name: "approved",      label: "Дата", options: { filter: false, sort: false, }   },
      { name: "approved_view",      label: "Дата схвалення", options: { filter: false, sort: false, }   },
    ];

    if(!this.state) {
      this.state = {
          consumer:{},
          consumerId: null,

          consumerContract:{},
          consumerContractId:null,

          shipments:[],

          personal_map:{},

          message:"",
          messageType:"",
      };
    }
    this.onError =  ApiModel.onError(this).bind(this);
    this.loadOneConsumer = ApiModel.loadOneConsumer(this, "consumer", this.onError).bind(this);
    this.loadOneConsumerContract  = ApiModel.loadOneConsumerContract(this, "consumerContract", this.onError);    

    this.loadShipments  = ApiModel.loadShipments(this, "shipments", this.onError);
    this.loadPersonalMap  = ApiModel.loadPersonalMap(this, "personal_map");
    // this.loadDeliveriesMap  = ApiModel.loadDeliveriesMap(this, "deliveries_map", this.onError);
  }

  componentDidUpdate(){
    // var app = this.context;
    var self = this;

    if(this.props.consumerId !==  this.state.consumerId
       || this.props.consumerContractId !==  this.state.consumerContractId) {
        self.setState({
            consumerId: this.props.consumerId,
            consumerContractId: this.props.consumerContractId,
        });
        self.loadOneConsumer(self.props.consumerId).finally(function(){
          self.loadOneConsumerContract(self.props.consumerId, self.props.consumerContractId).finally(function(){
            self.loadShipments(self.props.consumerContractId);
          });
        });
    }
  }

  componentDidMount(){
    var self = this;
    // var app = self.context;
    self.setState({
        consumerId: this.props.consumerId,
        consumerContractId: this.props.consumerContractId,
    });
    self.loadOneConsumer(self.props.consumerId).finally(function(){
      self.loadOneConsumerContract(self.props.consumerId, self.props.consumerContractId).finally(function(){
        self.loadShipments(self.props.consumerContractId).finally(function(){
          self.loadPersonalMap();
        });
      });
    });
  }


  mapTable(){
    var self=this;
    var app = self.context;
    const { classes } = self.props;
    return function(row, ind){
      var elm =  JSON.parse(JSON.stringify(row));

      if(self.state.personal_map[elm['emplyoes_id']]){
        let p = self.state.personal_map[elm['emplyoes_id']];
        elm['emplyoes_id_view'] = (<div>{p.fname} {p.sname} {p.lname}</div>)
      } else {
        elm['emplyoes_id_view'] = elm['emplyoes_id'];
      }

      try{
        elm['create_day_view'] = moment(elm['create_day']).format('DD.MM.YYYY  HH:mm');
      } catch (e) {
        elm['create_day_view'] = elm['create_day'];
      }

      elm['approved_view'] = !!elm['approved'] ? "Так" : "Ні";

      elm['action'] = (<>
        <div className={classes.btnGroup}>
          <CrmButton
              classNameMain={classes.rowActionButton}
              classNameSattelite={classes.rowActionButtonSattelite}
              size="small"
              onClick={
                () => app.setState( { cmd_consumer:[
                      {
                        action: "shipment_info",
                        consumerId: self.props.consumerId || 0,
                        consumerContractId: self.props.consumerContractId || 0,
                        shipmentId: elm.id || 0,
                      }
                    ]})
              }
            >
            Переглянути
          </CrmButton>
          <CrmButton
              classNameMain={classes.rowActionButton}
              classNameSattelite={classes.rowActionButtonSattelite}
              size="small"
              onClick={
                () => app.setState( { cmd_consumer:[
                      {
                        action: "shipment_approve",
                        consumerId: self.props.consumerId || 0,
                        consumerContractId: self.props.consumerContractId || 0,
                        shipmentId: elm.id || 0,
                      }
                    ]})
              }
            >
            Схвалити
          </CrmButton>
        </div>
      </>);
      return  elm;
    }
  }


  render(){
    // global
    // 
    // var filter = this.props.filter ;
    // console.log("receiver filter", filter);
    var self = this;
    var app = self.context;
    const { classes } = self.props;

    return (
      
        <>
          <Grid container spacing={4}>
            {self.props.breadcrumbs &&
              <Grid item xs={12}>
                <div className={classes.breadCrumbs}>
                  <Link href="javascript:void(1)"
                    onClick={() => app.setState({cmd_consumer:[
                          {
                            action: 'consumers',
                          },
                        ]})
                    }>Клієнти</Link>
                  /
                  <Link href="javascript:void(1)"
                    onClick={() => app.setState({cmd_consumer:[
                          {
                            action: 'consumer_info', 
                            "consumerId": self.state.consumer.id,
                          },
                        ]})
                    }>{self.state.consumer.name}</Link>
                  /
                  <Link href="javascript:void(1)"
                    onClick={() => app.setState({cmd_consumer:[
                          {
                            action: 'contracts', 
                            "consumerId": self.state.consumer.id || 0,
                          },
                        ]})
                    }>Договори</Link>
                  /
                  <Link href="javascript:void(1)"
                    onClick={() => app.setState({cmd_consumer:[
                          {
                            action: 'contract_info', 
                            "consumerId": self.state.consumer.id || 0,
                            consumerContractId: self.state.consumerContractId || 0,
                          },
                        ]})
                    }>Договір {self.state.consumerContract.contract_number}</Link>
                  / Відвантаження
                </div>
              </Grid>            
            }

            <Grid item xs={12}>
                <MUIDataTable
                  data={this.state.shipments.map( self.mapTable() )}
                  columns={self.columns}
                  options={{
                    filterType: "checkbox",
                    download:false,
                    print:false,
                    search:false,
                    filter:false,
                    viewColumns:false,
                    selectableRows: "none",
                    textLabels: ApiModel.textLabels,
                  }}
                  className={classes.crmXTable + ' ' + classes.crmXColWidth}
                />
            </Grid>
          </Grid>
        </>
    );
  }

}
export default withStyles(styles)(Shipments);

