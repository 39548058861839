import React, {Component} from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  InputBase,
  Menu,
  MenuItem,
  Fab,
  Grid,
  Link
} from "@material-ui/core";
import {
  Menu as MenuIcon,
  MailOutline as MailIcon,
  NotificationsNone as NotificationsIcon,
  Person as AccountIcon,
  Search as SearchIcon,
  Assessment,
  ArrowBack as ArrowBackIcon,
  SupervisorAccount,
  AccountBalance,
  PlayForWork as ProviderIcon,
  EmojiEvents as TaskIcon,
  CardGiftcard as ProductIcon
} from "@material-ui/icons";
import classNames from "classnames";

// components
import { Badge, Typography, Button } from "../Wrappers";
import Notification from "../Notification/Notification";
import UserAvatar from "../UserAvatar/UserAvatar";

// context
import { AppContext } from "../../context/AppContext";

// styles
import { withStyles } from "@material-ui/core/styles";
import Themes from "../../themes";
import customStyles from "./styles";
var styles = customStyles(Themes.default);


class Header extends Component {

  static contextType = AppContext;

  constructor(props) {
    super(props);
    if(!this.state) {
      this.state = {
        profileMenu:null,
      };
    }
  }

  setProfileMenu(val) { this.setState({profileMenu:val}) }

  render(){
    var {classes} = this.props;

    // global
    var app = this.context;
    var self= this;

      return (
        <AppBar position="relative" className={classes.appBar}>
          <Toolbar className={classes.toolbar}>
              <Grid container spacing={0}>
                <Grid item lg={10} md={10} sm={10} xs={9}>
                  <Link
                    className={classes.headerNavCard}
                    
                    href="#/app/auction">
                      <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACYAAAAkCAMAAADSK7iXAAAAAXNSR0IB2cksfwAAAexQTFRFAAAAn5yuoJ2soJytn52u////opqsoJytoJytoJyuoJusn5+soJysn5yuoJytoJutqqqqoZysoJytn5uroJytoJytoZytoJutoJytoJysoJytoJytoJytv4C/n5utoJuuoJyuoJytoJytoJuuoJytoJytn5ytoJytoJusoJutoJytqqqqoJ2soJytoZytoaGuppmzoJytoJytoJysn5msoZysoZysn5yuoZytoJyto5mtoJ2toJ2roJutoJytqpWqn5ysoJ2toZ2tnZ2xoJyuoJusoZysop2soZuuoJytoJuuoJytqqqqoJ2tn5+voJytoZutnp6qn52toJyto5yqn5usoJytopmqn5ytn5utoJytoJ2tkpK2oJyunZ2qn5ytoZ2tm5uxn52uopyuoJytoJytgICAoJytoJytmZmqoJytoJ2sop2uoJytoJmvn5qvnp6tn5+vnJytnZ2uoJyuoJyuoJytoJytoZ2soJytoJusoJutoZytoZ2uoJytn52toJytoJytoZ2toJytn5ysoZyvn5+foJytoJytopuun5yuoJytoJysnZ2xoJytoZyuoJ2tpJutoJ2uoJytoJytn52soZutoJutoZytnp6toJyupZa0oZquoJ2toJytn5ysoZusoJyrn5utoJ2tdNjNtQAAAKR0Uk5TAEizvmgBR//MuXsljpDWzQOa7kDB4+D2sX796N4EOGFx/oNr8KeI+1mu4gaU+rUTFPTGzyhvMaDQ9xlORpzsDLCMvRqBaV80ivWp5wmrEPKSKnDzJHj8HqiA7ZkHoyeYghdlLOuGAtq0D5NTPJ8jMCIgHy90vNHlcsfSc2Q5w7ib2UHqUDYI4dwpVea2DfjY6Rxb3ct1VGaqMr8RTNd5TVxDwJ4g35O9AAACEElEQVR4nGNgQAeMTMwsrBii6ICNnYOTnQuHJDcPLxTw8QswCArBeLzCKMpE2EXFIEBcgoFBUgrKkWaXkUVSJcfOLg9lKigqKauoQjlq6uwaSMo0tTi1tCFMAR1ddj19CNvAkNfIGKHKRNHUjJ0FxjO3gLF4LK2s2W3gymzt7BkcYMYhlFlZOjKw8jnBVDnrujAwmLG7oivjNTRgYHBjd4dyPTyBXAYvbx9UZVaWakBSW8sXwvXzDwBRgYpBqMoCgg0gXg8Bc0PDwsF0BNQ4qLJw3UgwbR8VDaJivGMhuuPCgpCVxav4QcQTLBOBZBJ7MsztKfYIZTDDgKEnnsrAkJaeAfNxSFgoQlm8XSZMPEvFniGbPQcefrl29jBlebq2+UBQUFhYWFSsWMJQqmhhUQaK5nIVFU9wVICVVbAjg0oGp6ogOKgGBzFYWQ17bT4c1NUzNDTC7WRIBkcgWJk7exNcWLZZjaGFvRXOdwxugyljbdeEC2sA40u2wwgW553g2IH6NIm9Cyrs3N0DJIXDItogwSuT3otQxurQJw/OOcn9EyaC6Em66TXhmWaphpNDkGOhLYJ9ytRp02eEdZhATJ05C+Rpw6zZDMjKGBjm1Ouys8+d5wN3ZPj8BQvhPETqZUhLjGHABZCU4QNDWdmCRWAgbQiheXEZI22OBCzEcClbhMyLxKmMONOSLFDAYiQpAA5ae0ZpsWVpAAAAAElFTkSuQmCC" alt="" />
                      <span className={self.props.sidebarMenuId === "auction"
                                       ? classes.headerNavButton + " active" 
                                       : classes.headerNavButton }>
                        Аукціони 
                      </span>
                  </Link>
                  <Link
                    className={classes.headerNavCard}
                    href="#/app/task">
                      <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAoCAMAAACsAtiWAAAAAXNSR0IB2cksfwAAAc5QTFRFAAAAqpWqoJ2roZysoZ2un5yun5uuv4C/oZutoJytoJytoJytoJytoJytoZytoJyun5yugICAoJytoJytoJysoJytoJuvqqqqn5qtoZyuoJytmZmzoJ2toJytn5uroZ2toJutqqqqpZa0oJytoJytoZ2soJyuoJytoJytmZmqn5ytoJysoJyroJytoJyuoJytn5yun5uuoZysoJyuoZuuoJytoJyu////oqKin5utoJusoJytoJyto5yqoJytn52tn5utopeuoJytoJ2toJusoJytoJytoZusoJytoZytoJytop2sn5+voJytnp6qn5utnJyqoJytoJqsoJ2tnZ2xoJ2tn5msn5ysoJyuoJytopyun52sqqqqoJytn5ytoZ2toJutn5yun5ytoJytoJytoJytoZytoJytoZ2toJuuoJytn5utoaGuoZyunpqroJutn5ysoJusoJytoZusoJutn5urop2uoJuuoJytoJytoJ2un5+voJutnJytoJ2uoJyspKSkn5qvoJ2tn5+snp6qoJutoJysn5+fn52toJysnp6tn5ytn5ysoJytn5uskpK2oJuuoJytoZ2toJutop6sn5ytoJ2uopuun52toJytu3iJTQAAAJp0Uk5TAAxGb4eQRQRXuvz//ebguVUCS9vPdjMGNXfRCp7JQEGcCRHG+nJ0+8QPyPFD86P5oFJscU/hwgELfdL04yTdYDgW1Kt7k+xcw7XvNBDwFcAS/itjGk4oYr+yLHUDPqi9c42I4onapejFa+eFE9g69lBpvi7NPTxh9fKmIGYfXosOMMolKuS2CG2OIpWwzkoHqfh6rjddWylw3poY5XIAAAH6SURBVHicY2BAAYxMzCysLMxsjAzYATsHJxc3Dy8fLw83F7+AIBYVQsLcIqJi4kCWuISkFLewELoCaRluWTkkvrwst4IiigolZRVVVKMF1dQ1NJHN0NLWwbCZTVdZGsHT05fH4jodFQM425DbCIsKBgZjbhMoS9zUzBzKtLC0sraRsYXyzDXs2CEse24HqJijE5ezi6ubNROU78DtDmF4KMNM9vTyBpI+Hr5+UAEtfzAVEAhzSRB3MJgO0XeBijDzKIGoUO4wqEA4NzQkIiKhIlHc4ECO1o+BCsTGQRnx/FAGu0oCiErkQ/esj3USjJmcAiJZU9FUxKSpw7zNwMcKIpPSUVWEZXBnwjlZ2UAixy4XRUVevk0BnGPBXQg0tUi3GEVJiZUPglPKXcbAoMptiKIigLsciZddIc7gU1mF6pJqbqRIF7QBytYE1qIrQUp8ddyxDAz1WTG4lTQkRwBJocCIRhQlmk3NcLaefguIYmrlbmtnwApcuDsgDMVO7mokcXGDLijLiLsbJliAoqSaGxZwPb2wtMjQx22LXYk/XFAMFIJw0D+hGVNJ+8RJWF2LpIRhsl0MViU9CPYUbqzGICthSORmNiegJMaAW2OqrQU+JQwM03K5gUDF1wi3EgaG6QLlM1R9WfEpAQM+qiiZSVhJeR4SRw6StgDFG1gGrntcpgAAAABJRU5ErkJggg==" alt="" />
                      <span  className={self.props.sidebarMenuId === "task"
                                       ? classes.headerNavButton + " active" 
                                       : classes.headerNavButton}>
                        Завдання 
                      </span>
                  </Link>
                  <Link
                    className={classes.headerNavCard}
                    href="#/app/consumer">
                      <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACUAAAAiCAMAAADvReCJAAAAAXNSR0IB2cksfwAAAh9QTFRFAAAAKxVAIBc9IBc+HxY+IRc/Ixc6HxY9IBY+IBY+IBY9IBY+IBY+IBY+IRY9AAAAIBc/HxU/IRQ7Hxc+JhpAIBY+HxY/Hxc+IRg9IBc+HxY+IBVAQABAIBY+IBY+IBBAIxdAIBY9IBY+IRY+IBc+IRY8IBY+IBU+IBc+IBY+IBY+IBc/IBY+IRY/IBY+IBY9IRU/IRU9IBY9IBU+IBY+IRc+IBU9IBY+IRY+GhozIBY+Hxc+IhVAIhc+IBY+IRU/JBJAIBY+IBY+IBY+IBhAIxVAIBY/HBw5IBY+IBY+IBY+IhFEIBY+IBY+IBY/HxU+IBc+IBY+IBY+IhU+IRY+IBY+MwAzIBY+HxY+HxY/IBc+KysrHxc+AAAAHhg7IBU/JBI3IBQ9IhRAIRU9Hxc+IBY+IRY+Hhg9IBU9IBY+IBc+IBU+IBVAJBg9IBU9IBY/IBY/IRc9IBU+IBY9IBY+IBY9Ihg/IBY+IBU+HxQ9HhQ8IBY+IBU+ICBAHxY/IBU+IBY+Hhc8HxU/IRU9IBU/IBU+IRY+IBU+IBY+HxY9AABVIRc+IBc+HxU+HhY9JCRJIBU+IBc+HxY/HBNCIBhAIBY+IBY+IBc9IhQ8HxQ9IRZDIRc/HBw5IBY9IBY/IBY+IRY+Hxg/IBY+IBc+IBY/Hxc+Ihc8IRY+HxQ9IRlCIBY+IBY9IRY+IBY+IBY+IBY+IBc+IBY+HxY+HxY+IRU++Uh/DwAAALV0Uk5TAAxYiItmFlPp/+Da/fZ1AVmzJ5MU8Kt7Nm/7GATdoBAspsWU1y+61leutobfRdloPVSBj+S0v+y8CoBCPC38bRzRsfJAJNgJpX/qD8L+r2ud994lrLkFz9uC7gbLAituDj80hWPGjCp5+amQMBVgftBkX0fm3DX4eBkz+r4IOcrxIptsYXd0sueaA3yRYjsHqLWjGyD16E8mSxdlElB2l15B4cDUWkzEMh+tt5XJ0vSq5dNzPhXtDloAAAI0SURBVHicY2CAAEYmZhZWNgb8gJ2Dk4ubh5ePH58iAU5BIQYGNmFOZjyKRETFIIaIc0rgViUpJQ1hyMjKIUTlFaRRVCkqwVjKKjCWqhoPJ6e6BpIqTS0YS1sHytDl1dM3MNTiNEKoMjaB+c3UDGqbuYUliLbitIarsuEUgDBs7ewhDHspBzDN72iKMMzJ2QVEucq6uUMEPGAu9fRCqPL28fXzZw0IDAqGCoSEQhl8vEjuD/MPt+OMiPSG+yIqGuqxGAYUEBuGxInztQLTBr7xYDoh0QUNJIGEkzmN2RjcU5xTIWamcWKAdJDnMgJ5ODI5sxIgZmfr5aACDc4csERuXr59OswF2QWozosu5CySgTAtEaJoqopLgFaWljEwZKhLcZanVlRiU1VVXZNTW1ff0Mhg3NTc0uoUyNvGj6mqXaUWSMp3BED50p2cHl0Yqpy7wVQPN1ykl9MYQxUvH5hiCUII5XP2QVXJw4Rq6kHm98tOQKiSiZgIUSXNkwQVsvadlMsw2bRaFcl8yWpvsKopnC4woZapdtN8Racju2IG50x0VQyzZlsVzUEomTuPoZ+TFV1VNLvwhPkLGOGq4n1VGZSr0FQtXMQpu3gJp/lSuK4gNQZoSMBVLeNZvgJIrVzFGQ9TpsFZjKZqluhqaBJdY5cDVcW/dl0sqirt9blQuf6gDTDDDDhTUFWtmwR3dffULhjTb6MlQ3ZgQYE6Z2kBCPBMK4ABLs5NMOYizs0MW7IJAxcApVWctOh8vV0AAAAASUVORK5CYII=" alt="" />
                      <span  className={self.props.sidebarMenuId === "consumer"
                                       ? classes.headerNavButton + " active" 
                                       : classes.headerNavButton} >
                        Клієнти 
                      </span>
                  </Link>
                  <Link
                    className={classes.headerNavCard}
                    href="#/app/product">
                      <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAhCAMAAACGE/kZAAAAAXNSR0IB2cksfwAAAMZQTFRFAAAAm5uxoZyuoZytoJutoZytoJuuoJytoJytoJutn5yuoJ2toJytoJytopeunZ2xoJutoZytoZ2toJytppmzoJytoJytn5+sn5ysoJytn52toJytoJytn5+voJytnp6woZuuoJ2tn52toJ2sn5qvn5ysoJytn5urn5utop2snpqroJ2soJytoqKioJytoJysoaGuoJytn5yuoJytoJytoZ2uoZysn5usopmqqqqqoJytoJ2tn5qtn5+voJytopyu////oJ2tr7TMJAAAAEJ0Uk5TABd3tc3QYfT4u6Bj/6cWGvalf/UUvrQlUN+4y6wg8x2KynCUMLDcQMA0OrO6C/mLE/CNhv2HMUoeA/rXNRA7LAGMKoi7BAAAAM9JREFUeJzdkNkSgiAYRrHEFMlMU7Nss7J93/d8/5cqy8kER+49F/DzzZkBPgAiuFyehx/4fI4DiQgFUUIhklgQkhwZF5XopBSxTDsltRwPymqJkjS9Eg8qukZJhkkmpvEbreoXu1YlqNnhYIE6ZlIH0GkwcCCAKKmPf1DmpVhvzVYQt+NlvqWOG9HFvUDqe3+Z2yGuG4TSMO1NIzwOtsk0TZrNF+91uVqn/m6z3e0PcHVMlU7tM8aXK1UB0dPt/ngClkSTfclDDDwIfMjEfwEpEhwmpq3ARgAAAABJRU5ErkJggg==" alt="" />
                      <span  className={self.props.sidebarMenuId === "product"
                                       ? classes.headerNavButton + " active" 
                                       : classes.headerNavButton}  >
                        Товари 
                      </span>
                  </Link>
                  <Link
                    className={classes.headerNavCard}
                    href="#/app/provider">
                      <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAmCAMAAAB9PwLlAAAAAXNSR0IB2cksfwAAAbZQTFRFAAAAopyuoJuun5yun5yuoZ2toZqumZmzn5yuoJytoJytoJytoJytoJ2toJytoJytoZ2upKSkqqqqoJytoJutoJ2sqpWqn5qvoJytoJyt////n5ytoJytoJuuo5yqoJytoJysoZusn52toJ2umZmqoJytoJytppmzoZyunp6qoJytoJysoZyuo5mtn5utn52tqqqqn5uroJyuoqKioJ2toJytoJysnp6toJytoZytoJyuoJytoJytoJytoZ2tn5+voZytoJutn5utn5yumZmZoJusoJ2toZ2uoJyuoZytoJ2soJytoJutoJytoJ2top2uoJytoJ2sgICAopeun5urn5ytoJytn52tm5uxoZyspJutoJytoJysoZysn5utoJmvoZysoZutoJyuoJytoZ2soJutoJytn52toJytoZ2tn5utoJytoZquoJytopuqoJytoJytoJ2sn5ysoJysoJytoZ6toJytn5+fn5ytoJytn5+wn5ytoJytnpqroJyuoJytn5usn5utoJytnp6qoJuun5uunZ2uoJ2roJuuoJytoZysoJytoJusoJytoJytn5qtoJytoJyt0Yu4ogAAAJJ0Uk5TACxrjZB/TApIzP/74tfw84cOA5bNVgww+d8BqP1uJNHxLnBbD9TdFJ0q/H53GYBtBkBxC+nEtiLm4HTo+Dt6INDkwKAFaWM5hKqU3Jzrqzx2UwIWPV20uBcxHOrPb30jrZK543Jm7mDvvTj3Jtoh28mzsIvTROUIyOwtlfo6vz54hc4VYUUvRqnWX/V7g3w1weFD6mjKAAACI0lEQVR4nI2Ti1vSYBTGTzpTDgQEJhM+QhcFLVobpIzSxGlX0MrSArsbXcRuiGgqKZblpTL/4+bGNhjY0/s82znvd377ztmzbwC1OtLSSrUdbYfD1GFBq+2YHR3O403rLjd2nuiSEw9Fd3uaAF4f8Xur+clAT28jweApwwRPn2kYJuQI19qz7DkzEaHPd3Acd4EHQQ7RWPiiy0T09ceJmEhYL4Xoy4nEgFPAQRPhuCLgEEBy2IICgDQyilfrgVG8dh1vMIwYuIm3GCaVhrE20x7Ez/cTxO5e7ziLOHwbBu6YiJS73g/hXRORtk3UeT+5ZyLu42St5RNTYNYD8WGNc5JMA5GdfmQgj/FJAwCQmRYn1VmePsPn3iYEZF/gjPtljpPIq9fN6sq46RTBN2/9XYcB/yNXfFaYjZu/qjFHRMrjgfJSJNukPucj9NS7vsx7z4ePn2jimzNvz9FiNAiFgpwnKQgyIs3VNQtKZD4mR40AaJ8nUtAAQkX7gpIUigzDlCglX7AXQxqwuPR5Wc1aWaudtbaoZnlsabFKrKyWNbgzDONfNFNeXVGTNYzq/XrWoTKjuyiuKXHDoh+er/LJ+ob6zzRh2TgIm7ipPzSI35VL0w+ltrVtvNQOxpR9dG1vyedt96ex8KukzqLr9y4PZdwzFnx/1PfRtYdlyLG8saAUFawqns1BZd/wagOllab9ClD5pK4A2uR7CY2VZJ4Cgfq3hL+ccGGFNK9hcwAAAABJRU5ErkJggg==" alt="" />
                      <span  className={self.props.sidebarMenuId === "provider"
                                       ? classes.headerNavButton + " active" 
                                       : classes.headerNavButton}>
                        Постачальники 
                      </span>
                  </Link>
                  <Link
                    className={classes.headerNavCard}
                    href="#/app/report">
                      <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACUAAAAiCAMAAADvReCJAAAAAXNSR0IB2cksfwAAAT5QTFRFAAAAn5uroJytn5yuoZuun5yuoJytn5utoJytn5ysoZytkpK2n5+vn5+foJytoJytoJytn5msn5ysn5+wnp6qoJyuoJysmZmzoJytoJytnZ2qo5mtop2sqqqqn52toJytoZyvoZysoJyt////oJyuoJytopqsn5+vnJyqoZytoJutoJysoJ2unp6qqqqqn52soJyun5yuoJytn52toJytqqqqoJutoJusoJytoJytoJusnpqroJutoJytoJqsoJytoZ2toZ2toJutpZa0mZmZoJusoJytoJytoJ2tn52tn5ytoJytpJutoJytoZytoZytoJytoJytoZysoJmvn5ysn5uuoZutoJytgICAoJytoJytn5yuoJysoJ2soZyuoJytoZ2voJ2uoZ2uoJ2tn5ytoJ2uoZ2uoJytoJytoZ2twXX+NgAAAGp0Uk5TAECfoE+Q9MDssNAHEAjD/9YoUC0V1X4KyesnGTQGuPU2mvgBo/pHIBKlpI5bKgl1cVX9YIkDnGlL/Jc69rQr7sV/5BEFe9z3122I4xyxaqL+m18jTVKS2wLweY2LlNh8SV45jJWmh+jheinMP4oAAAEeSURBVHicY2BkYsYBWBgQgJWNHTvg4ERWxcWAHXAOFVXcPMiAF4cqPn5kIIBDlaAQMhCmoetFRMUIqxKXkJQCq5JmRAYyKKpk5eQVIDYqovhRiRdJlbKKqhrUXbLqyEADYpamFojU5tfB4XpeXRCppwck9PkNcPnR0EgHyjLmN8EZEqZm/OZghoUlkzJOVQzKVmBl1ja2pgy4VUGU2dk7iDPgUwVS5ugk6syAosrF1c3dw1MZWZkXv7cPA4oqZV9VKz//gEAU44KCGVBVhfCHAlnm/ArYLIerCgsHsSIio5AlomPQVMXGgVXZxyOrwkhfofwJQFYifxJeVRFySslBKZapDHhVMfCmpfNnZJoSUAVyFbq3yMkdRJVMRJVyAFPKJmTQ5JYtAAAAAElFTkSuQmCC" alt="" />
                      <span  className={self.props.sidebarMenuId === "report"
                                       ? classes.headerNavButton + " active" 
                                       : classes.headerNavButton}>
                        Звіти 
                      </span>
                  </Link>
                </Grid>

                <Grid item lg={2} md={2} sm={2} xs={4}>
                  <div className={classes.headerProfileButton}>
                    <IconButton
                      aria-haspopup="true"
                      color="inherit"
                      className={classes.headerProfileName}
                      aria-controls="profile-menu"
                      onClick={e => self.setProfileMenu(e.currentTarget)}
                    >
                      <AccountIcon classes={{ root: classes.headerIcon }} />
                      <Typography variant="inherit" weight="medium">
                        {app.state.userFullName || "Anonymous"}  
                      </Typography>
                    </IconButton>
                    <Menu
                      id="profile-menu"
                      open={Boolean(self.state.profileMenu)}
                      anchorEl={self.state.profileMenu}
                      onClose={() => self.setProfileMenu(null)}
                      className={classes.headerMenu}
                      classes={{ paper: classes.profileMenu }}
                      disableAutoFocusItem
                    >
                        <div className={classes.profileMenuUser}>
                          <Typography variant="h4" weight="medium">
                            {app.state.userFullName || "Anonymous"} 
                          </Typography>
                        </div>

                        <MenuItem
                          className={classNames(
                            classes.profileMenuItem,
                            classes.headerMenuItem,
                          )}
                          onClick={() => {app.setState({
                                personal_id:0,
                                root_id:0,
                                type:'',
                                isAuthorized: false,
                                authToken: "",
                          })}}
                        >
                          <AccountIcon className={classes.profileMenuIcon} /> Вийти
                        </MenuItem>
                    </Menu>
                  </div>
                </Grid>
              </Grid>
          </Toolbar>
        </AppBar>
      );
  }
}
export default withStyles(styles)(Header);
