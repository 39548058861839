import React, {Component} from "react";
import {
  Badge as BadgeBase,
  Typography as TypographyBase,
  Button as ButtonBase,
} from "@material-ui/core";
import classnames from "classnames";

import { AppContext } from "../../context/AppContext";

// styles
import { withStyles } from "@material-ui/core/styles";
import Themes from "../../themes";
var theme = Themes.default;

var styles = (th => ({
  badge: {
    fontWeight: 600,
    height: 16,
    minWidth: 16,
  },
}))(theme);


class Badge extends Component {

  static contextType = AppContext;

  render(){
    var {children, colorBrightness, color, classes} = this.props;

    // global
    var app = this.context;
    var self= this;

    var Styled = createStyled({
      badge: {
        backgroundColor: getColor(color, theme, colorBrightness),
      },
    });

    return (
      <Styled>
        {styledProps => (
          <BadgeBase
            classes={{
              badge: classnames(classes.badge, styledProps.classes.badge),
            }}
            {...self.props}
          >
            {children}
          </BadgeBase>
        )}
      </Styled>
    );
  }
}
Badge = withStyles(styles)(Badge);


class Typography extends Component {

  static contextType = AppContext;

  render(){
    var { children,
          weight,
          size,
          colorBrightness,
          color, 
          classes} = this.props;
    var app = this.context;
    var self= this;
    return (
      <TypographyBase
        style={{
          color: getColor(color, theme, colorBrightness),
          fontWeight: getFontWeight(weight),
          fontSize: getFontSize(size, self.props.variant, theme),
        }}
        {...self.props}
      >
        {children}
      </TypographyBase>
    );
  }
}
Typography = withStyles(styles)(Typography);



class Button extends Component {

  static contextType = AppContext;

  render(){
    var { children, color, className, classes} = this.props;
    var Styled = createStyled({
        root: {
          color: getColor(color, theme),
        },
        contained: {
          backgroundColor: getColor(color, theme),
          boxShadow: theme.customShadows.widget,
          color: `${color ? "white" : theme.palette.text.primary} !important`,
          "&:hover": {
            backgroundColor: getColor(color, theme, "light"),
            boxShadow: theme.customShadows.widgetWide,
          },
          "&:active": {
            boxShadow: theme.customShadows.widgetWide,
          },
        },
        outlined: {
          color: getColor(color, theme),
          borderColor: getColor(color, theme),
        },
        select: {
          backgroundColor: theme.palette.primary.main,
          color: "#fff",
        },
      });
    // global
    var app = this.context;
    var self= this;
    return (
      <Styled>
        {({ classes }) => (
          <ButtonBase
            classes={{
              contained: classes.contained,
              root: classes.root,
              outlined: classes.outlined,
            }}
            {...self.props}
            className={classnames(
              {
                [classes.select]: self.props.select,
              },
              className,
            )}
          >
            {children}
          </ButtonBase>
        )}
      </Styled>
    );
  }
}
Button = withStyles(styles)(Button);

// export  {Badge, withStyles(styles)(Typography), withStyles(styles)(Button) };
export  {Badge, Typography, Button};

// ########################################################################

function getColor(color, theme, brigtness = "main") {
  if (color && theme.palette[color] && theme.palette[color][brigtness]) {
    return theme.palette[color][brigtness];
  }
}

function getFontWeight(style) {
  switch (style) {
    case "light":
      return 300;
    case "medium":
      return 500;
    case "bold":
      return 600;
    default:
      return 400;
  }
}

function getFontSize(size, variant = "", theme) {
  var multiplier;

  switch (size) {
    case "sm":
      multiplier = 0.8;
      break;
    case "md":
      multiplier = 1.5;
      break;
    case "xl":
      multiplier = 2;
      break;
    case "xxl":
      multiplier = 3;
      break;
    default:
      multiplier = 1;
      break;
  }

  var defaultSize =
    variant && theme.typography[variant]
      ? theme.typography[variant].fontSize
      : theme.typography.fontSize + "px";

  return `calc(${defaultSize} * ${multiplier})`;
}

function createStyled(styles, options) {
  var Styled = function(props) {
    const { children, ...other } = props;
    return children(other);
  };

  return withStyles(styles, options)(Styled);
}
