import React, {Component}  from "react";
import {
  Grid,
  Select,
  MenuItem,
  Button,
  TextField,
  Link,
} from "@material-ui/core";
// import moment from 'moment';

// components
// import MUIDataTable from "mui-datatables";
// import validators from '../../validators';
import validators   from '../../../../utils/validators';
import MessageBlock from '../../../../utils/MessageBlock';
// context
import { AppContext } from "../../../../context/AppContext";
import ApiModel       from "../../../../utils/ApiModel";
// styles
import { withStyles } from "@material-ui/core/styles";
import Themes from "../../../../themes";
import customStyles from "./styles";
// import { Alarm } from "@material-ui/icons";
const theme = Themes.default;
const styles = customStyles(theme);




class ContractInfo extends Component {

  static contextType = AppContext;
  constructor(props) {
    super(props);
    if(!this.state) {
      this.state = {
        providerId:this.props.providerId,
        contract_number: this.props.contract_number,
        provider:{},
        contract: {},
        errors: {},
        message:"",
        messageType:"",

        deal_status:[],
        company:[]
      };
    }
    // var self =this;

    var nonEmpty = function(val) {
            if(val.trim().length === 0 ){
                return "Будь ласка, введіть значення";
            }
            return "";
        };

    this.validator={
      "contract_number": nonEmpty,
      "date_contract": nonEmpty,
      "determinate_days": function(val) {
         if(validators.isPositiveInteger(val) !== true ){
                    return "Будь ласка, виберіть значення";
                }
                return "";
      },
      "deal_status": function(val) {
         if(validators.isPositiveInteger(val) !== true ){
                    return "Будь ласка, виберіть значення";
                }
                return "";
      },
      "summary": function(val) {
         if(validators.isPositiveFloat(val) !== true ){
                    return "Будь ласка, виберіть значення";
                }
                return "";
      },
     };

     this.handleSubmit = this.handleSubmit.bind(this);
     this.loadCompany = ApiModel.loadCompany(this, "company");
  }

  componentDidUpdate(){
    if(this.props.providerId !==  this.state.providerId 
       || this.props.contract_number !==  this.state.contract_number) {
      this.setState({
         providerId: this.props.providerId,
         contract_number: this.props.contract_number,
      });
      this.loadProvider().finally(function(){
        this.loadContract();
      });
    }    
  }

  componentDidMount(){
    // var app = this.context;
    var self = this;
    this.setState({
        providerId: this.props.providerId,
        contract_number: this.props.contract_number,
    });
    self.loadProvider().finally(function(){
      self.loadContract().finally(function(){
        self.loadDealStatus().finally(function(){
          self.loadCompany();
        });
      });
    });
  }


  loadProvider(){
    var app = this.context;
    var self = this;
    var onError = function(errorMessage) {
        self.setState({
            "message": errorMessage,
            "messageType": "error"
        });
    };
    var data = {"param": [{"provider_id": parseInt(this.props.providerId)}]};
    return app.postData(
        '/providers/get',
        data
      ).then(function(responseJson){
          // console.log("responseJson", responseJson, responseJson["status"]);
          if(responseJson["status"] === "OK") {
              let data = responseJson["detail"][0];            
              self.setState({
                  "provider": data
              });
          } else if(responseJson["status"] === "NOT_FOUND") {
              self.props.app.setState({
                  "message": responseJson["comment"],
                  "messageType": "error"
              });
          } else {
              onError(responseJson["comment"]);
          }
    }).catch(() => onError("Не вдалося завантажити постачальника"));
  }

  loadContract(){
    var app = this.context;
    var self = this;
    // var parent = this.props.parent;

    var onError = function(errorMessage) {
          self.setState({
            "message":errorMessage,
            "messageType":"error",
          });
        };
    return app.postData(
      '/providers_contract/get',
      {"param": [{contract_number:self.props.contract_number}]}
    ).then(function(responseJson){
    // console.log("responseJson", responseJson, responseJson["status"]);
    if(responseJson["status"] === "OK") {
        self.setState({
          "contract": responseJson["detail"][0]
        });
    } else {
        onError(responseJson["comment"]);
    }
    }).catch(() => onError("Невідома помилка"));
  }

  loadDealStatus(){
    var app = this.context;
    var self = this;
    var onError = function(errorMessage) {
          self.setState({
            "message":errorMessage,
            "messageType":"error",
          });
        };
    return app.postData(
    '/deal_status/get',
    {"param": []}
    ).then(function(responseJson){
    // console.log("responseJson", responseJson, responseJson["status"]);
    if(responseJson["status"] === "OK") {
        self.setState({
        "deal_status": responseJson["detail"]
        });
    } else {
        onError(responseJson["comment"]);
    }
    }).catch(() => onError("Невідома помилка"));
  }

  validate(record){
    var self = this;
    var errors={};
    var errorExists = false;
    [
      "contract_number",
      "date_contract",
      "determinate_days",
      "deal_status",
      "company_id",
      "summary",
    ].map(function(elm) {
          if(self.validator[elm]) {
            errors[elm]  = self.validator[elm](record[elm]);
            if (errors[elm]){
              errorExists = true;
            }
          } else {
            errors[elm] = "";
          }
          return elm;
    });

    return {
      errors:errors,
      errorExists: errorExists
    }
  }


  handleChangeFactory(attributeName) {
      var self = this;
      return function(event) {
        event.preventDefault();
        // deep copy of the data
        var contract = JSON.parse(JSON.stringify(self.state.contract));
        
        contract[attributeName] = event.target.value;

        var err = self.validate(contract);
      
        self.setState({
            contract: contract,
            errors: err.errors,
            message: "Дані змінено, але не збережено",
            messageType: "warning",
        });
      }
  }

  // handleCheckboxFactory(attributeName) {
  //   var self = this;
  //   return function(event) {
  //       var provider = JSON.parse(JSON.stringify(self.state.provider));
  //       provider[attributeName] =  event.target.checked;
  //       self.setState({
  //           "provider": provider,
  //           "message": "Дані змінено, але не збережено",
  //           "messageType": "warning"
  //       });
  //   }
  // }


  handleSubmit(event) {
        event.preventDefault();
        var self = this;
        var app = this.context;
        
        let contract = JSON.parse(JSON.stringify(this.state.contract));

        contract.provider_id =  this.state.providerId;

        // do validation
        var err = self.validate(contract);

        if(err.errorExists) {
            self.setState({
              errors: err.errors,
              message:"Дані не збережено",
              messageType:"error",
            });
            return;
        }

        var onError = function(errorMessage) {
            self.setState({
              "message":errorMessage,
              "messageType":"error",
            });
          };

        if(contract.contract_number.length > 0){
          let postData = {
              "provider_id":contract.provider_id,
              "contract_number":contract.contract_number,
              "company_id":contract.company_id,
              "date_contract":contract.date_contract,
              "determinate_days":contract.determinate_days,
              "deal_status": contract.deal_status,
              "summary": contract.summary,
          };
          console.log('updating', postData);
          app.postData(
              '/providers_contract/edit',
              [postData]
              ).then(function(responseJson){
              // console.log("responseJson", responseJson, responseJson["status"]);
              if(responseJson["status"] === "OK") {
                  self.setState({
                      "message": "Дані оновлено",
                      "messageType": "success"
                  });
              } else if(responseJson["status"] === "EXPECTATION_FAILED") {
                  onError("Не вдалося зберегти дані");
              } else {
                  onError(responseJson["comment"]);
              }
              }).catch(() => onError("Невідома помилка"));
        } else {
            let postData = {
                "provider_id":contract.provider_id,
                "contract_number":contract.contract_number,
                "company_id":contract.company_id,
                "date_contract":contract.date_contract,
                "determinate_days":contract.determinate_days,
                "deal_status": contract.deal_status,
                "summary": contract.summary,
            };
            console.log('creating', postData);
            // this.props.app.postData(
            //     '/providers_contract/add',
            //     [postData]
            //     ).then(function(responseJson){
            //     // console.log("responseJson", responseJson, responseJson["status"]);
            //     if(responseJson["status"] === "OK") {
            //         var provider = Object.assign({}, self.state.provider);
            //         provider.id = responseJson["detail"][0];
            //         self.setState({
            //             "provider": provider,
            //             "message": "Дані збережено",
            //             "messageType": "success"
            //         });
            //     } else if(responseJson["status"] === "EXPECTATION_FAILED") {
            //         onError("Не вдалося зберегти дані");
            //     } else {
            //         onError(responseJson["comment"]);
            //     }
            //     }).catch(() => onError("Невідома помилка"));
        }
  }

  
  handleDeleteFactory() {
      var self = this;
      var app = this.context;
      return function(event) {
          event.preventDefault();
          var id = self.props.contract_number;
          if(id) {
              var onError = function(errorMessage) {
                  self.setState({
                      "message":errorMessage,
                      "messageType":"error",
                  });
              };
              var postData = [id];
              // post the DELETE command
              app.postData(
                      '/providers_contract/delete',
                      postData
                  ).then(function(responseJson){
                      if(responseJson["status"] === "OK") {
                          self.props.parent.setState({
                            contract_number: null,
                            view:self.props.parent.views.contracts,
                          });
                      } else if(responseJson["status"] === "EXPECTATION_FAILED") {
                          onError("Не вдалося видалити дані");
                      } else {
                          onError(responseJson["comment"]);
                      }
                  }).catch(() => onError("Невідома помилка"));
          }
      }
  }

  // getError(attr, i, subAttr){
  //     var errors = JSON.parse(JSON.stringify(this.state.errors));
  //     errors = errors[attr] || {};
  //     errors = errors["@"+i] || {};
  //     return errors[subAttr];
  // }



  render(){
    // global
    var app = this.context;
    var self = this;
    const { classes } = self.props;

    return (<>
      <div>
        <Grid container spacing={4}>
          <Grid item xs={12}>
          <div className={classes.breadCrumbs}>
                <Link href="javascript:void(0)" onClick={() => self.props.parent.setState({
                  providerId: null,
                  view:self.props.parent.views.providers,
                })}>Постачальники</Link>
                / 
                <Link href="javascript:void(0)" onClick={() => self.props.parent.setState({
                  view:self.props.parent.views.providerInfo,
                })}>{self.state.provider.name}</Link>
                / 
                <Link href="javascript:void(0)" onClick={() => self.props.parent.setState({
                  view:self.props.parent.views.contracts,
                })}>Договори</Link>
                / {self.state.contract_number}
          </div>
          </Grid>
          <Grid item xs={5}>
            <h1 className={classes.pageTitle}>Договір "{self.state.contract_number}"</h1>
          </Grid>
          <Grid item xs={7}>
            <div className={classes.topButtons}>
              { app.state.userType === "admin" &&
                <>
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  className={classes.submitButtonDanger}
                  onClick={self.handleDeleteFactory()}
                  >
                  Видалити
                </Button>
                &nbsp;
                </>
              }

              <span className={classes.btnBlock}>
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  className={classes.submitButtonSattelite}
                  onClick={() => self.props.parent.setState({view: self.props.parent.views.contractSpecs})}
                  >
                  &nbsp;
                </Button>
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  className={classes.submitButton}
                  onClick={() => self.props.parent.setState({view: self.props.parent.views.contractSpecs})}
                  >
                  Специфікація
                </Button>
              </span>

              <span className={classes.btnBlock}>
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  className={classes.submitButtonSattelite}
                  onClick={() => self.props.parent.setState({view: self.props.parent.views.contractAppendixes})}
                  >
                  &nbsp;
                </Button>
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  className={classes.submitButton}
                  onClick={() => self.props.parent.setState({view: self.props.parent.views.contractAppendixes})}
                  >
                  Додаткові угоди
                </Button>
              </span>
            </div>

          </Grid>

          {self.state.message && (
            <Grid item xs={12}>
              <MessageBlock message={self.state.message} messageType={self.state.messageType}/>
            </Grid>
          )}


          <Grid item xs={12}>
            <div className={classes.crmxCard}>
              <Grid container spacing={4}>
                <Grid item xs={3}>
                  <div className={classes.formFieldLabel}>
                    Номер договору
                  </div>
                </Grid>
                <Grid item xs={9}>
                  
                  {
                      self.state.contract.contract_number
                      ? (<div className={classes.formFieldLabel}>{self.state.contract.contract_number}</div>)
                      : (<TextField
                            value={self.state.contract.contract_number}
                            onChange={self.handleChangeFactory("contract_number")}
                            margin="dense"
                            placeholder="Номер договору"
                            type="text"
                            fullWidth
                            variant="standard" 
                            className={self.state.errors.contract_number ? classes.textFieldErr : classes.textField}
                            />)
                  }
                </Grid>


                <Grid item xs={3}>
                  <div className={classes.formFieldLabel}>
                    Дата договору
                  </div>
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    value={self.state.contract.date_contract}
                    onChange={self.handleChangeFactory("date_contract")}
                    margin="dense"
                    placeholder="Дата договору"
                    type="date"
                    fullWidth
                    variant="standard" 
                    className={self.state.errors.date_contract ? classes.textFieldErr : classes.textField}
                    />
                </Grid>

                <Grid item xs={3}>
                  <div className={classes.formFieldLabel}>
                    Відстрочка платежу
                  </div>
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    value={self.state.contract.determinate_days}
                    onChange={self.handleChangeFactory("determinate_days")}
                    margin="dense"
                    placeholder="Відстрочка платежу"
                    type="text"
                    fullWidth
                    variant="standard" 
                    className={self.state.errors.determinate_days ? classes.textFieldErr : classes.textField}
                    />
                </Grid>

                <Grid item xs={3}>
                  <div className={classes.formFieldLabel}>
                    Сума
                  </div>
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    value={self.state.contract.summary}
                    onChange={self.handleChangeFactory("summary")}
                    margin="dense"
                    placeholder="МФО"
                    type="text"
                    fullWidth
                    variant="standard" 
                    className={self.state.errors.summary ? classes.textFieldErr : classes.textField}
                    />
                </Grid>


                <Grid item xs={3}>
                  <div className={classes.formFieldLabel}>
                    Статус
                  </div>
                </Grid>
                <Grid item xs={9}>
                    <Select
                      value={self.state.contract.deal_status || ''}
                      onChange={self.handleChangeFactory("deal_status")}
                      className={self.state.errors.company_id ? classes.selectFieldErr : classes.selectField}
                    >
                      <MenuItem value="" key={'deal_status_id_X'} >-</MenuItem>
                      {self.state.deal_status.map(function(elm, ind){
                          return <MenuItem key={'deal_status_id_' + ind} value={elm.id}  className={classes.selectOption}>{elm.name}</MenuItem>;
                      })}
                    </Select>
                </Grid>

                <Grid item xs={3}>
                  <div className={classes.formFieldLabel}>
                    Компанія
                  </div>
                </Grid>
                <Grid item xs={9}>
                    <Select
                      value={self.state.contract.company_id || ''}
                      onChange={self.handleChangeFactory("status")}
                      className={self.state.errors.company_id ? classes.selectFieldErr : classes.selectField}
                    >
                      <MenuItem value="" key={'company_id_X'} >-</MenuItem>
                      {self.state.company.map(function(elm, ind){
                          return <MenuItem key={'company_id_' + ind} value={elm.id}  className={classes.selectOption}>{elm.name}</MenuItem>;
                      })}
                    </Select>
                </Grid>

              </Grid>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.formButtons} style={{textAlign:"center"}}>
              <Button
                variant="contained"
                color="success"
                size="large"
                className={classes.submitButtonSattelite}
                onClick={self.handleSubmit}
                >
                &nbsp;
              </Button>
              <Button
                variant="contained"
                color="success"
                size="large"
                className={classes.submitButton}
                onClick={self.handleSubmit}
                >
                Зберегти
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
    </>);
    
  }
}
export default withStyles(styles)(ContractInfo);

