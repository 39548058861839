import React from "react";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        hasError: false,
        errorMessage:"",
    };
  }

  static getDerivedStateFromError(error) {
    // Обновить состояние с тем, чтобы следующий рендер показал запасной UI.
    return {
        hasError: true,
        errorMessage: error
    };
  }
  componentDidCatch(error, errorInfo) {
    // Можно также сохранить информацию об ошибке в соответствующую службу журнала ошибок
    // logErrorToMyService(error, errorInfo);
  }
  render() {
    if (this.state.hasError) {
        // Можно отрендерить запасной UI произвольного вида
        return <>
        <h1>Вибачте, сталась помилка</h1>
        {this.state.errorMessage}
        </>;
    }
    return this.props.children;
  }
}

export  {ErrorBoundary};