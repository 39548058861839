import React, {Component}  from "react";
import {
  Grid,
  Select,
  MenuItem,
  Button,
  TextField,
  TextareaAutosize,
  Checkbox,
  Link,
} from "@material-ui/core";
import moment from 'moment';
import DateTimePicker from '@mui/lab/DateTimePicker';
import { RadioGroup, Radio, FormControlLabel, FormControl, FormLabel} from '@mui/material';

// components
// import { Typography } from "../../../../components/Wrappers";
// import MUIDataTable from "mui-datatables";
// import validators from '../../validators';
import validators   from '../../../../utils/validators';
import MessageBlock from '../../../../utils/MessageBlock';
import {CrmDateTimePicker} from '../../../../components/CrmDateTimePicker/CrmDateTimePicker';
// context
import { AppContext } from "../../../../context/AppContext";
import ApiModel from "../../../../utils/ApiModel";
import regions   from '../../../../utils/regions_ua';

// styles
import { withStyles } from "@material-ui/core/styles";
import Themes from "../../../../themes";
import customStyles from "./styles";
// import { Alarm } from "@material-ui/icons";
const theme = Themes.default;
const styles = customStyles(theme);



/**
  id                  bigint(20)
  id_prozoro          text
  url_prozoro         text
  company_id          bigint(20) => companies
  type_auction        bigint(20) => type_payments
  type_product        bigint(20) => type_product
  waiting_total_deal  float
  region              text
  propositions_up_to  datetime
  date_auction        datetime
  member_price        float
  personal_id         bigint(20)  => employes
  comments            text
  deadline            datetime
  status_play         tinyint(1)
  comment_not_play    text
  type_task           bigint(20)  => type_task
 */
class AuctionResult extends Component {

  static contextType = AppContext;
  constructor(props) {
    super(props);

    this.defaultTaskRecord = ApiModel.defaultTaskRecord();

    if(!this.state) {
      this.state = {
        auctionId:this.props.auctionId,
        auction:ApiModel.defaultAuctionRecord(),

        errors: {},
        message:"",
        messageType:"",

        company:[],
        type_payments:[],
        type_product:[],
        personal_tech:[],
        personal_nontech:[],
        filteredPersonal_tech:[],
        filteredPersonal_nontech:[],
        type_task:[],
        auction_result:[],
        auction_result_map:{},
        default_task_status:{},
      };
    }
    var self =this;

    var nonEmpty = function(val) {
      if((val + '').trim().length === 0 ){
          return "Будь ласка, введіть значення";
      }
      return "";
    };
    var isPositiveFloat = function(val) {
      if(validators.isPositiveFloat(val) !== true ){
        return "Будь ласка, виберіть значення";
      }
      return "";
    }
    var isPositiveInteger = function(val) {
      if(validators.isPositiveInteger(val) !== true ){
        return "Будь ласка, виберіть значення";
      }
      return "";
    }
    this.validator = {
      result: nonEmpty,
      comment_not_play: nonEmpty, //  text
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeFactory = ApiModel.handleChangeFactory.bind(this);

    this.validate = ApiModel.validate(this);
    this.onError = ApiModel.onError(this);

    this.loadOneAuction = ApiModel.loadOneAuction(this, "auction", this.onError);
    this.loadAuctionResult  = ApiModel.loadAuctionResult(this, "auction_result");
    this.loadAuctionResultMap  = ApiModel.loadAuctionResultMap(this, "auction_result_map");
    
  }

  componentDidUpdate(){
    var self = this;
    if(self.props.auctionId !==  self.state.auctionId
    || self.props.auctionProzoroId !==  self.state.auctionProzoroId) {  
      self.setState({ 
        auctionId: self.props.auctionId,
        auctionProzoroId:self.props.auctionProzoroId,
      });
      self.loadOneAuction(self.props.auctionId, self.props.auctionProzoroId);
    }
  }

  componentDidMount(){
    // var app = this.context;
    var self = this;
    self.setState({
        auctionId: self.props.auctionId,
    });
    self.loadAuctionResult();
    self.loadAuctionResultMap();
    self.loadOneAuction(self.props.auctionId, self.props.auctionProzoroId);
  }


  handleSubmit(event) {
      event.preventDefault();
      var self = this;
      var app = this.context;
        
      let auction = JSON.parse(JSON.stringify(this.state.auction));

      // if(self.state.auction.result) {
      //   self.setState({
      //     message:"Аукціон вже завершено. Ви не зможете змінити дані",
      //     messageType:"error",
      //   });
      //   return;
      // }
      // do validation
      var err = self.validate(auction);

      if(err.errorExists) {
        self.setState({
          errors: err.errors,
          message:"Дані не збережено",
          messageType:"error",
        });
        return;
      }
      var onError = ApiModel.onError(self);

      let postData = {
        id:parseInt(auction.id) || 0, //  bigint(20)
        id_prozoro: auction.id_prozoro || "",
        result: self.props.forceResult || auction.result,             //  text
        comment_not_play: auction.comment_not_play,             //  text
      };
      // console.log('updating', postData);
      app.postData(
          '/auction/result',
          [postData]
      ).then(function(responseJson){
        // console.log("responseJson", responseJson, responseJson["status"]);
        if(responseJson["status"] === "OK") {
          self.setState({
            // "message": (<div>Дані оновлено</div>),
            "message": "Дані оновлено",
            "messageType": "success"
          });
          app.setState({cmd_auction:[
            {action: 'reload'},
            {action: 'closePopup'},
          ]});
        } else if(responseJson["status"] === "FORBIDDEN") {
          var errorMsg = "За даним аукціоном завершені не всі завдання !";
          if(responseJson["unclosed_tasks"] && responseJson["unclosed_tasks"].length) {
            errorMsg += "Будь ласка завершите завдання за номерами: ";
            for(let i=0; i<responseJson["unclosed_tasks"].length; i++){
              errorMsg += responseJson["unclosed_tasks"][i] + ";";
            }
          }
          throw errorMsg;
        } else if(responseJson["status"] === "EXPECTATION_FAILED") {
          // onError("Не вдалося зберегти дані");
          throw "Не вдалося зберегти дані";
        } else {
          // onError(responseJson["comment"]);
          throw responseJson["comment"];
        }
      }).catch((reason) => onError(("" + reason) || "Невідома помилка"));        
  }

  render(){
    // global
    var app = this.context;
    var self = this;
    const { classes } = self.props;

    return (<>
      <div>
        <Grid container spacing={3}>
          {false &&
            (<Grid item xs={7}>
              <div className={classes.topButtons}>
                { app.state.userType === "admin" &&
                  <>
                  <Button
                    variant="contained"
                    color="success"
                    size="large"
                    className={classes.submitButtonDanger}
                    // onClick={self.handleDeleteFactory(ind)}
                    >
                    Видалити
                  </Button>
                  &nbsp;
                  </>
                }
              </div>
            </Grid>) }


          {self.state.message && (<Grid item xs={12}>
          <MessageBlock message={self.state.message} messageType={self.state.messageType}/>
          </Grid>)}

          <Grid item xs={6} md={3}>
            <div className={classes.formFieldLabel}>
              ID в prozoro
            </div>
          </Grid>
          <Grid item xs={6} md={9}>
            <div className={classes.formFieldLabel}>
              <Link href={self.state.auction.url_prozoro} target="blank">
                {self.state.auction.id_prozoro}
              </Link>
            </div>
          </Grid>
          


              <Grid item xs={6} md={3}>
                <div className={classes.formFieldLabel}>
                  Результат
                </div>
              </Grid>
              <Grid item xs={6} md={9}>

              {!self.props.forceResult && 
                  <Select
                    value={self.state.auction.result || ''}
                    onChange={self.handleChangeFactory("auction", "result", self.validate)}
                    className={self.state.errors.result ? classes.selectFieldErr : classes.selectField}
                  >
                    <MenuItem value="" key={'result_X'} >-</MenuItem>
                    {self.state.auction_result.map(function(elm, ind){
                        if (elm.id === "won") { return null; }
                        if (elm.id === "not_play") { return null; }
                        return <MenuItem key={'result_' + ind} value={elm.id}  className={classes.selectOption}>{elm.name}</MenuItem>;
                    })}
                  </Select>
                }
                { !!self.props.forceResult &&
                  <div className={classes.formFieldLabel}>
                    {self.state.auction_result_map[self.props.forceResult]}
                  </div>                 
                }


              </Grid>


          <Grid item xs={6} md={3}>
            <div className={classes.formFieldLabel}>
              Причина
            </div>
          </Grid>
          <Grid item xs={6} md={9}>
            <TextareaAutosize
              minRows={3}
              style={{width:"100%"}}
              value={self.state.auction.comment_not_play}
              onChange={self.handleChangeFactory("auction", "comment_not_play", self.validate)}
              margin="dense"
              placeholder="Причина поразки"
              type="text"
              variant="standard" 
              className={self.state.errors.comment_not_play ? classes.textFieldErr : classes.textField}
              />
          </Grid>
      

          <Grid item xs={12}>
            <div className={classes.formButtons}>
              <Button
                variant="contained"
                color="success"
                size="large"
                className={classes.submitButtonSattelite}
                onClick={() => 
                  app.setState( { cmd_auction:[  {action: "closePopup"}   ] })
                }
                >
                &nbsp;
              </Button>
              <Button
                variant="contained"
                color="success"
                size="large"
                className={classes.submitButton}
                onClick={() => 
                  app.setState( { cmd_auction:[  {action: "closePopup"}   ] })
                }
                >
                Закрити
              </Button>
              
              <Button
                variant="contained"
                color="success"
                size="large"
                className={classes.submitButtonSattelite}
                onClick={self.handleSubmit}
                >
                &nbsp;
              </Button>
              <Button
                variant="contained"
                color="success"
                size="large"
                className={classes.submitButton}
                onClick={self.handleSubmit}
                >
                Зберегти
              </Button>

            </div>
          </Grid>
      </Grid>
      </div>
    </>);
    
  }
}
export default withStyles(styles)(AuctionResult);

