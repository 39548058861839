import React, {Component}  from "react";
import {
  Grid,
  // Select,
  Button,
  TextField,
  Link,
} from "@material-ui/core";
// import moment from 'moment';

// components
// import MUIDataTable from "mui-datatables";
import validators   from '../../../../utils/validators';
import MessageBlock from '../../../../utils/MessageBlock';
// context
import { AppContext } from "../../../../context/AppContext";

// styles
import { withStyles } from "@material-ui/core/styles";
import Themes from "../../../../themes";
import customStyles from "./styles";
// import { ThumbDown } from "@material-ui/icons";
// import { Alarm } from "@material-ui/icons";
const theme = Themes.default;
const styles = customStyles(theme);




class ProviderInfo extends Component {

  static contextType = AppContext;
  constructor(props) {
    super(props);
    if(!this.state) {
      this.state = {
        providerId:false,
        provider:{},
        errors:{},
        message:"",
        messageType:"",
      };
    }
    var self =this;
    // var positiveFloat = function(val) {
    //         if(!self.isPositiveFloat(val)){
    //             return "Будь ласка, введіть додатнє число";
    //         }
    //         return "";
    //     };
    var nonEmpty = function(val) {
            if(val.trim().length === 0 ){
                return "Будь ласка, введіть значення";
            }
            return "";
        };
    this.validator={
      "name": nonEmpty,
      "mfo": nonEmpty,
      "okpo": nonEmpty,
      "determinate_days": function(val) {
         if(validators.isPositiveInteger(val) !== true ){
                    return "Будь ласка, виберіть значення";
                }
                return "";
      },
      "telephone": function(val) {
                if(self.state.provider.telephone.length === 0 ){
                   return "Будь ласка, введіть хоча б один телефонний номер";
                }
                return "";
      },
      "email": function(val) {
          if(self.state.provider.email.length === 0 ){
              return "Будь ласка, введіть хоча б одну електронну адресу";
          }
          return "";
      },
      "email.email": function(val) {
                if(val.trim().length === 0 ){
                    return "Будь ласка, введіть значення";
                }
                if(validators.isValidEmail(val) !== true ){
                    return "Будь ласка, введіть адресу електронної пошти";
                }
                return "";
            },
      "telephone.telephone": function(val) {
          if(val.trim().length === 0 ){
              return "Будь ласка, введіть значення";
          }
          if(validators.isValidTelephoneNumber(val) !== true ){
              return "Будь ласка, введіть телефонний номер";
          }
          return "";
      },
     };

     this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidUpdate(){
    if(this.props.providerId !==  this.state.providerId) {
      this.setState({providerId: this.props.providerId });
      this.loadProvider();
    }    
  }

  componentDidMount(){
    // var app = this.context;
    this.setState({providerId: this.props.providerId });
    this.loadProvider();
  }


  validate(provider){
    var self = this;
    var errors={};
    var errorExists = false;
    [
      "name", "determinate_days", "status", "mfo",
      "okpo", "nds", "rr"
      // , "telephone", "email"
    ].map(function(elm) {
          if(self.validator[elm]) {
            errors[elm]  = self.validator[elm](provider[elm]);
            if (errors[elm]){
              errorExists = true;
            }
          } else {
            errors[elm] = "";
          }
          return elm;
    });

    return {
      errors:errors,
      errorExists: errorExists
    }
  }


  handleChangeFactory(attributeName) {
      var self = this;
      return function(event) {
        event.preventDefault();
        // deep copy of the data
            // update price if validated
            var provider = JSON.parse(JSON.stringify(self.state.provider));
            
            provider[attributeName] = event.target.value;

            var err = self.validate(provider);
          
            self.setState({
                provider: provider,
                errors: err.errors,
                message: "Дані змінено, але не збережено",
                messageType: "warning",
            });
      }
  }

  handleCheckboxFactory(attributeName) {
    var self = this;
    return function(event) {
        var provider = JSON.parse(JSON.stringify(self.state.provider));
        provider[attributeName] =  event.target.checked;
        self.setState({
            "provider": provider,
            "message": "Дані змінено, але не збережено",
            "messageType": "warning"
        });
    }
  }


  handleSubmit(event) {
        event.preventDefault();
        var self = this;
        var app = this.context;
        
        let provider = JSON.parse(JSON.stringify(this.state.provider));

        provider.provider_id =  provider.id;

        // do validation
        var err = self.validate(provider);

        if(err.errorExists) {
            self.setState({
              errors: err.errors,
              message:"Дані не збережено",
              messageType:"error",
            });
            return;
        }

        var onError = function(errorMessage) {
            self.setState({
              "message":errorMessage,
              "messageType":"error",
            });
          };

        if(provider.id>0){
          console.log('updating', provider);
          let postData = {
              "provider_id":provider.id,
              "name":provider.name,
              "mfo":provider.mfo,
              "okpo":provider.okpo,
              "rr": provider.rr,
              "nds": provider.nds,
              "determinate_days": provider.determinate_days,
              "status": provider.status,
              "email": provider.email,
              "telephone": provider.telephone,
          };
          app.postData(
              '/providers/edit',
              [postData]
              ).then(function(responseJson){
              // console.log("responseJson", responseJson, responseJson["status"]);
              if(responseJson["status"] === "OK") {
                  self.setState({
                      "message": "Дані оновлено",
                      "messageType": "success"
                  });
              } else if(responseJson["status"] === "EXPECTATION_FAILED") {
                  onError("Не вдалося зберегти дані");
              } else {
                  onError(responseJson["comment"]);
              }
              }).catch(() => onError("Невідома помилка"));
        } else {
            console.log('creating', provider);
            // let postData = {
            //     // "provider_id":provider.id,
            //     "name":provider.name,
            //     "mfo":provider.mfo,
            //     "okpo":provider.okpo,
            //     "rr": provider.rr,
            //     "nds": provider.nds,
            //     "determinate_days": provider.determinate_days,
            //     "status": provider.status,
            //     "email": provider.email,
            //     "telephone": provider.telephone,
            // };
            // this.props.app.postData(
            //     '/providers/add',
            //     [postData]
            //     ).then(function(responseJson){
            //     // console.log("responseJson", responseJson, responseJson["status"]);
            //     if(responseJson["status"] === "OK") {
            //         var provider = Object.assign({}, self.state.provider);
            //         provider.id = responseJson["detail"][0];
            //         self.setState({
            //             "provider": provider,
            //             "message": "Дані збережено",
            //             "messageType": "success"
            //         });
            //     } else if(responseJson["status"] === "EXPECTATION_FAILED") {
            //         onError("Не вдалося зберегти дані");
            //     } else {
            //         onError(responseJson["comment"]);
            //     }
            //     }).catch(() => onError("Невідома помилка"));
        }
  }

  handleTableFactory(action, attr, i, subAttr) {
        var self = this;
        if(action === "update") {
            return function(event) {
                // console.log("update", attr, subAttr);
                event.preventDefault();
                var provider = JSON.parse(JSON.stringify(self.state.provider));
                provider[attr][i][subAttr] = event.target.value;
                var errors = JSON.parse(JSON.stringify(self.state.errors));

                let validatorName = attr + '.' + subAttr;
                if(self.validator[validatorName]){
                    errors[attr] = errors[attr] || {};
                    errors[attr]["@"+i] = errors[attr]["@"+i] || {};
                    errors[attr]["@"+i][subAttr] = self.validator[validatorName](provider[attr][i][subAttr]);
                }
                self.setState({
                    "provider": provider,
                    "message": "Дані змінено, але не збережено",
                    "messageType": "warning",
                    "errors": errors
                });
            };
        }
        if(action === "delete") {
            return function(event) {
                // console.log("delete", attr, subAttr);
                event.preventDefault();
                var provider = JSON.parse(JSON.stringify(self.state.provider));
                provider[attr].splice(i, 1);
                self.setState({
                    "provider": provider,
                    "message": "Дані змінено, але не збережено",
                    "messageType": "warning"
                });
            };        
        }
        if(action === "add") {
            return function(event) {
                // console.log("add", attr, subAttr);
                event.preventDefault();
                var provider = JSON.parse(JSON.stringify(self.state.provider));
                provider[attr].push(subAttr);
                self.setState({
                    "provider": provider,
                    "message": "Дані змінено, але не збережено",
                    "messageType": "warning"
                });
            };
        }
  }

  handleDeleteFactory() {
      var self = this;
      var app = this.context;
      return function(event) {
          event.preventDefault();
          var id = self.props.providerId;
          if(id) {
              var onError = function(errorMessage) {
                  self.setState({
                      "message":errorMessage,
                      "messageType":"error",
                  });
              };
              var postData = [id];
              // post the DELETE command
              app.postData(
                      '/providers/delete',
                      postData
                  ).then(function(responseJson){
                      if(responseJson["status"] === "OK") {
                          self.props.parent.setState({
                            providerId: null,
                            view:self.props.parent.views.providers,
                          });
                      } else if(responseJson["status"] === "EXPECTATION_FAILED") {
                          onError("Не вдалося видалити дані");
                      } else {
                          onError(responseJson["comment"]);
                      }
                  }).catch(() => onError("Невідома помилка"));
          }
      }
  }

  getError(attr, i, subAttr){
      var errors = JSON.parse(JSON.stringify(this.state.errors));
      errors = errors[attr] || {};
      errors = errors["@"+i] || {};
      return errors[subAttr];
  }


  loadProvider(){
    var app = this.context;
    var self = this;
    var onError = function(errorMessage) {
        self.setState({
            "message": errorMessage,
            "messageType": "error"
        });
    };
    var data = {"param": [{"provider_id": parseInt(this.props.providerId)}]};
    return app.postData(
        '/providers/get',
        data
      ).then(function(responseJson){
          // console.log("responseJson", responseJson, responseJson["status"]);
          if(responseJson["status"] === "OK") {
              let data = responseJson["detail"][0];            
              self.setState({
                  "provider": data
              });
          } else if(responseJson["status"] === "NOT_FOUND") {
              self.props.app.setState({
                  "message": responseJson["comment"],
                  "messageType": "error"
              });
          } else {
              onError(responseJson["comment"]);
          }
    }).catch(() => onError("Не вдалося завантажити постачальника"));
  }

  render(){
    // global
    var app = this.context;
    var self = this;
    const { classes } = self.props;

    return (<>
      <div>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <div className={classes.breadCrumbs}>
                <Link href="javascript:void(1)"
                  onClick={() => self.props.parent.setState({
                    providerId: null,
                    view:self.props.parent.views.providers,
                  })}>Постачальники</Link>
                / {self.state.provider.name}
            </div>
          </Grid>
          <Grid item xs={7}>
            <h1 className={classes.pageTitle}>Постачальник "{self.state.provider.name}"</h1>
          </Grid>
          <Grid item xs={5}>
            <div className={classes.topButtons}>
            { app.state.userType === "admin" &&
              <>
              <Button
                variant="contained"
                color="success"
                size="small"
                className={classes.submitButtonDanger}
                onClick={self.handleDeleteFactory()}
                >
                Видалити
              </Button>
              &nbsp;
              </>
            }
              
              <Button
                variant="contained"
                color="success"
                size="small"
                className={classes.submitButtonSattelite}
                onClick={() => self.props.parent.setState({view: self.props.parent.views.contracts})}
                >
                &nbsp;
              </Button>
              <Button
                variant="contained"
                color="success"
                size="small"
                className={classes.submitButton}
                onClick={() => self.props.parent.setState({view: self.props.parent.views.contracts})}
                >
                Договори
              </Button>
            </div>
          </Grid>

          {self.state.message && (
            <Grid item xs={12}>
              <MessageBlock message={self.state.message} messageType={self.state.messageType}/>
            </Grid>
          )}

          <Grid  item xs={6}>
            <div className={classes.crmxCard}>
              <Grid container spacing={4}>

                <Grid item xs={5}>
                  <div className={classes.formFieldLabel}>
                    Назва
                  </div>
                </Grid>
                <Grid item xs={7}>
                  <TextField
                    value={self.state.provider.name}
                    onChange={self.handleChangeFactory("name")}
                    margin="dense"
                    placeholder="Назва"
                    type="text"
                    fullWidth
                    variant="standard" 
                    className={self.state.errors.name ? classes.textFieldErr : classes.textField}
                    />
                </Grid>
              
                <Grid item xs={5}>
                  <div className={classes.formFieldLabel}>
                    Рахунок
                  </div>
                </Grid>
                <Grid item xs={7}>
                  <TextField
                    value={self.state.provider.rr}
                    onChange={self.handleChangeFactory("rr")}
                    margin="dense"
                    placeholder="Рахунок"
                    type="text"
                    fullWidth
                    variant="standard" 
                    className={self.state.errors.rr ? classes.textFieldErr : classes.textField}
                    />
                </Grid>

                <Grid item xs={5}>
                  <div className={classes.formFieldLabel}>
                    МФО
                  </div>
                </Grid>
                <Grid item xs={7}>
                  <TextField
                    value={self.state.provider.mfo}
                    onChange={self.handleChangeFactory("mfo")}
                    margin="dense"
                    placeholder="МФО"
                    type="text"
                    fullWidth
                    variant="standard" 
                    className={self.state.errors.mfo ? classes.textFieldErr : classes.textField}
                    />
                </Grid>

                <Grid item xs={5}>
                  <div className={classes.formFieldLabel}>
                    ЄДРПОУ
                  </div>
                </Grid>
                <Grid item xs={7}>
                  <TextField
                    value={self.state.provider.okpo}
                    onChange={self.handleChangeFactory("okpo")}
                    margin="dense"
                    placeholder="ЄДРПОУ"
                    type="text"
                    fullWidth
                    variant="standard" 
                    className={self.state.errors.okpo ? classes.textFieldErr : classes.textField}
                    />
                </Grid>

                <Grid item xs={5}>
                  <div className={classes.formFieldLabel}>
                    Відстрочка платежу
                  </div>
                </Grid>
                <Grid item xs={7}>
                  <TextField
                    value={self.state.provider.determinate_days}
                    onChange={self.handleChangeFactory("determinate_days")}
                    margin="dense"
                    placeholder="Відстрочка платежу"
                    type="text"
                    fullWidth
                    variant="standard" 
                    className={self.state.errors.determinate_days ? classes.textFieldErr : classes.textField}
                    />
                </Grid>
                
                <Grid item xs={5}>
                  <label className={classes.formFieldLabel}>
                    <input type="checkbox" 
                          checked={self.state.provider.nds}
                          onChange={this.handleCheckboxFactory("nds")}
                          onClick={this.handleCheckboxFactory("nds")} />
                    Платник ПДВ
                  </label>
                </Grid>
                <Grid item xs={7}>
                  <label className={classes.formFieldLabel}>
                  <input type="checkbox" 
                        checked={self.state.provider.status}
                        onChange={this.handleCheckboxFactory("status")}
                        onClick={this.handleCheckboxFactory("status")} />
                  Активний
                  </label>
                </Grid>

              </Grid>
            </div>
          </Grid>

          <Grid  item xs={6}>
            <div className={classes.crmxCard}>
                <div className={classes.formFieldLabel}>Телефони:</div>
                <table className={classes.crmXTable + " " + classes.crmXColWidth} cellspacing="0">
                <thead>
                  <tr>
                    <th>&nbsp;</th>
                    <th>Телефон</th>
                    <th>Примітка</th>
                  </tr>
                </thead>
                <tbody>
                {this.state.provider.telephone && this.state.provider.telephone.map(function(object, i){
                    return <tr>
                        <td>
                          <div  className={classes.columnAction}>
                            <button className={classes.rowActionButtonRed}
                                onClick={self.handleTableFactory("delete","telephone", i, "telephone")}
                            >&minus;</button>
                            <button className={classes.rowActionButtonSattelite}
                                onClick={self.handleTableFactory("delete","telephone", i, "telephone")}
                            >&gt;</button>
                          </div>
                        </td>
                        <td>
                          <TextField
                            key={"telephone_value_"+i}
                            value={object.telephone}
                            onChange={self.handleTableFactory("update", "telephone", i, "telephone")}
                            margin="dense"
                            placeholder="Номер телефона"
                            type="text"
                            fullWidth
                            variant="standard" 
                            className={self.getError("telephone", i, "telephone") ? classes.tableTextFieldErr : classes.tableTextField}
                            />
                        </td>
                        <td>
                          <TextField
                            key={"telephone_comment_"+i}
                            value={object.comment}
                            onChange={self.handleTableFactory("update", "telephone", i, "comment")}
                            margin="dense"
                            placeholder="Примітки"
                            type="text"
                            fullWidth
                            variant="standard" 
                            className={self.getError("telephone", i, "comment") ? classes.tableTextFieldErr : classes.tableTextField}
                            />
                        </td>
                    </tr>;
                })}
                <tr>
                  <td>
                    <div  className={classes.columnAction}>
                      <button className={classes.rowActionButton}
                              onClick={self.handleTableFactory("add","telephone", null, {"telephone_id":0, "telephone":"", "comment":""})}
                      >+</button>
                      <button className={classes.rowActionButtonSattelite}
                              onClick={self.handleTableFactory("add","telephone", null, {"telephone_id":0, "telephone":"", "comment":""})}
                      >&gt;</button>
                    </div>
                  </td>
                  <td></td>
                  <td></td>
                </tr>
                </tbody>
                </table>
            </div>
            <div className={classes.crmxCard}>
                <div className={classes.formFieldLabel}>E-mail:</div>
                <table className={classes.crmXTable + " " + classes.crmXColWidth} cellspacing="0">
                <thead>
                  <tr>
                    <th>&nbsp;</th>
                    <th>Email</th>
                    <th>Примітка</th>
                  </tr>
                </thead>
                <tbody>

                {this.state.provider.email && this.state.provider.email.map(function(object, i){
                    return <tr>
                        <td>
                          <div  className={classes.columnAction}>
                          <button className={classes.rowActionButtonRed}
                              onClick={self.handleTableFactory("delete","email", i, "email")}
                          >&minus;</button><button className={classes.rowActionButtonSattelite}
                              onClick={self.handleTableFactory("delete","email", i, "email")}
                          >&gt;</button>
                          </div>
                        </td>
                        <td>
                          <TextField
                            key={"email_value_"+i}
                            value={object.email}
                            onChange={self.handleTableFactory("update", "email", i, "email")}
                            margin="dense"
                            placeholder="email"
                            type="text"
                            fullWidth
                            variant="standard" 
                            className={self.getError("email", i, "email") ? classes.tableTextFieldErr : classes.tableTextField}
                            />
                        </td>
                        <td>
                          <TextField
                            key={"email_comment_"+i}
                            value={object.comment}
                            onChange={self.handleTableFactory("update", "email", i, "comment")}
                            margin="dense"
                            placeholder="Примітки"
                            type="text"
                            fullWidth
                            variant="standard" 
                            className={self.getError("email", i, "comment") ? classes.tableTextFieldErr : classes.tableTextField}
                            />
                        </td>
                    </tr>;
                })}
                <tr>
                  <td>
                    <div  className={classes.columnAction}>
                        <button className={classes.rowActionButton}
                                onClick={self.handleTableFactory("add","email", null, {"email_id":0, "email":"", "comment":""})}
                        >+</button><button className={classes.rowActionButtonSattelite}
                                onClick={self.handleTableFactory("add","email", null, {"email_id":0, "email":"", "comment":""})}
                        >&gt;</button>
                    </div>
                  </td>
                  <td></td>
                  <td></td>
                </tr>
                </tbody>
                </table>
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={classes.formButtons} style={{textAlign:"center"}}>
            

              <Button
                variant="contained"
                color="success"
                size="large"
                className={classes.submitButtonSattelite}
                onClick={self.handleSubmit}
                >
                &nbsp;
              </Button>
              <Button
                variant="contained"
                color="success"
                size="large"
                className={classes.submitButton}
                onClick={self.handleSubmit}
                >
                Зберегти
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
    </>);
    
  }
}
export default withStyles(styles)(ProviderInfo);

