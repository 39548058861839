import React, {Component} from "react";
import {
  Button
} from "@material-ui/core";


/**
 <CrmButton
  classNameMain={...}
  classNameSattelite={...}
  size="large|small"
  onClick={...}
 >
 btnText
 </CrmButton>
 */
class CrmButton extends Component {
  render(){
    var {children, onClick, classNameMain, classNameSattelite, size} = this.props;
    return (
      <>
        <span style={{display:"inline-block", whiteSpace:"nowrap"}}>
          <Button
            variant="contained"
            color="primary"
            size={size}
            className={classNameMain}
            onClick={onClick}
          >
            {children}
          </Button>
          <Button
            variant="contained"
            color="primary"
            size={size}
            className={classNameSattelite}
            onClick={onClick}
            >
              &gt;
          </Button>
        </span>
      </>
    );
  }
}

export  {CrmButton};
