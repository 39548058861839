import React, {Component}  from "react";
import {
  Grid,
  Select,
  MenuItem,
  Button,
  TextField,
  TextareaAutosize,
  // Link,
} from "@material-ui/core";
import moment from 'moment';
// import DateTimePicker from '@mui/lab/DateTimePicker';
// import DatePicker from '@mui/lab/DatePicker';
import {
      CrmDateTimePicker, 
      // CrmDatePicker
} from '../../../../components/CrmDateTimePicker/CrmDateTimePicker';

// components
// import MUIDataTable from "mui-datatables";
import validators   from '../../../../utils/validators';
import MessageBlock from '../../../../utils/MessageBlock';
// context
import { AppContext } from "../../../../context/AppContext";
import ApiModel from "../../../../utils/ApiModel";
// import regions   from '../../../../utils/regions_ua';

// styles
import { withStyles } from "@material-ui/core/styles";
import Themes from "../../../../themes";
import customStyles from "./styles";
// import { Alarm } from "@material-ui/icons";
const theme = Themes.default;
const styles = customStyles(theme);



/**
id: <int>
				task_main_id:<int>
				requester_id:<int>
				personal_id:<int>
				start_date: <date>
				end_date:<datetime>
				comments:<text>
				task_status:<int>
 */
class TaskInfo extends Component {

  static contextType = AppContext;
  constructor(props) {
    super(props);
    let now = moment().format('YYYY-MM-DD HH:mm:ss');
    this.defaultRecord=JSON.stringify({
      id:0,                                   //  bigint(20)
      contractor_okpo: 0,
      task_main_id:0,   //  bigint(20)
      requester_id:0,   //  bigint(20)
      personal_id:0,                          //  bigint(20) => companies
      start_date:now,                         //  datetime
      end_date:now,                           //  datetime
      comments:"",                            //  text
      task_status:0,                          //  bigint(20) => type_payments
    });

    if(!this.state) {
      this.state = {
        taskId:this.props.taskId,
        task:{},

        errors: {},
        message:"",
        messageType:"",

        personal:[],              // list of persons
        filteredPersonal:[],
        task_status:[],           // list of task statuses
        type_task:[],
        default_task_status:{},
      };
    }
    // var self =this;

    var nonEmpty = function(val) {
      if((val + '').trim().length === 0 ){
          return "Будь ласка, введіть значення";
      }
      return "";
    };

    var isPositiveInteger = function(val) {
      if(validators.isPositiveInteger(val) !== true ){
        return "Будь ласка, виберіть значення";
      }
      return "";
    }
    this.validator = {
      // task_main_id:isPositiveInteger,
      // requester_id:isPositiveInteger,
      personal_id:isPositiveInteger,
      start_date:nonEmpty, 
      end_date:nonEmpty, 
      // comments:nonEmpty, 
      task_status:isPositiveInteger,
      type_task:isPositiveInteger,
      contractor_okpo:nonEmpty
     };

     this.handleSubmit = this.handleSubmit.bind(this);
     this.loadPersonal = ApiModel.loadPersonal(this, "personal", "filteredPersonal");
     this.loadTaskStatus = ApiModel.loadTaskStatus(this, "task_status");
     this.loadTaskType = ApiModel.loadTaskType(this, "type_task");
     this.loadOneTask = ApiModel.loadOneTask(this, "task");
     this.loadTaskStatusDefault = ApiModel.loadTaskStatusDefault(this, "default_task_status");
     this.validate = ApiModel.validate(this);
  }

  componentDidUpdate(){
    if(this.props.taskId !==  this.state.taskId) {
      var self = this;
      this.setState({ taskId: this.props.taskId,  });
      self.loadOneTask().finally(function(){
        self.applyDefaults();
      });
    }    
  }

  componentDidMount(){
    // var app = this.context;
    var self = this;
    self.setState({
        taskId: this.props.taskId,
    });
    self.loadPersonal().finally(function(){
      self.loadTaskStatus().finally(function(){
        self.loadTaskType().finally(function(){
          self.loadOneTask().finally(function(){
            self.loadTaskStatusDefault().finally(function(){
              self.applyDefaults();
            });
          });
        });
      });
    });
  }

  applyDefaults(){
    var self = this;
    var task = JSON.parse(JSON.stringify(self.state.task));

    var n_updates=0;

    if(!task.contractor_okpo) {
      task.contractor_okpo = self.props.default_contractor_okpo || "";
      if(task.contractor_okpo) n_updates += 1;
    }

    if(!task.end_date) {
      task.end_date = self.props.default_end_date || "";
      if(task.end_date) n_updates += 1;
    }

    if(!task.personal_id) {
      task.personal_id = parseInt(self.props.default_personal_id) || "";
      if(task.personal_id) n_updates += 1;
    }

    if(!task.type_task) {
      task.type_task = parseInt(self.props.default_type_task) || "";
      if(task.type_task) n_updates += 1;
    }

    if(!task.task_status) {
      if(self.state.default_task_status && self.state.default_task_status.id) {
        task.task_status = parseInt(self.state.default_task_status.id) || "";
      }
      if(task.task_status) n_updates += 1;
    }
    if(!task.comments) {
      if(self.props.default_comments ) {
        task.comments = self.props.default_comments || "";
      }
      if(task.task_status) n_updates += 1;
    }
    

    if(n_updates > 0) {
      console.log("task", task);
      self.setState({
          task: task,
      });
    }



  }

  handleChangeFactory(attributeName) {
      var self = this;
      return function(event) {
        event.preventDefault();
        // deep copy of the data
        var task = JSON.parse(JSON.stringify(self.state.task));
        
        task[attributeName] = event.target.value;

        //var err = self.validate(task);
      
        self.setState({
            task: task,
            //errors: err.errors,
            message: "Дані змінено, але не збережено",
            messageType: "warning",
        });
      }
  }

  handleDateChangeFactory(attributeName) {
      var self = this;
      return function(newValue) {
        // console.log(attributeName, newValue.format('YYYY-MM-DD HH:mm:ss'));
        // deep copy of the data
        var task = JSON.parse(JSON.stringify(self.state.task));
        
        task[attributeName] = newValue.format('YYYY-MM-DD HH:mm:ss');

        //var err = self.validate(task);
      
        self.setState({
            task: task,
            //errors: err.errors,
            message: "Дані змінено, але не збережено",
            messageType: "warning",
        });
      }
  }

  handleCheckboxFactory(attributeName) {
    var self = this;
    return function(event) {
        var task = JSON.parse(JSON.stringify(self.state.task));
        task[attributeName] =  event.target.checked;
        self.setState({
            "auction": task,
            "message": "Дані змінено, але не збережено",
            "messageType": "warning"
        });
    }
  }

  handleSubmit(event) {
        event.preventDefault();
        var self = this;
        var app = this.context;
        
        let task = JSON.parse(JSON.stringify(this.state.task));

        // do validation
        console.log('validate', task);
        var err = self.validate(task);

        if(err.errorExists) {
            self.setState({
              errors: err.errors,
              message:"Виправте дані",
              messageType:"error",
            });
            return;
        }

        var onError = function(errorMessage) {
            self.setState({
              "message":errorMessage,
              "messageType":"error",
            });
          };
        let defaults = JSON.parse(this.defaultRecord);
        let postData = {
              task_main_id:parseInt(self.props.parentTaskId) || defaults.task_main_id, //  int
              personal_id:parseInt(task.personal_id) || defaults.personal_id,                           //  text
              start_date:task.start_date || defaults.start_date,
              end_date:task.end_date || defaults.end_date,
              comments:task.comments || defaults.comments,
              task_status:parseInt(task.task_status) || defaults.task_status, //  int
              type_task:parseInt(task.type_task) || defaults.type_task,       //  int
        };
        let contractor_okpo = task.contractor_okpo || self.props.contractorOkpo;
        if(contractor_okpo) {
          postData.contractor_okpo = contractor_okpo;
        }

        // requester_id:parseInt(task.requester_id) || app.state.personal_id || app.state.root_id,                           //  text
        // if(app.state.userType === "admin") {
          postData.requester_admin_id = parseInt( task.requester_admin_id) ||  app.state.root_id;
        // } else {
          postData.requester_id = parseInt(task.requester_id) || app.state.personal_id;
        // }
        if(task.id){
          console.log('updating', postData);
          postData['id'] = parseInt(task.id) || 0;
          app.postData(
              '/tasks/edit',
              [postData]
              ).then(function(responseJson){
              // console.log("responseJson", responseJson, responseJson["status"]);
              if(responseJson["status"] === "OK") {
                  self.setState({
                      "message": "Дані оновлено",
                      "messageType": "success"
                  });
                  app.setState( { cmd_task:[
                    {action:"closePopup"},
                    {action:"refresh"},
                  ]});
              } else if(responseJson["status"] === "EXPECTATION_FAILED") {
                  onError("Не вдалося зберегти дані");
              } else {
                  onError(responseJson["comment"]);
              }
              }).catch(() => onError("Невідома помилка"));
        } else {
            console.log('creating', postData);
            app.postData(
                '/tasks/add',
                [postData]
            ).then(function(responseJson){
                // console.log("responseJson", responseJson, responseJson["status"]);
                if(responseJson["status"] === "OK") {
                    var task = Object.assign({}, self.state.task);
                    task.id = responseJson["detail"][0];
                    app.setState( { cmd_task:[
                      {action:'refresh'},
                      {action:'closePopup'},
                      {action:'setAuctionId', taskId: task.id, auctionId: self.props.default_auction_id},
                    ] });
                } else if(responseJson["status"] === "EXPECTATION_FAILED") {
                    onError("Не вдалося зберегти дані");
                } else {
                    onError(responseJson["comment"]);
                }
            }).catch(() => onError("Невідома помилка"));
        }
  }

  render(){
    // global
    var app = this.context;
    var self = this;
    const { classes } = self.props;

    console.log("auctionId", self.props.auctionId);
    return (<>
      <div>
        <Grid container spacing={3}>

          {self.state.message && (<Grid item xs={12}>
          <MessageBlock message={self.state.message} messageType={self.state.messageType}/>
          </Grid>)}

          {self.props.taskId 
            ? (<Grid item xs={12}><h1>Змінити завдання</h1></Grid>)
            : (<Grid item xs={12}><h1>Додати завдання</h1></Grid>)
          }
          { self.props.parentTaskId > 0 
            ? (<Grid item xs={12}><h2>Долучити співробітника до завдання №{self.props.parentTaskId}</h2></Grid>)
            : ''
          }

          <Grid item xs={6} md={3}>
            <div className={classes.formFieldLabel}>
              ЄДРПОУ контрагента
            </div>
          </Grid>
          <Grid item xs={6} md={9}>
            <TextField
              value={self.state.task.contractor_okpo}
              onChange={self.handleChangeFactory("contractor_okpo")}
              margin="dense"
              placeholder="ЄДРПОУ контрагента"
              type="text"
              fullWidth
              variant="standard" 
              className={self.state.errors.contractor_okpo ? classes.textFieldErr : classes.textField}
              />
          </Grid>

          <Grid item xs={6} md={3}>
            <div className={classes.formFieldLabel}>
              Завдання для співробітника
            </div>
          </Grid>
          <Grid item xs={6} md={9}>
              <Select
                value={self.state.task.personal_id || ''}
                onChange={self.handleChangeFactory("personal_id")}
                className={self.state.errors.personal_id ? classes.selectFieldErr : classes.selectField}
              >
                <MenuItem value="" key={'personal_X'} >-</MenuItem>
                {self.state.personal.map(function(elm, ind){
                    return <MenuItem key={'personal_' + ind} value={elm.id}  className={classes.selectOption}>{elm.fname} {elm.sname} {elm.lname}</MenuItem>;
                })}
              </Select>
          </Grid>

          <Grid item xs={6} md={3}>
            <div className={classes.formFieldLabel}>
              Дата початку
            </div>
          </Grid>
          <Grid item xs={6} md={9}>
            <CrmDateTimePicker
              renderInput={(props) => <TextField {...props} />}
              label="Дата початку"
              value={self.state.task.start_date}
              onChange={self.handleDateChangeFactory("start_date")}
              margin="dense"
              placeholder="Дата початку"
              variant="standard" 
              className={self.state.errors.start_date ? classes.textFieldErr : classes.textField}
            />
          </Grid>

          <Grid item xs={6} md={3}>
            <div className={classes.formFieldLabel}>
              Термін виконання
            </div>
          </Grid>
          <Grid item xs={6} md={9}>
            <CrmDateTimePicker
              renderInput={(props) => <TextField {...props} />}
              label="Термін виконання"
              value={self.state.task.end_date}
              onChange={self.handleDateChangeFactory("end_date")}
              margin="dense"
              placeholder="Термін виконання"
              variant="standard" 
              className={self.state.errors.end_date ? classes.textFieldErr : classes.textField}
            />
          </Grid>

          <Grid item xs={6} md={3}>
            <div className={classes.formFieldLabel}>
              Коментар до завдання
            </div>
          </Grid>
          <Grid item xs={6} md={9}>
            <TextareaAutosize
              minRows={3}
              style={{width:"100%"}}
              value={self.state.task.comments}
              onChange={self.handleChangeFactory("comments")}
              margin="dense"
              placeholder="Примітки"
              type="text"
              variant="standard" 
              className={self.state.errors.comments ? classes.textFieldErr : classes.textField}
              />
          </Grid>


          <Grid item xs={6} md={3}>
            <div className={classes.formFieldLabel}>
              Статус завдання
            </div>
          </Grid>
          <Grid item xs={6} md={9}>
              <Select
                value={self.state.task.task_status || ''}
                onChange={self.handleChangeFactory("task_status")}
                className={self.state.errors.task_status ? classes.selectFieldErr : classes.selectField}
              >
                <MenuItem value="" key={'task_status_X'} >-</MenuItem>
                {self.state.task_status.filter(x => x.name !== "Завершено").map(function(elm, ind){
                    return <MenuItem key={'task_status_' + ind} value={elm.id}  className={classes.selectOption}>{elm.name}</MenuItem>;
                })}
              </Select>
          </Grid>

          <Grid item xs={6} md={3}>
            <div className={classes.formFieldLabel}>
              Тип завдання
            </div>
          </Grid>
          <Grid item xs={6} md={9}>
              <Select
                value={self.state.task.type_task || ''}
                onChange={self.handleChangeFactory("type_task")}
                className={self.state.errors.type_task ? classes.selectFieldErr : classes.selectField}
              >
                <MenuItem value="" key={'type_task_X'} >-</MenuItem>
                {self.state.type_task.map(function(elm, ind){
                    return <MenuItem key={'type_task_' + ind} value={elm.id}  className={classes.selectOption}>{elm.name}</MenuItem>;
                })}
              </Select>
          </Grid>


          <Grid item xs={12}>
            <div className={classes.formButtons}>
              <Button
                variant="contained"
                color="success"
                size="large"
                className={classes.submitButtonSattelite}
                onClick={() => app.setState( { cmd_task:[{action:"closePopup"}] })}
                >
                &nbsp;
              </Button>
              <Button
                variant="contained"
                color="success"
                size="large"
                className={classes.submitButton}
                onClick={() => app.setState( { cmd_task:[{action:"closePopup"}] })}
                >
                Закрити
              </Button>
              
              <Button
                variant="contained"
                color="success"
                size="large"
                className={classes.submitButtonSattelite}
                onClick={self.handleSubmit}
                >
                &nbsp;
              </Button>
              <Button
                variant="contained"
                color="success"
                size="large"
                className={classes.submitButton}
                onClick={self.handleSubmit}
                >
                Зберегти
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
    </>);
    
  }
}
export default withStyles(styles)(TaskInfo);

