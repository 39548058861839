
function customStyles(theme) {
  return {
    breadCrumbs:{
    },
    popOver:theme.popOver,
    popOverMini: theme.popOverMini,
    formButtons:theme.formButtons,
    submitButton:theme.submitButton,
    textFieldErr:theme.textFieldErr,
    textField:theme.textField,
    tableTextFieldErr:theme.tableTextFieldErr,
    tableTextField:theme.tableTextField,
    rowActionButton:theme.rowActionButton,
    rowActionButtonSattelite:theme.rowActionButtonSattelite,
    rowActionButtonDisabled:theme.rowActionButtonDisabled,
    rowActionButtonSatteliteDisabled:theme.rowActionButtonSatteliteDisabled,
    rowActionButtonRed: theme.rowActionButtonRed,
    columnAction: theme.columnAction,
    submitButtonDanger:theme.submitButtonDanger,
    submitButtonSattelite: theme.submitButtonSattelite,
    selectFieldErr: theme.selectFieldErr,
    selectField: theme.selectField,
    pageTitle:theme.pageTitle,
    topButtons:theme.topButtons,
    btnBlock:theme.btnBlock,
    blockError:theme.blockError,
    formFieldLabel:theme.formFieldLabel,
    crmXTable: theme.crmXTable,
    btnGroup:theme.btnGroup,
    crmXColWidth:{
      "& tbody td:nth-child(1)":{width:"5%", textAlign:"center",},
      "& tbody td:nth-child(2)":{width:"10%", textAlign:"center",},
      "& tbody td:nth-child(3)":{width:"15%", textAlign:"center",},
      "& tbody td:nth-child(4)":{width:"15%", textAlign:"center",},
      "& tbody td:nth-child(5)":{width:"10%", textAlign:"center",},
      "& tbody td:nth-child(6)":{width:"10%", textAlign:"center",},
      "& tbody td:nth-child(7)":{width:"10%", textAlign:"center",},
      "& tbody td:nth-child(8)":{width:"10%", textAlign:"center",},
      "& tbody td:nth-child(9)":{width:"10%", textAlign:"left",},
    }
  };
}

export default customStyles;