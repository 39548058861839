import React, {Component}  from "react";
import {
  //Grid,
  // LinearProgress,
  // Select,
  // OutlinedInput,
  // MenuItem,
  Button,
  //Popover
} from "@material-ui/core";


// components
// import Widget from "../../components/Widget/Widget";
// import PageTitle from "../../components/PageTitle/PageTitle";
// import { Typography } from "../../components/Wrappers/Wrappers";
// import Dot from "../../components/Sidebar/components/Dot";
import MUIDataTable from "mui-datatables";

// context
import { AppContext } from "../../context/AppContext";
import MessageBlock from '../../utils/MessageBlock';

import ProviderInfo from "./components/ProviderInfo/ProviderInfo";
import Contracts from "./components/Contracts/Contracts";
import ContractInfo from "./components/ContractInfo/ContractInfo";
import ContractSpecs from "./components/ContractSpecs/ContractSpecs";
import ContractAppendixes from "./components/ContractAppendixes/ContractAppendixes";
import ContractAppendixInfo from "./components/ContractAppendixInfo/ContractAppendixInfo";
import ContractAppendixSpecs from "./components/ContractAppendixSpecs/ContractAppendixSpecs";

// styles
import { withStyles } from "@material-ui/core/styles";
import Themes from "../../themes";
import customStyles from "./styles";
const theme = Themes.default;
const styles = customStyles(theme);



class Provider extends Component {

  static contextType = AppContext;

  constructor(props) {

    super(props);

    this.views = {
      providers:  "providers",
      providerInfo:  "providerInfo",
      contracts:  "contracts",
      contractInfo:  "contractInfo",
      contractSpecs: "contractSpecs",
      contractAppendixes: "contractAppendixes",
      contractAppendixInfo: "contractAppendixInfo",
      contractAppendixSpecs: "contractAppendixSpecs",
    }

    this.columns=[
      {
        name: "id",
        label: "id",
        options: { filter: false, sort: false, }
      },
      {
        name: "name",
        label: "Назва",
        options: { filter: false, sort: false, }
      },
      {
        name: "status_view",
        label: "Активний",
        options: { filter: false, sort: false, }
      },
      {
        name: "action",
        label: "Дії",
        options: { filter: false, sort: false, }
      },
    ];
    if(!this.state) {
      this.state = {
          providers:[],
          providerId: null,
          contract_number: null,
          contractAppendixId: null,
          view:"providers",
          message:"",
          messageType:"",
          filterHash:"",
      };
    }
    // this.filterHash = JSON.stringify(this.props.filter);
    this.closeProviderInfo = this.closeProviderInfo.bind(this);

    // this.validators = new Validators();
  }

  componentDidUpdate(){
    var newFilterHash = JSON.stringify(this.props.filter);
    if(newFilterHash !==  this.state.filterHash) {
      this.setState({
        filterHash: newFilterHash,
        view:this.views.providers,
      });
      this.loadProviders();
    }
  }

  componentDidMount(){
    var app = this.context;
    var self = this;
    this.loadProviders().then(function(){
      app.setState({
        sidebarMenuId:"provider",
        view: self.views.providers,
        filterHash: JSON.stringify(self.props.filter)
      });
    });
  }
  

  closeProviderInfo(){
    this.setState({
      providerId: 0,
      view:this.views.providers,
    });
  }

  showProviderInfoFactory(id) {
      var self=this;
      return function(event){
        self.setState({
          providerId: id,
          view:self.views.providerInfo,
        });
      }
  }

  loadProviders(){
    var app = this.context;
    var self = this;
    const { classes } = self.props;

    var onError = function(errorMessage) {
          self.setState({
            "message":errorMessage,
            "messageType":"error",
          });
        };

    var filter = this.props.filter ;
    var param = {};
    if(filter.edrpou) param["edrpou"] = filter.edrpou;
    if(filter.providerId) param["provider_id"] = filter.providerId;
    if(filter.typePaymentsId) param["type_payments_id"] = filter.typePaymentsId;
    if(filter.dealStatusId) param["deal_status_id"] = filter.dealStatusId;
    if(filter.determinateDays) param["determinate_days"] = filter.determinateDays;

    if(Object.keys(param).length > 0) {
        param = [param];
    } else {
        param = [];
    }

    return app.postData(
      '/providers/get',
      {"param": param}
    ).then(function(responseJson){
      if(responseJson["status"] === "OK") {
        var items = responseJson["detail"].map(function(elm){
            elm["status_view"] = !!elm["status"] ? "Так" : "Ні";
            elm["action"]=(<>
              <span className={classes.rowButton}>
                        <Button 
                          onClick={self.showProviderInfoFactory(elm.id)}
                          className={classes.rowActionButton}
                          >
                          Показати
                        </Button>
                        <Button 
                          onClick={self.showProviderInfoFactory(elm.id)}
                          className={classes.rowActionButtonSattelite}
                          >
                          &gt;
                        </Button>
              </span>
            </>);
            return elm;
        });
        self.setState({
          "providers": items,
          "message":"",
          "messageType":"",
        });
      } else {
        self.setState({
          "providers": [],
          "message":responseJson["comment"] || "Не вдалося знайти записи. Спробуйте змінии умови пошуку.",
          "messageType":"error",
        });
      }
    }).catch(() => onError("Невідома помилка"));
  }

  render(){
    // global
    // var app = this.context;
    var self = this;
    const { classes } = self.props;

    return (
      <div className={classes.crmXContent}>
      <div id="mainDataTable"></div>
      { self.state.view === self.views.providers && (
       <>
          {self.state.message && (<div>
              <MessageBlock message={self.state.message} messageType={self.state.messageType}/>
          </div>)}
          <MUIDataTable
            // title="Employee List"
            data={self.state.providers}
            columns={self.columns}
            options={{
              filterType: "checkbox",
              download:false,
              print:false,
              search:false,
              filter:false,
              viewColumns:false,
              selectableRows: "none",
              textLabels: {
                  body: {
                    noMatch: "Вибачте, записів не знайдено",
                    toolTip: "Впорядкувати",
                    columnHeaderTooltip: column => `Впорядкувати за ${column.label}`
                  },
                  pagination: {
                    next: "Наступна сторінка",
                    previous: "Попередня сторінка",
                    rowsPerPage: "Рядків на сторінці:",
                    displayRows: "із",
                  },
                  toolbar: {
                    search: "Знайти",
                    downloadCsv: "Скачати CSV",
                    print: "Надрукувати",
                    viewColumns: "Показаи колонки",
                    filterTable: "Фільтрувати таблицю",
                  },
                  filter: {
                    all: "Всі",
                    title: "Фільтри",
                    reset: "Очистити",
                  },
                  viewColumns: {
                    title: "Показати колонки",
                    titleAria: "Показати/Приховати колонки",
                  },
                  selectedRows: {
                    text: "рідків вибрано",
                    delete: "Видалити",
                    deleteAria: "Видалити вибрані рядки",
                  },
              }
            }}
            className={classes.crmXTable + ' ' + classes.crmXColWidth}
          />       
       </>
      )
      }
      
      { self.state.view === self.views.providerInfo &&
        <ProviderInfo providerId={self.state.providerId} parent={this}/>
      }

      { self.state.view === self.views.contracts &&
        <Contracts providerId={self.state.providerId} parent={this}/>
      }

      { self.state.view === self.views.contractInfo &&
        <ContractInfo 
          providerId={self.state.providerId} 
          contract_number={self.state.contract_number}
          parent={this}/>
      }

      { self.state.view === self.views.contractSpecs &&
        <ContractSpecs 
          providerId={self.state.providerId} 
          contract_number={self.state.contract_number}
          parent={this}/>
      }

      { self.state.view === self.views.contractAppendixes &&
        <ContractAppendixes 
          providerId={self.state.providerId} 
          contract_number={self.state.contract_number}
          parent={this}/>
      }
      
      { self.state.view === self.views.contractAppendixInfo &&
        <ContractAppendixInfo 
          providerId={self.state.providerId} 
          contract_number={self.state.contract_number}
          appendixId={self.state.appendixId}
          parent={this}/>
      }

      { self.state.view === self.views.contractAppendixSpecs &&
        <ContractAppendixSpecs 
          providerId={self.state.providerId} 
          contract_number={self.state.contract_number}
          appendixId={self.state.appendixId}
          parent={this}/>
      }
      
      
      </div>
    );
  }

}
export default withStyles(styles)(Provider);

