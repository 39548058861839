import React, {Component}  from "react";
import {
  Grid,
  // Select,
  // MenuItem,
  Button,
  // TextField,
  // TextareaAutosize,
  // Link,
  // Popover,
} from "@material-ui/core";
import moment from 'moment';
// import DateTimePicker from '@mui/lab/DateTimePicker';
// import DatePicker from '@mui/lab/DatePicker';
// import { styled } from '@mui/material/styles';


// components
// import MUIDataTable from "mui-datatables";
// import validators   from '../../../../utils/validators';
import MessageBlock from '../../../../utils/MessageBlock';
// context
import { AppContext } from "../../../../context/AppContext";
import ApiModel from "../../../../utils/ApiModel";
// import regions   from '../../../../utils/regions_ua';

// styles
import { withStyles } from "@material-ui/core/styles";
import Themes from "../../../../themes";
import customStyles from "./styles";
// import { Alarm } from "@material-ui/icons";


const theme = Themes.default;
const styles = customStyles(theme);
// const Input = styled('input')({  display: 'none',});

// const patternImage = new RegExp('(jpg|jpeg|png)$', 'i');
// const patternPdf = new RegExp('pdf$', 'i');

/**
id: <int>
				task_main_id:<int>
				requester_id:<int>
				personal_id:<int>
				start_date: <date>
				end_date:<datetime>
				comments:<text>
				task_status:<int>
 */
class TaskFiles extends Component {

  static contextType = AppContext;
  constructor(props) {
    super(props);
    let now = moment().format('YYYY-MM-DD HH:mm:ss');
    this.defaultRecord=JSON.stringify({
      id:0,                                   //  bigint(20)
      task_main_id:this.props.parentTaskId,   //  bigint(20)
      requester_id:0,   //  bigint(20)
      personal_id:0,                          //  bigint(20) => companies
      start_date:now,                         //  datetime
      end_date:now,                           //  datetime
      comments:"",                            //  text
      task_status:0,                          //  bigint(20) => type_payments
    });

    if(!this.state) {
      this.state = {
        taskId:this.props.taskId,
        task:{},
        task_files:[],

        errors: {},
        message:"",
        messageType:"",
      };
    }
    // var self =this;

    // this.handleSubmit = this.handleSubmit.bind(this);
    this.loadOneTask = ApiModel.loadOneTask(this, "task");
    this.loadOneTaskFiles = ApiModel.loadOneTaskFiles(this, "task_files");
  }

  componentDidUpdate(){
    if(this.props.taskId !==  this.state.taskId) {
      var self = this;
      this.setState({ taskId: this.props.taskId,  });
      self.loadOneTask().finally(function(){
        self.loadOneTaskFiles();
      });
    }    
  }

  componentDidMount(){
    // var app = this.context;
    var self = this;
    self.setState({
        taskId: this.props.taskId,
    });
    self.loadOneTask().finally(function(){
      self.loadOneTaskFiles();
    });
  }

  

  render(){
    // global
    var app = this.context;
    var self = this;
    const { classes } = self.props;

    return (<>
      <div id="fileListPopup">
        <Grid container spacing={3}>

          {self.state.message && (<Grid item xs={12}>
          <MessageBlock message={self.state.message} messageType={self.state.messageType}/>
          </Grid>)}

          <Grid item xs={12}><h1>Файли завдання</h1></Grid>

          <Grid item xs={12}>
            {self.state.task_files.map(function(elm, ind){
              let basename = elm.url.substring(elm.url.lastIndexOf('/')+1); 
              return (<div key={"file_" + ind}>
              <Button
                variant="contained"
                color="success"
                size="small"
                className={classes.submitButtonDanger}
                onClick={ApiModel.deleteTaskFile(self, elm.id, ()=>self.loadOneTaskFiles())}
                >
                Видалити
              </Button>
                &nbsp;
                <a href={elm.url} target="_blank" rel="noreferrer">{basename}</a>
              </div>);
            })}
          </Grid>

          <Grid item xs={12}>
            <div className={classes.formButtons}>
              <Button
                variant="contained"
                color="success"
                size="large"
                className={classes.submitButtonSattelite}
                onClick={() => app.setState( { cmd_task:[{action:"closePopup"}] })}
                >
                &nbsp;
              </Button>
              <Button
                variant="contained"
                color="success"
                size="large"
                className={classes.submitButton}
                onClick={() => app.setState( { cmd_task:[{action:"closePopup"}] })}
                >
                Закрити
              </Button>

            </div>
          </Grid>
        </Grid>
        </div>
    </>);
    
  }
}
export default withStyles(styles)(TaskFiles);

