function config(mode) {
	this.appName = "CRMX";
	console.log("mode", mode);
	if(mode==="local"){
		this.apiRootUrl = '//localhost:8080';
	} else {
		this.apiRootUrl = 'https://crm.ztk-crm.com.ua:8443';
	}	
}

module.exports = config;
