import React, {Component}  from "react";
import {
  Grid,
  // LinearProgress,
  // Select,
  // OutlinedInput,
  // MenuItem,
  Button,
  Popover
} from "@material-ui/core";


// components
// import Widget from "../../components/Widget";
// import PageTitle from "../../components/PageTitle";
// import { Typography } from "../../components/Wrappers";
// import Dot from "../../components/Sidebar/components/Dot";
import MUIDataTable from "mui-datatables";

// context
import { AppContext } from "../../context/AppContext";


import ProductPrice from "./components/ProductPrice/ProductPrice";

// styles
import { withStyles } from "@material-ui/core/styles";
import Themes from "../../themes";
import customStyles from "./styles";
const theme = Themes.default;
const styles = customStyles(theme);



class Product extends Component {

  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.columns=[
      {
        name: "id",
        label: "id",
        options: { filter: false, sort: true, }
      },
      {
        name: "name",
        label: "Назва",
        options: { filter: false, sort: false, }
      },
      {
        name: "type_products_name",
        label: "Тип товару",
        options: { filter: false, sort: false, }
      },
      {
        name: "action",
        label: "Дії",
        options: { filter: false, sort: false, }
      },
    ];
    if(!this.state) {
      this.state = {
          tabledata:[],
          currentProductId: null,
          pricePopupVisible: false,
          message:"",
          messageType:"",
      };
    }

    this.filterHash = JSON.stringify(this.props.filter);
    this.handleClose = this.handleClose.bind(this);
  }

  componentDidUpdate(){
    var newFilterHash = JSON.stringify(this.props.filter);
    if(newFilterHash !==  this.filterHash) {
      this.filterHash = newFilterHash;
      this.loadTableData();
    }
  }

  componentDidMount(){
    var app = this.context;
    this.loadTableData().then(function(){
      app.setState({sidebarMenuId:"product"});
    });
  }
  
  showPricePopupFactory(id) {
      var self=this;
      return function(event){
        self.setState({
          currentProductId: id,
          pricePopupVisible:true,
        });
      }
  }

  handleClose(){
    this.setState({
      currentProductId: 0,
      pricePopupVisible:false,
    });
  }

  closePopup() {
    this.setState({
      currentProductId: 0,
      pricePopupVisible:false,
    });
  }

  loadTableData(){
    var app = this.context;
    var self = this;
    const { classes } = self.props;
    var onError = function(errorMessage) {
          self.setState({
            "message":errorMessage,
            "messageType":"error",
          });
        };

    var filter = this.props.filter ;
    var param = {};
    if(filter.productName) param["name"] = filter.productName;
    if(filter.productId) param["product_id"] = filter.productId;
    if(filter.typeProductId) param["type_products"] = filter.typeProductId;
    if(filter.typeFuelId) param["type_fuel_id"] = filter.typeFuelId;
    if(filter.typeTyriesId) param["type_tyries_id"] = filter.typeTyriesId;
    if(filter.radiusTiresId) param["radius_id"] = filter.radiusTiresId;

    if(Object.keys(param).length > 0) {
        param = [param];
    } else {
        param = [];
    }

    return app.postData(
      '/product/get',
      {"param": param}
    ).then(function(responseJson){
      // console.log("responseJson", responseJson, responseJson["status"]);
      if(responseJson["status"] === "OK") {
        var items = responseJson["detail"].map(elm => 
                  ({
                     id:elm.id,
                     name: elm.name,
                     type_products:elm.type_products,
                     type_products_name:elm.type_products_name,
                     action:(<>
                        <Button 
                          onClick={self.showPricePopupFactory(elm.id)}
                          className={classes.rowActionButton}
                          >
                          Встановити ціну
                        </Button>
                        <Button 
                          onClick={self.showPricePopupFactory(elm.id)}
                          className={classes.rowActionButtonSattelite}
                          >
                          &gt;
                        </Button>
                      </>)
                    }) );
        self.setState({
          "tabledata": items,
        });
      } else {
          onError(responseJson["comment"]);
      }
    }).catch(() => onError("Невідома помилка"));
  }

  render(){
    // global
    // var app = this.context;
    var self = this;
    const { classes } = self.props;

    // <PageTitle title="Товари"/>
    return (
      <>
      
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <div id="mainDataTable"></div>
          <MUIDataTable
            // title="Employee List"
            data={this.state.tabledata}
            columns={self.columns}
            options={{
              filterType: "checkbox",
              download:false,
              print:false,
              search:false,
              filter:false,
              viewColumns:false,
              selectableRows: "none",
              textLabels: {
                  body: {
                    noMatch: "Вибачте, записів не знайдено",
                    toolTip: "Впорядкувати",
                    columnHeaderTooltip: column => `Впорядкувати за ${column.label}`
                  },
                  pagination: {
                    next: "Наступна сторінка",
                    previous: "Попередня сторінка",
                    rowsPerPage: "Рядків на сторінці:",
                    displayRows: "із",
                  },
                  toolbar: {
                    search: "Знайти",
                    downloadCsv: "Скачати CSV",
                    print: "Надрукувати",
                    viewColumns: "Показаи колонки",
                    filterTable: "Фільтрувати таблицю",
                  },
                  filter: {
                    all: "Всі",
                    title: "Фільтри",
                    reset: "Очистити",
                  },
                  viewColumns: {
                    title: "Показати колонки",
                    titleAria: "Показати/Приховати колонки",
                  },
                  selectedRows: {
                    text: "рідків вибрано",
                    delete: "Видалити",
                    deleteAria: "Видалити вибрані рядки",
                  },
                }
            }}
            className={classes.crmXTable}
          />
        </Grid>
      </Grid>
      <Popover
        id="popover"
        open={self.state.pricePopupVisible}
        // anchorEl={document.getElementById('mainDataTable')}
        anchorEl={document.getElementById('root')}
        // onClose={self.handleClose}
        className={classes.popOver}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <ProductPrice productId={self.state.currentProductId} parent={this}/>
        
      </Popover>
      </>
    );
  }
}
export default withStyles(styles)(Product);

