
function customStyles(theme) {
  return {
    root: {
      display: "flex",
      maxWidth: "100vw",
      overflowX: "hidden",
    },
    content: {
      flexGrow: 1,
      padding: "24px 24px 24px 8px",
      width: `calc(100vw - 240px)`,
      minHeight: "100vh",
    },
    contentShift: {
      width: `calc(100vw - ${240 + theme.spacing(6)}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    fakeToolbar: {
      ...theme.mixins.toolbar,
      minHeight:"100px",
      [theme.breakpoints.up("sm")]: {
        minHeight:"100px",
      },
    },
    link: {
      '&:not(:first-child)': {
        paddingLeft: 15
      }
    }
  };
}
export default customStyles;
