import React, {Component}  from "react";
import {
  // Grid,
  // LinearProgress,
  // Select,
  // OutlinedInput,
  // MenuItem,
  Button,
  // TextField,
  Link,
  // Popover
} from "@material-ui/core";
import moment from 'moment';

// components
// import { Typography } from "../../../../components/Wrappers";
import MUIDataTable from "mui-datatables";

// context
import { AppContext } from "../../../../context/AppContext";

// styles
import { withStyles } from "@material-ui/core/styles";
import Themes from "../../../../themes";
import customStyles from "./styles";
// import { Alarm } from "@material-ui/icons";
const theme = Themes.default;
const styles = customStyles(theme);

class Contracts extends Component {

  static contextType = AppContext;

  constructor(props) {
    super(props);
    // var self = this;
    this.columns=[
      {
        name: "contract_number",
        label: "Номер договору",
        options: { filter: false, sort: false, }
      },
      {
        name: "date_contract_view",
        label: "Дата",
        options: { filter: false, sort: false, }
      },
      {
        name: "status_name",
        label: "Статус",
        options: { filter: false, sort: false, }
      },

      {
        name: "summary",
        label: "Сума",
        options: { filter: false, sort: false, }
      },
      {
        name: "action",
        label: "Дії",
        options: { filter: false, sort: false, }
      },
    ];
    if(!this.state) {
      this.state = {
        providerId:false,
        provider:{contract:[]},
        errors:{},
        message:"",
        messageType:"",

        deal_status:{},
      };
    }

    // var positiveFloat = function(val) {
    //         if(!self.isPositiveFloat(val)){
    //             return "Будь ласка, введіть додатнє число";
    //         }
    //         return "";
    //     };
    // this.validator={
    //     "price_rozn": positiveFloat,
    //     "price_opt": positiveFloat,
    //     "date_price": function(val){
    //         if(val.trim().length === 0 ){
    //             return "Будь ласка, введіть значення";
    //         }
    //         var x = moment(val);
    //         if(!x.isValid()){
    //             return "Будь ласка, введіть дату";
    //         }
    //         return "";
    //     }
    // };
    this.mapTable = this.mapTable.bind(this);
  }

  componentDidUpdate(){
    if(this.props.providerId !==  this.state.providerId) {
      this.setState({providerId: this.props.providerId });
      this.loadProvider();
    }    
  }

  componentDidMount(){
    // var app = this.context;
    var self =this;
    self.setState({providerId: this.props.providerId });
    
    self.loadProvider().finally(function(){
      self.loadDealStatus();
    });
  }

  loadProvider(){
    var app = this.context;
    var self = this;
    var onError = function(errorMessage) {
        self.setState({
            "message": errorMessage,
            "messageType": "error"
        });
    };
    var data = {"param": [{"provider_id": parseInt(this.props.providerId)}]};
    return app.postData(
        '/providers/get',
        data
      ).then(function(responseJson){
          // console.log("responseJson", responseJson, responseJson["status"]);
          if(responseJson["status"] === "OK") {
              let data = responseJson["detail"][0];            
              self.setState({
                  "provider": data
              });
          } else if(responseJson["status"] === "NOT_FOUND") {
              self.props.app.setState({
                  "message": responseJson["comment"],
                  "messageType": "error"
              });
          } else {
              onError(responseJson["comment"]);
          }
    }).catch(() => onError("Не вдалося завантажити постачальника"));
  }


  loadDealStatus(){
    var app = this.context;
    var self = this;
    var onError = function(errorMessage) {
          self.setState({
            "message":errorMessage,
            "messageType":"error",
          });
        };
    return app.postData(
    '/deal_status/get',
    {"param": []}
    ).then(function(responseJson){
    // console.log("responseJson", responseJson, responseJson["status"]);
    if(responseJson["status"] === "OK") {
        var the_map = {};
        responseJson["detail"].map(function(elm){
            the_map[elm.id] = elm.name;
            return elm;
        })
        self.setState({
        "deal_status": the_map
        });
    } else {
        onError(responseJson["comment"]);
    }
    }).catch(() => onError("Невідома помилка"));
  }


  handleChangeFactory(attributeName, ind) {
      var self = this;
      return function(event) {
        event.preventDefault();
        // deep copy of the data
        var tabledata = JSON.parse(JSON.stringify(self.state.tabledata));

        if(ind<0) {
            // add new price to end
            console.log(moment(), moment().format("YYYY-MM-DD"));
            var newPrice = {
                "product_id": self.props.productId,
                "price_rozn": "",
                "price_opt": "",
                "date_price": moment().format("YYYY-MM-DD"),
                "date_price_err":1,
                "price_rozn_err":1,
                "price_opt_err":1
            };
            newPrice[attributeName] = event.target.value;
            tabledata.push(newPrice);
            self.setState({
                "tabledata": tabledata
            });
        } else {
            // update price if validated
            var p = tabledata[ind];
            
            p[attributeName] = event.target.value;

            p.err_price_rozn = "";
            if(self.validator.price_rozn) {
                p.price_rozn_err = self.validator.price_rozn(p.price_rozn);
            }
            p.err_price_opt = "";
            if(self.validator.price_opt) {
                p.price_opt_err = self.validator.price_opt(p.price_opt);
            }
            p.err_date_price = "";
            if(self.validator.date_price) {
                p.date_price_err = self.validator.date_price(p.date_price);
            }

            self.setState({
                "tabledata": tabledata
            });

            // post the updated data
            if(p.err_price_rozn.length + p.err_price_opt.length + p.err_date_price.length === 0) {
                if(self.saveTimeout) {
                    window.clearTimeout(self.saveTimeout);
                }
                self.saveTimeout = window.setTimeout(function(){
                    self.saveOnePrice(p);
                }, 2000);
            }
        }
      }
  }

  saveOnePrice(p){
      var self=this;
      var app = this.context;
      var onError = function(errorMessage) {
          self.setState({
          "message":errorMessage,
          "messageType":"error",
          });
      };
      let postData = {
          "products_id":p.product_id,
          "price_rozn":p.price_rozn,
          "price_opt":p.price_opt,
          "date_price":p.date_price,
          "price_product_id": p.id,
      };
      
      if (postData.price_product_id) {
          // post the UPDATE
          app.postData(
              '/price_product/edit',
              [postData]
          ).then(function(responseJson){
          if(responseJson["status"] === "OK") {
              // self.setState({
              //     "message": "Дані оновлено",
              //     "messageType": "success"
              // });
              self.loadTableData(false);
          } else if(responseJson["status"] === "EXPECTATION_FAILED") {
              onError("Не вдалося зберегти дані");
          } else {
              onError(responseJson["comment"]);
          }
          }).catch(() => onError("Невідома помилка"));
      } else {
          // post the ADD
          app.postData(
              '/price_product/add',
              [postData]
          ).then(function(responseJson){
              if(responseJson["status"] === "OK") {
                  // self.setState({
                  //     "message": "Дані оновлено",
                  //     "messageType": "success"
                  // });
                  self.loadTableData(false);
              } else if(responseJson["status"] === "EXPECTATION_FAILED") {
                  onError("Не вдалося зберегти дані");
              } else {
                  onError(responseJson["comment"]);
              }
          }).catch(() => onError("Невідома помилка"));
      }
  }

  handleDeleteFactory(ind) {
      var self = this;
      var app = this.context;
      return function(event) {
          event.preventDefault();

          var id = self.state.tabledata[ind].id;

          if(id) {
              var onError = function(errorMessage) {
                  self.setState({
                      "message":errorMessage,
                      "messageType":"error",
                  });
              };
              var postData = [id];
              // post the DELETE command
              app.postData(
                      '/price_product/delete',
                      postData
                  ).then(function(responseJson){
                      if(responseJson["status"] === "OK") {
                          self.setState({
                              "message": "Дані оновлено",
                              "messageType": "success"
                          });
                          self.loadTableData(false);
                      } else if(responseJson["status"] === "EXPECTATION_FAILED") {
                          onError("Не вдалося зберегти дані");
                      } else {
                          onError(responseJson["comment"]);
                      }
                  }).catch(() => onError("Невідома помилка"));
          } else {
              var tabledata = JSON.parse(JSON.stringify(self.state.tabledata));
              tabledata.splice(ind, 1);
              self.setState({
                  "tabledata": tabledata
              });
          }
      }
  }

  mapTable(){
    var self=this;
    const { classes } = self.props;
    return function(elm, ind){
      let date_contract_view = false;
      try{
        date_contract_view = moment(elm.date_contract, "YYYY-MM-DD").format("DD.MM.YYYY");
      } catch (e) {
        date_contract_view = elm.date_contract;
      }
      
      return   ({
                contract_number:elm.contract_number,
                date_contract:elm.date_contract,
                date_contract_view:date_contract_view,
                determinate_days: elm.determinate_days,
                status: elm.status,
                status_name: (self.state.deal_status[elm.status] ?  self.state.deal_status[elm.status]: elm.status),
                summary: elm.summary,
                provider_id: elm.provider_id,
                provider_name: elm.provider_name,
                // input_date_price:(<TextField
                //                     value={self.state.tabledata[ind]["date_price"]}
                //                     onChange={self.handleChangeFactory("date_price", ind)}
                //                     margin="normal"
                //                     placeholder="Дата"
                //                     type="date"
                //                     fullWidth
                //                     variant="standard" 
                //                     className={elm.date_price_err ? classes.tableTextFieldErr : classes.tableTextField}
                //                     />),
                // input_price_opt:(<TextField
                //                     value={self.state.tabledata[ind]["price_opt"]}
                //                     onChange={self.handleChangeFactory("price_opt", ind)}
                //                     margin="normal"
                //                     placeholder="Оптова ціна"
                //                     type="text"
                //                     fullWidth
                //                     variant="standard" 
                //                     className={elm.price_opt_err ? classes.tableTextFieldErr : classes.tableTextField}
                //                     />),
                // input_price_rozn:(<TextField
                //                     value={self.state.tabledata[ind]["price_rozn"]}
                //                     onChange={self.handleChangeFactory("price_rozn", ind)}
                //                     margin="normal"
                //                     placeholder="Оптова ціна"
                //                     type="text"
                //                     fullWidth
                //                     variant="standard" 
                //                     className={elm.price_rozn_err ? classes.tableTextFieldErr : classes.tableTextField}
                //                     />),
                action:(<>
                        <Button
                          variant="contained"
                          color="success"
                          size="large"
                          className={classes.rowActionButton}
                          onClick={() => self.props.parent.setState({
                              view:self.props.parent.views.contractInfo,
                              contract_number: elm.contract_number
                          })}
                        >
                        Показати
                      </Button>
                        <Button
                          variant="contained"
                          color="success"
                          size="large"
                          className={classes.rowActionButtonSattelite}
                          onClick={() => self.props.parent.setState({
                              view:self.props.parent.views.contractInfo,
                              contract_number: elm.contract_number
                          })}
                        >
                        &gt;
                      </Button>
                      </>),
                });
    }
  }


  render(){
    // global
    // var app = this.context;
    var self = this;
    const { classes } = self.props;


    return (
      <>
          <div className={classes.breadCrumbs}>
                <Link  href="javascript:void(1)" onClick={() => self.props.parent.setState({
                  providerId: null,
                  view:self.props.parent.views.providers,
                })}>Постачальники</Link>
                / 
                <Link  href="javascript:void(1)" onClick={() => self.props.parent.setState({
                  view:self.props.parent.views.providerInfo,
                })}>{self.state.provider.name}</Link>
                / Договори
          </div>
          <h1 className={classes.pageTitle}>Договори постачальника "{self.state.provider.name}"</h1>
          
          <MUIDataTable
            data={this.state.provider.contract.map( self.mapTable() )}
            columns={self.columns}
            options={{
              filterType: "checkbox",
              download:false,
              print:false,
              search:false,
              filter:false,
              viewColumns:false,
              selectableRows: "none",
              textLabels: {
                  body: {
                    noMatch: "Вибачте, записів не знайдено",
                    toolTip: "Впорядкувати",
                    columnHeaderTooltip: column => `Впорядкувати за ${column.label}`
                  },
                  pagination: {
                    next: "Наступна сторінка",
                    previous: "Попередня сторінка",
                    rowsPerPage: "Рядків на сторінці:",
                    displayRows: "із",
                  },
                  toolbar: {
                    search: "Знайти",
                    downloadCsv: "Скачати CSV",
                    print: "Надрукувати",
                    viewColumns: "Показаи колонки",
                    filterTable: "Фільтрувати таблицю",
                  },
                  filter: {
                    all: "Всі",
                    title: "Фільтри",
                    reset: "Очистити",
                  },
                  viewColumns: {
                    title: "Показати колонки",
                    titleAria: "Показати/Приховати колонки",
                  },
                  selectedRows: {
                    text: "рідків вибрано",
                    delete: "Видалити",
                    deleteAria: "Видалити вибрані рядки",
                  },
                }
            }}
            className={classes.crmXTable + ' ' + classes.crmXColWidth}
          />
      </>
    );
  }

}
export default withStyles(styles)(Contracts);

