import React, { Component } from "react";
import {
  // Collapse,
  // Divider,
  List,
  ListItem,
  // ListItemIcon,
  // ListItemText,
  TextField,
  // FormLabel,  
  // FormControlLabel,
  // FormControl,
  Select,
  // Typography,
  Box,
  Button,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import {
  Search as SearchIcon,
} from "@material-ui/icons";

import MuiDownshift from "mui-downshift";

// import { Inbox as InboxIcon } from "@material-ui/icons";
// import { Link } from "react-router-dom";
// import classnames from "classnames";

import { AppContext } from "../../../../context/AppContext";

// styles
import { withStyles } from "@material-ui/core/styles";
import Themes from "../../../../themes";
import customStyles from "./styles";
var theme = Themes.default;
var styles = customStyles(theme);

class ProductFilter extends Component {

  static contextType = AppContext;

  constructor(props) {
    super(props);
    if(!this.state) {
      this.state = {
        productName:"",
        productId: "",
        typeProductId:"",
        typeFuelId:"",
        typeTyriesId:"",
        radiusTiresId:"",

        product:[],
        filteredProduct:[],
        type_product:[],
        type_fuel:[],
        type_tyries:[],
        radius_tires:[],
      };
    }
    // this.setStateProperty = this.setStateProperty.bind(this);
    this.handleProductStateChange = this.handleProductStateChange.bind(this);
  }

  setFilter(upd) { 
    var app = this.context;
    var filter_product=JSON.parse(JSON.stringify(app.state.filter_product || {})) || {};
    filter_product = filter_product || {};
    for(var k in upd){
      filter_product[k]=upd[k];
    }
    app.setState({filter_product});
    this.setState(upd);
  }

  componentDidMount(){
    var self = this;
    var app = this.context;
    self.loadProduct().finally(function(){
          self.loadTypeFuel().finally(function(){
              self.loadTypeProduct().finally(function(){
                  self.loadTypeTyries().finally(function(){
                      self.loadRadiusTires().finally(function(){
                          var filter_product=JSON.parse(JSON.stringify(app.state.filter_product || {})) || {};
                          filter_product = filter_product || {};
                          self.setState(filter_product)
                      });        
                  });
              });
          });
      });
  }

  loadProduct(){
    var app = this.context;
    var self = this;
    var onError = function(errorMessage) {
          self.setState({
            "message":errorMessage,
            "messageType":"error",
          });
        };
    return app.postData(
      '/product/get',
      {"param": []}
    ).then(function(responseJson){
      // console.log("responseJson", responseJson, responseJson["status"]);
      if(responseJson["status"] === "OK") {
        var items = responseJson["detail"].map(elm => 
                  ({value:elm.id, label: elm.name }) );
        items.sort((a, b) => a.label.localeCompare(b.label));
        self.setState({
          "product": items,
          "filteredProduct": items
        });
      } else {
          onError(responseJson["comment"]);
      }
    }).catch(() => onError("Невідома помилка"));
  }

  loadTypeFuel(){
    var app = this.context;
    var self = this;
    var onError = function(errorMessage) {
          self.setState({
            "message":errorMessage,
            "messageType":"error",
          });
        };
    return app.postData(
      '/fuel/get',
      {"param": []}
    ).then(function(responseJson){
      // console.log("responseJson", responseJson, responseJson["status"]);
      if(responseJson["status"] === "OK") {
          self.setState({
            "type_fuel": responseJson["detail"]
          });
      } else {
          onError(responseJson["comment"]);
      }
    }).catch(() => onError("Невідома помилка"));
  }

  loadTypeProduct(){
    var app = this.context;
    var self = this;
    var onError = function(errorMessage) {
          self.setState({
            "message":errorMessage,
            "messageType":"error",
          });
        };
    return app.postData(
      '/type_products/get',
      {"param": []}
    ).then(function(responseJson){
      // console.log("responseJson", responseJson, responseJson["status"]);
      if(responseJson["status"] === "OK") {
          self.setState({
            "type_product": responseJson["detail"]
          });
      } else {
          onError(responseJson["comment"]);
      }
    }).catch(() => onError("Невідома помилка"));
  }

  loadTypeTyries(){
    var app = this.context;
    var self = this;
    var onError = function(errorMessage) {
          self.setState({
            "message":errorMessage,
            "messageType":"error",
          });
        };
    return app.postData(
      '/type_tyries/get',
      {"param": []}
    ).then(function(responseJson){
      // console.log("responseJson", responseJson, responseJson["status"]);
      if(responseJson["status"] === "OK") {
          self.setState({
            "type_tyries": responseJson["detail"]
          });
      } else {
          onError(responseJson["comment"]);
      }
    }).catch(() => onError("Невідома помилка"));
  }

  loadRadiusTires(){
    var app = this.context;
    var self = this;
    var onError = function(errorMessage) {
          self.setState({
            "message":errorMessage,
            "messageType":"error",
          });
        };
    return app.postData(
      '/radius_tires/get',
      {"param": []}
    ).then(function(responseJson){
      // console.log("responseJson", responseJson, responseJson["status"]);
      if(responseJson["status"] === "OK") {
          self.setState({
            "radius_tires": responseJson["detail"]
          });
      } else {
          onError(responseJson["comment"]);
      }
    }).catch(() => onError("Невідома помилка"));
  }

  handleProductStateChange = changes => {
		if (typeof changes.inputValue === 'string') {
      console.log("changes.inputValue", changes.inputValue);
			this.setState({filteredProduct: this.filterProduct(changes.inputValue)});
		}
		if (this.input && this.props.blurOnSelect) {
			this.input.blur();
		}
	};

  filterProduct (filter){
    return this.state.product.filter(item => 
        item.label.toLowerCase().includes(filter.toLowerCase())
    );
  }


  render(){
    var {classes} = this.props;
    // global
    // var app = this.context;
    var self= this;


    return (
        <>
          <List className={classes.sidebarList}>

            <ListItem
                className={classes.sidebarListItem}
              >
              <Box  className={classes.sidebarFilterEdrpouRow} >
                <div className={classes.sidebarFilterEdrpou}>
                  <TextField
                    value={self.state.productName}
                    onChange={e => self.setState({"productName": e.target.value})}
                    margin="normal"
                    placeholder="Найменування товару"
                    type="text"
                    fullWidth
                    variant="standard" 
                    className={classes.sidebarFilterText}
                    />
                  </div>
                  <div className={classes.sidebarEdrpouBtn}><SearchIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} /></div>
              </Box>
            </ListItem>

            <ListItem
              className={classes.sidebarListItem + " filter"}
            >
              <InputLabel className={classes.sidebarLabel}>Товар</InputLabel>
              <MuiDownshift
                items={self.state.filteredProduct}
                onChange={e => self.setState({"productId" : ( e && e.value) || "" })}
                onStateChange={this.handleProductStateChange}
                getListItemKey={rowIndex => self.state.product[rowIndex].value}
                inputRef={node => {
                  this.input = node;
                }}
                className={classes.sidebarCombobox}
              />
            </ListItem>

            <ListItem
              className={classes.sidebarListItem + " filter"}
            >
              <InputLabel className={classes.sidebarLabel}>Тип товару</InputLabel>
              <Select
                value={self.state.typeProductId}
                className={classes.sidebarFilterSelect}
                multiple={false}
                onChange={e => self.setState({"typeProductId": e.target.value})}
              >
                <MenuItem value="" key={'type_product_id_X'} >-</MenuItem>
                {self.state.type_product.map(function(elm, ind){
                    return <MenuItem key={'type_product_id_' + ind} value={elm.id}>{elm.name}</MenuItem>;
                })}
              </Select>
            </ListItem>

            <ListItem
              className={classes.sidebarListItem + " filter"}
            >
              <InputLabel className={classes.sidebarLabel}>Тип палива</InputLabel>
              <Select
                value={self.state.typeFuelId}
                className={classes.sidebarFilterSelect}
                onChange={e => self.setState({"typeFuelId": e.target.value})}
              >
                <MenuItem value="" key={'type_fuel_id_X'} >-</MenuItem>
                {self.state.type_fuel.map(function(elm, ind){
                    return <MenuItem key={'type_fuel_id_' + ind} value={elm.id}  className={classes.selectOption}>{elm.name}</MenuItem>;
                })}
              </Select>
            </ListItem>

            <ListItem
              className={classes.sidebarListItem + " filter"}
            >
              <InputLabel className={classes.sidebarLabel}>Тип шин</InputLabel>
              <Select
                value={self.state.typeTyriesId}
                className={classes.sidebarFilterSelect}
                onChange={e => self.setState({"typeTyriesId": e.target.value})}
              >
                <MenuItem value="" key={'type_tyries_id_X'} >-</MenuItem>
                {self.state.type_tyries.map(function(elm, ind){
                    return <MenuItem key={'ttype_tyries_id_' + ind} value={elm.id}  className={classes.selectOption}>{elm.name}</MenuItem>;
                })}
              </Select>
            </ListItem>


            <ListItem
              className={classes.sidebarListItem + " filter"}
            >
              <InputLabel className={classes.sidebarLabel}>Радіус шин</InputLabel>
              <Select
                value={self.state.radiusTiresId}
                className={classes.sidebarFilterSelect}
                onChange={e => self.setState({"radiusTiresId": e.target.value})}
              >
                <MenuItem value="" key={'radius_tires_id_X'} >-</MenuItem>
                {self.state.radius_tires.map(function(elm, ind){
                    return <MenuItem key={'radius_tires_id_' + ind} value={elm.id}  className={classes.selectOption}>{elm.name}</MenuItem>;
                })}
              </Select>
            </ListItem>

            <ListItem
              className={classes.sidebarListItem + " button"}
            >
              <Button
                variant="contained"
                className={classes.sidebarFilterButton}
                onClick={e => self.setFilter({
                  productName:self.state.productName,
                  productId: self.state.productId,
                  typeProductId:self.state.typeProductId,
                  typeFuelId:self.state.typeFuelId,
                  typeTyriesId:self.state.typeTyriesId,
                  radiusTiresId:self.state.radiusTiresId,
                })}
                >Фільтрувати</Button>
            </ListItem>
            <ListItem
              className={classes.sidebarListItem + " button"}
            >
              <Button 
                variant="contained"
                className={classes.sidebarFilterButton}
                onClick={e => self.setFilter({
                  productName:"",
                  productId: "",
                  typeProductId:"",
                  typeFuelId:"",
                  typeTyriesId:"",
                  radiusTiresId:"",
                })}
                >Очистити фільтр</Button>
            </ListItem>

          </List>
        </>
        );

  }

}

export default withStyles(styles)(ProductFilter);

