import React, { Component } from "react";
import {
  // Collapse,
  // Divider,
  List,
  ListItem,
  // ListItemIcon,
  // ListItemText,
  TextField,
  // FormLabel,  
  // FormControlLabel,
  // FormControl,
  // Select,
  // Typography,
  Box,
  Button,
  // MenuItem,
  InputLabel,
} from "@material-ui/core";
import {
  Search as SearchIcon,
} from "@material-ui/icons";
import MuiDownshift from "mui-downshift";
// import { Inbox as InboxIcon } from "@material-ui/icons";
// import { Link } from "react-router-dom";
// import classnames from "classnames";

import { AppContext } from "../../../../context/AppContext";

// styles
import { withStyles } from "@material-ui/core/styles";
import Themes from "../../../../themes";
import customStyles from "./styles";
var theme = Themes.default;
var styles = customStyles(theme);

class ProviderFilter extends Component {

  static contextType = AppContext;

  constructor(props) {
    super(props);
    if(!this.state) {
      this.state = {
        edrpou:"",
        providerId:"",
        providerSelected:null,

        typePaymentsId:"",
        dealStatusId:"",
        determinateDays:"",

        type_payments:[],
        deal_status:[],
        provider:[],
        filteredProvider:[],
      };
    }
    this.handleProviderStateChange = this.handleProviderStateChange.bind(this);
    this.handleProviderSelected = this.handleProviderSelected.bind(this);
  }

  filterProvider(filter){
    return this.state.provider.filter(item => 
        item.label.toLowerCase().includes(filter.toLowerCase())
    );
  }

  handleProviderSelected(e) {
    // var app = this.context;
    let newProviderId = ( e && e.value) || "";
    let providerSelected = this.state.provider.filter(item => item.value === newProviderId);

    this.setState({
      providerId : newProviderId,
      providerSelected:providerSelected[0] || null,
    });
  }

  handleProviderStateChange = changes => {
		if (typeof changes.inputValue === 'string') {
      console.log("changes.inputValue", changes.inputValue);
			this.setState({filteredProvider: this.filterProvider(changes.inputValue)});
		}
		if (this.input && this.props.blurOnSelect) {
			this.input.blur();
		}
	};

  setFilter(upd) { 
    var app = this.context;
    var filter_provider=JSON.parse(JSON.stringify(app.state.filter_provider || {})) || {};
    filter_provider = filter_provider || {};
    for(var k in upd){
      filter_provider[k]=upd[k];
    }
    
    let providerSelected = this.state.provider.filter(item => item.value === filter_provider.providerId);
    filter_provider.providerSelected = providerSelected[0] || null;

    app.setState({
      filter_provider:filter_provider,
    });
    this.setState(filter_provider);
  }

  componentDidMount(){
    var self = this;
    var app = this.context;
    self.loadProvider().finally(function(){
      self.loadTypePayments().finally(function(){
        self.loadDealStatus().finally(function(){        
          var filter_provider=JSON.parse(JSON.stringify(app.state.filter_provider || {})) || {};
          filter_provider = filter_provider || {};
          self.setState(filter_provider)
        });
      });
    });
  }

  loadTypePayments(){
    var app = this.context;
    var self = this;
    var onError = function(errorMessage) {
          self.setState({
            "message":errorMessage,
            "messageType":"error",
          });
        };

    return app.postData(
      '/type_payments/get',
      {"param": []}
    ).then(function(responseJson){
      // console.log("responseJson", responseJson, responseJson["status"]);
      if(responseJson["status"] === "OK") {
          self.setState({
          "type_payments": responseJson["detail"]
          });
      } else {
          onError(responseJson["comment"]);
      }
    }).catch(() => onError("Невідома помилка"));
  }

  loadDealStatus(){
    var app = this.context;
    var self = this;
    var onError = function(errorMessage) {
          self.setState({
            "message":errorMessage,
            "messageType":"error",
          });
        };
    return app.postData(
    '/deal_status/get',
    {"param": []}
    ).then(function(responseJson){
    // console.log("responseJson", responseJson, responseJson["status"]);
    if(responseJson["status"] === "OK") {
        self.setState({
        "deal_status": responseJson["detail"]
        });
    } else {
        onError(responseJson["comment"]);
    }
    }).catch(() => onError("Невідома помилка"));
  }

  loadProvider(){
    var app = this.context;
    var self = this;
    var onError = function(errorMessage) {
          self.setState({
            "message":errorMessage,
            "messageType":"error",
          });
        };
    return app.postData(
      '/providers/get',
      {"param": []}
    ).then(function(responseJson){
      // console.log("responseJson", responseJson, responseJson["status"]);
      if(responseJson["status"] === "OK") {
        var items = responseJson["detail"].map(elm => ({value:elm.id, label: elm.name }) );
        items.sort((a, b) => a.label.localeCompare(b.label));
        self.setState({
          "provider": items,
          "filteredProvider": items
        });
      } else {
          onError(responseJson["comment"]);
      }
    }).catch(() => onError("Невідома помилка"));
  }

  render(){
    var {classes} = this.props;
    // global
    // var app = this.context;
    var self= this;


    return (
        <>
          <List className={classes.sidebarList}>

            <ListItem
                className={classes.sidebarListItem}
              >
              <Box  className={classes.sidebarFilterEdrpouRow} >
                <div className={classes.sidebarFilterEdrpou}>
                  <TextField
                    value={self.state.edrpou}
                    onChange={e => self.setState({"edrpou": e.target.value})}
                    margin="normal"
                    placeholder="Найменування / ЄДРПОУ"
                    type="text"
                    fullWidth
                    variant="standard" 
                    className={classes.sidebarFilterText}
                    />
                  </div>
                  <div className={classes.sidebarEdrpouBtn}><SearchIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} /></div>
              </Box>
            </ListItem>



            <ListItem
              className={classes.sidebarListItem + " filter"}
            >
              <InputLabel className={classes.sidebarLabel}>Постачальник</InputLabel>
              <MuiDownshift
                items={self.state.filteredProvider}
                onChange={this.handleProviderSelected}
                onStateChange={this.handleProviderStateChange}
                selectedItem={self.state.providerSelected}
                getListItemKey={rowIndex => self.state.provider[rowIndex].value}
                inputRef={node => {
                  this.input = node;
                }}
                className={classes.sidebarCombobox}
              />
            </ListItem>

            <ListItem
              className={classes.sidebarListItem + " button"}
            >
              <Button
                variant="contained"
                className={classes.sidebarFilterButton}
                onClick={() => self.setFilter({
                  edrpou:self.state.edrpou,
                  providerId:self.state.providerId,
                  typePaymentsId:self.state.typePaymentsId,
                  dealStatusId:self.state.dealStatusId,
                  determinateDays:self.state.determinateDays,
                })}
                >Фільтрувати</Button>
            </ListItem>
            <ListItem
              className={classes.sidebarListItem + " button"}
            >
              <Button 
                variant="contained"
                className={classes.sidebarFilterButton}
                onClick={() => self.setFilter({
                  edrpou:"",
                  providerId:"",
                  typePaymentsId:"",
                  dealStatusId:"",
                  determinateDays:"",
                })}
                >Очистити фільтр</Button>
            </ListItem>

          </List>
        </>
        );

  }

}

export default withStyles(styles)(ProviderFilter);

