function customStyles(theme){
    return {
        tableTextField:{
            "& input" : {
                backgroundColor: theme.crm.white,
                color:theme.crm.black,
                padding:"0.5em 1em",
            },
            margin:"0px",
        },
        tableTextFieldErr:{
            "& input" : {
                backgroundColor: theme.crm.red,
                color:theme.crm.white,
                padding:"0.5em 1em",
            },
            margin:"0px",
        },
        priceAddButton:{
            marginTop:"1em",
            marginRight:"1em",
            backgroundColor: theme.crm.green,
            color: theme.crm.white,
            "&:hover":{
                backgroundColor: theme.crm.greenLight,
                color: theme.crm.white,
            }
        },
        priceCloseButton:{
            backgroundColor: theme.crm.redDark,
            color: theme.crm.white,
            marginTop:"1em",
            marginRight:"1em",
            "&:hover":{
                backgroundColor: theme.crm.red,
                color: theme.crm.white,
            }
        },

        rowActionButton:{
            backgroundColor: theme.crm.green,
            color: theme.crm.white,
            fontSize:"12px",
            borderRadius:"0px",
            fontWeight:"normal",
            lineHeight:"1em",
            height:"30px",
            paddingLeft:"0.5em",
            paddingRight:"0.5em",
            "&:hover":{
                backgroundColor: theme.crm.greenLight,
                color: theme.crm.white,
            }
        },
        rowActionButtonSattelite:{
            backgroundColor: theme.crm.blue,
            color: theme.crm.white,
            fontSize:"12px",
            borderRadius:"0px",
            fontWeight:"normal",
            lineHeight:"1em",
            height:"30px",
            paddingLeft:"2px",
            paddingRight:"2px",
            marginLeft:"2px",
            width:"13px",
            overflow:"hidden",
            minWidth:"13px",
            "&:hover":{
            backgroundColor: theme.crm.blueLight,
            color: theme.crm.white,
            }
        },
        priceTable:{
            boxShadow:"none",
            "& th.MuiTableCell-root":{
                fontSize:"12px",
                backgroundColor:"#F8F8FA",
                textTransform:"uppercase",
                fontWeight:"bold",
                verticalAlign:"bottom",
                borderTop:"1px solid rgb(222, 226, 230)",
                borderBottom:"2px solid rgb(222, 226, 230)",
                borderLeft:"2px solid rgb(217, 203, 186)",
                padding:"10px",
                color:"rgba(0, 0, 0, 0.54)",
                textAlign:"center",
            },
            "& th.MuiTableCell-root:first-child":{
                borderLeft:"none",
            },
            "& td.MuiTableCell-root":{
                fontSize:"12px",
                // backgroundColor:"#F8F8FA",
                borderBottom:"2px solid rgb(217, 203, 186)",
                borderLeft:"2px solid rgb(217, 203, 186)",
                padding:"0px",
                color:"rgba(0, 0, 0, 0.87)",
                textAlign:"center",
            },
            "& td.MuiTableCell-root:first-child":{
                borderLeft:"none",
            },
            "& td.MuiTableCell-root.MuiTableCell-footer":{
                border:"none",
            },
        }
      };
}
export default customStyles;
