function customStyles(theme){
    return {
      breadCrumbs:{
      },
      formButtons:theme.formButtons,
      submitButton:theme.submitButton,
      textFieldErr:theme.textFieldErr,
      textField:theme.textField,
      tableTextFieldErr:theme.tableTextFieldErr,
      tableTextField:theme.tableTextField,
      rowActionButton:theme.rowActionButton,
      rowActionButtonSattelite:theme.rowActionButtonSattelite,
      rowActionButtonRed: theme.rowActionButtonRed,
      columnAction: theme.columnAction,
      submitButtonDanger:theme.submitButtonDanger,
      submitButtonSattelite: theme.submitButtonSattelite,
      selectFieldErr: theme.selectFieldErr,
      selectField: theme.selectField,
      pageTitle:theme.pageTitle,
      topButtons:theme.topButtons,
      btnBlock:theme.btnBlock,
      formFieldLabel:theme.formFieldLabel,
    };
}
export default customStyles;
