import React, {Component}  from "react";
import {
  Grid,
  // Button,
  // Popover,
  Link
} from "@material-ui/core";

import moment from 'moment';

// components
import MUIDataTable from "mui-datatables";


import { AppContext } from "../../../../context/AppContext";
import ApiModel from "../../../../utils/ApiModel";

import {CrmButton} from "../../../../components/CrmButton/CrmButton";

// styles
import { withStyles } from "@material-ui/core/styles";
import Themes from "../../../../themes";
import customStyles from "./styles";
const theme = Themes.default;
const styles = customStyles(theme);



class ConsumerContracts extends Component {

  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.columns=[
      { name: "id",      label: "id",      options: { filter: false, sort: false, }   },
      // { name: "auction_id",    label: "Аукціон",   options: { filter: false, sort: false, }   },
      // { name: "consumer_id",     label: "МФО",     options: { filter: false, sort: false, }   },
      { name: "contract_number",    label: "Номер",  options: { filter: false, sort: false, }   },
      //  { name: "contract_date",      label: "Дата", options: { filter: false, sort: false, }   },
      { name: "contract_date_view",      label: "Дата", options: { filter: false, sort: false, }   },
      //  { name: "type_product",      label: "Тип товару", options: { filter: false, sort: false, }   },
      { name: "type_product_view",      label: "Тип товару", options: { filter: false, sort: false, }   },
      { name: "real_summary", label: "Сума", options: { filter: false, sort: false, }   },
      // { name: "company_id", label: "Компанія", options: { filter: false, sort: false, }   },
      { name: "company_id_view", label: "Компанія", options: { filter: false, sort: false, }   },
      // { name: "personal_id",  label: "Відповідальний",     options: { filter: false, sort: false, }   },
      { name: "personal_id_view",  label: "Відповідальний",     options: { filter: false, sort: false, }   },
      { name: "action",  label: "Дії",     options: { filter: false, sort: false, }   },
    ];

    if(!this.state) {
      this.state = {
          consumer:{},
          consumerId: null,

          consumerContracts:[],
          consumerContractId:null,

          company_map:{},
          personal_map:{},
          type_product_map:{},

          message:"",
          messageType:"",
          popupFormVisible:false,
      };
    }
    this.filterHash = JSON.stringify(this.props.filter);
    this.onError =  ApiModel.onError(this).bind(this);
    this.loadCompanyMap  = ApiModel.loadCompanyMap(this, "company_map");
    this.loadTypeProductMap  = ApiModel.loadTypeProductMap(this, "type_product_map");
    this.loadPersonalMap  = ApiModel.loadPersonalMap(this, "personal_map");
    this.loadOneConsumer = ApiModel.loadOneConsumer(this, "consumer", this.onError).bind(this);
    this.loadConsumerContracts = ApiModel.loadConsumerContracts(this, "consumerContracts", this.onError);
  }

  componentDidUpdate(){
    // var app = this.context;
    if(this.props.consumerId !=this.state.consumerId){
        this.setState({
          consumerId: this.props.consumerId,
        });
      this.loadConsumerContracts(this.props.consumerId);
    }
  }

  componentDidMount(){
    var self = this;
    var app = self.context;
    self.loadOneConsumer(self.props.consumerId).finally(function(){
      self.loadConsumerContracts(self.props.consumerId).finally(function(){
        self.loadCompanyMap().finally(function(){
          self.loadPersonalMap().finally(function(){
            self.loadTypeProductMap();
          });
        });
      });
    });
  }

  showConsumerInfoFactory(id) {
      var self=this;
      return function(event){
        self.setState({
          consumerId: id,
          view:self.views.consumerInfo,
        });
      }
  }

  mapTable(){
    var self=this;
    var app = self.context;
    const { classes } = self.props;
    return function(row, ind){
      var elm =  JSON.parse(JSON.stringify(row));

      if(self.state.company_map[elm['company_id']]){
        let c =  self.state.company_map[elm['company_id']];
        elm['company_id_view'] =  c.name;
      }else{
        elm['company_id_view'] =  elm['company_id'];
      }


      if(self.state.personal_map[elm['personal_id']]){
        let p = self.state.personal_map[elm['personal_id']];
        elm['personal_id_view'] = (<div>{p.fname} {p.sname} {p.lname}</div>)
      } else {
        elm['personal_id_view'] = elm['personal_id'];
      }

      if(self.state.type_product_map[elm['type_product']]){
        let p = self.state.type_product_map[elm['type_product']];
        elm['type_product_view'] = (<div>{p.name}</div>)
      } else {
        elm['type_product_view'] = elm['type_product'];
      }

      try{
        elm['contract_date_view'] = moment(elm['contract_date']).format('DD.MM.YYYY  HH:mm');
      } catch (e) {
        elm['contract_date_view'] = elm['contract_date'];
      }

      elm['action'] = (<>
        <div className={classes.btnGroup}>
          <CrmButton
              classNameMain={classes.rowActionButton}
              classNameSattelite={classes.rowActionButtonSattelite}
              size="small"
              onClick={
                () => app.setState( { cmd_consumer:[
                      {
                        action: "shipment_info",
                        consumerId: self.props.consumerId || 0,
                        consumerContractId: elm.id || 0,
                        shipmentId: 0
                      }
                    ]})
              }
            >
            Додати відвантаження
          </CrmButton>
        </div>
        <div className={classes.btnGroup}>
          {/* <CrmButton
              classNameMain={classes.rowActionButton}
              classNameSattelite={classes.rowActionButtonSattelite}
              size="small"
              onClick={
                () => app.setState( { cmd_consumer:[
                      {
                        action: "shipments",
                        consumerId: self.props.consumerId || 0,
                        consumerContractId: elm.id || 0,
                      }
                    ]})
              }
            >
            Переглянути відвантаження
          </CrmButton>    */}
          <CrmButton
              classNameMain={classes.rowActionButton}
              classNameSattelite={classes.rowActionButtonSattelite}
              size="small"
              onClick={
                () => {
                  let cmd = {
                        action: "contract_info",
                        consumerId: self.props.consumerId || 0,
                        consumerContractId: elm.id || 0,
                      };
                  app.setState( { cmd_consumer:[ cmd ]} )
                }
              }
            >
            Переглянути
          </CrmButton>
          <CrmButton
              classNameMain={classes.rowActionButton}
              classNameSattelite={classes.rowActionButtonSattelite}
              size="small"
              onClick={
                () => app.setState( { cmd_consumer:[
                      {
                        action: "contract_specs",
                        consumerId: self.props.consumerId || 0,
                        consumerContractId: elm.id || 0,
                      }
                    ]})
              }
            >
            Специфікація
          </CrmButton>
        </div>
      </>);
      return  elm;
    }
  }


  render(){
    // global
    // 
    // var filter = this.props.filter ;
    // console.log("receiver filter", filter);
    var self = this;
    var app = self.context;
    const { classes } = self.props;

    return (
      
        <>
          <Grid container spacing={4}>
            {this.props.breadcrumbs &&
              <Grid item xs={12}>
                <div className={classes.breadCrumbs}>
                  <Link href="javascript:void(1)"
                    onClick={() => app.setState({cmd_consumer:[
                          {
                            action: 'consumers',
                          },
                        ]})
                    }>Клієнти</Link>
                  /
                  <Link href="javascript:void(1)"
                    onClick={() => app.setState({cmd_consumer:[
                          {
                            action: 'consumer_info', 
                            "consumerId": self.state.consumer.id,
                          },
                        ]})
                    }>{self.state.consumer.name}</Link>
                  / Договори
                </div>
              </Grid>
            }
            <Grid item xs={12}>
                <MUIDataTable
                  data={this.state.consumerContracts.map( self.mapTable() )}
                  columns={self.columns}
                  options={{
                    filterType: "checkbox",
                    download:false,
                    print:false,
                    search:false,
                    filter:false,
                    viewColumns:false,
                    selectableRows: "none",
                    textLabels: ApiModel.textLabels,
                  }}
                  className={classes.crmXTable + ' ' + classes.crmXColWidth}
                />
            </Grid>
          </Grid>
        </>
    );
  }

}
export default withStyles(styles)(ConsumerContracts);

