
import React, { Component } from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";

import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateAdapter from '@mui/lab/AdapterMoment';

// components
import Layout from "./Layout";



// pages
import Error from "../pages/error";
import Login from "../pages/login";

import { AppContext } from "../context/AppContext";

import Themes from "../themes";

import AppStorage from "../utils/storage";
const AppConfig = require('../config');

// require('custom-env').env();


class App extends Component {

  constructor(props) {
    super(props);
    this.appStorage = new AppStorage('crmX'); 
    this.appConfig = new AppConfig(process.env.REACT_APP_API_MODE);

    // initail state
    let initialState = {
          personal_id:0,
          root_id:0,
          userType:'',
          isAuthorized: false,
          authToken: "",
          userFullName:'',
          theme:'',
          isSidebarOpened:true,
          sidebarMenuId:"",
          filter_auction:{},
          filter_task:{},
          filter_consumer:{},
          filter_product:{},
          filter_provider:{},
          filter_report:{},
          cmd_auction:[],
          cmd_task:[],
          cmd_consumer:[],
          cmd_product:[],
          cmd_provider:[],
          cmd_report:[],
       };
    let storedState = this.appStorage.appReadStorage();
    if(storedState) {
       for(let k in storedState){
          initialState[k] = storedState[k];
       }
    }

    // initialState.isAuthorized = false;
    initialState.theme = Themes.default;
    this.state = initialState;
    this.appStorage.appWriteStorage(this.state);
  }

  componentDidUpdate(prevProps) {
    this.appStorage.appWriteStorage(this.state);
  }

  render() {
    return (
    <LocalizationProvider dateAdapter={DateAdapter}>
      <AppContext.Provider value={this}>
        <HashRouter>
          <Switch>
            <Route exact path="/" render={() => <Redirect to="/app/dashboard" />} />
            <Route
              exact
              path="/app"
              render={() => <Redirect to="/app/dashboard" />}
            />
            <PublicRoute path="/login" component={Login}/>
            <PrivateRoute path="/app" component={Layout}/>
            <Route component={Error} />
          </Switch>
        </HashRouter>
      </AppContext.Provider>
    </LocalizationProvider>
    );
  }


  getUrl(url){
     return this.appConfig.apiRootUrl + url;
  }

  async _postData(url = '', data = {}){
    /** 
     *  postData('https://example.com/answer', { answer: 42 })
     *   .then((data) => {
     *    console.log(data); // JSON data parsed by `response.json()` call
     * });
     */
    var params =  {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        // 'Content-Type': 'application/json'
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *client
      // body: JSON.stringify(data) // body data type must match "Content-Type" header
    };
    if(data && data.constructor && data.constructor.name === "FormData") {
      params.body = data;
    } else {
      params.headers['Content-Type'] = 'application/json';
      params.body = JSON.stringify(data);
    }


    if(this.state.isAuthorized) {
      params['headers']['Authorization'] = this.state.authToken;
    }
    console.log('fetch from', this.getUrl(url));
    const response = await window.fetch(this.getUrl(url), params);
    return await response.json(); // parses JSON response into native JavaScript objects
  }

  async postData(url = '', data = {}){
    var self = this;
    return this._postData(url, data).then(function(responseJson){
      if(responseJson["status"] === "UNAUTHORIZED") {
        self.setState({
            isAuthorized: false,
            authToken: "",
        });
        throw new Error('UNAUTHORIZED');
      } 
      return responseJson;
    })
  }
}
export default App;



function PublicRoute({ component, ...rest }) {
  var app = React.useContext(AppContext);
  // console.log("app.state.isAuthorized", app.state.isAuthorized);
  var isAuthorized = app.state.isAuthorized;
  return (
    <Route
      {...rest}
      render={props =>
        isAuthorized ? (
          <Redirect
            to={{
              pathname: "/",
            }}
          />
        ) : (
          React.createElement(component, props)
        )
      }
    />
  );
}

function PrivateRoute({ component, ...rest }) {
  var app = React.useContext(AppContext);
  // console.log("app.state.isAuthorized", app.state.isAuthorized);
  var isAuthorized = app.state.isAuthorized;
  return (
    <Route
      {...rest}
      render={props =>
        isAuthorized ? (
          React.createElement(component, props)
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: {
                from: props.location,
              },
            }}
          />
        )
      }
    />
  );
}
