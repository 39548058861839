// import moment from 'moment';

const DelivererModel = {
    loadDeliveries: function(self, attributeName, onError){
        return function() {
            var app = self.context;
            var data = {"param": []};
            return app.postData(
                '/delivers/get',
                data
            ).then(function(responseJson){
            // console.log("responseJson", responseJson, responseJson["status"]);
            if(responseJson["status"] === "OK") {
                let update = {};
                update[attributeName] = responseJson["detail"];
                self.setState(update);
                return responseJson["detail"];
            } else if(responseJson["status"] === "NOT_FOUND") {
                self.props.app.setState({
                    "message": responseJson["comment"],
                    "messageType": "error"
                });
                return [];
            } else {
                onError(responseJson["comment"]);
                return [];
            }
            }).catch(() => onError("Не вдалося завантажити статуси завдань"));
        };
    },
    loadDeliveriesMap: function(self, attributeName, onError){
        return function() {
            var app = self.context;
            var data = {"param": []};
            return app.postData(
                '/delivers/get',
                data
            ).then(function(responseJson){
            // console.log("responseJson", responseJson, responseJson["status"]);
            if(responseJson["status"] === "OK") {     
                let theMap = {};
                for(let i=0; i<responseJson["detail"].length; i++){
                    theMap[responseJson["detail"][i].id] = responseJson["detail"][i];
                }
                let update = {};
                update[attributeName] = theMap;
                self.setState(update);
                return theMap;
            } else if(responseJson["status"] === "NOT_FOUND") {
                self.props.app.setState({
                    "message": responseJson["comment"],
                    "messageType": "error"
                });
                return {};
            } else {
                onError(responseJson["comment"]);
                return {};
            }
            }).catch(() => onError("Не вдалося завантажити статуси завдань"));
        };
    },


};


export default DelivererModel;