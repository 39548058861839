

function customStyles(theme) {
  return {
    rowActionButton:theme.rowActionButton,
    rowActionButtonSattelite:theme.rowActionButtonSattelite,
    crmXTable:theme.crmXTable,
    crmXContent:theme.crmXContent,
    crmXColWidth:{
       "& tbody td:nth-child(1)":{width:"10%", textAlign:"center",},
       "& tbody td:nth-child(2)":{width:"60%", textAlign:"left",},
       "& tbody td:nth-child(3)":{width:"15%", textAlign:"center",},
       "& tbody td:nth-child(4)":{width:"15%", textAlign:"center",},
    }
  };
}

export default customStyles;