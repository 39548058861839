// import moment from 'moment';
import ShipmentModel from "./models/shipment";
import ConsumerModel from "./models/consumer";
import ConsumerContractModel from "./models/consumercontract";
import TaskModel from "./models/task";
import AuctionModel from "./models/auction";
import Handlers from "./models/handlers";
import Validators from "./models/validators";
import CompanyModel from "./models/company";
import PersonalModel from "./models/personal";
import ProductsModel from "./models/products";
import TaskStatusModel from "./models/taskstatus";
import DelivererModel from "./models/deliverer";
import TaskTypeModel from "./models/tasktype";
import TypeProductModel from "./models/typeproduct";
import TypePaymentModel from "./models/typepayment";
import UnitsModel from "./models/units";
import ProviderModel from "./models/provider";


const ApiModel = {

    loadProviders: ProviderModel.loadProviders,
    loadProvidersMap: ProviderModel.loadProvidersMap,

    loadCompany: CompanyModel.loadCompany,
    loadCompanyMap: CompanyModel.loadCompanyMap,

    loadPersonal: PersonalModel.loadPersonal,
    loadPersonalMap: PersonalModel.loadPersonalMap,
    loadAdminMap: PersonalModel.loadAdminMap,

    loadProducts: ProductsModel.loadProducts,

    loadTaskStatus: TaskStatusModel.loadTaskStatus,
    loadTaskStatusMap: TaskStatusModel.loadTaskStatusMap,
    loadTaskStatusDefault: TaskStatusModel.loadTaskStatusDefault,

    loadDeliveries: DelivererModel.loadDeliveries,
    loadDeliveriesMap: DelivererModel.loadDeliveriesMap,

    loadOneAuction: AuctionModel.loadOneAuction,
    defaultAuctionRecord: AuctionModel.defaultAuctionRecord,
    loadAuctionResultMap: AuctionModel.loadAuctionResultMap,
    loadAuctionResult: AuctionModel.loadAuctionResult,

    loadOneTask: TaskModel.loadOneTask,
    defaultTaskRecord: TaskModel.defaultTaskRecord,
    loadOneTaskFiles: TaskModel.loadOneTaskFiles,
    deleteTaskFile: TaskModel.deleteTaskFile,

    loadConsumer: ConsumerModel.loadConsumer,
    loadConsumerMap: ConsumerModel.loadConsumerMap,
    loadOneConsumer: ConsumerModel.loadOneConsumer,

    loadConsumerContracts: ConsumerContractModel.loadConsumerContracts,
    loadOneConsumerContract: ConsumerContractModel.loadOneConsumerContract,
    defaultConsumerContract: ConsumerContractModel.defaultConsumerContract,
    loadOneConsumerContractProgress: ConsumerContractModel.loadOneConsumerContractProgress,

    loadShipments: ShipmentModel.loadShipments,
    loadOneShipment: ShipmentModel.loadOneShipment,
    defaultShipmentRecord: ShipmentModel.defaultShipmentRecord,
    defaultShipmentDetailRecord: ShipmentModel.defaultShipmentDetailRecord,
    loadOneShipmentDetail: ShipmentModel.loadOneShipmentDetail,
    approveShipment: ShipmentModel.approveShipment,

    handleChangeFactory: Handlers.handleChangeFactory,
    handleChangeFactory2: Handlers.handleChangeFactory2,
    handleDateChangeFactory: Handlers.handleDateChangeFactory,
    handleDateChangeFactory2: Handlers.handleDateChangeFactory2,
    handleCheckboxFactory: Handlers.handleCheckboxFactory,
    handleCheckboxFactory2: Handlers.handleCheckboxFactory2,
    handleTableFactory: Handlers.handleTableFactory,
    getError: Handlers.getError,
    onError: Handlers.onError,

    atomicValidator: Validators.atomicValidator,
    validate: Validators.validate,

    loadTaskType: TaskTypeModel.loadTaskType,
    loadTaskTypeMap: TaskTypeModel.loadTaskTypeMap,

    loadTypeProduct: TypeProductModel.loadTypeProduct,
    loadTypeProductMap: TypeProductModel.loadTypeProductMap,

    loadUnits: UnitsModel.loadUnits,

    loadTypePayments: TypePaymentModel.loadTypePayments,
    loadTypePaymentsMap: TypePaymentModel.loadTypePaymentsMap,

    textLabels:{
        body: {
            noMatch: "Вибачте, записів не знайдено",
            toolTip: "Впорядкувати",
            columnHeaderTooltip: column => `Впорядкувати за ${column.label}`
        },
        pagination: {
            next: "Наступна сторінка",
            previous: "Попередня сторінка",
            rowsPerPage: "Рядків на сторінці:",
            displayRows: "із",
        },
        toolbar: {
            search: "Знайти",
            downloadCsv: "Скачати CSV",
            print: "Надрукувати",
            viewColumns: "Показати колонки",
            filterTable: "Фільтрувати таблицю",
        },
        filter: {
            all: "Всі",
            title: "Фільтри",
            reset: "Очистити",
        },
        viewColumns: {
            title: "Показати колонки",
            titleAria: "Показати/Приховати колонки",
        },
        selectedRows: {
            text: "рідків вибрано",
            delete: "Видалити",
            deleteAria: "Видалити вибрані рядки",
        },
    },

};

export default ApiModel;