

const ConsumerContractModel = {

    loadConsumerContracts: function(self, attributeName, onError) {
        return function(consumerId) {
            var app = self.context;
            // const { classes } = self.props;

            var param = {};
            param["consumer_id"] = consumerId;

            if(Object.keys(param).length > 0) {
                param = [param];
            } else {
                param = [];
            }

            return app.postData(
                '/consumers/contracts/get',
                {"param": param}
            ).then(function(responseJson){
                if(responseJson["status"] === "OK") {
                    let update={};
                    update[attributeName]=responseJson["detail"];
                    self.setState(update);   
                    return responseJson["detail"];
                } else {
                    onError(responseJson["comment"]);
                    return [];
                }
            }).catch(() => onError("Не вдалося завантажити перелік договорів"));
        };
    },
    loadOneConsumerContract: function(self, attributeName, onError) {
        return function(consumerId, consumerContractId) {
            var app = self.context;
            // const { classes } = self.props;

            var param = {};
            param["consumer_id"] = consumerId;
            param["consumers_contract_id"] = consumerContractId;

            if(Object.keys(param).length > 0) {
                param = [param];
            } else {
                param = [];
            }

            return app.postData(
                '/consumers/contracts/get',
                {"param": param}
            ).then(function(responseJson){
                if(responseJson["status"] === "OK") {
                    let update={};
                    update[attributeName] = responseJson["detail"][0];
                    self.setState(update);
                    return responseJson["detail"][0];
                } else {
                    onError(responseJson["comment"]);
                    return {};
                }
            }).catch(() => onError("Не вдалося завантажити договір."));
        };
    },

    loadOneConsumerContractProgress: function(self, attributeName, onError) {
        return function(consumerId, consumerContractId) {
            var app = self.context;
            // const { classes } = self.props;

            var param = {};
            param["consumer_id"] = consumerId;
            param["consumers_contract_id"] = consumerContractId;

            if(Object.keys(param).length > 0) {
                param = [param];
            } else {
                param = [];
            }

            return app.postData(
                '/consumers/contracts/progress',
                {"param": param}
            ).then(function(responseJson){
                if(responseJson["status"] === "OK") {
                    let update={};
                    update[attributeName] = responseJson["detail"][0];
                    self.setState(update);
                    return responseJson["detail"][0];
                } else {
                    onError(responseJson["comment"]);
                    return {};
                }
            }).catch(() => onError("Не вдалося завантажити інформацію про стан договору"));
        };
    },

    defaultConsumerContract: function(){
        return  {
            id:0,               // bigint
            auction_id:0,       // bigint
            type_product:0,     // bigint
            consumer_id:0,      // bigint
            contract_number:"", // text
            contract_date: "",  // date
            real_summary:0,     // float
            company_id:0,       // bigint
            personal_id:0,      // bigint
        };
    },


};


export default ConsumerContractModel;