import React, { Component } from "react";
import {
  // Collapse,
  // Divider,
  List,
  ListItem,
  // ListItemIcon,
  // ListItemText,
  TextField,
  // FormLabel,  
  // FormControlLabel,
  // FormControl,
  // Select,
  // Typography,
  Box,
  Button,
  // MenuItem,
  InputLabel,
} from "@material-ui/core";
import {
  Search as SearchIcon,
} from "@material-ui/icons";
import * as Icons from "@material-ui/icons";
import MuiDownshift from "mui-downshift";

// import { Inbox as InboxIcon } from "@material-ui/icons";
// import { Link } from "react-router-dom";
// import classnames from "classnames";

import { AppContext } from "../../../../context/AppContext";
import ApiModel from "../../../../utils/ApiModel";
// styles
import { withStyles } from "@material-ui/core/styles";
import Themes from "../../../../themes";
import customStyles from "./styles";
var theme = Themes.default;
var styles = customStyles(theme);

class ConsumerFilter extends Component {

  static contextType = AppContext;

  constructor(props) {
    super(props);
    if(!this.state) {
      this.state = {
        edrpou:"",
        consumerId: "",
        consumerSearchValue: "",
        consumerSelected: {},

        consumer:[],
        filteredConsumer:[],
      };
    }
    this.handleConsumerStateChange = this.handleConsumerStateChange.bind(this);
    this.handleConsumerSelected = this.handleConsumerSelected.bind(this);
    this.loadConsumer = ApiModel.loadConsumer(this, "consumer");
  }

  setFilter(upd) { 
    var app = this.context;
    var filter_consumer=JSON.parse(JSON.stringify(app.state.filter_consumer || {})) || {};
    filter_consumer = filter_consumer || {};
    for(var k in upd){
      filter_consumer[k]=upd[k];
    }
    app.setState({
      filter_consumer:filter_consumer,
      cmd_consumer:[
        {action: "consumers", }
      ],
    });
    this.setState(upd);
  }

  handleConsumerStateChange = changes => {
		if (typeof changes.inputValue === 'string') {
			this.setState({
        filteredConsumer: this.filterConsumer(changes.inputValue, this.state.consumer),
        consumerSearchValue: changes.inputValue,
      });
		}
		if (this.input && this.props.blurOnSelect) {
			this.input.blur();
		}
	};

  filterConsumer (filter, rawList){
    if(!rawList){
      return [];
    }
    var items = rawList.map(elm => ({value:elm.id, label: elm.name }) );
    items.sort((a, b) => a.label.localeCompare(b.label));
    return items.filter(item => 
        item.label.toLowerCase().includes(filter.toLowerCase())
    );
  }

  handleConsumerSelected(e) {
    var app = this.context;
    let newConsumerId = ( e && e.value) || "";
    var items = this.state.consumer.map(elm => ({value:elm.id, label: elm.name }) );
    let consumerSelected = items.filter(item => item.value === newConsumerId);

    this.setState({
      consumerId : newConsumerId,
      consumerSelected:consumerSelected[0] || {},
    });

    var filter_consumer=JSON.parse(JSON.stringify(app.state.filter_consumer || {})) || {};
    filter_consumer = filter_consumer || {};
    filter_consumer.consumerId = newConsumerId;
    filter_consumer.consumerSelected = consumerSelected[0];
    app.setState({filter_consumer:filter_consumer});
  }

  componentDidMount(){
    var self = this;
    var app = this.context;
    
    self.loadConsumer().then(function(consumers){
      var filteredConsumer =  self.filterConsumer(self.state.consumerSearchValue, consumers);

      var items = self.state.consumer.map(elm => ({value:elm.id, label: elm.name }) );
      let consumerSelected = items.filter(item => item.value === app.state.filter_consumer.consumerId);

      self.setState({
        filteredConsumer: filteredConsumer,
        consumerSelected: consumerSelected,
      });
    }).finally(function(){
      var filter_consumer=JSON.parse(JSON.stringify(app.state.filter_consumer || {})) || {};
      filter_consumer = filter_consumer || {};
      filter_consumer.edrpou = filter_consumer.edrpou || "";
      filter_consumer.consumerId = filter_consumer.consumerId || "";
      filter_consumer.consumerSearchValue = filter_consumer.consumerSearchValue || "";
      self.setState(filter_consumer)
      app.setState({filter_consumer:filter_consumer});
    });
  }

  render(){
    var {classes} = this.props;

    var app = this.context;
    var self= this;

    return (
        <>
          <List className={classes.sidebarList}>

            <ListItem
                className={classes.sidebarListItem}
              >
              <Box  className={classes.sidebarFilterEdrpouRow} >
                <div className={classes.sidebarFilterEdrpou}>
                  <TextField
                    value={self.state.edrpou}
                    onChange={e => self.setState({"edrpou": e.target.value})}
                    margin="normal"
                    placeholder="Найменування / ЄДРПОУ"
                    type="text"
                    fullWidth
                    variant="standard" 
                    className={classes.sidebarFilterText}
                    />
                  </div>
                  <div className={classes.sidebarEdrpouBtn}><SearchIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} /></div>
              </Box>
            </ListItem>


            { app.state.userType === "admin" &&
              <ListItem
                className={classes.sidebarListItem + " button"}
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  className={classes.greenButtonAdd}
                  onClick={function(){
                    // console.log({cmd_consumer:['add']});
                    app.setState({cmd_consumer:[{
                      action:"consumer_info",
                      consumerId:0,
                    }]})
                  }}
                >
                <Icons.AddBox />
                &nbsp;
                Додати клієнта
              </Button>
              </ListItem>
            }

            <ListItem
              className={classes.sidebarListItem + " filter"}
            >
              <InputLabel className={classes.sidebarLabel}>Клієнт</InputLabel>
              <MuiDownshift
                items={self.state.filteredConsumer}
                onChange={this.handleConsumerSelected}
                selectedItem={self.state.consumerSelected}
                onStateChange={this.handleConsumerStateChange}
                getListItemKey={rowIndex => self.state.consumer[rowIndex].value}
                inputRef={node => {
                  this.input = node;
                }}
                className={classes.sidebarCombobox}
              />
            </ListItem>

            <ListItem
              className={classes.sidebarListItem + " button"}
            >
              <Button
                variant="contained"
                className={classes.sidebarFilterButton}
                onClick={e => self.setFilter({
                  edrpou: self.state.edrpou,
                  consumerId: self.state.consumerId,
                })}
                >Фільтрувати</Button>
            </ListItem>

            <ListItem
              className={classes.sidebarListItem + " button"}
            >
              <Button 
                variant="contained"
                className={classes.sidebarFilterButton}
                onClick={e => self.setFilter({
                  edrpou: "",
                  consumerId: "",
                  consumerSearchValue: "",
                  consumerSelected: null,
                })}
                >Очистити фільтр</Button>
            </ListItem>

          </List>
        </>
        );

  }

}

export default withStyles(styles)(ConsumerFilter);

