import React, {Component} from "react";
import { Button } from "@material-ui/core";
import {
  NotificationsNone as NotificationsIcon,
  ThumbUp as ThumbUpIcon,
  ShoppingCart as ShoppingCartIcon,
  LocalOffer as TicketIcon,
  BusinessCenter as DeliveredIcon,
  SmsFailed as FeedbackIcon,
  DiscFull as DiscIcon,
  Email as MessageIcon,
  Report as ReportIcon,
  Error as DefenceIcon,
  AccountBox as CustomerIcon,
  Done as ShippedIcon,
  Publish as UploadIcon,
} from "@material-ui/icons";

import classnames from "classnames";
import tinycolor from "tinycolor2";

// components
import { Typography } from "../Wrappers";

import { AppContext } from "../../context/AppContext";

// styles
import { withStyles } from "@material-ui/core/styles";
import Themes from "../../themes";
import customStyles from "./styles";
var theme = Themes.default;
var styles = customStyles(theme);

const typesIcons = {
  "e-commerce": <ShoppingCartIcon />,
  notification: <NotificationsIcon />,
  offer: <TicketIcon />,
  info: <ThumbUpIcon />,
  message: <MessageIcon />,
  feedback: <FeedbackIcon />,
  customer: <CustomerIcon />,
  shipped: <ShippedIcon />,
  delivered: <DeliveredIcon />,
  defence: <DefenceIcon />,
  report: <ReportIcon />,
  upload: <UploadIcon />,
  disc: <DiscIcon />,
};

class Notification extends Component {

  static contextType = AppContext;

  constructor(props) {
    super(props);
    // if(!this.state) {
    //   this.state = {
    //     moreButtonRef:null,
    //     isMoreMenuOpen: false
    //   };
    // }
    // this.toggleCollapse = this.toggleCollapse.bind(this);
  }

  // setMoreMenuOpen(val) { this.setState({isMoreMenuOpen:val}) }
  // setMoreButtonRef(val) { this.setState({moreButtonRef:val}) }

  render() {
    var {classes,variant,} = this.props;
    var props = this.props;

    const icon = getIconByType(props.type);
    const iconWithStyles = React.cloneElement(icon, {
      classes: {
        root: classes.notificationIcon,
      },
      style: {
        color:
          variant !== "contained" &&
          theme.palette[props.color] &&
          theme.palette[props.color].main,
      },
    });

    return (
      <div
        className={classnames(classes.notificationContainer, props.className, {
          [classes.notificationContained]: variant === "contained",
          [classes.notificationContainedShadowless]: props.shadowless,
        })}
        style={{
          backgroundColor:
            variant === "contained" &&
            theme.palette[props.color] &&
            theme.palette[props.color].main,
        }}
      >
        <div
          className={classnames(classes.notificationIconContainer, {
            [classes.notificationIconContainerContained]: variant === "contained",
            [classes.notificationIconContainerRounded]: variant === "rounded",
          })}
          style={{
            backgroundColor:
              variant === "rounded" &&
              theme.palette[props.color] &&
              tinycolor(theme.palette[props.color].main)
                .setAlpha(0.15)
                .toRgbString(),
          }}
        >
          {iconWithStyles}
        </div>
        <div className={classes.messageContainer}>
          <Typography
            className={classnames({
              [classes.containedTypography]: variant === "contained",
            })}
            variant={props.typographyVariant}
            size={variant !== "contained" && !props.typographyVariant && "md"}
          >
            {props.message}
          </Typography>
          {props.extraButton && props.extraButtonClick && (
            <Button
              onClick={props.extraButtonClick}
              disableRipple
              className={classes.extraButton}
            >
              {props.extraButton}
            </Button>
          )}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Notification);

// ####################################################################
function getIconByType(type = "offer") {
  return typesIcons[type];
}
