import React, {Component}  from "react";
import {
  Grid,
  Link,
  //LinearProgress,
  Select,
  //OutlinedInput,
  MenuItem,
  Button,
  TextField,
  //Popover
} from "@material-ui/core";
//import moment from 'moment';

// components
// import { Typography } from "../../../../components/Wrappers";
import MUIDataTable from "mui-datatables";

// context
import { AppContext } from "../../../../context/AppContext";

import validators   from '../../../../utils/validators';
import MessageBlock from '../../../../utils/MessageBlock';
// styles
import { withStyles } from "@material-ui/core/styles";
import Themes from "../../../../themes";
import customStyles from "./styles";
// import { Alarm } from "@material-ui/icons";
const theme = Themes.default;
const styles = customStyles(theme);

class ContractAppendixSpecs extends Component {

  static contextType = AppContext;

  constructor(props) {
    super(props);
    //var self = this;
    this.columns=[
      {
        name: "select_product_id",
        label: "Товар",
        options: { filter: false, sort: false, }
      },
      {
        name: "select_unit_id",
        label: "Одиниці виміру",
        options: { filter: false, sort: true, }
      },
      {
        name: "input_count",
        label: "Кількість",
        options: { filter: false, sort: false, }
      },
      {
        name: "input_price_one",
        label: "Ціна за одиницю",
        options: { filter: false, sort: false, }
      },
      {
        name: "total",
        label: "Всього",
        options: { filter: false, sort: false, }
      },
      {
        name: "action",
        label: "Дії",
        options: { filter: false, sort: false, }
      },

    ];
    if(!this.state) {
      this.state = {

        providerId:this.props.providerId,
        provider:{},

        contract_number: this.props.contract_number,
        contract: {},

        appendixId:this.props.appendixId,
        appendix:{},

        specification:[],

        message:"",
        messageType:"",

        products:[],
        units:[],
      };
    }

    var positiveFloat = function(val) {
            if(!validators.isPositiveFloat(val)){
                return "Будь ласка, введіть додатнє число";
            }
            return "";
        };
    var positiveInteger = function(val) {
            if(!validators.isPositiveInteger(val)){
                return "Будь ласка, введіть додатнє число";
            }
            return "";
        };
    this.validator={
      "count": positiveFloat,
      "price_one": positiveFloat,
      "total": positiveFloat,
      "product_id":positiveInteger,
      "unit_id":positiveInteger,
    };
    this.mapTable = this.mapTable.bind(this);
  }

  componentDidUpdate(){
    console.log("componentDidUpdate()");
    if(this.props.providerId !==  this.state.providerId 
       || this.props.contract_number !==  this.state.contract_number
       || this.props.appendixId !==  this.state.appendixId) {
      this.setState({
        providerId: this.props.providerId,
        contract_number: this.props.contract_number,
        appendixId:this.props.appendixId,
      });
      var self = this;
      self.loadProvider().finally(function(){
        self.loadContract().finally(function(){
          self.loadAppendix().finally(function(){
            self.loadSpecification();
          });
        });
      });
    }    
  }

  componentDidMount(){
    // var app = this.context;
    var self = this;
    this.setState({
      providerId: this.props.providerId,
      contract_number: this.props.contract_number,
    });
    self.loadProvider().finally(function(){
      self.loadContract().finally(function(){
        self.loadAppendix().finally(function(){
          self.loadSpecification().finally(function(){
              self.loadUnits().finally(function(){
                self.loadProducts();        
              });
          });
        });
      });
    });
  }

  loadProvider(){
    var app = this.context;
    var self = this;
    var onError = function(errorMessage) {
        self.setState({
            "message": errorMessage,
            "messageType": "error"
        });
    };
    var data = {"param": [{"provider_id": parseInt(this.props.providerId)}]};
    return app.postData(
        '/providers/get',
        data
      ).then(function(responseJson){
          // console.log("responseJson", responseJson, responseJson["status"]);
          if(responseJson["status"] === "OK") {
              let data = responseJson["detail"][0];            
              self.setState({
                  "provider": data
              });
          } else if(responseJson["status"] === "NOT_FOUND") {
              self.props.app.setState({
                  "message": responseJson["comment"],
                  "messageType": "error"
              });
          } else {
              onError(responseJson["comment"]);
          }
    }).catch(() => onError("Не вдалося завантажити постачальника"));
  }

  loadContract() {
    var app = this.context;
    var self = this;
    var onError = function(errorMessage) {
      self.setState({
        "message": errorMessage,
        "messageType": "error"
      });
    };
    var data = {"param": [{"contract_number": self.props.contract_number}]};
    return app.postData(
      '/providers_contract/get',
      data
    ).then(function(responseJson){
      // console.log("responseJson", responseJson, responseJson["status"]);
      if(responseJson["status"] === "OK") {
        let data = responseJson["detail"][0];            
        self.setState({
          "contract": data
        });
      } else if(responseJson["status"] === "NOT_FOUND") {
        self.props.app.setState({
          "message": responseJson["comment"],
          "messageType": "error"
        });
      } else {
        onError(responseJson["comment"]);
      }
    }).catch(() => onError("Не вдалося завантажити договір"));
  }

  loadAppendix(){

    var appendixId = parseInt(this.props.appendixId);
    if (!appendixId) {
        return;
    }

    var app = this.context;
    var self = this;
    var onError = function(errorMessage) {
        self.setState({
          "message":errorMessage,
          "messageType":"error",
        });
    };
    var data = {"param": [{"provider_additional_agreement_id": appendixId}]};
    return app.postData(
      '/provider_additional_agreement/get',
      data
    ).then(function(responseJson){
      // console.log("responseJson", responseJson, responseJson["status"]);
      if(responseJson["status"] === "OK") {
        let data = responseJson["detail"];            
        self.setState({
          "appendix": data[0],
        });
      } else if(responseJson["status"] === "NOT_FOUND") {
        self.props.app.setState({
          "message": responseJson["comment"],
          "messageType": "error"
        });
      } else {
        onError(responseJson["comment"]);
      }
    }).catch(() => onError("Не вдалося завантажити спеціфікацію"));
  }

  loadSpecification(){
    var app = this.context;
    var self = this;
    var onError = function(errorMessage) {
        self.setState({
          "message":errorMessage,
          "messageType":"error",
        });
    };
    var data = {"param": [{"provider_additional_agreement_id": self.props.appendixId}]};
    return app.postData(
      '/provider_additional_agreement_specification/get',
      data
    ).then(function(responseJson){
      // console.log("responseJson", responseJson, responseJson["status"]);
      if(responseJson["status"] === "OK") {
        let data = responseJson["detail"];            
        self.setState({
          "specification": data,
        });
      } else if(responseJson["status"] === "NOT_FOUND") {
        self.props.app.setState({
          "message": responseJson["comment"],
          "messageType": "error"
        });
      } else {
        onError(responseJson["comment"]);
      }
    }).catch(() => onError("Не вдалося завантажити спеціфікацію"));
  }

  loadProducts() {
    var app = this.context;
    var self = this;
    var onError = function(errorMessage) {
      self.setState({
        "message":errorMessage,
        "messageType":"error",
      });
    };
    return app.postData(
      '/product/get',
      {"param": []}
    ).then(function(responseJson){
      // console.log("responseJson", responseJson, responseJson["status"]);
      if(responseJson["status"] === "OK") {
        self.setState({
          "products": responseJson["detail"]
        });
      } else {
        onError(responseJson["comment"]);
      }
    }).catch(() => onError("Не вдалося завантажити товари"));
  }

  loadUnits() {
    var app = this.context;
    var self = this;
    var onError = function(errorMessage) {
      self.setState({
        "message":errorMessage,
       "messageType":"error",
      });
    };
    // get list of deal_status
    return app.postData(
      '/units/get',
      {"param": []}
    ).then(function(responseJson){
      // console.log("responseJson", responseJson, responseJson["status"]);
      if(responseJson["status"] === "OK") {
        self.setState({
          "units": responseJson["detail"]
        });
      } else {
        onError(responseJson["comment"]);
      }
    }).catch(() => onError("Не вдалося завантажити одиниці виміру"));
  }

  mapTable(){
    var self=this;
    const { classes } = self.props;
    return function(row, ind){
      var elm =  JSON.parse(JSON.stringify(row));
      elm['select_product_id'] = (
          <Select
                value={self.state.specification[ind]["product_id"]}
                onChange={self.handleChangeFactory("product_id", ind)}
                className={elm.error_product_id ? classes.listSelectFieldErr : classes.listSelectField}
              >
                <MenuItem value="" key={'product_id_X'} >-</MenuItem>
                {self.state.products.map(function(elm, ind){
                    return <MenuItem key={'product_id_' + ind} value={elm.id}  className={classes.listSelectOption}>{elm.name}</MenuItem>;
                })}
          </Select>
      );
      elm['select_unit_id'] = (
          <Select
                value={self.state.specification[ind]["unit_id"]}
                onChange={self.handleChangeFactory("unit_id", ind)}
                className={elm.error_product_id ? classes.listSelectFieldErr : classes.listSelectField}
              >
                <MenuItem value="" key={'unit_id_X'} >-</MenuItem>
                {self.state.units.map(function(elm, ind){
                    return <MenuItem key={'unit_id_' + ind} value={elm.id}  className={classes.listSelectOption}>{elm.name}</MenuItem>;
                })}
          </Select>
      );
      elm['input_count'] = (
          <TextField
            value={self.state.specification[ind]["count"]}
            onChange={self.handleChangeFactory("count", ind)}
            margin="normal"
            placeholder="Кількість"
            type="text"
            fullWidth
            variant="standard" 
            className={elm.error_count ? classes.tableTextFieldErr : classes.tableTextField}
            />
      );
      elm['input_price_one'] = (
          <TextField
            value={self.state.specification[ind]["price_one"]}
            onChange={self.handleChangeFactory("price_one", ind)}
            margin="normal"
            placeholder="Кількість"
            type="text"
            fullWidth
            variant="standard" 
            className={elm.error_price_one ? classes.tableTextFieldErr : classes.tableTextField}
            />
      );
      elm['action'] = (<div className={classes.btnGroup}>
                          <Button
                            variant="contained"
                            color="success"
                            size="large"
                            className={classes.rowActionButton}
                            onClick={self.handleDeleteFactory(ind)}
                          >
                          Видалити
                        </Button>
                          <Button
                            variant="contained"
                            color="success"
                            size="large"
                            className={classes.rowActionButtonSattelite}
                            onClick={self.handleDeleteFactory(ind)}
                          >
                          &gt;
                        </Button>
                        </div>);
      return  elm;
        
;
    }
  }

  validate(record){
    var self = this;
    //var errors={};
    var errorExists = false;
    [
      "count",
      "price_one",
      "product_id",
      "total",
      "unit_id"
    ].map(function(elm) {
          var error;
          if(self.validator[elm]) {
            error  = self.validator[elm](record[elm]);
            if (error){
              errorExists = true;
            }
          } else {
            error = "";
          }
          record["error_" + elm] = error;
          return elm;
    });

    return errorExists;
  }

  handleChangeFactory(attributeName, ind) {
      var self = this;
      return function(event) {
        event.preventDefault();
        // deep copy of the data
        var specification = JSON.parse(JSON.stringify(self.state.specification));

        if(ind<0) {
            // add new row to end
            // console.log(moment(), moment().format("YYYY-MM-DD"));
            var newRow = {
                "additional_agreement_id": self.props.appendixId,
                "id":0,

                "count": "",
                "price_one": "",
                "product_id": "",
                "unit_id":"",
                "total": "",

                "error_count":1,
                "error_product_id":1,
                "error_unit_id":1,
                "error_price_one":1
            };

            // newRow[attributeName] = event.target.value;
            specification.push(newRow);
            self.setState({
                "specification": specification
            });
        } else {
            // update price if validated
            var p = specification[ind];
            
            p[attributeName] = event.target.value;

            try{
              p.total = p.price_one * p.count;
            } catch(e){
            }
            
            var errorExists = self.validate(p);
            
            self.setState({
                "specification": specification
            });

            // post the updated data
            if(!errorExists) {
                if(self.saveTimeout) {
                    window.clearTimeout(self.saveTimeout);
                }
                self.saveTimeout = window.setTimeout(function(){
                    self.saveOneRow(p);
                }, 2000);
            }
        }
      }
  }

  saveOneRow(p){
      var self=this;
      var app = this.context;
      var onError = function(errorMessage) {
          self.setState({
          "message":errorMessage,
          "messageType":"error",
          });
      };
      let postData = {
          id: parseInt(p["id"]),
          additional_agreement_id: p["additional_agreement_id"],
          product_id: parseInt(p["product_id"]),
          unit_id: parseInt(p["unit_id"]),
          count: parseInt(p["count"]),
          price_one: parseFloat(p["price_one"]),
          total: parseFloat(p["total"]),
      };
      if (postData.id) {
          // post the UPDATE
          app.postData(
              '/provider_additional_agreement_specification/edit',
              [postData]
          ).then(function(responseJson){
          if(responseJson["status"] === "OK") {
              self.setState({
                  "message": "Дані оновлено",
                  "messageType": "success"
              });
              self.loadSpecification(false);
          } else if(responseJson["status"] === "EXPECTATION_FAILED") {
              onError("Не вдалося зберегти дані");
          } else {
              onError(responseJson["comment"]);
          }
          }).catch(() => onError("Невідома помилка"));
      } else {
          // post the ADD
          app.postData(
              '/provider_additional_agreement_specification/add',
              [postData]
          ).then(function(responseJson){
              if(responseJson["status"] === "OK") {
                  self.setState({
                      "message": "Рядок додано",
                      "messageType": "success"
                  });
                  self.loadSpecification(false);
              } else if(responseJson["status"] === "EXPECTATION_FAILED") {
                  onError("Не вдалося зберегти дані");
              } else {
                  onError(responseJson["comment"]);
              }
          }).catch(() => onError("Невідома помилка"));
      }
  }

  handleDeleteFactory(ind) {
      var self = this;
      var app = this.context;
      return function(event) {
          event.preventDefault();

          var id = self.state.specification[ind].id;

          if(id) {
              var onError = function(errorMessage) {
                  self.setState({
                      "message":errorMessage,
                      "messageType":"error",
                  });
              };
              var postData = [id];
              // post the DELETE command
              app.postData(
                    '/provider_additional_agreement_specification/delete',
                    postData
                  ).then(function(responseJson){
                      if(responseJson["status"] === "OK") {
                          self.setState({
                              "message": "Дані оновлено",
                              "messageType": "success"
                          });
                          self.loadSpecification(false);
                      } else if(responseJson["status"] === "EXPECTATION_FAILED") {
                          onError("Не вдалося зберегти дані");
                      } else {
                          onError(responseJson["comment"]);
                      }
                  }).catch(() => onError("Невідома помилка"));
          } else {
              var specification = JSON.parse(JSON.stringify(self.state.specification));
              specification.splice(ind, 1);
              self.setState({
                  "specification": specification
              });
          }
      }
  }

  render(){
    // global
    //var app = this.context;
    var self = this;
    const { classes } = self.props;

    // console.log(self.state.provider);
    return (
      <>

      <div>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <div className={classes.breadCrumbs}>
                  <Link href="javascript:void(0)" onClick={() => self.props.parent.setState({
                    providerId: null,
                    view:self.props.parent.views.providers,
                  })}>Постачальники</Link>
                  / 
                  <Link href="javascript:void(0)" onClick={() => self.props.parent.setState({
                    view:self.props.parent.views.providerInfo,
                  })}>{self.state.provider.name}</Link>
                  / 
                  <Link href="javascript:void(0)" onClick={() => self.props.parent.setState({
                    view:self.props.parent.views.contracts,
                  }
                  )}>Договори</Link>
                  / 
                  <Link href="javascript:void(0)" onClick={() => self.props.parent.setState({
                    view:self.props.parent.views.contractInfo,
                    contract_number :self.state.contract_number,
                  }
                  )}>{self.state.contract_number}</Link>
                  / 
                  <Link href="javascript:void(0)" onClick={() => self.props.parent.setState({
                    view:self.props.parent.views.contractAppendixes,
                  })}>Додаткові угоди</Link>
                  / 
                  <Link href="javascript:void(0)" onClick={() => self.props.parent.setState({
                    view:self.props.parent.views.contractAppendixInfo,
                  })}> {self.state.appendix.dop_agreement_number}</Link>
                  / Спеціфікація
            </div>
          </Grid>
          <Grid item xs={7}>
            <h1 className={classes.pageTitle}>Спеціфікація додаткової угоди "{self.state.appendix.dop_agreement_number}"</h1>
          </Grid>
          <Grid item xs={5}>
            <div className={classes.topButtons}>
              <span className={classes.btnBlock}>
                  <Button
                    variant="contained"
                    color="success"
                    className={classes.submitButtonSattelite}
                    onClick={self.handleChangeFactory("all", -1)}
                    >
                    &nbsp;
                  </Button>
                <Button
                    color="success"
                    onClick={self.handleChangeFactory("all", -1)}
                    className={classes.submitButton}
                    >Додати рядок</Button>
              </span>
            </div>
          </Grid>
          <Grid item xs={12}>
            <MessageBlock message={self.state.message} messageType={self.state.messageType}/>
          </Grid>
          <Grid item xs={12}>

            <MUIDataTable
              data={this.state.specification.map( self.mapTable() )}
              columns={self.columns}
              options={{
                filterType: "checkbox",
                download:false,
                print:false,
                search:false,
                filter:false,
                viewColumns:false,
                selectableRows: "none",
                textLabels: {
                    body: {
                      noMatch: "Вибачте, записів не знайдено",
                      toolTip: "Впорядкувати",
                      columnHeaderTooltip: column => `Впорядкувати за ${column.label}`
                    },
                    pagination: {
                      next: "Наступна сторінка",
                      previous: "Попередня сторінка",
                      rowsPerPage: "Рядків на сторінці:",
                      displayRows: "із",
                    },
                    toolbar: {
                      search: "Знайти",
                      downloadCsv: "Скачати CSV",
                      print: "Надрукувати",
                      viewColumns: "Показаи колонки",
                      filterTable: "Фільтрувати таблицю",
                    },
                    filter: {
                      all: "Всі",
                      title: "Фільтри",
                      reset: "Очистити",
                    },
                    viewColumns: {
                      title: "Показати колонки",
                      titleAria: "Показати/Приховати колонки",
                    },
                    selectedRows: {
                      text: "рідків вибрано",
                      delete: "Видалити",
                      deleteAria: "Видалити вибрані рядки",
                    },
                  }
              }}
              className={classes.crmXTable + ' ' + classes.crmXColWidth}
            />
          </Grid>
        </Grid>
      </div>
      </>
    );
  }
}
export default withStyles(styles)(ContractAppendixSpecs);

