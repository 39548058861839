import React, { Component } from 'react'
import { withStyles } from "@material-ui/core/styles";

import Themes from "../themes";
const theme = Themes.default;
var customStyles=function(theme){
    return {
        blockWarning: theme.blockWarning,
        blockError: theme.blockError,
        blockSuccess: theme.blockSuccess,
    };
};
const styles = customStyles(theme);
// import { Alarm } from "@material-ui/icons";


class MessageBlock extends Component {
    render() {

        if(this.props.message) {
            var self = this;
            const { classes } = self.props;
            var className="alert alert-primary";
            switch(this.props.messageType){

                case "primary": className=classes.blockSuccess; break;
                case "success": className=classes.blockSuccess; break;

                case "secondary": className="alert alert-secondary"; break;

                case "danger": className=classes.blockError; break;
                case "error": className=classes.blockError; break;

                case "warning": className=classes.blockWarning; break;
                case "warn": className=classes.blockWarning; break;

                case "info": className="alert alert-info"; break;

                case "light": className="alert alert-light"; break;

                case "dark": className="alert alert-dark"; break;

                default: className="alert alert-primary"; break;
            }
            return (<div className={className}>{this.props.message}</div>);
        } else {
            return <></>;
        }
    }
}


export default withStyles(styles)(MessageBlock)
