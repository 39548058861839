import React, {Component}  from "react";
import {
  Grid,
  Select,
  Button,
  TextField,
  MenuItem,
  TextareaAutosize,
  FormControlLabel,
  Checkbox,
  Link,
} from "@material-ui/core";
import moment from 'moment';

// components
// import { Typography } from "../../../../components/Wrappers";
import validators   from '../../../../utils/validators';
import MessageBlock from '../../../../utils/MessageBlock';
import { AppContext } from "../../../../context/AppContext";
import ApiModel from "../../../../utils/ApiModel";
import {CrmDateTimePicker, CrmDatePicker} from '../../../../components/CrmDateTimePicker/CrmDateTimePicker';
import {CrmButton} from '../../../../components/CrmButton/CrmButton';
import ShipmentDetail from '../ShipmentDetail/ShipmentDetail';


// styles
import { withStyles } from "@material-ui/core/styles";
import Themes from "../../../../themes";
import customStyles from "./styles";


const theme = Themes.default;
const styles = customStyles(theme);




class ShipmentInfo extends Component {

  static contextType = AppContext;
  constructor(props) {
    super(props);

    this.defaultRecord = JSON.stringify(ApiModel.defaultShipmentRecord());
    if(!this.state) {
      this.state = {
        consumerId:false,
        consumer:{},

        consumerContractId:false,
        consumerContract:{},
        consumerContractProgress:{},

        shipmentId:false,
        shipment:{},
        errors:{},

        personal:[],
        personal_map:{},

        message:"",
        messageType:"",
      };
    }
    // var self =this;
    // id                    bigint      (NULL)     NO      PRI     (NULL)   auto_increment  select,insert,update,references           
    // consumer_id           bigint      (NULL)     NO      MUL     (NULL)                   select,insert,update,references           
    // consumer_contract_id  bigint      (NULL)     NO      MUL     (NULL)                   select,insert,update,references           
    // emplyoes_id           bigint      (NULL)     NO      MUL     (NULL)                   select,insert,update,references           
    // summary               float       (NULL)     NO              (NULL)                   select,insert,update,references           
    // create_day            datetime    (NULL)     NO              (NULL)                   select,insert,update,references           
    // approved              tinyint(1)  (NULL)     YES             (NULL)                   select,insert,update,references           

    this.validator={
        consumer_id:ApiModel.atomicValidator.isPositiveInteger,
        consumer_contract_id:ApiModel.atomicValidator.isPositiveInteger,
        emplyoes_id:ApiModel.atomicValidator.isPositiveInteger,
        summary: ApiModel.atomicValidator.isPositiveFloat,
        create_day: ApiModel.atomicValidator.nonEmpty,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeFactory = ApiModel.handleChangeFactory2(this);
    // this.handleCheckboxFactory = ApiModel.handleCheckboxFactory2(this);
    this.handleDateChangeFactory = ApiModel.handleDateChangeFactory2(this);

    this.validate = ApiModel.validate(this).bind(this);
    this.onError = ApiModel.onError(this).bind(this);

    this.loadOneConsumer = ApiModel.loadOneConsumer(this, "consumer", this.onError);
    this.loadOneConsumerContract = ApiModel.loadOneConsumerContract(this, "consumerContract", this.onError);
    this.loadOneShipment = ApiModel.loadOneShipment(this, "shipment", this.onError);
    this.loadOneConsumerContractProgress = ApiModel.loadOneConsumerContractProgress(this, "consumerContractProgress", this.onError);

    this.loadPersonal  = ApiModel.loadPersonal(this, "personal");
    this.loadPersonalMap  = ApiModel.loadPersonalMap(this, "personal_map");
  }


  componentDidMount(){
    // var app = this.context;
    
    var self = this;
    // var app = self.context;
    this.setState({
        consumerId: this.props.consumerId,
        consumerContractId: this.props.consumerContractId,
        shipmentId: this.props.shipmentId,
    });
    self.loadOneConsumer(self.props.consumerId).finally(function(){
        self.loadOneConsumerContract(self.props.consumerId, self.props.consumerContractId).finally(function(){
            self.loadOneShipment(self.props.shipmentId).finally(function(){
                self.loadOneConsumerContractProgress(self.props.consumerId, self.props.consumerContractId).finally(function(){
                  self.loadPersonal().finally(function(){
                    self.loadPersonalMap();
                  });
                });
            });
        });
    });
  }

  componentDidUpdate(){
    if(this.props.consumerId !==  this.state.consumerId
    || this.props.consumerContractId !==  this.state.consumerContractId
    || this.props.shipmentId !==  this.state.shipmentId
    ) {
      var self = this;
      this.setState({
        consumerId: this.props.consumerId,
        consumerContractId: this.props.consumerContractId,
        shipmentId: this.props.shipmentId,
      });
      self.loadOneConsumer(self.props.consumerId).finally(function(){
        self.loadOneConsumerContract(self.props.consumerId, self.props.consumerContractId).finally(function(){
          self.loadOneShipment(self.props.shipmentId).finally(function(){
            self.loadOneConsumerContractProgress(self.props.consumerId, self.props.consumerContractId);
          });
        });
      });
    }    
  }


  handleSubmit(event) {
        event.preventDefault();
        var self = this;
        var app = this.context;
        
        let shipment = JSON.parse(JSON.stringify(this.state.shipment));

        shipment.consumer_id = self.props.consumerId;
        shipment.consumer_contract_id = self.props.consumerContractId;

        // do validation
        var err = self.validate(shipment);
        if(err.errorExists) {
            self.setState({
              errors: err.errors,
              message:"Дані не збережено",
              messageType:"error",
            });
            return;
        }

        let postData = JSON.parse(this.defaultRecord);

        postData.emplyoes_id=parseInt(shipment.emplyoes_id);
        postData.summary=parseFloat(shipment.summary);
        postData.create_day=shipment.create_day;
        postData.approved=shipment.approved;

        if(shipment.id>0){
            postData.id=parseInt(self.props.shipmentId);
            // console.log('updating consumerContract', postData);
            app.postData(
                '/consumers/contracts/shipments/edit',
                [postData]
            ).then(function(responseJson){
                // console.log("responseJson", responseJson, responseJson["status"]);
                if(responseJson["status"] === "OK") {
                    self.setState({
                        "message": "Дані оновлено",
                        "messageType": "success"
                    });
                } else if(responseJson["status"] === "EXPECTATION_FAILED") {
                    self.onError("Не вдалося зберегти дані");
                } else {
                    self.onError(responseJson["comment"]);
                }
            }).catch(() => self.onError("Невідома помилка"));
        } else {
            postData.id=0;
            // console.log('creating consumerContract', postData);
            app.postData(
                '/consumers/contracts/shipments/add',
                [postData]
            ).then(function(responseJson){
                // console.log("responseJson", responseJson, responseJson["status"]);
                if(responseJson["status"] === "OK") {
                    postData.id = responseJson["detail"][0];
                    self.setState({
                        "shipmentId": postData.id,
                        "shipment": postData,
                        "message": "Дані збережено",
                        "messageType": "success"
                    });
                } else if(responseJson["status"] === "EXPECTATION_FAILED") {
                    self.onError("Не вдалося зберегти дані");
                } else {
                    self.onError(responseJson["comment"]);
                }
            }).catch(() => self.onError("Невідома помилка"));
        }
  }



  render(){
    // global
    var app = this.context;
    var self = this;
    const { classes } = self.props;


    //+ emplyoes_id           bigint      (NULL)     NO      MUL     (NULL)                   select,insert,update,references           
    //+ summary               float       (NULL)     NO              (NULL)                   select,insert,update,references           
    //+ create_day            datetime    (NULL)     NO              (NULL)                   select,insert,update,references           
    // approved              tinyint(1)  (NULL)     YES             (NULL)                   select,insert,update,references           

    var emplyoes_id = self.state.shipment.emplyoes_id || app.state.personal_id;

    var user_can_approve = app.state.root_id > 0;

    return (<>
      <div>
        <Grid container spacing={4}>

            {self.state.message && (
                <Grid item xs={12}>
                <MessageBlock message={self.state.message} messageType={self.state.messageType}/>
                </Grid>
            )}
            <Grid item xs={12}>
              <div className={classes.breadCrumbs}>
                <Link 
                  // href="javascript:void(1)"
                  onClick={() => app.setState({cmd_consumer:[
                        {
                          action: 'consumers',
                        },
                      ]})
                  }>Клієнти</Link>
                /
                <Link 
                  // href="javascript:void(1)"
                  onClick={() => app.setState({cmd_consumer:[
                        {
                          action: 'consumer_info', 
                          consumerId: self.state.consumer.id,
                        },
                      ]})
                  }>{self.state.consumer.name}</Link>
                /
                <Link 
                  // href="javascript:void(1)"
                  onClick={() => app.setState({cmd_consumer:[
                        {
                          action: 'contract_info', 
                          consumerId: self.state.consumer.id,
                          consumerContractId: self.state.consumerContractId,
                        },
                      ]})
                  }>Договір {self.state.consumerContract.contract_number}</Link>
                / {self.state.shipment.id > 0
                   ? (<>{self.state.shipment.supply_datetime} {self.state.shipment.receiver} </>)
                   : <span>Нове відвантаження</span>
                }
              </div>
            </Grid>

            <Grid item xs={5}>
                <div className={classes.formFieldLabel}>
                Отримувач
                </div>
            </Grid>
            <Grid item xs={7}>
                <div className={classes.formFieldLabel}>
                  {self.state.consumer.name}
                </div>
            </Grid>

            <Grid item xs={5}>
                <div className={classes.formFieldLabel}>
                ЄДРПОУ
                </div>
            </Grid>
            <Grid item xs={7}>
                <div className={classes.formFieldLabel}>
                  {self.state.consumer.okpo}
                </div>
            </Grid>

            <Grid item xs={5}>
              <div className={classes.formFieldLabel}> 
                Договір
              </div>
            </Grid>
            <Grid item xs={7}>
                <div className={classes.formFieldLabel}>
                  {self.state.consumerContract.contract_number}
                  від 
                  {moment(self.state.consumerContract.contract_date).format('DD.MM.YYYY  HH:mm')}
                </div>
            </Grid>


            <Grid item xs={5}>
              <div className={classes.formFieldLabel}> 
                По договору вже відвантажено
              </div>
            </Grid>
            <Grid item xs={7}>
                <div className={classes.formFieldLabel}>
                  {self.state.consumerContractProgress.summary} грн
                </div>
            </Grid>


            <Grid item xs={5}>
                <div className={classes.formFieldLabel}>
                    Хто створює відвантаження
                </div>
            </Grid>
            <Grid item xs={7}>
                {app.state.personal_id > 0 &&
                  <div className={classes.formFieldLabel}>
                    {self.state.personal_map[emplyoes_id]}
                  </div>
                }
                {app.state.root_id > 0 &&
                  <Select
                      value={self.state.shipment.emplyoes_id || ''}
                      onChange={self.handleChangeFactory("shipment", "emplyoes_id")}
                      className={self.state.errors.emplyoes_id ? classes.selectFieldErr : classes.selectField}
                  >
                      <MenuItem value="" key={'emplyoes_id_X'} >-</MenuItem>
                      {self.state.personal.map(function(elm, ind){
                          return <MenuItem key={'emplyoes_id_' + ind} value={elm.id}  className={classes.selectOption}>{elm.fname} {elm.sname} {elm.lname}</MenuItem>;
                      })}
                  </Select>
                }
            </Grid>

            <Grid item xs={5}>
                <div className={classes.formFieldLabel}>
                Дата відвантаження
                </div>
            </Grid>
            <Grid item xs={7}>
              <CrmDateTimePicker
                renderInput={(props) => <TextField {...props} />}
                // label="Дата договору"
                value={self.state.shipment.create_day}
                onChange={self.handleDateChangeFactory("shipment", "create_day")}
                margin="dense"
                placeholder="Дата відвантаження"
                variant="standard" 
                className={self.state.errors.create_day ? classes.textFieldErr : classes.textField}
              />
            </Grid>

            <Grid item xs={5}>
                <div className={classes.formFieldLabel}>
                Сума відвантаження
                </div>
            </Grid>
            <Grid item xs={7}>
                <div className={classes.formFieldLabel}>
                {self.state.shipment.summary} грн.
                </div>
            </Grid>


          <Grid item xs={12}>
            <div className={classes.formButtons} style={{textAlign:"center"}}>
              <CrmButton
                   classNameMain={classes.submitButton}
                   classNameSattelite={classes.submitButtonSattelite}
                   size="large"
                   onClick={self.handleSubmit}
                 >
                 Зберегти
              </CrmButton> 
              &nbsp; &nbsp;
              {user_can_approve && 
                <CrmButton
                    classNameMain={classes.submitButton}
                    classNameSattelite={classes.submitButtonSattelite}
                    size="large"
                    onClick={
                        () => app.setState( { cmd_consumer:[
                              {
                                action: "shipment_approve",
                                consumerId: self.props.consumerId || 0,
                                consumersContractId: self.props.consumersContractId || 0,
                                shipmentId: self.props.shipmentId || 0,
                                approve: true,
                              }
                            ]})
                    }
                    >
                    Узгодити відвантаження
                </CrmButton>
              }
            </div>
          </Grid>

          <Grid item xs={12}>
            <br/>
            <br/>
            <h1 style={{textAlign:"center", textTransform:"uppercase"}}>ЗАЯВКА НА ВІДВАНТАЖЕННЯ</h1>
          </Grid>
        </Grid>
        <ShipmentDetail consumerId={self.state.consumerId} 
            consumerContractId={self.state.consumerContractId} 
            shipmentId={self.state.shipmentId} 
            breadcrumbs={false}
            header={false}
            parent={this}/>
      </div>
    </>);
    
  }
}
export default withStyles(styles)(ShipmentInfo);

