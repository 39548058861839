import React, {Component}  from "react";
import {
  Grid,
  Popover,
  Link,
  // Button
} from "@material-ui/core";
import moment from 'moment';
import MUIDataTable from "mui-datatables";
// components
//import Widget from "../../components/Widget";
//import PageTitle from "../../components/PageTitle";
//import { Typography } from "../../components/Wrappers";
//import Dot from "../../components/Sidebar/components/Dot";

import TaskInfo from "./components/TaskInfo/TaskInfo";
import TaskComment from "./components/TaskComment/TaskComment";
import TaskStatus from "./components/TaskStatus/TaskStatus";
import TaskUpload from "./components/TaskUpload/TaskUpload";
import TaskFiles from "./components/TaskFiles/TaskFiles";
import TaskFinish from "./components/TaskFinish/TaskFinish";

// context
import { AppContext } from "../../context/AppContext";
import MessageBlock from '../../utils/MessageBlock';

// styles
import { withStyles } from "@material-ui/core/styles";
import Themes from "../../themes";
import ApiModel from "../../utils/ApiModel";
import {CrmButton} from "../../components/CrmButton/CrmButton";
import customStyles from "./styles";
const theme = Themes.default;
const styles = customStyles(theme);

const redBg="{background-color:" + theme.crm.red + "; color:" + theme.crm.white + ";}"
const yellowBg="{background-color:" + theme.crm.yellowSand + "; color:" + theme.crm.black + ";}"


class Task extends Component {

  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.columns = [
      { name: "id",          label: "ID",  options: { filter: false, sort: false, } },
      { name: "task_main_id",  label: "Головне завдання", options: { filter: false, sort: false, } },
      // { name: "requester_id",   label: "Від співробітника",  options: { filter: false, sort: false, } },
      // { name: "requester_admin_id",   label: "Від співробітника",  options: { filter: false, sort: false, } },
      { name: "requester_view", label: "Від співробітника",  options: { filter: false, sort: false, } },
      // { name: "personal_id",    label: "Для співробітника",  options: { filter: false, sort: false, } },
      { name: "personal_id_view",    label: "Для співробітника",  options: { filter: false, sort: false, } },
      { name: "contractor_okpo",    label: "ЄДРПОУ контрагента",  options: { filter: false, sort: false, } },
      // { name: "start_date",  label: "Дата початку",  options: { filter: false, sort: false, } },
      { name: "start_date_view",  label: "Дата початку",  options: { filter: false, sort: false, } },
      { name: "end_date_view",  label: "Термін виконання",  options: { filter: false, sort: false, } },
      // { name: "comments",  label: "Коментарі",  options: { filter: false, sort: false, } },
      // { name: "task_status",  label: "Статус",  options: { filter: false, sort: false, } },
      { name: "task_status_view",  label: "Статус",  options: { filter: false, sort: false, } },
      // { name: "type_task",  label: "Тип",  options: { filter: false, sort: false, } },
      { name: "type_task_view",  label: "Тип",  options: { filter: false, sort: false, } },

      { name: "action",  label: "Дії",  options: { filter: false, sort: false, } },
    ];

    if(!this.state) {
      this.state = {
        taskId:0,                 // task is being edited
        parentTaskId:0,              // subtaskOf - to create new subtask

        default_auction_id: 0,
        default_contractor_okpo:"",
        default_end_date: "",
        default_personal_id:"",
        default_type_task: "",

        tasks:[],                 // list of tasks
        popupFormVisible:false,   // add/edit form is visible
        commentFormVisible:false, // "add comment" form visible
        statusFormVisible:false,  // "update status" form visible
        uploadFormVisible:false,  // "upload" form visible
        filesFormVisible:false,   // "files" form visible
        finishFormVisible:false,  // "finish task" form visible

        personal_map:{},          // list of persons
        task_status_map:{},           // list of task statuses
        type_task_map:{},
        admin_map:{},

        message: "",
        messageType: "",
      };
    }

    this.filterHash = JSON.stringify(this.props.filter);
    this.closePopupForm = this.closePopupForm.bind(this);

    this.loadTaskTypeMap = ApiModel.loadTaskTypeMap(this,"type_task_map");
    this.loadPersonalMap = ApiModel.loadPersonalMap(this, "personal_map");
    this.loadTaskStatusMap = ApiModel.loadTaskStatusMap(this, "task_status_map");
    this.loadAdminMap = ApiModel.loadAdminMap(this, "admin_map");
  }

  componentDidUpdate(){
    // var app = this.context;
    // var filter = this.props.filter ;
    // console.log("Task componentDidUpdate")
    // console.log("receiver filter", filter);

    this.processCmd();
    var newFilterHash = JSON.stringify(this.props.filter);
    if(newFilterHash !==  this.filterHash) {
      this.filterHash = newFilterHash;
      this.loadTasks();
    }
  }

  processCmd(){
    var app = this.context;
    var onError = ApiModel.onError(this);
    if(this.props.cmd.length > 0){
       for(let i=0; i<this.props.cmd.length; i++) {
          let cmd = this.props.cmd[i];

          switch(cmd.action) {
            case 'add':
              this.setState({
                popupFormVisible: true,
                commentFormVisible:false,
                taskId:0,
                parentTaskId:0,
                // default values
                default_auction_id: cmd.auction_id || 0,
                default_contractor_okpo:cmd.contractor_okpo || 0,
                default_end_date: cmd.end_date || "",
                default_personal_id: cmd.personal_id || 0,
                default_type_task: cmd.type_task || 0,
                default_comments: cmd.comments || ""
              });
            break;
            case "edit":
              this.setState({
                popupFormVisible: true,
                commentFormVisible:false,
                taskId: cmd.taskId || 0,
                parentTaskId:0,
              });
            break;
            case "subtask":
              this.setState({
                popupFormVisible:true,
                commentFormVisible:false,
                taskId: 0,
                parentTaskId: cmd.parentTaskId,
              });
            break;
            case "comment":
              this.setState({
                popupFormVisible:false,
                commentFormVisible:true,
                taskId: cmd.taskId || 0,
                parentTaskId: 0,
              });
            break;
            case "refresh":
              this.loadTasks();
            break;
            case "closePopup":
              this.closePopupForm();
            break;
            case "setAuctionId":
              app.postData(
                '/aukcion_task/add',
                {"task_id":cmd.taskId, "aukcion_id":cmd.auctionId}
              ).then(function(){
                // 
              }).catch(() => onError("Не вдалося приєднати завдання до аукціона"));
            break;
            default:
            break;
          }
       }
       app.setState( { cmd_task:[] });
    }
  }

  componentDidMount(){
    var app = this.context;
    var self = this;
    var popupFormVisible = this.props.cmd && this.props.cmd[0]==='add';
    app.setState({
      sidebarMenuId:"task",
      popupFormVisible:popupFormVisible,
    });

    self.loadTasks().finally(function(){
      self.loadPersonalMap().finally(function(){
        self.loadTaskStatusMap().finally(function(){
          self.loadTaskTypeMap().finally(function(){
            self.loadAdminMap();
          });
        });
      });
    });
  }


  loadTasks(){
    var app = this.context;
    var self = this;
    var onError = function(errorMessage) {
        self.setState({
          message: errorMessage,
          messageType: "error",
        });
    };

    var filter = this.props.filter ;
    var param = {};

    
    if(filter.taskId) param["task_id"] = filter.taskId;
    if(filter.edrpou) param["edrpou"] = filter.edrpou;
    if(filter.personalId) param["personal_id"] = filter.personalId;
    if(filter.taskStatusId) param["task_status"] = filter.taskStatusId;
    if(filter.taskTypeId) param["type_task"] = filter.taskTypeId;
    // if(filter.dateMin) param["date_min"] = filter.dateMin;
    // if(filter.dateMax) param["date_max"] = filter.dateMax;
    if(!!filter.dateMin && !!filter.dateMax) {
      param["period"]=[filter.dateMin, filter.dateMax];
    }
    // var filterPresent = Object.keys(param).length > 0;

    switch(filter.taskSuccess){
        case "failed_status": 
          param["failed_status"] = true;
          param["success_status"] = false;
        break;
        case "success_status": 
          param["failed_status"] = false;
          param["success_status"] = true;
        break;
        default: 
        break;
    }

    if(app.state.userType !== "admin") {
      param["personal_id"] = app.state.personal_id;
    }


    if(Object.keys(param).length > 0) {
        param = [param];
    } else {
        param = [];
    }
    return app.postData(
        '/tasks/get',
        {"param": param}
    ).then(function(responseJson){
      // console.log("responseJson", responseJson, responseJson["status"]);
      if(responseJson["status"] === "OK") {
        self.setState({
          "tasks": responseJson["detail"], 
          "message": "",
          "messageType": "success" 
        });
      } else if(responseJson["status"] === "NOT_FOUND") {
        self.setState({
          "tasks": [], 
          "message": "Дані не знайдено, очистіть фільтр та спробуйте ще раз",
          "messageType": "error"
        });
      } else {
        onError(responseJson["comment"]);
      }
    }).catch(() => onError("Не вдалося завантажити завдання"));
  }


  closePopupForm(){
    // var app = this.context;
    // app.setState( { cmd_task:[] });
    this.setState({
      commentFormVisible:false,
      popupFormVisible:false,
      statusFormVisible:false,
      uploadFormVisible:false,
      filesFormVisible:false,
      finishFormVisible:false,
      taskId:0,
      parentTaskId:0,
    });
  }

  mapTable(){
    var self=this;
    var app = this.context;
    const { classes } = self.props;
    return function(row, ind){
      var elm =  JSON.parse(JSON.stringify(row));

      if(self.state.personal_map[elm['requester_id']]){
        let p = self.state.personal_map[elm['requester_id']];
        elm['requester_view'] = (<div>{p.fname} {p.sname} {p.lname}</div>)
      } else if(self.state.admin_map[elm['requester_admin_id']]){
        let p = self.state.admin_map[elm['requester_admin_id']];
        elm['requester_view'] = (<div>{p.f_name} {p.s_name} {p.l_name}</div>)
      }

      //requester_admin_id  admin_map

      if(self.state.personal_map[elm['personal_id']]){
        let p = self.state.personal_map[elm['personal_id']];
        elm['personal_id_view'] = (<div>{p.fname} {p.sname} {p.lname}</div>)
      } else {
        elm['personal_id_view'] = elm['personal_id'];
      }

      if(self.state.task_status_map[elm['task_status']]){
        let p = self.state.task_status_map[elm['task_status']];
        elm['task_status_view'] = p.name;
      } else {
        elm['task_status_view'] = elm['task_status'];
      }

      if(self.state.type_task_map[elm['type_task']]){
        let p = self.state.type_task_map[elm['type_task']];
        elm['type_task_view'] = (<div>{p.name}</div>)
      } else {
        elm['type_task_view'] = elm['type_task'];
      }

      elm['contractor_okpo'] = elm['contractor_okpo'] || "";

      // elm['start_date_view'] =  moment(elm['start_date']).format('DD.MM.YYYY  HH:mm:ss');
      elm['start_date_view'] =  moment(elm['start_date']).format('DD.MM.YYYY  HH:mm');

      let end_date_moment = moment(elm['end_date']);
      let finish_date_moment = false;

      if(elm['finish_date'] && elm['finish_date'] !== "null"){
        finish_date_moment = moment(elm['finish_date']);
      }
      // console.log("elm['finish_date']", elm['finish_date'], finish_date_moment);

      if(end_date_moment.isAfter(moment()) && !finish_date_moment){
        // если дедлайн в будущем и нет finish_date  - не красим
        // elm['end_date_view'] =  end_date_moment.format('DD.MM.YYYY HH:mm:ss');
        elm['end_date_view'] =  end_date_moment.format('DD.MM.YYYY HH:mm');
      } else if(end_date_moment.isAfter(moment()) && !!finish_date_moment) {
        // если дедлайн в будущем и есть finish_date - красим желтым
        elm['end_date_view'] =  (<>
          <style type="text/css">
            #MUIDataTableBodyRow-{ind} td {yellowBg}
          </style>
          {end_date_moment.format('DD.MM.YYYY HH:mm')}
        </>);        
      } else if(!end_date_moment.isAfter(moment()) && !finish_date_moment) {
        // если дедлайн в прошлом и нет finish_date  - красим красным
        elm['end_date_view'] =  (<>
          <style type="text/css">
            #MUIDataTableBodyRow-{ind} td {redBg}
          </style>
          {end_date_moment.format('DD.MM.YYYY HH:mm')}
        </>);        
      } else if(!end_date_moment.isAfter(moment()) && !!finish_date_moment) {
        // если дедлайн в прошлом и есть finish_date - красим желтым
        elm['end_date_view'] =  (<>
          <style type="text/css">
            #MUIDataTableBodyRow-{ind} td {yellowBg}
          </style>
          {end_date_moment.format('DD.MM.YYYY HH:mm')}
        </>);        
      }

      let commentButtonActive = true;
      if(finish_date_moment){ commentButtonActive = false;  }
      if(app.state.userType === "admin"){ commentButtonActive = true; }

      let editButtonActive = false;
      if(app.state.userType === "admin"){ editButtonActive = true; }

      let subtaskButtonActive = true;
      if(finish_date_moment){ subtaskButtonActive = false;  }
      if(app.state.userType === "admin"){ subtaskButtonActive = true; }

      let finishButtonActive = true;
      if(finish_date_moment){ finishButtonActive = false;  }
      if(app.state.userType === "admin"){ finishButtonActive = true; }

      let statusButtonActive = false;
      // if(finish_date_moment){ statusButtonActive = false;  }
      // if(app.state.userType === "admin"){ statusButtonActive = true; }

      let filesButtonActive = true;
      if(finish_date_moment){ filesButtonActive = false;  }
      if(app.state.userType === "admin"){ filesButtonActive = true; }

      let uploadButtonActive = true;
      if(finish_date_moment){ uploadButtonActive = false;  }
      if(app.state.userType === "admin"){ uploadButtonActive = true; }

      let urlButtonActive = true;
      // if(finish_date_moment){ urlButtonActive = false;  }
      // if(app.state.userType === "admin"){ urlButtonActive = true; }

      elm['action'] = (<>
        <div className={classes.btnGroup}>
          <CrmButton
            classNameMain={commentButtonActive ? classes.rowActionButton: classes.rowActionButtonDisabled}
            classNameSattelite={commentButtonActive ? classes.rowActionButtonSattelite : classes.rowActionButtonSatteliteDisabled}
            size="small"
            onClick={
              commentButtonActive
              ? () => self.setState({ taskId: elm.id, commentFormVisible:true,})
              : () => false
            }
          >
          Коментарі
          </CrmButton>
        </div>
        <div className={classes.btnGroup}>
          <CrmButton
            classNameMain={editButtonActive ? classes.rowActionButton: classes.rowActionButtonDisabled}
            classNameSattelite={editButtonActive ? classes.rowActionButtonSattelite : classes.rowActionButtonSatteliteDisabled}
            size="small"
            onClick={
            editButtonActive
            ? () => self.setState({taskId: elm.id, popupFormVisible:true, })
            : () => 1
            }
          >
          Переглянути
          </CrmButton>
        </div>
        <div className={classes.btnGroup}>
          <CrmButton
            classNameMain={subtaskButtonActive ? classes.rowActionButton: classes.rowActionButtonDisabled}
            classNameSattelite={subtaskButtonActive ? classes.rowActionButtonSattelite : classes.rowActionButtonSatteliteDisabled}
            size="small"
            onClick={
            subtaskButtonActive
            ? () => self.setState({
                taskId: 0,
                parentTaskId:elm.id,
                popupFormVisible:true,
                default_contractor_okpo:elm.contractor_okpo,
              })
            : () => 1
            }
          >
          Долучити співробітника
          </CrmButton>
        </div>
        <div className={classes.btnGroup}>
        {app.state.userType !== "admin" &&
          <CrmButton
            classNameMain={finishButtonActive ? classes.rowActionButton: classes.rowActionButtonDisabled}
            classNameSattelite={finishButtonActive ? classes.rowActionButtonSattelite : classes.rowActionButtonSatteliteDisabled}
            size="small"
            onClick={
              finishButtonActive
              ? () => self.setState({
                taskId: elm.id,
                finishFormVisible:true,
              })
              :() => 1}
          >
          Завершити завдання
          </CrmButton>
        }
        {app.state.userType === "admin" &&
          <CrmButton
            classNameMain={!finish_date_moment ? classes.rowActionButton: classes.rowActionButtonDisabled}
            classNameSattelite={!finish_date_moment ? classes.rowActionButtonSattelite : classes.rowActionButtonSatteliteDisabled}
            size="small"
            onClick={
              finishButtonActive
              ? () => self.setState({
                taskId: elm.id,
                finishFormVisible:true,
              })
              :() => 1}
          >
          Завершити завдання
          </CrmButton>
        }
        </div>
        {statusButtonActive &&
          <div className={classes.btnGroup}>
            <CrmButton
              classNameMain={classes.rowActionButton}
              classNameSattelite={classes.rowActionButtonSattelite}
              size="small"
              onClick={() => self.setState({
                taskId: elm.id,
                statusFormVisible:true,
              })}
            >
            Змінити статус
            </CrmButton>
          </div>
        }
        <div className={classes.btnGroup}>
            <CrmButton
              classNameMain={filesButtonActive ? classes.rowActionButton: classes.rowActionButtonDisabled}
              classNameSattelite={filesButtonActive ? classes.rowActionButtonSattelite : classes.rowActionButtonSatteliteDisabled}
              size="small"
              onClick={
                filesButtonActive
                ? () => self.setState({
                  taskId: elm.id,
                  filesFormVisible:true,
                })
                : () => 1
              }
            >
            Всі файли
            </CrmButton>
        </div>
        <div className={classes.btnGroup}>
            <CrmButton
              classNameMain={uploadButtonActive ? classes.rowActionButton: classes.rowActionButtonDisabled}
              classNameSattelite={uploadButtonActive ? classes.rowActionButtonSattelite : classes.rowActionButtonSatteliteDisabled}
              size="small"
              onClick={
                uploadButtonActive
                ? () => self.setState({
                  taskId: elm.id,
                  uploadFormVisible:true,
                })
                : () => 1
              }
            >
            Додати файл
            </CrmButton>
        </div>

        <div className={classes.btnGroup}>
            <CrmButton
              classNameMain={urlButtonActive ? classes.rowActionButton: classes.rowActionButtonDisabled}
              classNameSattelite={urlButtonActive ? classes.rowActionButtonSattelite : classes.rowActionButtonSatteliteDisabled}
              size="small"
            >
              <Link href={elm.url} target="blank" style={{color:"white"}}>
                  Деталі завдання
              </Link>
            </CrmButton>
        </div>        
      </>);
      // console.log(elm);
      return  elm;
    }
  }

  render(){
    // global
    // var app = this.context;
    var self = this;
    const { classes } = self.props;

    return (
      <>
      <Grid container spacing={4}>
        {self.state.message && (<Grid item xs={12}>
          <MessageBlock message={self.state.message} messageType={self.state.messageType}/>
        </Grid>)}
       <Grid item xs={12}>
          <MUIDataTable
              data={this.state.tasks.map( self.mapTable() )}
              columns={self.columns}
              options={{
                filterType: "checkbox",
                download:false,
                print:false,
                search:false,
                filter:false,
                viewColumns:false,
                selectableRows: "none",
                textLabels: {
                    body: {
                      noMatch: "Вибачте, записів не знайдено",
                      toolTip: "Впорядкувати",
                      columnHeaderTooltip: column => `Впорядкувати за ${column.label}`
                    },
                    pagination: {
                      next: "Наступна сторінка",
                      previous: "Попередня сторінка",
                      rowsPerPage: "Рядків на сторінці:",
                      displayRows: "із",
                    },
                    toolbar: {
                      search: "Знайти",
                      downloadCsv: "Скачати CSV",
                      print: "Надрукувати",
                      viewColumns: "Показаи колонки",
                      filterTable: "Фільтрувати таблицю",
                    },
                    filter: {
                      all: "Всі",
                      title: "Фільтри",
                      reset: "Очистити",
                    },
                    viewColumns: {
                      title: "Показати колонки",
                      titleAria: "Показати/Приховати колонки",
                    },
                    selectedRows: {
                      text: "рідків вибрано",
                      delete: "Видалити",
                      deleteAria: "Видалити вибрані рядки",
                    },
                  }
              }}
              className={classes.crmXTable + ' ' + classes.crmXColWidth}
            />
       </Grid>
      </Grid>
      {self.state.popupFormVisible && (
          <Popover
            open={self.state.popupFormVisible}
            anchorEl={document.getElementById('root')}
            // onClose={self.closePopupForm}
            className={classes.popOver}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <TaskInfo taskId={self.state.taskId} 
                      parentTaskId={self.state.parentTaskId} 

                      default_auction_id={self.state.default_auction_id}
                      default_contractor_okpo={self.state.default_contractor_okpo}
                      default_end_date={self.state.default_end_date}
                      default_personal_id={self.state.default_personal_id}
                      default_type_task={self.state.default_type_task}
                      default_comments={self.state.default_comments}

                      parent={this}/>
          </Popover>
      )} 
       {self.state.commentFormVisible && (
        <Popover
          id="popover"
          open={self.state.commentFormVisible}
          anchorEl={document.getElementById('root')}
          // onClose={self.closePopupForm}
          className={classes.popOverMini}
          anchorOrigin={{ vertical: 'top',  horizontal: 'left', }}
        >
          <TaskComment taskId={self.state.taskId} parent={this}/>
        </Popover>
      )} 
       {self.state.statusFormVisible && (
        <Popover
          open={self.state.statusFormVisible}
          anchorEl={document.getElementById('root')}
          // onClose={self.closePopupForm}
          className={classes.popOverMini}
          anchorOrigin={{ vertical: 'top',  horizontal: 'left', }}
        >
          <TaskStatus taskId={self.state.taskId} parent={this}/>
        </Popover>
      )} 
       {self.state.uploadFormVisible && (
        <Popover
          open={self.state.uploadFormVisible}
          anchorEl={document.getElementById('root')}
          // onClose={self.closePopupForm}
          className={classes.popOverMini}
          anchorOrigin={{ vertical: 'top',  horizontal: 'left', }}
        >
          <TaskUpload taskId={self.state.taskId} parent={this}/>
        </Popover>
      )} 
      {self.state.finishFormVisible && (
        <Popover
          open={self.state.finishFormVisible}
          anchorEl={document.getElementById('root')}
          // onClose={self.closePopupForm}
          className={classes.popOverMini}
          anchorOrigin={{ vertical: 'top',  horizontal: 'left', }}
        >
          <TaskFinish taskId={self.state.taskId} parent={this}/>
        </Popover>
      )} 
      {self.state.filesFormVisible && (
        <Popover
          open={self.state.filesFormVisible}
          anchorEl={document.getElementById('root')}
          // onClose={self.closePopupForm}
          className={classes.popOverMini}
          anchorOrigin={{ vertical: 'top',  horizontal: 'left', }}
        >
          <TaskFiles taskId={self.state.taskId} parent={this}/>
        </Popover>
      )} 

      </>
    );
  }

}
export default withStyles(styles)(Task);

