import React, {Component}  from "react";
import {
  Grid,
  // Select,
  // MenuItem,
  Button,
  // TextField,
  TextareaAutosize,
  // Link,
} from "@material-ui/core";
import moment from 'moment';
// import DateTimePicker from '@mui/lab/DateTimePicker';
// import DatePicker from '@mui/lab/DatePicker';
import { 
    RadioGroup, 
    Radio, 
    FormControlLabel, 
    FormControl, 
    // FormLabel
} from '@mui/material';


// components
// import MUIDataTable from "mui-datatables";
// import validators from '../../validators';
import validators   from '../../../../utils/validators';
import MessageBlock from '../../../../utils/MessageBlock';
// context
import { AppContext } from "../../../../context/AppContext";
import ApiModel from "../../../../utils/ApiModel";
// import regions   from '../../../../utils/regions_ua';

// styles
import { withStyles } from "@material-ui/core/styles";
import Themes from "../../../../themes";
import customStyles from "./styles";
// import { Alarm } from "@material-ui/icons";
const theme = Themes.default;
const styles = customStyles(theme);



/**
id: <int>
				task_main_id:<int>
				requester_id:<int>
				personal_id:<int>
				start_date: <date>
				end_date:<datetime>
				comments:<text>
				task_status:<int>
 */
class TaskFinish extends Component {

  static contextType = AppContext;
  constructor(props) {
    super(props);
    let now = moment().format('YYYY-MM-DD HH:mm:ss');
    this.defaultRecord=JSON.stringify({
      closed_by_personal_id:0,
      closed_by_admins_id:0,
      task_id:0,
      close_time:now,

      success:-1,  
      comment_steps:"",   // Що було зроблено: 
      comment_results:"",   // Промежуточні результати які були досягнуті
      comment_excuse:"",  // Причини невдачі:
      comment_reject_cause:"",// Причини за якою не можливо виконати завдання:
      comment_success:"",   // Комментарі до успіху: 
    });

   



    if(!this.state) {
      this.state = {
        taskId:this.props.taskId,
        task:{},
        form_data:JSON.parse(this.defaultRecord),

        errors: {},
        message:"",
        messageType:"",

      };
    }
    var self =this;

    var nonEmpty = function(val) {
      if((val + '').trim().length === 0 ){
          return "Будь ласка, введіть значення";
      }
      return "";
    };

    var isPositiveInteger = function(val) {
      if(validators.isPositiveInteger(val) !== true ){
        return "Будь ласка, виберіть значення";
      }
      return "";
    }
    this.validator = {
      comment_excuse: function(val) {
        if(parseInt(self.state.form_data.success) !== 0) { return ""; }
        if((val + '').trim().length === 0 ){ return "Будь ласка, введіть значення"; }
        return "";
      },  // Причини невдачі:
      comment_steps: nonEmpty,     // Що було зроблено: 
      comment_results: nonEmpty,   // Промежуточні результати які були досягнуті
      comment_reject_cause:  function(val) {
        if(parseInt(self.state.form_data.success) !== 0) { return ""; }
        if((val + '').trim().length === 0 ){ return "Будь ласка, введіть значення"; }
        return "";
      },    // Причини за якою неможливо виконати завдання:
      comment_success:function(val) {
        if(parseInt(self.state.form_data.success) !== 1) { return ""; }
        if((val + '').trim().length === 0 ){ return "Будь ласка, введіть значення"; }
        return "";
      },
      success: isPositiveInteger,
     };

     this.handleSubmit = this.handleSubmit.bind(this);
     this.handleChangeFactory = ApiModel.handleChangeFactory.bind(this);
     this.loadOneTask = ApiModel.loadOneTask(this, "task");
     this.validate = ApiModel.validate(this);
  }

  componentDidUpdate(){
    if(this.props.taskId !==  this.state.taskId) {
      var self = this;
      self.setState({
        taskId: self.props.taskId,
        form_data:JSON.parse(self.defaultRecord),
      });
      self.loadOneTask();
    }    
  }

  componentDidMount(){
    // var app = this.context;
    var self = this;
    self.setState({
        taskId: this.props.taskId,
        form_data:JSON.parse(self.defaultRecord),
    });
    self.loadOneTask().finally(function(){
      
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    var self = this;
    var app = self.context;
    
    let task = JSON.parse(JSON.stringify(self.state.task));

    // do validation
    // console.log('validate', task);
    var err = self.validate(task);

    if(err.errorExists) {
      self.setState({
        errors: err.errors,
        message:"Виправте дані",
        messageType:"error",
      });
      return;
    }

    var onError = function(errorMessage) {
        self.setState({
          "message":errorMessage,
          "messageType":"error",
        });
    };


    if(task.id){
      let success = parseInt(self.state.form_data.success);
      let postData = {
        task_id: parseInt(task.id) || 0,
        closed_by_personal_id: parseInt(app.state.personal_id) || 0,
        closed_by_admins_id: parseInt(app.state.root_id) || 0,
        close_time: moment().format('YYYY-MM-DD HH:mm:ss'),
        failed_status: success===0,
        success_status: success===1,
      };

      // compose last comment
      let comment="";
      // 

      comment += "Що було зроблено:\n" + self.state.form_data.comment_steps;
      comment += "\n\nПроміжні результати які були досягнуті:\n" + self.state.form_data.comment_results;


      if(success===0){
        comment += "\n\nПричини невдачі:\n" + self.state.form_data.comment_excuse;
        comment += "\n\nПричини за якою не можливо виконати завдання:\n" + self.state.form_data.comment_reject_cause;
      }
      if(success===1){
        comment += "\n\nКомментарі до успіху:\n" + self.state.form_data.comment_success;
      }

      postData["comment"] = comment;



      console.log('updating', postData);

      app.postData(
        '/tasks/finish',
        [postData]
      ).then(function(responseJson){
        // console.log("responseJson", responseJson, responseJson["status"]);
        if(responseJson["status"] === "OK") {
          self.setState({
            "message": "Дані оновлено",
            "messageType": "success"
          });  
          app.setState( { cmd_task:[
            {action:"closePopup"},
            {action:"refresh"},
          ] });
        } else if(responseJson["status"] === "EXPECTATION_FAILED") {
            onError("Не вдалося зберегти дані");
        } else {
            onError(responseJson["comment"]);
        }
        }).catch(() => onError("Невідома помилка"));
    } else {
        onError("Не знайдено завдання")
    }
  }

  render(){
    // global
    var app = this.context;
    var self = this;
    const { classes } = self.props;

    return (<>
      <div>
        <Grid container spacing={3}>

          {self.state.message && (<Grid item xs={12}>
          <MessageBlock message={self.state.message} messageType={self.state.messageType}/>
          </Grid>)}

          <Grid item xs={12}><h1>Завершити завдання</h1></Grid>



          <Grid item xs={6} md={3}>
            <div className={classes.formFieldLabel}>
              Результат роботи
            </div>
          </Grid>
          <Grid item xs={6} md={9}>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="gender"
              name="controlled-radio-buttons-group"
              value={self.state.form_data.success}
              onChange={self.handleChangeFactory("form_data", "success", null)}
              className={self.state.errors.success ? classes.selectFieldErr : classes.selectField}
            >
              <FormControlLabel value="1" control={<Radio />} label="Успіх" />
              <FormControlLabel value="0" control={<Radio />} label="Невдача" />
            </RadioGroup>
          </FormControl>
          </Grid>

          <Grid item xs={6} md={3}>
            <div className={classes.formFieldLabel}>
              Що було зроблено:
            </div>
          </Grid>
          <Grid item xs={6} md={9}>
            <TextareaAutosize
              minRows={3}
              style={{width:"100%"}}
              value={self.state.form_data.comment_steps}
              onChange={self.handleChangeFactory("form_data", "comment_steps", null)}
              margin="dense"
              placeholder="Що було зроблено"
              type="text"
              variant="standard" 
              />
          </Grid>

          <Grid item xs={6} md={3}>
            <div className={classes.formFieldLabel}>
              Проміжні результати які були досягнуті:
            </div>
          </Grid>
          <Grid item xs={6} md={9}>
            <TextareaAutosize
              minRows={3}
              style={{width:"100%"}}
              value={self.state.form_data.comment_results}
              onChange={self.handleChangeFactory("form_data", "comment_results", null)}
              margin="dense"
              placeholder="Проміжні результати які були досягнуті"
              type="text"
              variant="standard" 
              />
          </Grid>


          {parseInt(self.state.form_data.success)===0 &&
            <>
              <Grid item xs={6} md={3}>
                <div className={classes.formFieldLabel}>
                  Причини невдачі:
                </div>
              </Grid>
              <Grid item xs={6} md={9}>
                <TextareaAutosize
                  minRows={3}
                  style={{width:"100%"}}
                  value={self.state.form_data.comment_excuse}
                  onChange={self.handleChangeFactory("form_data", "comment_excuse", null)}
                  margin="dense"
                  placeholder="Причини невдачі"
                  type="text"
                  variant="standard" 
                  />
              </Grid>
            </>
          }

          {parseInt(self.state.form_data.success)===0 &&
            <>
              <Grid item xs={6} md={3}>
                <div className={classes.formFieldLabel}>
                  Причини за якою не можливо виконати завдання:
                </div>
              </Grid>
              <Grid item xs={6} md={9}>
                <TextareaAutosize
                  minRows={3}
                  style={{width:"100%"}}
                  value={self.state.form_data.comment_reject_cause}
                  onChange={self.handleChangeFactory("form_data", "comment_reject_cause", null)}
                  margin="dense"
                  placeholder="Причини за якою не можливо виконати завдання"
                  type="text"
                  variant="standard" 
                  />
              </Grid>
            </>
          }

          {parseInt(self.state.form_data.success)===1 &&
            <>
              <Grid item xs={6} md={3}>
                <div className={classes.formFieldLabel}>
                  Комментарі до успіху:
                </div>
              </Grid>
              <Grid item xs={6} md={9}>
                <TextareaAutosize
                  minRows={3}
                  style={{width:"100%"}}
                  value={self.state.form_data.comment_success}
                  onChange={self.handleChangeFactory("form_data", "comment_success", null)}
                  margin="dense"
                  placeholder="Комментарі до успіху"
                  type="text"
                  variant="standard" 
                  />
              </Grid>
            </>
          }


          <Grid item xs={12}>
            <div className={classes.formButtons}>
              <Button
                variant="contained"
                color="success"
                size="large"
                className={classes.submitButtonSattelite}
                onClick={() => app.setState( { cmd_task:[{action:"closePopup"}] })}
                >
                &nbsp;
              </Button>
              <Button
                variant="contained"
                color="success"
                size="large"
                className={classes.submitButton}
                onClick={() => app.setState( { cmd_task:[{action:"closePopup"}] })}
                >
                Закрити
              </Button>
              
              <Button
                variant="contained"
                color="success"
                size="large"
                className={classes.submitButtonSattelite}
                onClick={self.handleSubmit}
                >
                &nbsp;
              </Button>
              <Button
                variant="contained"
                color="success"
                size="large"
                className={classes.submitButton}
                onClick={self.handleSubmit}
                >
                Зберегти
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
    </>);
    
  }
}
export default withStyles(styles)(TaskFinish);

