// import moment from 'moment';

const ProviderModel = {

    loadProviders: function(self, attributeName, onError){
        return function(filter) {
            var app = self.context;

            var param = {};
            if(filter && filter.edrpou) param["edrpou"] = filter.edrpou;
            if(filter && filter.providerId) param["provider_id"] = filter.providerId;
            if(filter && filter.typePaymentsId) param["type_payments_id"] = filter.typePaymentsId;
            if(filter && filter.dealStatusId) param["deal_status_id"] = filter.dealStatusId;
            if(filter && filter.determinateDays) param["determinate_days"] = filter.determinateDays;

            if(Object.keys(param).length > 0) {
                param = [param];
            } else {
                param = [];
            }

            return app.postData(
                '/providers/get',
                {"param": param}
            ).then(function(responseJson){
                // console.log("responseJson", responseJson, responseJson["status"]);
                if(responseJson["status"] === "OK") {
                    let update = {};
                    update[attributeName] = responseJson["detail"];
                    self.setState(update);
                    return responseJson["detail"];
                } else if(responseJson["status"] === "NOT_FOUND") {
                    onError(responseJson["comment"]);
                    return [];
                } else {
                    onError(responseJson["comment"]);
                    return [];
                }
            }).catch(() => onError("Не вдалося завантажити постачальникіі"));
        };
    },

    loadProvidersMap: function(self, attributeName, onError){
        return function(filter) {
            var app = self.context;

            var param = {};
            if(filter && filter.edrpou) param["edrpou"] = filter.edrpou;
            if(filter && filter.providerId) param["provider_id"] = filter.providerId;
            if(filter && filter.typePaymentsId) param["type_payments_id"] = filter.typePaymentsId;
            if(filter && filter.dealStatusId) param["deal_status_id"] = filter.dealStatusId;
            if(filter && filter.determinateDays) param["determinate_days"] = filter.determinateDays;

            if(Object.keys(param).length > 0) {
                param = [param];
            } else {
                param = [];
            }

            return app.postData(
                '/providers/get',
                {"param": param}
            ).then(function(responseJson){
                // console.log("responseJson", responseJson, responseJson["status"]);
                if(responseJson["status"] === "OK") {
                    let theMap = {};
                    for(let i=0; i<responseJson["detail"].length; i++){
                        theMap[responseJson["detail"][i].id] = responseJson["detail"][i];
                    }
                    let update = {};
                    update[attributeName] = theMap;
                    self.setState(update);
                    return theMap;
                } else if(responseJson["status"] === "NOT_FOUND") {
                    onError(responseJson["comment"]);
                    return {};
                } else {
                    onError(responseJson["comment"]);
                    return {};
                }
            }).catch(() => onError("Не вдалося завантажити постачальникіі"));
        };
    },


};


export default ProviderModel;