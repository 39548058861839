import React, {Component} from "react";
import {
  Route,
  Switch,
  // Redirect,
  withRouter,
} from "react-router-dom";
import classnames from "classnames";
import {
 Box,
 // IconButton,
 // Link
 } from '@material-ui/core'
// import Icon from '@mdi/react'

// //icons
// import {
//   mdiFacebook as FacebookIcon,
//   mdiTwitter as TwitterIcon,
//   mdiGithub as GithubIcon,
// } from '@mdi/js'


// components
import Header from "../Header";
import Sidebar from "../Sidebar";

// pages
import {ErrorBoundary} from "../ErrorBoundary/ErrorBoundary";
// import Dashboard from "../../pages/dashboard";
import Auction from "../../pages/auction";
import Task from "../../pages/task";
import Consumer from "../../pages/consumer";
import Product from "../../pages/product";
import Provider from "../../pages/provider";
import Report from "../../pages/report";

// import Maps from "../../pages/maps";
// import Tables from "../../pages/tables";
// import Icons from "../../pages/icons";
// import Charts from "../../pages/charts";

// context
import { AppContext } from "../../context/AppContext";

// styles
import { withStyles } from "@material-ui/core/styles";
import Themes from "../../themes";
import customStyles from "./styles";

import crmBackgroundImage from "../../images/body-background-image.png";

var styles = customStyles(Themes.default);

console.log(crmBackgroundImage);

class Layout extends Component {

  static contextType = AppContext;

  // constructor(props) {
  //     super(props);
  // }

  render(){

    // global
    var app = this.context;
    if(!app.state.filter){
      app.setState({filter:{}});
    }

    var self = this;

    const { classes } = self.props;

    return (
      <div className={classes.root}>
          <>
            
            <Sidebar sidebarMenuId={app.state.sidebarMenuId}/>
            <div
              className={classnames(classes.content, {
                [classes.contentShift]: app.state.isSidebarOpened,
              })}
            >
              <Header history={self.props.history} sidebarMenuId={app.state.sidebarMenuId}/>
              <ErrorBoundary>
                <Switch>
                  <Route path="/app/dashboard"><Auction filter={app.state.filter_auction} cmd={app.state.cmd_auction}/></Route>
                  <Route path="/app/auction" ><Auction filter={app.state.filter_auction} cmd={app.state.cmd_auction}/></Route>
                  <Route path="/app/task"    ><Task filter={app.state.filter_task} cmd={app.state.cmd_task}/></Route>
                  <Route path="/app/consumer"><Consumer filter={app.state.filter_consumer} cmd={app.state.cmd_consumer}/></Route>
                  <Route path="/app/product" ><Product filter={app.state.filter_product} cmd={app.state.cmd_product}/></Route>
                  <Route path="/app/provider"><Provider filter={app.state.filter_provider} cmd={app.state.cmd_provider}/></Route>
                  <Route path="/app/report"  ><Report filter={app.state.filter_report} cmd={app.state.cmd_report}/></Route>
                </Switch>
              </ErrorBoundary>
              <Box
                mt={5}
                width={"100%"}
                display={"flex"}
                alignItems={"center"}
                justifyContent="space-between"
              >
                
              </Box>
            </div>
          </>
      </div>
    );
  }
}


export default withStyles(styles)(withRouter(Layout));
