import moment from 'moment';

const AuctionModel = {
    loadOneAuction: function(self, attributeName, onError) {
        return function(auctionId, auctionProzoroId) {
            // var self = this;
            var app = self.context;

            if(!auctionId && !auctionProzoroId){
                return new Promise((resolve, reject) => {
                    setTimeout(() => {
                    resolve('foo');
                    }, 100);
                });
            }
            var data = {"param": [{
                "auction_id": auctionId,
                "id_prozoro": auctionProzoroId,
            }]};  
            return app.postData(
                '/auction/get',
                data
            ).then(function(responseJson){
                // console.log("responseJson", responseJson, responseJson["status"]);
                if(responseJson["status"] === "OK") {
                    let auction = responseJson["detail"][0];
                    auction['status_play'] = (!!auction['status_play']) ? "1" : "0";
                    let update={};
                    update[attributeName]=auction;
                    self.setState(update);
                    return auction;
                } else if(responseJson["status"] === "NOT_FOUND") {
                    self.props.app.setState({
                        "message": responseJson["comment"],
                        "messageType": "error"
                    });
                    return {};
                } else {
                    onError(responseJson["comment"]);
                    return {};
                }
            }).catch(() => onError("Не вдалося завантажити аукціон"));
        }
    },
    defaultAuctionRecord : function(){
        return {
            id:"",                 //  bigint(20)
            id_prozoro:"",         //  text
            url_prozoro:"",        //  text
            company_id:0,          //  bigint(20) => companies
            type_auction:0,        //  bigint(20) => type_payments
            type_product:0,        //  bigint(20) => type_product
            waiting_total_deal:"", //  float
            region:"",             //  text
            propositions_up_to:moment().format('YYYY-MM-DD HH:mm:ss'),//  datetime
            date_auction:moment().format('YYYY-MM-DD HH:mm:ss'),      //  datetime
            member_price:"",       //  float
            personal_id:0,         //  bigint(20)  => employes
            comments:"",           //  text
            deadline:moment().format('YYYY-MM-DD HH:mm:ss'),          //  datetime
            status_play:"1",       //  tinyint(1)
            comment_not_play:"",   //  text
            type_task:0,           //  bigint(20)  => type_task
            contractor_name:"",    //  text        utf8_general_ci  YES             (NULL)                   select,insert,update,references           
            contractor_okpo:"",    //  bigint(20)  (NULL)           YES             (NULL)                   select,insert,update,references           
            result:"",

            task_to_aukcion:[],              // tasks connected to auction
        };
    },

    loadAuctionResultMap: function(self, attributeName){
        return function(){
            let update = {};
            update[attributeName] = {
                        'won': "Перемога",
                        'lost': "Поразка",
                        'without_looking': "Не розглядався",
                        'we_are_disqualified': "Нас дискваліфіковано",
                        'all_disqualified': "Всіх дискваліфіковано",
                        'purchase_failed': "Закупівля не відбулася",
                        'skewed': "Скасовано",
                        'not_play': "Не приймаємо участь"
                    };
            self.setState(update);
        };
    },
    
    loadAuctionResult: function(self, attributeName){
        return function(){
            let update = {};
            update[attributeName] = [ 
               {id:'won', name:"Перемога"}, 
               {id:'lost', name:"Поразка"},
               {id:'without_looking', name:"Не розглядався"}, 
               {id:'we_are_disqualified', name:"Нас дискваліфіковано"}, 
               {id:'all_disqualified', name:"Всіх дискваліфіковано"}, 
               {id:'purchase_failed', name:"Закупівля не відбулася"}, 
               {id:'skewed', name:"Скасовано"}, 
               {id:'not_play', name:"Не приймаємо участь"}, 
            ];
            self.setState(update);
        };
    },

};


export default AuctionModel;