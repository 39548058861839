import React, {Component}  from "react";
import {
  Grid,
  Select,
  Checkbox,
  Button,
  TextField,
  TextareaAutosize,
  FormControlLabel,
  MenuItem,
  Link,
} from "@material-ui/core";
import moment from 'moment';

// components
// import { Typography } from "../../../../components/Wrappers";
import validators   from '../../../../utils/validators';
import MessageBlock from '../../../../utils/MessageBlock';
import { AppContext } from "../../../../context/AppContext";
import ApiModel from "../../../../utils/ApiModel";
import {CrmDateTimePicker, CrmDatePicker} from '../../../../components/CrmDateTimePicker/CrmDateTimePicker';
// styles
import { withStyles } from "@material-ui/core/styles";
import Themes from "../../../../themes";
import customStyles from "./styles";


const theme = Themes.default;
const styles = customStyles(theme);




class ConsumerFromAuction extends Component {

  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.defaultRecord = JSON.stringify({
      name: "", // text <= auction.contractor_name
      okpo: "", // text <= auction.contractor_okpo
      mfo: "",  // text
      rr: "",   // text
      deal_letters_address: "", // text
      comment:"", // text
      nds: false,  // boolean

      auction_id:0, //    bigint <= auction.id
      contract_number: "", // text
      contract_date: "",  //    datetime
      real_summary: "", //  float
      company_id: 0, //    bigint  <= auction.company_id
      personal_id:0, //   bigint
    });
    if(!this.state) {
      this.state = {
        consumer:{},

        auctionId:0,
        auctionProzoroId:0,

        auction:{},
        company:[],
        personal:[],

        errors:{},
        message:"",
        messageType:"",
      };
    }
    var self =this;

    var nonEmpty = function(val) {
        if(!val || (''+val).trim().length === 0 ){
            return "Будь ласка, введіть значення";
        }
        return "";
    };
    var isPositiveFloat = function(val) {
      if(validators.isPositiveFloat(val) !== true ){
        return "Будь ласка, виберіть значення";
      }
      return "";
    }
    var isPositiveInteger = function(val) {
      if(validators.isPositiveInteger(val) !== true ){
        return "Будь ласка, виберіть значення";
      }
      return "";
    }
    this.validator={
      // id   bigint
      name: nonEmpty, // text
      mfo: nonEmpty, // text
      okpo: nonEmpty, // text
      rr: nonEmpty, // text
      //  comment               // text
      deal_letters_address: nonEmpty, // text
      // nds                   tinyint(1)
      // auction_id    bigint <= auction.id
      contract_number: nonEmpty, // text
      contract_date: nonEmpty, // datetime
      real_summary: isPositiveFloat, //  float
      company_id: isPositiveInteger,//    bigint  <= auction.company_id
      personal_id: isPositiveInteger,//    bigint

      "telephone": function(val) {
        if(self.state.consumer.telephone.length === 0 ){
            return "Будь ласка, введіть хоча б один телефонний номер";
        }
        return "";
      },
      "email": function(val) {
        if(self.state.consumer.email.length === 0 ){
            return "Будь ласка, введіть хоча б одну електронну адресу";
        }
        return "";
      },
      "email.email": function(val) {
          if(val.trim().length === 0 ){
              return "Будь ласка, введіть значення";
          }
          if(validators.isValidEmail(val) !== true ){
              return "Будь ласка, введіть адресу електронної пошти";
          }
          return "";
      },
      "email.comment": function(val) {
        if(val.trim().length === 0 ){
            return "Будь ласка, введіть значення";
        }
        return "";
      },
      "telephone.telephone": function(val) {
        if(val.trim().length === 0 ){
            return "Будь ласка, введіть значення";
        }
        if(validators.isValidTelephoneNumber(val) !== true ){
            return "Будь ласка, введіть телефонний номер";
        }
        return "";
      },
      "telephone.comment": function(val) {
        if(val.trim().length === 0 ){
            return "Будь ласка, введіть значення";
        }
        return "";
      },
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeFactory = ApiModel.handleChangeFactory2(this);
    this.handleCheckboxFactory = ApiModel.handleCheckboxFactory2(this);
    this.handleDateChangeFactory = ApiModel.handleDateChangeFactory2(this);

    this.validate = ApiModel.validate(this);
    this.onError = ApiModel.onError(this);

    this.loadOneAuction = ApiModel.loadOneAuction(this, "auction", this.onError);
    this.loadCompany = ApiModel.loadCompany(this, "company");
    this.loadPersonal = ApiModel.loadPersonal(this, "personal", "filteredPersonal");
  }

  componentDidUpdate(){
    var self =this;
    if(
      this.props.auctionId !==  this.state.auctionId
      || this.props.auctionProzoroId !==  this.state.auctionProzoroId
    ) {
      this.setState({
        auctionId: this.props.auctionId,
        auctionProzoroId: this.props.auctionProzoroId,
        consumer: JSON.parse(this.defaultRecord)
      });
      self.loadOneAuction(self.props.auctionId, self.props.auctionProzoroId).finally(function(){
        self.applyDefaults();
      });
    }    
  }

  componentDidMount(){
    var self =this;
    this.setState({
      auctionId: this.props.auctionId,
      auctionProzoroId: this.props.auctionProzoroId,
      consumer: JSON.parse(this.defaultRecord)
    });
    self.loadOneAuction(self.props.auctionId, self.props.auctionProzoroId).finally(function(){
      self.loadCompany().finally(function(){
        self.loadPersonal().finally(function(){
          self.applyDefaults();
        });
      });
    });
  }

  applyDefaults(){
    let consumer = JSON.parse(JSON.stringify(this.state.consumer));
    consumer.name = consumer.name || this.state.auction.contractor_name;
    consumer.okpo = consumer.okpo || this.state.auction.contractor_okpo;
    consumer.auction_id = consumer.auction_id  || this.state.auction.id;
    consumer.company_id = consumer.company_id  || this.state.auction.company_id;
    consumer.mfo = consumer.mfo || "1111111";
    consumer.rr = consumer.rr || "UA000000000000000000000000000";
    consumer.deal_letters_address = consumer.deal_letters_address || "Адреса для листування";
    consumer.comment = consumer.comment || "Примітки";
    this.setState({consumer: consumer});
  }

  handleSubmit(event) {
    event.preventDefault();
    var self = this;
    var app = this.context;
        
    let consumer = JSON.parse(JSON.stringify(this.state.consumer));

    // apply defaults
    // consumer.consumer_id =  consumer.id;

      // do validation
      var err = self.validate(consumer);

      if(err.errorExists) {
          self.setState({
            errors: err.errors,
            message:"Дані не збережено",
            messageType:"error",
          });
          return;
      }

      var onError = ApiModel.onError(this);

      // console.log('creating', consumer);
      let postData = {
        name: consumer.name, // text <= auction.contractor_name
        okpo: consumer.okpo, // text <= auction.contractor_okpo
        mfo: consumer.mfo,  // text
        rr: consumer.rr,   // text
        deal_letters_address: consumer.deal_letters_address, // text
        comment:consumer.comment, // text
        nds: consumer.nds,  // boolean

        auction_id:self.props.auctionId, //    bigint <= auction.id
        contract_number: consumer.contract_number, // text
        contract_date:  consumer.contract_date,  //    datetime
        real_summary: parseFloat(consumer.real_summary), //  float
        company_id: parseInt(consumer.company_id), //    bigint  <= auction.company_id
        personal_id:parseInt(consumer.personal_id), //   bigint
        email: consumer.email,  // array
        telephone: consumer.telephone,  // array
      };
      app.postData(
          '/consumers/from_auction',
          [postData]
      ).then(function(responseJson){
          // console.log("responseJson", responseJson, responseJson["status"]);
          if(responseJson["status"] === "OK") {
              app.setState( { cmd_consumer:[
                {
                  action: "consumer_info",
                  consumerId: responseJson["detail"][0] || 0,
                  message: "Дані збережено",
                  messageType: "success",
                }
              ]});
          } else if(responseJson["status"] === "EXPECTATION_FAILED") {
              onError("Не вдалося зберегти дані");
          } else {
              onError(responseJson["comment"]);
          }
      }).catch(() => onError("Невідома помилка"));
        
  }

  handleTableFactory(action, attr, i, subAttr) {
        var self = this;
        if(action === "update") {
            return function(event) {
                // console.log("update", attr, subAttr);
                event.preventDefault();
                var consumer = JSON.parse(JSON.stringify(self.state.consumer)) || {};
                consumer[attr] = consumer[attr] || [];
                consumer[attr][i][subAttr] = event.target.value;
                var errors = JSON.parse(JSON.stringify(self.state.errors));

                let validatorName = attr + '.' + subAttr;
                if(self.validator[validatorName]){
                    errors[attr] = errors[attr] || {};
                    errors[attr]["@"+i] = errors[attr]["@"+i] || {};
                    errors[attr]["@"+i][subAttr] = self.validator[validatorName](consumer[attr][i][subAttr]);
                }
                self.setState({
                    "consumer": consumer,
                    "message": "Дані змінено, але не збережено",
                    "messageType": "warning",
                    "errors": errors
                });
            };
        }
        if(action === "delete") {
            return function(event) {
                // console.log("delete", attr, subAttr);
                event.preventDefault();
                var consumer = JSON.parse(JSON.stringify(self.state.consumer)) || {};
                consumer[attr] = consumer[attr] || [];
                consumer[attr].splice(i, 1);
                self.setState({
                    "consumer": consumer,
                    "message": "Дані змінено, але не збережено",
                    "messageType": "warning"
                });
            };        
        }
        if(action === "add") {
            return function(event) {
                // console.log("add", attr, subAttr);
                event.preventDefault();
                var consumer = JSON.parse(JSON.stringify(self.state.consumer)) || {};
                consumer[attr] = consumer[attr] || [];
                consumer[attr].push(subAttr);
                self.setState({
                    "consumer": consumer,
                    "message": "Дані змінено, але не збережено",
                    "messageType": "warning"
                });
            };
        }
  }

  handleDeleteFactory() {
      var self = this;
      var app = this.context;
      return function(event) {
          event.preventDefault();
          var id = self.props.consumerId;
          if(id) {
              var onError = function(errorMessage) {
                  self.setState({
                      "message":errorMessage,
                      "messageType":"error",
                  });
              };
              var postData = [id];
              // post the DELETE command
              app.postData(
                      '/consumers/delete',
                      postData
                  ).then(function(responseJson){
                      if(responseJson["status"] === "OK") {
                          self.props.parent.setState({
                            consumerId: null,
                            view:self.props.parent.views.consumers,
                          });
                      } else if(responseJson["status"] === "EXPECTATION_FAILED") {
                          onError("Не вдалося видалити дані");
                      } else {
                          onError(responseJson["comment"]);
                      }
                  }).catch(() => onError("Невідома помилка"));
          }
      }
  }

  getError(attr, i, subAttr){
      var errors = JSON.parse(JSON.stringify(this.state.errors));
      errors = errors[attr] || {};
      errors = errors["@"+i] || {};
      return errors[subAttr];
  }

  render(){
    // global
    var self = this;
    var app = self.context;
    
    const { classes } = self.props;

    return (<>
      <div>
        <Grid container spacing={4}>

          {self.state.message && (
            <Grid item xs={12}>
              <MessageBlock message={self.state.message} messageType={self.state.messageType}/>
            </Grid>
          )}

          <Grid item xs={12}>
            <div className={classes.breadCrumbs}>
              <Link href="javascript:void(1)"
                onClick={() => app.setState({cmd_consumer:[
                      {
                        action: 'consumers',
                      },
                    ]})
                }>Клієнти</Link>
              / Додати "{self.state.consumer.name}"
            </div>
          </Grid>


          <Grid  item xs={6}>
            <div className={classes.crmxCard}>
              <Grid container spacing={4}>

                <Grid item xs={5}>
                  <div className={classes.formFieldLabel}>
                    Назва*
                  </div>
                </Grid>
                <Grid item xs={7}>
                  <TextField
                    value={self.state.consumer.name}
                    onChange={self.handleChangeFactory("consumer","name")}
                    margin="dense"
                    placeholder="Назва"
                    type="text"
                    fullWidth
                    variant="standard" 
                    className={self.state.errors.name ? classes.textFieldErr : classes.textField}
                    />
                </Grid>

                <Grid item xs={5}>
                  <div className={classes.formFieldLabel}>
                    ЄДРПОУ*
                  </div>
                </Grid>
                <Grid item xs={7}>
                  <TextField
                    value={self.state.consumer.okpo}
                    onChange={self.handleChangeFactory("consumer","okpo")}
                    margin="dense"
                    placeholder="ЄДРПОУ"
                    type="text"
                    fullWidth
                    variant="standard" 
                    className={self.state.errors.okpo ? classes.textFieldErr : classes.textField}
                    />
                </Grid>

                <Grid item xs={5}>
                  <div className={classes.formFieldLabel}>
                    МФО*
                  </div>
                </Grid>
                <Grid item xs={7}>
                  <TextField
                    value={self.state.consumer.mfo}
                    onChange={self.handleChangeFactory("consumer", "mfo")}
                    margin="dense"
                    placeholder="МФО"
                    type="text"
                    fullWidth
                    variant="standard" 
                    className={self.state.errors.mfo ? classes.textFieldErr : classes.textField}
                    />
                </Grid>

                <Grid item xs={5}>
                  <div className={classes.formFieldLabel}>
                    Рахунок*
                  </div>
                </Grid>
                <Grid item xs={7}>
                  <TextField
                    value={self.state.consumer.rr}
                    onChange={self.handleChangeFactory("consumer","rr")}
                    margin="dense"
                    placeholder="Рахунок"
                    type="text"
                    fullWidth
                    variant="standard" 
                    className={self.state.errors.rr ? classes.textFieldErr : classes.textField}
                    />
                </Grid>

                <Grid item xs={5}>
                  <div className={classes.formFieldLabel}>
                    Адреса для листування*
                  </div>
                </Grid>
                <Grid item xs={7}>
                  <TextareaAutosize
                    value={self.state.consumer.deal_letters_address}
                    onChange={self.handleChangeFactory("consumer","deal_letters_address")}
                    margin="dense"
                    minRows={3}
                    style={{width:"100%"}}
                    placeholder="Адреса для листування"
                    type="text"
                    fullWidth
                    variant="standard" 
                    className={self.state.errors.deal_letters_address ? classes.textFieldErr : classes.textField}
                    />
                </Grid>


                <Grid item xs={5}>
                  <div className={classes.formFieldLabel}>
                    Примітки
                  </div>
                </Grid>
                <Grid item xs={7}>
                  <TextareaAutosize
                    minRows={3}
                    style={{width:"100%"}}
                    value={self.state.consumer.comment}
                    onChange={self.handleChangeFactory("consumer","comment")}
                    margin="dense"
                    placeholder="Примітки"
                    type="text"
                    variant="standard" 
                    // className={self.state.errors.comments ? classes.textFieldErr : classes.textField}
                    />
                </Grid>

                <Grid item xs={5}>
                  <div className={classes.formFieldLabel}>
                    Платник ПДВ
                  </div>
                </Grid>
                <Grid item xs={7}>
                  <FormControlLabel control={
                    <Checkbox 
                      checked={self.state.consumer.nds}
                      onChange={self.handleCheckboxFactory("consumer", "nds")}
                    />
                    } label="Платник ПДВ" 
                  />
                </Grid>


                <Grid item xs={5}>
                  <div className={classes.formFieldLabel}>
                    Номер договору*
                  </div>
                </Grid>
                <Grid item xs={7}>
                  <TextField
                    value={self.state.consumer.contract_number}
                    onChange={self.handleChangeFactory("consumer","contract_number")}
                    margin="dense"
                    placeholder="Номер договору"
                    type="text"
                    fullWidth
                    variant="standard" 
                    className={self.state.errors.contract_number ? classes.textFieldErr : classes.textField}
                    />
                </Grid>

                <Grid item xs={5}>
                  <div className={classes.formFieldLabel}>
                    Дата договору*
                  </div>
                </Grid>
                <Grid item xs={7}>
                  <CrmDateTimePicker
                    renderInput={(props) => <TextField {...props} />}
                    label="Дата договору"
                    value={self.state.consumer.contract_date}
                    onChange={self.handleDateChangeFactory("consumer","contract_date")}
                    margin="dense"
                    placeholder="Дата договору"
                    variant="standard" 
                    className={self.state.errors.contract_date ? classes.textFieldErr : classes.textField}
                  />
                </Grid>


                <Grid item xs={5}>
                  <div className={classes.formFieldLabel}>
                    Сума договору*
                  </div>
                </Grid>
                <Grid item xs={7}>
                  <TextField
                    value={self.state.consumer.real_summary}
                    onChange={self.handleChangeFactory("consumer","real_summary")}
                    margin="dense"
                    placeholder="Сума договору"
                    type="text"
                    fullWidth
                    variant="standard" 
                    className={self.state.errors.real_summary ? classes.textFieldErr : classes.textField}
                    />
                </Grid>

                <Grid item xs={5}>
                  <div className={classes.formFieldLabel}>
                    Компанія*
                  </div>
                </Grid>
                <Grid item xs={7}>
                    <Select
                      value={self.state.consumer.company_id || ''}
                      onChange={self.handleChangeFactory("consumer", "company_id")}
                      className={self.state.errors.company_id ? classes.selectFieldErr : classes.selectField}
                    >
                      <MenuItem value="" key={'company_id_X'} >-</MenuItem>
                      {self.state.company.map(function(elm, ind){
                          return <MenuItem key={'company_id_' + ind} value={elm.id}  className={classes.selectOption}>{elm.name}</MenuItem>;
                      })}
                    </Select>
                </Grid>



                <Grid item xs={5}>
                  <div className={classes.formFieldLabel}>
                    Відповідальний менеджер*
                  </div>
                </Grid>
                <Grid item xs={7}>
                    <Select
                      value={self.state.consumer.personal_id || ''}
                      onChange={self.handleChangeFactory("consumer", "personal_id")}
                      className={self.state.errors.personal_id ? classes.selectFieldErr : classes.selectField}
                    >
                      <MenuItem value="" key={'personal_X'} >-</MenuItem>
                      {self.state.personal.map(function(elm, ind){
                          return <MenuItem key={'personal_' + ind} value={elm.id}  className={classes.selectOption}>{elm.fname} {elm.sname} {elm.lname}</MenuItem>;
                      })}
                    </Select>
                </Grid>


              </Grid>
            </div>
          </Grid>

          <Grid  item xs={6}>
            <div className={classes.crmxCard}>
                <div className={classes.formFieldLabel}>Телефони:</div>
                <table className={classes.crmXTable + " " + classes.crmXColWidth} cellspacing="0">
                <thead>
                  <tr>
                    <th>&nbsp;</th>
                    <th>Телефон*</th>
                    <th>Примітки*</th>
                  </tr>
                </thead>
                <tbody>
                {this.state.consumer.telephone && this.state.consumer.telephone.map(function(object, i){
                    return <tr key={"telephone_"+i}>
                        <td>
                          <div  className={classes.columnAction}>
                            <button className={classes.rowActionButtonRed}
                                onClick={self.handleTableFactory("delete","telephone", i, "telephone")}
                            >&minus;</button>
                            <button className={classes.rowActionButtonSattelite}
                                onClick={self.handleTableFactory("delete","telephone", i, "telephone")}
                            >&gt;</button>
                          </div>
                        </td>
                        <td>
                          <TextField
                            key={"telephone_value_"+i}
                            value={object.telephone}
                            onChange={self.handleTableFactory("update", "telephone", i, "telephone")}
                            margin="dense"
                            placeholder="Номер телефона"
                            type="text"
                            fullWidth
                            variant="standard" 
                            className={self.getError("telephone", i, "telephone") ? classes.tableTextFieldErr : classes.tableTextField}
                            />
                        </td>
                        <td>
                          <TextField
                            key={"telephone_comment_"+i}
                            value={object.comment}
                            onChange={self.handleTableFactory("update", "telephone", i, "comment")}
                            margin="dense"
                            placeholder="Примітки"
                            type="text"
                            fullWidth
                            variant="standard" 
                            className={self.getError("telephone", i, "comment") ? classes.tableTextFieldErr : classes.tableTextField}
                            />
                        </td>
                    </tr>;
                })}
                <tr key={"telephone_x"}>
                  <td>
                    <div  className={classes.columnAction}>
                      <button className={classes.rowActionButton}
                              onClick={self.handleTableFactory("add","telephone", null, {"telephone_id":0, "telephone":"", "comment":""})}
                      >+</button>
                      <button className={classes.rowActionButtonSattelite}
                              onClick={self.handleTableFactory("add","telephone", null, {"telephone_id":0, "telephone":"", "comment":""})}
                      >&gt;</button>
                    </div>
                  </td>
                  <td></td>
                  <td></td>
                </tr>
                </tbody>
                </table>
            </div>
            <div className={classes.crmxCard}>
                <div className={classes.formFieldLabel}>E-mail:</div>
                <table className={classes.crmXTable + " " + classes.crmXColWidth} cellspacing="0">
                <thead>
                  <tr>
                    <th>&nbsp;</th>
                    <th>Email*</th>
                    <th>Примітки*</th>
                  </tr>
                </thead>
                <tbody>

                {this.state.consumer.email && this.state.consumer.email.map(function(object, i){
                    return <tr key={"email_"+i}>
                        <td>
                          <div  className={classes.columnAction}>
                          <button className={classes.rowActionButtonRed}
                              onClick={self.handleTableFactory("delete","email", i, "email")}
                          >&minus;</button><button className={classes.rowActionButtonSattelite}
                              onClick={self.handleTableFactory("delete","email", i, "email")}
                          >&gt;</button>
                          </div>
                        </td>
                        <td>
                          <TextField
                            key={"email_value_"+i}
                            value={object.email}
                            onChange={self.handleTableFactory("update", "email", i, "email")}
                            margin="dense"
                            placeholder="email"
                            type="text"
                            fullWidth
                            variant="standard" 
                            className={self.getError("email", i, "email") ? classes.tableTextFieldErr : classes.tableTextField}
                            />
                        </td>
                        <td>
                          <TextField
                            key={"email_comment_"+i}
                            value={object.comment}
                            onChange={self.handleTableFactory("update", "email", i, "comment")}
                            margin="dense"
                            placeholder="Примітки"
                            type="text"
                            fullWidth
                            variant="standard" 
                            className={self.getError("email", i, "comment") ? classes.tableTextFieldErr : classes.tableTextField}
                            />
                        </td>
                    </tr>;
                })}
                <tr key={"email_x"}>
                  <td>
                    <div  className={classes.columnAction}>
                        <button className={classes.rowActionButton}
                                onClick={self.handleTableFactory("add","email", null, {"email_id":0, "email":"", "comment":""})}
                        >+</button><button className={classes.rowActionButtonSattelite}
                                onClick={self.handleTableFactory("add","email", null, {"email_id":0, "email":"", "comment":""})}
                        >&gt;</button>
                    </div>
                  </td>
                  <td></td>
                  <td></td>
                </tr>
                </tbody>
                </table>
            </div>
          </Grid>
          <Grid item xs={12}>
            * Обов'язкові значення
          </Grid>
          <Grid item xs={12}>
            <div className={classes.formButtons} style={{textAlign:"center"}}>
            

              <Button
                variant="contained"
                color="success"
                size="large"
                className={classes.submitButtonSattelite}
                onClick={self.handleSubmit}
                >
                &nbsp;
              </Button>
              <Button
                variant="contained"
                color="success"
                size="large"
                className={classes.submitButton}
                onClick={self.handleSubmit}
                >
                Зберегти
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
    </>);
    
  }
}
export default withStyles(styles)(ConsumerFromAuction);

