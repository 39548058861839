function customStyles(theme){
    return {
      crmXTable: theme.crmXTable,
      rowActionButton:theme.rowActionButton,
      rowActionButtonSattelite:theme.rowActionButtonSattelite,
      pageTitle:theme.pageTitle,
      topButtons:theme.topButtons,
    crmXColWidth:{
       "& tbody td:nth-child(1)":{width:"15%", textAlign:"left",},
       "& tbody td:nth-child(2)":{width:"15%", textAlign:"center",},
       "& tbody td:nth-child(3)":{width:"15%", textAlign:"center",},
       "& tbody td:nth-child(4)":{width:"15%", textAlign:"center",},
       "& tbody td:nth-child(5)":{width:"15%", textAlign:"center",},
    }
    };
}
export default customStyles;
