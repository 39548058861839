import React, { Component } from "react";
import { Drawer, IconButton, List } from "@material-ui/core";
import {
  Home as HomeIcon,
  NotificationsNone as NotificationsIcon,
  FormatSize as TypographyIcon,
  FilterNone as UIElementsIcon,
  BorderAll as TableIcon,
  QuestionAnswer as SupportIcon,
  LibraryBooks as LibraryIcon,
  HelpOutline as FAQIcon,
  ArrowBack as ArrowBackIcon,
} from "@material-ui/icons";
import { withRouter } from "react-router-dom";
import classNames from "classnames";


// components
import AuctionFilter from "../../pages/auction/components/AuctionFilter/AuctionFilter";
import TaskFilter from "../../pages/task/components/TaskFilter/TaskFilter";
import ConsumerFilter from "../../pages/consumer/components/ConsumerFilter/ConsumerFilter";
import ProductFilter from "../../pages/product/components/ProductFilter/ProductFilter";
import ProviderFilter from "../../pages/provider/components/ProviderFilter/ProviderFilter";
import ReportFilter from "../../pages/report/components/ReportFilter/ReportFilter";

import { AppContext } from "../../context/AppContext";

// styles
import { withStyles } from "@material-ui/core/styles";
import Themes from "../../themes";
import customStyles from "./styles";
var theme = Themes.default;
var styles = customStyles(theme);


class Sidebar extends Component {

  static contextType = AppContext;

  constructor(props) {
    super(props);
    if(!this.state) {
      this.state = {
        isPermanent:true,
        sidebarBlockId:"",
      };
    }
    this.handleWindowWidthChange = this.handleWindowWidthChange.bind(this);
  }

  setPermanent(val) { this.setState({isPermanent:val}) }

  handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && this.state.isPermanent) {
      this.setPermanent(false);
    } else if (!isSmallScreen && !this.state.isPermanent) {
      this.setPermanent(true);
    }
  }
  
  componentWillMount() {
    window.addEventListener("resize", this.handleWindowWidthChange);
    this.handleWindowWidthChange();
  }

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.handleWindowWidthChange);
  }
  // componentDidMount(){
  //   var app = this.context;
  //   this.setState({sidebarBlockId:app.state.sidebarBlockId});
  // }
  render(){
    var {classes, location} = this.props;

    // global
    var app = this.context;
    var self= this;

    // var isSidebarOpened = app.state.isSidebarOpened;
    var isSidebarOpened = true;
    // var isPermanent = app.state.isPermanent;
    var isPermanent = true;
    // ##################################################################
    // var menuItems = structure[self.state.sidebarBlockId] || structure[""];
    return (
      <Drawer
        variant={isPermanent ? "permanent" : "temporary"}
        className={classNames(classes.drawer, {
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        })}
        classes={{
          paper: classNames({
            [classes.drawerOpen]: isSidebarOpened,
            [classes.drawerClose]: !isSidebarOpened,
          }),
        }}
        open={isSidebarOpened}
      >
        <div  className={classes.spacerTop}>&nbsp;</div>
        {self.props.sidebarMenuId === "" && 
          (
            <div>Default menu</div>
          )
        }

        {self.props.sidebarMenuId === "auction" && (<AuctionFilter/>)}
        {self.props.sidebarMenuId === "task" && (<TaskFilter/>)}
        {self.props.sidebarMenuId === "consumer" && (<ConsumerFilter/>)}
        {self.props.sidebarMenuId === "product" && (<ProductFilter/>)}
        {self.props.sidebarMenuId === "provider" && (<ProviderFilter/>)}
        {self.props.sidebarMenuId === "report" && (<ReportFilter/>)}
      </Drawer>
    );
  }
}

export default withStyles(styles)(withRouter(Sidebar));