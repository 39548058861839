import { alpha } from "@material-ui/core/styles/colorManipulator";

function customStyles(theme){
  return {
    logotype: {
      color: "black",
      marginLeft: theme.spacing(8),
      marginRight: theme.spacing(1),
      fontWeight: 500,
      fontSize: 18,
      whiteSpace: "nowrap",
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },
    appBar: {
      width: "auto",
      background:"none",
      boxShadow:"none",
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    toolbar: {
      paddingLeft: 0,
      paddingRight: theme.spacing(2),
      background:"none",
      whiteSpace:"nowrap",
    },
    hide: {
      display: "none",
    },
    grow: {
      flexGrow: 1,
    },
    search: {
      position: "relative",
      borderRadius: 25,
      paddingLeft: theme.spacing(2.5),
      width: 36,
      backgroundColor: alpha(theme.palette.common.black, 0),
      transition: theme.transitions.create(["background-color", "width"]),
      "&:hover": {
        cursor: "pointer",
        backgroundColor: alpha(theme.palette.common.black, 0.08),
      },
    },
    searchFocused: {
      backgroundColor: alpha(theme.palette.common.black, 0.08),
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: 250,
      },
    },
    searchIcon: {
      width: 36,
      right: 0,
      height: "100%",
      position: "absolute",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      transition: theme.transitions.create("right"),
      "&:hover": {
        cursor: "pointer",
      },
    },
    searchIconOpened: {
      right: theme.spacing(1.25),
    },
    inputRoot: {
      color: "inherit",
      width: "100%",
    },
    inputInput: {
      height: 36,
      padding: 0,
      paddingRight: 36 + theme.spacing(1.25),
      width: "100%",
    },
    messageContent: {
      display: "flex",
      flexDirection: "column",
    },
    headerMenu: {
      marginTop: theme.spacing(7),
    },
    headerMenuList: {
      display: "flex",
      flexDirection: "column",
    },
    headerMenuItem: {
      "&:hover, &:focus": {
        backgroundColor: theme.palette.background.light,
        // color: "white",
      },
    },
    headerMenuButton: {
      marginLeft: theme.spacing(2),
      padding: theme.spacing(0.5),
    },
    headerProfileName:{
      justifyContent: "start",
      alignItems: "flex-start",
      "& .MuiTypography-root":{
        whiteSpace:"normal",
        textAlign: "left",
        marginTop: "4px",
        lineHeight: "1.4em",
      }
    },
    crmxLogo:{
       marginTop:"18px",
       [theme.breakpoints.up("md")]: {
        marginTop:"45px",
       },
    },
    headerProfileButton:{
      color:"black",
      marginTop:"50px",
    },
    headerNavCard: {
      marginLeft: theme.spacing(0.5),
      padding: 0,
      display:"inline-block",
      textAlign:"center",
      marginTop:"15px",
      marginBottom:"10px",
      width:"15.5%",
    },
    headerNavCardActive:{
    },
    headerNavIcon: {
      fontSize: 28,
      color: "rgba(0, 0, 0, 0.35)",
      width:"2em",
      height: "2em",
      display: "none",

    },
    headerNavButton:{
      display:"block",
      textAlign:"center",
      overflow:"hidden",
      backgroundColor: "#A19DAE",
      paddingLeft:theme.spacing(1.0),
      paddingRight:theme.spacing(1.0),
      paddingTop:theme.spacing(0.5),
      paddingBottom:theme.spacing(0.5),
      color: "black",
      "&:hover, &:focus": {
        backgroundColor: "#191143",
        color: "white",
      },
      "&.active":{
        backgroundColor: "#191143",
        color: "white",
      },
      [theme.breakpoints.up("sm")]: {
          fontSize: "0.7em",
      },
      [theme.breakpoints.up("md")]: {
          fontSize: "0.8em",
      },
      [theme.breakpoints.up("lg")]: {
          fontSize: "0.9em",
      },
      textTransform:"uppercase",
    },
    headerMenuButtonSandwich: {
      marginLeft: 9,
      [theme.breakpoints.down("sm")]: {
        marginLeft: 0
      },
      padding: theme.spacing(0.5),
    },
    headerMenuButtonCollapse: {
      marginRight: theme.spacing(2),
      float:"left",
      color:"black",
    },
    headerIcon: {
      fontSize: 28,
      color: "rgba(0, 0, 0, 0.35)",
    },
    headerIconCollapse: {
      color: "black",
    },
    profileMenu: {
      minWidth: 265,
    },
    profileMenuUser: {
      display: "flex",
      flexDirection: "column",
      padding: theme.spacing(2),
    },
    profileMenuItem: {
      color: theme.palette.text.hint,
    },
    profileMenuIcon: {
      marginRight: theme.spacing(2),
      color: theme.palette.text.hint,
      '&:hover': {
        color: theme.palette.primary.main,
      }
    },
    profileMenuLink: {
      fontSize: 16,
      textDecoration: "none",
      "&:hover": {
        cursor: "pointer",
      },
    },
    messageNotification: {
      height: "auto",
      display: "flex",
      alignItems: "center",
      "&:hover, &:focus": {
        backgroundColor: theme.palette.background.light,
      },
    },
    messageNotificationSide: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginRight: theme.spacing(2),
    },
    messageNotificationBodySide: {
      alignItems: "flex-start",
      marginRight: 0,
    },
    sendMessageButton: {
      margin: theme.spacing(4),
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      textTransform: "none",
    },
    sendButtonIcon: {
      marginLeft: theme.spacing(2),
    },
    purchaseBtn: {
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      },
      marginRight: theme.spacing(3)
    }
  };
}

export default customStyles;