import React, {Component}  from "react";
import {
  Grid,
  Select,
  Button,
  TextField,
  TextareaAutosize,
  FormControlLabel,
  Checkbox,
  Link,
} from "@material-ui/core";
import moment from 'moment';

// components
// import { Typography } from "../../../../components/Wrappers";
import validators   from '../../../../utils/validators';
import MessageBlock from '../../../../utils/MessageBlock';
import { AppContext } from "../../../../context/AppContext";
import ApiModel from "../../../../utils/ApiModel";

import ConsumerContracts from "../ConsumerContracts/ConsumerContracts";


// styles
import { withStyles } from "@material-ui/core/styles";
import Themes from "../../../../themes";
import customStyles from "./styles";


const theme = Themes.default;
const styles = customStyles(theme);




class ConsumerInfo extends Component {

  static contextType = AppContext;
  constructor(props) {
    super(props);
    if(!this.state) {
      this.state = {
        consumerId:false,
        consumer:{},
        errors:{},
        message:"",
        messageType:"",
      };
    }
    var self =this;

    var nonEmpty = function(val) {
            if(!val || (''+val).trim().length === 0 ){
                return "Будь ласка, введіть значення";
            }
            return "";
        };
    this.validator={
      name: nonEmpty,
      mfo: nonEmpty,
      okpo: nonEmpty,
      rr: nonEmpty,
      deal_letters_address: nonEmpty, // text
      //  comment:consumer.comment, // text
      //  nds: consumer.nds,        // boolean
      telephone: function(val) {
                if(self.state.consumer.telephone.length === 0 ){
                   return "Будь ласка, введіть хоча б один телефонний номер";
                }
                return "";
      },
      email: function(val) {
          if(self.state.consumer.email.length === 0 ){
              return "Будь ласка, введіть хоча б одну електронну адресу";
          }
          return "";
      },
      "email.email": function(val) {
                if(val.trim().length === 0 ){
                    return "Будь ласка, введіть значення";
                }
                if(validators.isValidEmail(val) !== true ){
                    return "Будь ласка, введіть адресу електронної пошти";
                }
                return "";
            },
      "email.comment": function(val) {
        if(val.trim().length === 0 ){
            return "Будь ласка, введіть значення";
        }
        return "";
      },
      "telephone.telephone": function(val) {
          if(val.trim().length === 0 ){
              return "Будь ласка, введіть значення";
          }
          if(validators.isValidTelephoneNumber(val) !== true ){
              return "Будь ласка, введіть телефонний номер";
          }
          return "";
      },
      "telephone.comment": function(val) {
        if(val.trim().length === 0 ){
            return "Будь ласка, введіть значення";
        }
        return "";
      },
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeFactory = ApiModel.handleChangeFactory2(this);
    this.handleCheckboxFactory = ApiModel.handleCheckboxFactory2(this);
    this.handleDateChangeFactory = ApiModel.handleDateChangeFactory2(this);
    this.validate = ApiModel.validate(this).bind(this);
    this.onError = ApiModel.onError(this).bind(this);
    this.loadOneConsumer = ApiModel.loadOneConsumer(this, "consumer", this.onError);
  }

  componentDidUpdate(){
    if(this.props.consumerId !==  this.state.consumerId) {
      this.setState({consumerId: this.props.consumerId });
      this.loadOneConsumer(this.props.consumerId);
    }    
  }

  componentDidMount(){
    // var app = this.context;
    this.setState({consumerId: this.props.consumerId });
    this.loadOneConsumer(this.props.consumerId);
  }




  handleSubmit(event) {
        event.preventDefault();
        var self = this;
        var app = this.context;
        
        let consumer = JSON.parse(JSON.stringify(this.state.consumer));

        consumer.consumer_id =  consumer.id;

        // do validation
        var err = self.validate(consumer);

        if(err.errorExists) {
            self.setState({
              errors: err.errors,
              message:"Дані не збережено",
              messageType:"error",
            });
            return;
        }

        var onError = function(errorMessage) {
            self.setState({
              "message":errorMessage,
              "messageType":"error",
            });
          };

        if(consumer.id>0){
          console.log('updating', consumer);
          let postData = {
              consumer_id:consumer.id,
              name:consumer.name,
              mfo:consumer.mfo,
              okpo:consumer.okpo,
              rr: consumer.rr,
              deal_letters_address: consumer.deal_letters_address, // text
              comment:consumer.comment, // text
              nds: consumer.nds,  // boolean
              email: consumer.email,
              telephone: consumer.telephone,
          };
          app.postData(
              '/consumers/edit',
              [postData]
              ).then(function(responseJson){
              // console.log("responseJson", responseJson, responseJson["status"]);
              if(responseJson["status"] === "OK") {
                  self.setState({
                      "message": "Дані оновлено",
                      "messageType": "success"
                  });
              } else if(responseJson["status"] === "EXPECTATION_FAILED") {
                  onError("Не вдалося зберегти дані");
              } else {
                  onError(responseJson["comment"]);
              }
              }).catch(() => onError("Невідома помилка"));
        } else {
            console.log('creating', consumer);
            let postData = {
                // "consumer_id":consumer.id,
                "name":consumer.name,
                "mfo":consumer.mfo,
                "okpo":consumer.okpo,
                "rr": consumer.rr,
                "email": consumer.email,
                "telephone": consumer.telephone,
            };
            app.postData(
                '/consumers/add',
                [postData]
                ).then(function(responseJson){
                // console.log("responseJson", responseJson, responseJson["status"]);
                if(responseJson["status"] === "OK") {
                    var consumers = Object.assign({}, self.state.consumers);
                    consumers.id = responseJson["detail"][0];
                    self.setState({
                        "consumer": consumers,
                        "message": "Дані збережено",
                        "messageType": "success"
                    });
                } else if(responseJson["status"] === "EXPECTATION_FAILED") {
                    onError("Не вдалося зберегти дані");
                } else {
                    onError(responseJson["comment"]);
                }
                }).catch(() => onError("Невідома помилка"));
        }
  }

  handleTableFactory(action, attr, i, subAttr) {
        var self = this;
        if(action === "update") {
            return function(event) {
                // console.log("update", attr, subAttr);
                event.preventDefault();
                var consumer = JSON.parse(JSON.stringify(self.state.consumer)) || {};
                consumer[attr] = consumer[attr] || [];
                consumer[attr][i][subAttr] = event.target.value;
                var errors = JSON.parse(JSON.stringify(self.state.errors));

                let validatorName = attr + '.' + subAttr;
                if(self.validator[validatorName]){
                    errors[attr] = errors[attr] || {};
                    errors[attr]["@"+i] = errors[attr]["@"+i] || {};
                    errors[attr]["@"+i][subAttr] = self.validator[validatorName](consumer[attr][i][subAttr]);
                }
                self.setState({
                    "consumer": consumer,
                    "message": "Дані змінено, але не збережено",
                    "messageType": "warning",
                    "errors": errors
                });
            };
        }
        if(action === "delete") {
            return function(event) {
                // console.log("delete", attr, subAttr);
                event.preventDefault();
                var consumer = JSON.parse(JSON.stringify(self.state.consumer)) || {};
                consumer[attr] = consumer[attr] || [];
                consumer[attr].splice(i, 1);
                self.setState({
                    "consumer": consumer,
                    "message": "Дані змінено, але не збережено",
                    "messageType": "warning"
                });
            };        
        }
        if(action === "add") {
            return function(event) {
                // console.log("add", attr, subAttr);
                event.preventDefault();
                var consumer = JSON.parse(JSON.stringify(self.state.consumer)) || {};
                consumer[attr] = consumer[attr] || [];
                consumer[attr].push(subAttr);
                self.setState({
                    "consumer": consumer,
                    "message": "Дані змінено, але не збережено",
                    "messageType": "warning"
                });
            };
        }
  }

  handleDeleteFactory() {
      var self = this;
      var app = this.context;
      return function(event) {
          event.preventDefault();
          var id = self.props.consumerId;
          if(id) {
              var onError = function(errorMessage) {
                  self.setState({
                      "message":errorMessage,
                      "messageType":"error",
                  });
              };
              var postData = [id];
              // post the DELETE command
              app.postData(
                      '/consumers/delete',
                      postData
                  ).then(function(responseJson){
                      if(responseJson["status"] === "OK") {
                          self.props.parent.setState({
                            consumerId: null,
                            view:self.props.parent.views.consumers,
                          });
                      } else if(responseJson["status"] === "EXPECTATION_FAILED") {
                          onError("Не вдалося видалити дані");
                      } else {
                          onError(responseJson["comment"]);
                      }
                  }).catch(() => onError("Невідома помилка"));
          }
      }
  }

  getError(attr, i, subAttr){
      var errors = JSON.parse(JSON.stringify(this.state.errors));
      errors = errors[attr] || {};
      errors = errors["@"+i] || {};
      return errors[subAttr];
  }


  render(){
    // global
    var app = this.context;
    var self = this;
    const { classes } = self.props;
    //console.log("self.state.consumer", self.state.consumer);
    return (<>
      <div>
        <Grid container spacing={4}>

          {self.state.message && (
            <Grid item xs={12}>
              <MessageBlock message={self.state.message} messageType={self.state.messageType}/>
            </Grid>
          )}
          <Grid item xs={12}>
            <div className={classes.breadCrumbs}>
              <Link href="javascript:void(1)"
                onClick={() => app.setState({cmd_consumer:[
                      {
                        action: 'consumers',
                      },
                    ]})
                }>Клієнти</Link>
              / {self.state.consumer.id>0
                 ? self.state.consumer.name
                 : "Додати клієнта"}
            </div>
          </Grid>
          <Grid  item xs={6}>
            <div className={classes.crmxCard}>
              <Grid container spacing={4}>

                <Grid item xs={5}>
                  <div className={classes.formFieldLabel}>
                    Назва*
                  </div>
                </Grid>
                <Grid item xs={7}>
                  <TextField
                    value={self.state.consumer.name}
                    onChange={self.handleChangeFactory("consumer","name")}
                    margin="dense"
                    placeholder="Назва"
                    type="text"
                    fullWidth
                    variant="standard" 
                    className={self.state.errors.name ? classes.textFieldErr : classes.textField}
                    />
                </Grid>
                <Grid item xs={5}>
                  <div className={classes.formFieldLabel}>
                    ЄДРПОУ*
                  </div>
                </Grid>
                <Grid item xs={7}>
                  <TextField
                    value={self.state.consumer.okpo}
                    onChange={self.handleChangeFactory("consumer","okpo")}
                    margin="dense"
                    placeholder="ЄДРПОУ"
                    type="text"
                    fullWidth
                    variant="standard" 
                    className={self.state.errors.okpo ? classes.textFieldErr : classes.textField}
                    />
                </Grid>

                <Grid item xs={5}>
                  <div className={classes.formFieldLabel}>
                    МФО*
                  </div>
                </Grid>
                <Grid item xs={7}>
                  <TextField
                    value={self.state.consumer.mfo}
                    onChange={self.handleChangeFactory("consumer", "mfo")}
                    margin="dense"
                    placeholder="МФО"
                    type="text"
                    fullWidth
                    variant="standard" 
                    className={self.state.errors.mfo ? classes.textFieldErr : classes.textField}
                    />
                </Grid>

                <Grid item xs={5}>
                  <div className={classes.formFieldLabel}>
                    Рахунок*
                  </div>
                </Grid>
                <Grid item xs={7}>
                  <TextField
                    value={self.state.consumer.rr}
                    onChange={self.handleChangeFactory("consumer","rr")}
                    margin="dense"
                    placeholder="Рахунок"
                    type="text"
                    fullWidth
                    variant="standard" 
                    className={self.state.errors.rr ? classes.textFieldErr : classes.textField}
                    />
                </Grid>




                <Grid item xs={5}>
                  <div className={classes.formFieldLabel}>
                    Адреса для листування*
                  </div>
                </Grid>
                <Grid item xs={7}>
                  <TextField
                    value={self.state.consumer.deal_letters_address}
                    onChange={self.handleChangeFactory("consumer","deal_letters_address")}
                    margin="dense"
                    placeholder="Адреса для листування"
                    type="text"
                    fullWidth
                    variant="standard" 
                    className={self.state.errors.deal_letters_address ? classes.textFieldErr : classes.textField}
                    />
                </Grid>


                <Grid item xs={5}>
                  <div className={classes.formFieldLabel}>
                    Примітки
                  </div>
                </Grid>
                <Grid item xs={7}>
                  <TextareaAutosize
                    minRows={3}
                    style={{width:"100%"}}
                    value={self.state.consumer.comment}
                    onChange={self.handleChangeFactory("consumer","comment")}
                    margin="dense"
                    placeholder="Примітки"
                    type="text"
                    variant="standard" 
                    // className={self.state.errors.comments ? classes.textFieldErr : classes.textField}
                    />
                </Grid>

                <Grid item xs={5}>
                  <div className={classes.formFieldLabel}>
                    Платник ПДВ
                  </div>
                </Grid>
                <Grid item xs={7}>
                  <FormControlLabel control={
                    <Checkbox 
                      checked={self.state.consumer.nds || false}
                      onChange={self.handleCheckboxFactory("consumer", "nds")}
                    />
                    } label="Платник ПДВ" 
                  />
                </Grid>

              </Grid>
            </div>
          </Grid>

          <Grid  item xs={6}>
            <div className={classes.crmxCard}>
                <div className={classes.formFieldLabel}>Телефони:</div>
                <table className={classes.crmXTable + " " + classes.crmXColWidth} cellspacing="0">
                <thead>
                  <tr>
                    <th>&nbsp;</th>
                    <th>Телефон*</th>
                    <th>Примітки*</th>
                  </tr>
                </thead>
                <tbody>
                {this.state.consumer.telephone && this.state.consumer.telephone.map(function(object, i){
                    return <tr key={"consumer.telephone." + i}>
                        <td>
                          <div  className={classes.columnAction}>
                            <button className={classes.rowActionButtonRed}
                                onClick={self.handleTableFactory("delete","telephone", i, "telephone")}
                            >&minus;</button>
                            <button className={classes.rowActionButtonSattelite}
                                onClick={self.handleTableFactory("delete","telephone", i, "telephone")}
                            >&gt;</button>
                          </div>
                        </td>
                        <td>
                          <TextField
                            key={"telephone_value_"+i}
                            value={object.telephone}
                            onChange={self.handleTableFactory("update", "telephone", i, "telephone")}
                            margin="dense"
                            placeholder="Номер телефона"
                            type="text"
                            fullWidth
                            variant="standard" 
                            className={self.getError("telephone", i, "telephone") ? classes.tableTextFieldErr : classes.tableTextField}
                            />
                        </td>
                        <td>
                          <TextField
                            key={"telephone_comment_"+i}
                            value={object.comment}
                            onChange={self.handleTableFactory("update", "telephone", i, "comment")}
                            margin="dense"
                            placeholder="Примітки"
                            type="text"
                            fullWidth
                            variant="standard" 
                            className={self.getError("telephone", i, "comment") ? classes.tableTextFieldErr : classes.tableTextField}
                            />
                        </td>
                    </tr>;
                })}
                <tr key={"consumer.telephone.btns"}>
                  <td>
                    <div  className={classes.columnAction}>
                      <button className={classes.rowActionButton}
                              onClick={self.handleTableFactory("add","telephone", null, {"telephone_id":0, "telephone":"", "comment":""})}
                      >+</button>
                      <button className={classes.rowActionButtonSattelite}
                              onClick={self.handleTableFactory("add","telephone", null, {"telephone_id":0, "telephone":"", "comment":""})}
                      >&gt;</button>
                    </div>
                  </td>
                  <td></td>
                  <td></td>
                </tr>
                </tbody>
                </table>
            </div>
            <div className={classes.crmxCard}>
                <div className={classes.formFieldLabel}>E-mail:</div>
                <table className={classes.crmXTable + " " + classes.crmXColWidth} cellspacing="0">
                <thead>
                  <tr>
                    <th>&nbsp;</th>
                    <th>Email*</th>
                    <th>Примітки*</th>
                  </tr>
                </thead>
                <tbody>

                {this.state.consumer.email && this.state.consumer.email.map(function(object, i){
                    return <tr key={"consumer.email." + i}>
                        <td>
                          <div  className={classes.columnAction}>
                          <button className={classes.rowActionButtonRed}
                              onClick={self.handleTableFactory("delete","email", i, "email")}
                          >&minus;</button><button className={classes.rowActionButtonSattelite}
                              onClick={self.handleTableFactory("delete","email", i, "email")}
                          >&gt;</button>
                          </div>
                        </td>
                        <td>
                          <TextField
                            key={"email_value_"+i}
                            value={object.email}
                            onChange={self.handleTableFactory("update", "email", i, "email")}
                            margin="dense"
                            placeholder="email"
                            type="text"
                            fullWidth
                            variant="standard" 
                            className={self.getError("email", i, "email") ? classes.tableTextFieldErr : classes.tableTextField}
                            />
                        </td>
                        <td>
                          <TextField
                            key={"email_comment_"+i}
                            value={object.comment}
                            onChange={self.handleTableFactory("update", "email", i, "comment")}
                            margin="dense"
                            placeholder="Примітки"
                            type="text"
                            fullWidth
                            variant="standard" 
                            className={self.getError("email", i, "comment") ? classes.tableTextFieldErr : classes.tableTextField}
                            />
                        </td>
                    </tr>;
                })}
                <tr>
                  <td>
                    <div  className={classes.columnAction}>
                        <button className={classes.rowActionButton}
                                onClick={self.handleTableFactory("add","email", null, {"email_id":0, "email":"", "comment":""})}
                        >+</button><button className={classes.rowActionButtonSattelite}
                                onClick={self.handleTableFactory("add","email", null, {"email_id":0, "email":"", "comment":""})}
                        >&gt;</button>
                    </div>
                  </td>
                  <td></td>
                  <td></td>
                </tr>
                </tbody>
                </table>
            </div>
          </Grid>
          <Grid item xs={12}>
            * Обов'язкові значення
          </Grid>
          <Grid item xs={12}>
            <div className={classes.formButtons} style={{textAlign:"center"}}>
            

              <Button
                variant="contained"
                color="success"
                size="large"
                className={classes.submitButtonSattelite}
                onClick={self.handleSubmit}
                >
                &nbsp;
              </Button>
              <Button
                variant="contained"
                color="success"
                size="large"
                className={classes.submitButton}
                onClick={self.handleSubmit}
                >
                Зберегти
              </Button>
            </div>
          </Grid>

        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <h2>Договори</h2>            
          </Grid>
        </Grid>
        <ConsumerContracts consumerId={self.props.consumerId} parent={this} breadcrumbs={false}/>
      </div>
    </>);
    
  }
}
export default withStyles(styles)(ConsumerInfo);

