import React, { Component } from "react";
import {
  // Collapse,
  // Divider,
  List,
  ListItem,
  // ListItemIcon,
  // ListItemText,
  // Checkbox,
  TextField,
  // FormLabel,  
  // FormControlLabel,
  // FormControl,
  Select,
  // Typography,
  Box,
  Button,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import * as Icons from "@material-ui/icons";
import MuiDownshift from "mui-downshift";

import {
  Search as SearchIcon,
} from "@material-ui/icons";

// import { Inbox as InboxIcon } from "@material-ui/icons";
// import { Link } from "react-router-dom";
// import classnames from "classnames";

import {CrmDatePicker} from '../../../../components/CrmDateTimePicker/CrmDateTimePicker';

import { AppContext } from "../../../../context/AppContext";
import ApiModel from "../../../../utils/ApiModel";
// styles
import { withStyles } from "@material-ui/core/styles";
import Themes from "../../../../themes";
import customStyles from "./styles";
var theme = Themes.default;
var styles = customStyles(theme);

class TaskFilter extends Component {

  static contextType = AppContext;

  constructor(props) {
    super(props);
    if(!this.state) {
      this.state = {
        edrpou:"",
        personalId: "",
        taskStatusId:"",
        taskTypeId:"",
        dateMin:"",
        dateMax:"",
        myOwnTasks:false,
        taskSuccess:"",

        err_dateMin:false,
        err_dateMax:false,

        personal:[],
        filteredPersonal:[],
        task_status:[],           // list of task statuses
        type_task:[],
      };
    }
    this.handlePersonalStateChange = this.handlePersonalStateChange.bind(this);
    this.loadPersonal = ApiModel.loadPersonal(this, "personal", "filteredPersonal");
    this.loadTaskStatus = ApiModel.loadTaskStatus(this, "task_status");
    this.loadTaskType = ApiModel.loadTaskType(this, "type_task");
  }


  componentDidMount(){
    var self = this;
    var app = this.context;
    self.loadPersonal().finally(function(){
      var filter_task=JSON.parse(JSON.stringify(app.state.filter_task || {})) || {};
      filter_task = filter_task || {};
      self.setState(filter_task);
      self.loadTaskStatus().finally(function(){
        self.loadTaskType();
      });
    });
  }

  updateFactory(attr) {
    var self = this;
    return function(e) {

      var updates=Object.assign({}, self.state);
      if(attr==='dateMin'){
        // console.log('dateMin', e);
        updates[attr] = e.format('YYYY-MM-DD');
      } else if (attr==='dateMax') {
        // console.log('dateMax', e);
        updates[attr] = e.format('YYYY-MM-DD');
      } else {
        updates[attr] = e.target.value
      }

      if(!!updates['dateMin'] && !!updates['dateMax']) {
         // both dates are set
         updates['err_dateMin'] = false;
         updates['err_dateMax'] = false;
      } else if( !updates['dateMin'] && !updates['dateMax']) {
         // both dates are NOT set
         updates['err_dateMin'] = false;
         updates['err_dateMax'] = false;
      } else if( !!updates['dateMin'] && !updates['dateMax']) {
         // dateMin is set and dateMax is NOT set
         updates['err_dateMin'] = false;
         updates['err_dateMax'] = true;
      } else if( !updates['dateMin'] && !!updates['dateMax']) {
         // dateMin is NOT set and dateMax is set
         updates['err_dateMin'] = true;
         updates['err_dateMax'] = false;
      }

      self.setState(updates);
    };
  }


  setFilter(upd) {
    var app = this.context;
    var filter_task=JSON.parse(JSON.stringify(app.state.filter_task || {})) || {};
    filter_task = filter_task || {};
    for(var k in upd){
      filter_task[k]=upd[k];
    }
    app.setState({filter_task:filter_task});
    this.setState(upd);
  }

  filterPersonal(filter){
    return this.state.personal.filter(item => 
        item.label.toLowerCase().includes(filter.toLowerCase())
    );
  }

  handlePersonalStateChange = changes => {
		if (typeof changes.inputValue === 'string') {
			this.setState({filteredPersonal: this.filterPersonal(changes.inputValue)});
		}
		if (this.input && this.props.blurOnSelect) {
			this.input.blur();
		}
	};

  render(){
    var {classes} = this.props;
    // global
    var app = this.context;
    var self= this;

    return (
        <>
          <List className={classes.sidebarList}>

            <ListItem
                className={classes.sidebarListItem}
              >
              <Box  className={classes.sidebarFilterEdrpouRow} >
                <div className={classes.sidebarFilterEdrpou}>
                  <TextField
                    value={self.state.edrpou}
                    onChange={self.updateFactory("edrpou")}
                    margin="normal"
                    placeholder="Найменування / ЄДРПОУ"
                    type="text"
                    fullWidth
                    variant="standard" 
                    className={classes.sidebarFilterText}
                    />
                  </div>
                  <div className={classes.sidebarEdrpouBtn}><SearchIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} /></div>
              </Box>
            </ListItem>
            { false && app.state.userType === "admin" &&
              <ListItem
                className={classes.sidebarListItem + " button"}
              >
                <Button
                  variant="contained"
                  color="success"
                  size="large"
                  className={classes.greenButtonAdd}
                  onClick={()=> app.setState({
                    cmd_task:[
                        {action:'add'}
                    ]
                  })}
                >
                <Icons.AddBox />
                &nbsp;
                Додати завдання
              </Button>
              </ListItem>
            }

            { app.state.userType === "admin" &&
              <ListItem
                className={classes.sidebarListItem + " filter"}
              >
                <InputLabel className={classes.sidebarLabel}>Співробітник</InputLabel>
                <MuiDownshift
                  items={self.state.filteredPersonal}
                  value={self.state.personalId}
                  onChange={e => self.setState({"personalId": ( e && e.value) || "" })}
                  onStateChange={this.handlePersonalStateChange}
                  getListItemKey={rowIndex => self.state.personal[rowIndex].value}
                  inputRef={node => {
                    this.input = node;
                  }}
                  className={classes.sidebarCombobox}
                />

              </ListItem>
            }

            <ListItem
              className={classes.sidebarListItem + " filter"}
            >
              <InputLabel className={classes.sidebarLabel}>За статусом завдання</InputLabel>
              <Select
                value={self.state.taskStatusId}
                className={classes.sidebarFilterSelect}
                multiple={false}
                onChange={self.updateFactory("taskStatusId")}
              >
                <MenuItem value="" key={'task_status_id_X'} >-</MenuItem>
                {self.state.task_status.map(function(elm, ind){
                    return <MenuItem key={'task_status_id_' + ind} value={elm.id}>{elm.name}</MenuItem>;
                })}
              </Select>
            </ListItem>

            <ListItem
              className={classes.sidebarListItem + " filter"}
            >
              <InputLabel className={classes.sidebarLabel}>Успішність виконання</InputLabel>
              <Select
                value={self.state.taskSuccess}
                className={classes.sidebarFilterSelect}
                multiple={false}
                onChange={self.updateFactory("taskSuccess")}
              >
                <MenuItem value="" key={'task_status_id_X'} >-</MenuItem>
                <MenuItem value="failed_status" key={'task_status_id_failed_status'} >Не виконано</MenuItem>
                <MenuItem value="success_status" key={'task_status_id_success_status'} >Виконано</MenuItem>
              </Select>
            </ListItem>

            <ListItem
              className={classes.sidebarListItem + " filter"}
            >
              <InputLabel className={classes.sidebarLabel}>За типом завдання</InputLabel>
              <Select
                value={self.state.taskTypeId}
                className={classes.sidebarFilterSelect}
                multiple={false}
                onChange={self.updateFactory("taskTypeId")}
              >
                <MenuItem value="" key={'task_type_id_X'} >-</MenuItem>
                {self.state.type_task.map(function(elm, ind){
                    return <MenuItem key={'task_type_id_' + ind} value={elm.id}>{elm.name}</MenuItem>;
                })}
              </Select>
            </ListItem>

            <ListItem
              className={classes.sidebarListItem + " filter"}
            >
              <InputLabel 
                id="labelAuctionDates"
                className={classes.sidebarLabel}
                >За період</InputLabel>
              <CrmDatePicker
                renderInput={(props) => <TextField {...props} />}
                label=""
                value={self.state.dateMin}
                onChange={self.updateFactory("dateMin")}
                margin="dense"
                placeholder="Від дати"
                variant="standard" 
                className={self.state.err_dateMin ? classes.sidebarFilterTextError : classes.sidebarFilterText}
              />
              <br/>
              <CrmDatePicker
                renderInput={(props) => <TextField {...props} />}
                label=""
                value={self.state.dateMax}
                onChange={self.updateFactory("dateMax")}
                margin="dense"
                placeholder="До дати"
                variant="standard" 
                className={self.state.err_dateMax ? classes.sidebarFilterTextError : classes.sidebarFilterText}
              />
            </ListItem>



            <ListItem
              className={classes.sidebarListItem + " button"}
            >
              <Button
                variant="contained"
                className={classes.sidebarFilterButton}
                onClick={e => self.setFilter({
                  edrpou: self.state.edrpou,
                  personalId: self.state.personalId,
                  taskStatusId:self.state.taskStatusId,
                  taskTypeId:self.state.taskTypeId,
                  dateMin:self.state.dateMin,
                  dateMax:self.state.dateMax,
                  myOwnTasks:!!self.state.myOwnTasks,
                  taskSuccess: self.state.taskSuccess,
                })}
                >Фільтрувати</Button>
            </ListItem>
            <ListItem
              className={classes.sidebarListItem + " button"}
            >
              <Button 
                variant="contained"
                className={classes.sidebarFilterButton}
                onClick={e => self.setFilter({
                  edrpou:"",
                  personalId: "",
                  taskStatusId:"",
                  taskTypeId:"",
                  dateMin:null,
                  dateMax:null,
                  myOwnTasks:false,
                  taskSuccess: "",
                })}
                >Очистити фільтр</Button>
            </ListItem>

          </List>
        </>
        );

  }

}

export default withStyles(styles)(TaskFilter);

