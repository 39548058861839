

function regions_ua(){

    let region_list = [
        "Автономна Республіка Крим",
        "Вінницька область", 
        "Волинська область", 
        "Дніпропетровська область", 
        "Донецька область", 
        "Житомирська область", 
        "Закарпатська область", 
        "Запорізька область", 
        "Івано-Франківська область", 
        "Київська область", 
        "Кіровоградська область", 
        "Луганська область", 
        "Львівська область", 
        "Миколаївська область", 
        "Одеська область", 
        "Полтавська область", 
        "Рівненська область", 
        "Сумська область", 
        "Тернопільська область", 
        "Харківська область", 
        "Херсонська область", 
        "Хмельницька область", 
        "Черкаська область", 
        "Чернівецька область", 
        "Чернігівська область", 
        "місто Київ",
        "місто Севастополь"
    ];
    return region_list;
}

export  default regions_ua();
