import React, {Component} from "react";
import {
  Button
} from "@material-ui/core";
import DateTimePicker from '@mui/lab/DateTimePicker';
import DatePicker from '@mui/lab/DatePicker';
// import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import "moment/locale/uk";
//import "moment/locale/ru";
import moment from "moment";
moment.locale("uk"); // ukrainian

/**
 <CrmButton
  classNameMain={...}
  classNameSattelite={...}
  size="large|small"
  onClick={...}
 >
 btnText
 </CrmButton>
 */
class CrmDateTimePicker extends Component {
  render(){
    var { renderInput,
          label,
          value,
          onChange,
          margin,
          placeholder,
          variant,
          className
    } = this.props;
    return (
      <>
            <DateTimePicker
              renderInput={renderInput}
              label={label}
              value={value}
              onChange={onChange}
              margin={margin}
              placeholder={placeholder}
              variant={variant} 
              className={className}
            />
      </>
    );
  }
}


class CrmDatePicker extends Component {
  render(){
    var { renderInput,
          label,
          value,
          onChange,
          margin,
          placeholder,
          variant,
          className
    } = this.props;
    return (
      <>
            <DatePicker
              renderInput={renderInput}
              label={label}
              value={value}
              onChange={onChange}
              margin={margin}
              placeholder={placeholder}
              variant={variant} 
              className={className}
            />
      </>
    );
  }
}

export  {CrmDateTimePicker, CrmDatePicker};
