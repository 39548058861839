function customStyles(theme){
    return {
      breadCrumbs:{
      },
      formButtons:theme.formButtons,
      submitButton:theme.submitButton,
      textFieldErr:theme.textFieldErr,
      textField:theme.textField,
      tableTextFieldErr:theme.tableTextFieldErr,
      tableTextField:theme.tableTextField,
      rowActionButton:theme.rowActionButton,
      rowActionButtonSattelite:theme.rowActionButtonSattelite,
      rowActionButtonRed: theme.rowActionButtonRed,
      columnAction: theme.columnAction,
      submitButtonDanger:theme.submitButtonDanger,
      submitButtonSattelite: theme.submitButtonSattelite,
      pageTitle:theme.pageTitle,
      topButtons:theme.topButtons,
      formFieldLabel:theme.formFieldLabel,
      crmxCard:theme.crmxCard,
      selectFieldErr: theme.selectFieldErr,
      selectField: theme.selectField,
      crmXTable:theme.crmXTable,
      crmXColWidth:{
        "& tbody td:nth-child(1)":{width:"10%", textAlign:"center",},
        "& tbody td:nth-child(2)":{width:"60%", textAlign:"left",},
        "& tbody td:nth-child(3)":{width:"15%", textAlign:"center",},
        "& tbody td:nth-child(4)":{width:"15%", textAlign:"center",},
      }
    };
}
export default customStyles;
