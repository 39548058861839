import tinycolor from "tinycolor2";

// const primary = "#536DFE";
// const secondary = "#FF5C93";
// const warning = "#FFC260";
// const success = "#3CD4A0";
// const info = "#9013FE";
const primary = "#312a6f";
const secondary = "#a19dae";
const warning = "#FFC260";
const success = "#008000";
const info = "#9013FE";

const lightenRate = 7.5;
const darkenRate = 15;

const defaultTheme = {
    crm:{
      black:'#000000',
      green:'#008000',
      greenLight:'#00aa00',
      greyLight:'#a19dae',
      grey:tinycolor('#a19dae')
        .darken(lightenRate)
        .toHexString(),
      blue:'#312a6f',
      blueLight:'#5246ba',
      white:'#ffffff',
      red:'#ff0000',
      redDark:'#880000',
      yellow:'#FFFF00',
      yellowSand:'#FFFF66',
      fontSizeFilter:"14px",
      filterMarkerImage:"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAlCAYAAAByI9gkAAABhWlDQ1BJQ0MgcHJvZmlsZQAAKJF9kT1Iw0AcxV9TRdGqoEVEBDNUJwuiIo5ahSJUCLVCqw4ml35Bk5YkxcVRcC04+LFYdXBx1tXBVRAEP0Dc3JwUXaTE/yWFFjEeHPfj3b3H3TtAqBaYZraMA5puGfFoREymVsW2V3SiG/0YRq/MzNKcJMXgOb7u4ePrXZhneZ/7c3SpaZMBPpF4lpUMi3iDeHrTKnHeJw6ynKwSnxOPGXRB4keuKy6/cc46LPDMoJGIzxMHicVsEytNzHKGRjxFHFI1nfKFpMsq5y3OWqHM6vfkLwyk9ZVlrtMcQhSLWIIEEQrKyKMAC2FadVJMxGk/4uEfdPwSuRRy5cHIsYAiNMiOH/wPfndrZiYn3KRABGh9se2PEaBtF6hVbPv72LZrJ4D/GbjSG/5iFZj5JL3S0EJHQM82cHHd0JQ94HIHGHgqyYbsSH6aQiYDvJ/RN6WAvlugY83trb6P0wcgQV3FboCDQ2A0S9nrHu9ub+7t3zP1/n4AXMhynhliuEIAAAAGYktHRAAAAAAAAPlDu38AAAAJcEhZcwAACxMAAAsTAQCanBgAAAAHdElNRQflCw4WICc1dyi4AAAAGXRFWHRDb21tZW50AENyZWF0ZWQgd2l0aCBHSU1QV4EOFwAAAW9JREFUSMedlT9IAlEcx7/eZHANjl6teRIhRFhjh9Ikh3CTGERLowZHk0voYGBrNPRvkGyV46aGSxvDrYSwuc4tIY9qew1hdOqd73ff7b3Hh/d+39/v/X4hxhgDUX17AAEB1LKegoE39RYdPDu9hW0PEKLE2LcHyGyVAYD/Rsf5hl68/Ftzg8fVJnrPrzTQNDowjQfXnsADHZYaE/u+5rz0bOS02tQzwQ/a2z3xfIngBw0/PvlBHmgCNI0OclptJuQCvdzzUogxxtpWF3rxglSzAgA0ru/JP0QAgLi8EAzM72xiXpyjg1EpgvN6gQS7Ss6vxHzzGJMllKvbdBAA1GySC55aq2o2CTW7wR/juPTCFdp3j7RvBQCVozxi8UU6KIphVKr5qWma2TpisoSDkkYHR2YpqQS/OeN9NZMuY+h80fqqKIZd+SXNDiW9grX1pd8FI8p+e2ery/uMPK2iUgRKKkGbViO1rW4wkGzOf/0A6n/DPHFyT2wAAAAASUVORK5CYII=",
      filterShadow:"1px 1px 1px 0px rgba(173, 173, 173, 0.2)",
    },
  palette: {
    primary: {
      main: primary,
      light: tinycolor(primary)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(primary)
        .darken(darkenRate)
        .toHexString(),
    },
    secondary: {
      main: secondary,
      light: tinycolor(secondary)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(secondary)
        .darken(darkenRate)
        .toHexString(),
      contrastText: "#FFFFFF",
    },
    warning: {
      main: warning,
      light: tinycolor(warning)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(warning)
        .darken(darkenRate)
        .toHexString(),
    },
    success: {
      main: success,
      light: tinycolor(success)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(success)
        .darken(darkenRate)
        .toHexString(),
    },
    info: {
      main: info,
      light: tinycolor(info)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(info)
        .darken(darkenRate)
        .toHexString(),
    },
    text: {
      primary: "#4A4A4A",
      secondary: "#6E6E6E",
      hint: "#B9B9B9",
    },
    background: {
      default: "#F6F7FF",
      light: "#F3F5FF",
    },
  },
  customShadows: {
    widget:
      "0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
    widgetDark:
      "0px 3px 18px 0px #4558A3B3, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
    widgetWide:
      "0px 12px 33px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
  },
  overrides: {
    MuiBackdrop: {
      root: {
        backgroundColor: "#4A4A4A1A",
      },
    },
    MuiMenu: {
      paper: {
        boxShadow:
          "0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
      },
    },
    MuiSelect: {
      icon: {
        color: "#B9B9B9",
      },
    },
    MuiListItem: {
      root: {
        "&$selected": {
          backgroundColor: "#F3F5FF !important",
          "&:focus": {
            backgroundColor: "#F3F5FF",
          },
        },
      },
      button: {
        "&:hover, &:focus": {
          backgroundColor: "#F3F5FF",
        },
      },
    },
    MuiTouchRipple: {
      child: {
        backgroundColor: "white",
      },
    },
    MuiTableRow: {
      root: {
        height: 56,
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: "1px solid rgba(224, 224, 224, .5)",
        paddingLeft: 24
      },
      head: {
        fontSize: "0.95rem",
      },
      body: {
        fontSize: "0.95rem",
      },
    },
    PrivateSwitchBase: {
      root: {
        marginLeft: 10
      }
    }
  },
};

defaultTheme.popOver = {
        width:"100%",
        "& .MuiPopover-paper":{
          width:"80%",
          height:"90%",
          padding:"1em",
          marginLeft:"10%",
          overflowY:"scroll",
        }
};
defaultTheme.popOverMini = {
        width:"100%",
        "& .MuiPopover-paper":{
          width:"80%",
          height:"auto",
          padding:"1em",
          marginLeft:"10%",
          // overflowY:"scroll",
        }
};
defaultTheme.rowActionButton= {
    backgroundColor: defaultTheme.crm.green,
    color: defaultTheme.crm.white,
    fontSize:"12px",
    borderRadius:"0px",
    fontWeight:"normal",
    lineHeight:"1em",
    height:"30px",
    border:"none",
    boxShadow: "0px 0px 8px 0px rgba(34, 60, 80, 0.2)",
    "&:hover":{
      backgroundColor: defaultTheme.crm.greenLight,
      color: defaultTheme.crm.white,
    }
};
defaultTheme.rowActionButtonRed = {
    backgroundColor: defaultTheme.crm.red,
    color: defaultTheme.crm.white,
    fontSize:"12px",
    borderRadius:"0px",
    fontWeight:"normal",
    lineHeight:"1em",
    height:"30px",
    border:"none",
    boxShadow: "0px 0px 8px 0px rgba(34, 60, 80, 0.2)",
    "&:hover":{
      backgroundColor: defaultTheme.crm.redDark,
      color: defaultTheme.crm.white,
    }
};
defaultTheme.rowActionButtonDisabled= {
    backgroundColor: defaultTheme.crm.grey,
    color: defaultTheme.crm.white,
    fontSize:"12px",
    borderRadius:"0px",
    fontWeight:"normal",
    lineHeight:"1em",
    height:"30px",
    border:"none",
    boxShadow: "0px 0px 8px 0px rgba(34, 60, 80, 0.2)",
    "&:hover":{
      backgroundColor: defaultTheme.crm.greyLight,
      color: defaultTheme.crm.white,
    }
};
defaultTheme.rowActionButtonSattelite = {
    backgroundColor: defaultTheme.crm.blue,
    color: defaultTheme.crm.white,
    fontSize:"12px",
    borderRadius:"0px",
    fontWeight:"normal",
    lineHeight:"1em",
    height:"30px",
    paddingLeft:"2px",
    paddingRight:"2px",
    marginLeft:"2px",
    width:"13px",
    overflow:"hidden",
    minWidth:"13px",
    border:"none",
    boxShadow: "0px 0px 8px 0px rgba(34, 60, 80, 0.2)",
    "&:hover":{
      backgroundColor: defaultTheme.crm.blueLight,
      color: defaultTheme.crm.white,
    }
};
defaultTheme.rowActionButtonSatteliteDisabled = {
    backgroundColor: defaultTheme.crm.grey,
    color: defaultTheme.crm.white,
    fontSize:"12px",
    borderRadius:"0px",
    fontWeight:"normal",
    lineHeight:"1em",
    height:"30px",
    paddingLeft:"2px",
    paddingRight:"2px",
    marginLeft:"2px",
    width:"13px",
    overflow:"hidden",
    minWidth:"13px",
    border:"none",
    boxShadow: "0px 0px 8px 0px rgba(34, 60, 80, 0.2)",
    "&:hover":{
      backgroundColor: defaultTheme.crm.greyLight,
      color: defaultTheme.crm.white,
    }
};
defaultTheme.crmXTable = {
      // marginTop: "2em",
      boxShadow:"none",
      "& table":{
        borderCollapse: "inherit",
      },
      "& thead th":{
        fontSize:"12px",
        backgroundColor:"#F8F8FA",
        textTransform:"uppercase",
        fontWeight:"bold",
        verticalAlign:"bottom",
        borderTop:"1px solid rgb(222, 226, 230)",
        borderBottom:"2px solid rgb(222, 226, 230)",
        borderLeft:"2px solid rgb(217, 203, 186)",
        padding:"10px",
        color:"rgba(0, 0, 0, 0.54)",
        textAlign:"center",
        lineHeight:"1em",
      },
      "& thead th:first-child":{
        borderLeft:"none",
      },
      "& tbody td":{
        fontSize:"12px",
        backgroundColor:"#ffffff",
        borderBottom:"2px solid rgb(217, 203, 186)",
        borderLeft:"2px solid rgb(217, 203, 186)",
        padding:"10px",
        color:"rgba(0, 0, 0, 0.87)",
        lineHeight:"1em",
      },
      "& td:first-child":{
        borderLeft:"none",
      },
      "& td.MuiTableCell-root.MuiTableCell-footer":{
        border:"none",
      },
};
defaultTheme.crmXContent = {
  padding:"1em",
};
defaultTheme.formButtons = {
  textAlign:"center",
};

defaultTheme.submitButtonSattelite = {
  backgroundColor:defaultTheme.crm.green,
  color:defaultTheme.crm.white,
  borderRadius:"0px",
  // fontSize: "1em",
  paddingLeft:"2px",
  paddingRight:"2px",
  marginRight:"2px",
  marginLeft:"2px",
  width:"8px",
  overflow:"hidden",
  minWidth:"8px",
  "&:hover":{
    backgroundColor:defaultTheme.crm.greenLight,
  }
};
defaultTheme.submitButton = {
  backgroundColor:defaultTheme.crm.green,
  borderRadius:"0px",
  // fontSize: "1em",
  color:defaultTheme.crm.white,
  "&:hover":{
    backgroundColor:defaultTheme.crm.greenLight,
  }
};
defaultTheme.submitButtonDanger = {
  backgroundColor:defaultTheme.crm.red,
  color:defaultTheme.crm.white,
  borderRadius:"0px",
  "&:hover":{
    backgroundColor:defaultTheme.crm.redDark,
  }
};
defaultTheme.submitButtonDangerSattelite = {
  backgroundColor:defaultTheme.crm.red,
  color:defaultTheme.crm.white,
  borderRadius:"0px",
  // fontSize: "1em",
  paddingLeft:"2px",
  paddingRight:"2px",
  marginRight:"2px",
  marginLeft:"10px",
  width:"8px",
  overflow:"hidden",
  minWidth:"8px",
  "&:hover":{
    backgroundColor:defaultTheme.crm.redDark,
  }
};
defaultTheme.textFieldErr = {
  "& input" : {
    backgroundColor: defaultTheme.crm.red,
    color:defaultTheme.crm.white,
  },
};

defaultTheme.textField = {
  "& input" : {
    backgroundColor: defaultTheme.crm.white,
    color:defaultTheme.crm.black,
  },
};

defaultTheme.tableTextFieldErr = {
  "& input" : {
    backgroundColor: defaultTheme.crm.red,
    color:defaultTheme.crm.white,
    padding:"0.3em 0.5em",
  },
  margin:"0px"
};

defaultTheme.tableTextField = {
  "& input" : {
    backgroundColor: defaultTheme.crm.white,
    color:defaultTheme.crm.black,
    padding:"0.3em 0.5em",
  },
  margin:"0px"
};


defaultTheme.columnAction = {
  textAlign:"right",
  whiteSpace:"nowrap",
};

defaultTheme.blockWarning = {
  backgroundColor: defaultTheme.crm.yellow,
  color:defaultTheme.crm.black,
  padding:"1em 1em",
  "&.dense":{
      padding:"0 0.3em",
  }
};

defaultTheme.blockError = {
  backgroundColor: defaultTheme.crm.red,
  color:defaultTheme.crm.white,
  padding:"1em 1em",
  "&.dense":{
      padding:"0 0.3em",
  }
};

defaultTheme.blockSuccess = {
  backgroundColor: defaultTheme.crm.green,
  color:defaultTheme.crm.white,
  padding:"1em 1em",
};

defaultTheme.selectFieldErr = {
    marginTop: "0",
    marginBottom: "0",
    backgroundColor: defaultTheme.crm.red,
    color:defaultTheme.crm.white,
    width:"100%",
};

defaultTheme.selectField = {
    marginTop: "0",
    marginBottom: "0",
    backgroundColor: defaultTheme.crm.white,
    color:defaultTheme.crm.black,
    width:"100%",
};


defaultTheme.listSelectFieldErr = {
    marginTop: "0",
    marginBottom: "0",
    backgroundColor: defaultTheme.crm.red,
    color:defaultTheme.crm.white,
    width:"100%",
};
defaultTheme.listSelectField = {
    marginTop: "0",
    marginBottom: "0",
    backgroundColor: defaultTheme.crm.white,
    color:defaultTheme.crm.black,
    width:"100%",
};

defaultTheme.listSelectOption = {

};

defaultTheme.pageTitle = {
  margin:"0",
};
defaultTheme.topButtons = {
  textAlign:"right",
};
defaultTheme.btnBlock = {
  whiteSpace:"nowrap",
  display:"inline-block",
};
defaultTheme.formFieldLabel = {
  textTransform:"uppercase",
  color:"rgba(0, 0, 0, 0.54)",
  display:"block",
  paddingTop: "10px",
};

defaultTheme.crmxCard = {
    borderRadius:"4px",
    boxShadow: "0px 0px 8px 0px rgba(34, 60, 80, 0.2)",
    padding:"1em",
    marginBottom:"2em",
};

defaultTheme.btnGroup = {
  whiteSpace:"nowrap",
  display:"inline-block",
  marginBottom:"3px"
};
defaultTheme.greenButtonAdd = {
    backgroundColor:defaultTheme.crm.green,
    color:"white",
    width:"100%",
    "&:hover": {
      backgroundColor: defaultTheme.crm.greenLight
    }
};
export default defaultTheme;
