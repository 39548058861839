import React, {Component}  from "react";
import {
  Grid,
  // Select,
  // MenuItem,
  Button,
  // TextField,
  // TextareaAutosize,
  // Link,
} from "@material-ui/core";
import moment from 'moment';
// import DateTimePicker from '@mui/lab/DateTimePicker';
// import DatePicker from '@mui/lab/DatePicker';
import { styled } from '@mui/material/styles';


// components
// import validators   from '../../../../utils/validators';
import MessageBlock from '../../../../utils/MessageBlock';
// context
import { AppContext } from "../../../../context/AppContext";
import ApiModel from "../../../../utils/ApiModel";
// import regions   from '../../../../utils/regions_ua';

// styles
import { withStyles } from "@material-ui/core/styles";
import Themes from "../../../../themes";
import customStyles from "./styles";
// import { Alarm } from "@material-ui/icons";


const theme = Themes.default;
const styles = customStyles(theme);
const Input = styled('input')({
  display: 'none',
});

/**
id: <int>
				task_main_id:<int>
				requester_id:<int>
				personal_id:<int>
				start_date: <date>
				end_date:<datetime>
				comments:<text>
				task_status:<int>
 */
class TaskUpload extends Component {

  static contextType = AppContext;
  constructor(props) {
    super(props);
    let now = moment().format('YYYY-MM-DD HH:mm:ss');
    this.defaultRecord=JSON.stringify({
      id:0,                                   //  bigint(20)
      task_main_id:this.props.parentTaskId,   //  bigint(20)
      requester_id:0,   //  bigint(20)
      personal_id:0,                          //  bigint(20) => companies
      start_date:now,                         //  datetime
      end_date:now,                           //  datetime
      comments:"",                            //  text
      task_status:0,                          //  bigint(20) => type_payments
    });

    if(!this.state) {
      this.state = {
        taskId:this.props.taskId,
        task:{},

        errors: {},
        message:"",
        messageType:"",

        personal:[],              // list of persons
        task_status:[],           // list of task statuses
      };
    }
    // var self =this;

    this.handleSubmit = this.handleSubmit.bind(this);
    this.loadOneTask = ApiModel.loadOneTask(this, "task");
  }

  componentDidUpdate(){
    if(this.props.taskId !==  this.state.taskId) {
      var self = this;
      this.setState({ taskId: this.props.taskId,  });
      self.loadOneTask();
    }    
  }

  componentDidMount(){
    // var app = this.context;
    var self = this;
    self.setState({
        taskId: this.props.taskId,
    });
    self.loadOneTask();
  }

  handleSubmit(event) {
    event.preventDefault();
    var self = this;
    var app = this.context;
    
    if(event.target.files && event.target.files[0]) {
      const data = new FormData();
      data.append('file',event.target.files[0] );
      data.append('task_id', parseInt(self.state.task.id));

      var onError = function(errorMessage) {
        self.setState({
          "message":errorMessage,
          "messageType":"error",
        });
      };
      app.postData(
        '/tasks/upload',
        data
      ).then(function(responseJson){
        // console.log("responseJson", responseJson, responseJson["status"]);
        if(responseJson["status"] === "OK") {
          self.setState({
            "message": "Дані оновлено",
            "messageType": "success"
          });
        } else if(responseJson["status"] === "EXPECTATION_FAILED") {
          onError("Не вдалося зберегти дані");
        } else {
          onError(responseJson["comment"]);
        }
      }).catch(() => onError("Невідома помилка"));
    }
  }

  render(){
    // global
    var app = this.context;
    var self = this;
    const { classes } = self.props;

    return (<>
      <div>
        <Grid container spacing={3}>

          {self.state.message && (<Grid item xs={12}>
          <MessageBlock message={self.state.message} messageType={self.state.messageType}/>
          </Grid>)}

          <Grid item xs={12}><h1>Додати файли до завдання</h1></Grid>

          <Grid item xs={6} md={3}>
            <div className={classes.formFieldLabel}>
              Додати файли
            </div>
          </Grid>
          <Grid item xs={6} md={9}>
            
            <Button variant="contained" component="label">
              Вибрати файли
              <Input accept="*/*" 
                     id="contained-button-file" 
                     multiple type="file" 
                     onChange={self.handleSubmit}
                     />
            </Button>
          </Grid>

          <Grid item xs={12}>
            <div className={classes.formButtons}>
              <Button
                variant="contained"
                color="success"
                size="large"
                className={classes.submitButtonSattelite}
                onClick={() => app.setState( { cmd_task:[{action:"closePopup"}] })}
                >
                &nbsp;
              </Button>
              <Button
                variant="contained"
                color="success"
                size="large"
                className={classes.submitButton}
                onClick={() => app.setState( { cmd_task:[{action:"closePopup"}] })}
                >
                Закрити
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
    </>);
    
  }
}
export default withStyles(styles)(TaskUpload);

