// import moment from 'moment';

const Validators = {



    atomicValidator:{
        nonEmpty: function(val) {
            if(!val || (''+val).trim().length === 0 ){
                return "Будь ласка, введіть значення";
            }
            return "";
        },
        isPositiveInteger: function(val) {
            let intVal = parseInt(val);
            if(isNaN(intVal)) {
                return "Будь ласка, введіть натуральне число";
            }
            if(intVal<=0){
                return "Будь ласка, введіть натуральне число";
            }
            return "";
        },
        isPositiveFloat: function(val) {
            let intVal = parseFloat(val);
            if(isNaN(intVal)) {
                return "Будь ласка, введіть додатнє число";
            }
            if(intVal<0){
                return "Будь ласка, введіть додатнє число";
            }
            return "";
        }
    },

    validate: function(self) {
        return function (record){
            var errors={};
            var errorExists = false;
            Object.keys(record).map(function(elm) {
                if(self.validator[elm]) {
                    errors[elm]  = self.validator[elm](record[elm]);
                    if (errors[elm]){
                    errorExists = true;
                    }
                } else {
                    errors[elm] = "";
                }
                return elm;
            });

            return {
            errors:errors,
            errorExists: errorExists
            }
        }
    },

};


export default Validators;