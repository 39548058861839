// import moment from 'moment';

const Handlers = {

    handleChangeFactory: function (entityName, attributeName, validate) {
      var self = this;
      return function(event) {
        event.preventDefault();
        // console.log(entityName, attributeName, event.target.value);
        // deep copy of the data
        var entity = JSON.parse(JSON.stringify(self.state[entityName]));
        
        entity[attributeName] = event.target.value;

        var update = {};
        update[entityName] = entity;
        update["message"] = "Дані змінено, але не збережено";
        update["messageType"] = "warning";

        // if(validate) {
        //     var err = validate(entity);
        //     update["errors"] =  err.errors;
        // }
        self.setState(update);
      }
    },



    handleChangeFactory2: function(self) {
        return function (entityName, attributeName) {
            return function(event) {
                event.preventDefault();
                // console.log(entityName, attributeName, event.target.value);
                var entity = JSON.parse(JSON.stringify(self.state[entityName]));
                
                entity[attributeName] = event.target.value;

                var update = {};
                update[entityName] = entity;
                update["message"] = "Дані змінено, але не збережено";
                update["messageType"] = "warning";
                self.setState(update);
            }
        };
    },


    handleDateChangeFactory: function (entityName, attributeName, validate) {
        var self = this;
        return function(newValue) {
            // console.log(attributeName, newValue.format('YYYY-MM-DD HH:mm:ss'));
            // deep copy of the data
            var entity = JSON.parse(JSON.stringify(self.state[entityName]));
            
            entity[attributeName] = newValue.format('YYYY-MM-DD HH:mm:ss');

            var update = {};
            update[entityName] = entity;
            update["message"] = "Дані змінено, але не збережено";
            update["messageType"] = "warning";

            // if(validate) {
            //     var err = validate(entity);
            //     update["errors"] =  err.errors;
            // }
        
            self.setState(update);
        }
    },





    handleDateChangeFactory2: function(self) {
        return function (entityName, attributeName) {
            return function(newValue) {

                var entity = JSON.parse(JSON.stringify(self.state[entityName]));
                
                entity[attributeName] = newValue.format('YYYY-MM-DD HH:mm:ss');

                var update = {};
                update[entityName] = entity;
                update["message"] = "Дані змінено, але не збережено";
                update["messageType"] = "warning";
                self.setState(update);
            }
        };
    },


    handleCheckboxFactory: function (entityName, attributeName) {
        var self = this;
        return function(event) {
            var entity = JSON.parse(JSON.stringify(self.state[entityName]));
            entity[attributeName] = event.target.checked;
            var update = {};
            update[entityName] = entity;
            update["message"] = "Дані змінено, але не збережено";
            update["messageType"] = "warning";
            self.setState(update);
        }
    },



    handleCheckboxFactory2:  function(self) {
        return function (entityName, attributeName) {
            return function(event) {
                var entity = JSON.parse(JSON.stringify(self.state[entityName]));
                entity[attributeName] = event.target.checked;
                var update = {};
                update[entityName] = entity;
                update["message"] = "Дані змінено, але не збережено";
                update["messageType"] = "warning";
                self.setState(update);
            }
        };
    },



    handleTableFactory:  function(self, entityName, errorsName) {
        return function (action, attr, i, subAttr) {
            if(action === "update") {
                return function(event) {
                    // console.log("update", attr, subAttr);
                    event.preventDefault();
                    var entity = JSON.parse(JSON.stringify(self.state[entityName])) || {};
                    entity[attr] = entity[attr] || [];
                    entity[attr][i][subAttr] = event.target.value;
                    var errors = JSON.parse(JSON.stringify(self.state[errorsName]));

                    let validatorName = attr + '.' + subAttr;
                    if(self.validator && self.validator[validatorName]){
                        errors[attr] = errors[attr] || {};
                        errors[attr]["@"+i] = errors[attr]["@"+i] || {};
                        errors[attr]["@"+i][subAttr] = self.validator[validatorName](entity[attr][i][subAttr]);
                    }
                    let updates={
                        "message": "Дані змінено, але не збережено",
                        "messageType": "warning",
                    };
                    updates[entityName] = entity;
                    updates[errorsName] = errors;
                    self.setState(updates);
                };
            }
            if(action === "delete") {
                return function(event) {
                    // console.log("delete", attr, subAttr);
                    event.preventDefault();
                    var entity = JSON.parse(JSON.stringify(self.state[entityName])) || {};
                    entity[attr] = entity[attr] || [];
                    entity[attr].splice(i, 1);
                    let updates={
                        "message": "Дані змінено, але не збережено",
                        "messageType": "warning",
                    };
                    updates[entityName] = entity;
                    self.setState(updates);
                };        
            }
            if(action === "add") {
                return function(event) {
                    // console.log("add", attr, subAttr);
                    event.preventDefault();
                    var entity = JSON.parse(JSON.stringify(self.state[entityName])) || {};
                    entity[attr] = entity[attr] || [];
                    entity[attr].push(subAttr);
                    let updates={
                        "message": "Дані змінено, але не збережено",
                        "messageType": "warning",
                    };
                    updates[entityName] = entity;
                    self.setState(updates);
                };
            }
        };
    },

    getError: function(self, errorsName) {
        return function (attr, i, subAttr){
            var errors = JSON.parse(JSON.stringify(self.state[errorsName]));
            errors = errors[attr] || {};
            errors = errors["@"+i] || {};
            return errors[subAttr];
        };
    },

    onError:function(self){
        return function(errorMessage) {
            // console.log(errorMessage);
            let msg;
            if (typeof errorMessage === 'string' || errorMessage instanceof String) {
                msg = errorMessage;
            } else {
                msg = JSON.stringify(errorMessage);
            }
            self.setState({
            message: msg,
            messageType: "error",
            });
        };
    },
};


export default Handlers;