import moment from 'moment';

const TaskModel = {
    loadOneTask: function(self, attributeName) {
        return function() {
            // var self = this;
            var app = self.context;
            var onError = function(errorMessage) {
                self.setState({
                    auction:JSON.parse(self.defaultRecord),
                    message: errorMessage,
                    messageType: "error",
                });
            };
            var task_id = parseInt(self.props.taskId);
            
            // console.log('task_id=', task_id);
            if(!task_id){
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                resolve(JSON.parse(self.defaultRecord));
                }, 100);
            });
            }
            var data = {"param": [{"task_id": task_id}]};
            return app.postData(
                '/tasks/get',
                data
            ).then(function(responseJson){
            // console.log("responseJson", responseJson, responseJson["status"]);
            if(responseJson["status"] === "OK") {
                let update={};
                update[attributeName]=responseJson["detail"][0];
                self.setState(update);
                return responseJson["detail"][0];
            } else if(responseJson["status"] === "NOT_FOUND") {
                self.props.app.setState({
                    "message": responseJson["comment"],
                    "messageType": "error"
                });
                return {};
            } else {
                onError(responseJson["comment"]);
                return {};
            }
            }).catch(() => onError("Не вдалося завантажити завдання"));
        }
    },
    defaultTaskRecord : function(){
        return {
            id:0,
            task_main_id:0,   //  int
            personal_id:0,    //  text
            start_date: moment().format('YYYY-MM-DD HH:mm:ss'),
            end_date:"",
            comments:"",
            task_status:0,    //  int
            type_task:0,      //  int
            is_technical: false,
        };
    },
    loadOneTaskFiles: function(self, task_files) {
        return function() {
            // var self = this;
            var app = self.context;
            var onError = function(errorMessage) {
                self.setState({
                auction:JSON.parse(self.defaultRecord),
                message: errorMessage,
                messageType: "error",
                });
            };
            var task_id = parseInt(self.props.taskId);
            
            console.log('task_id=', task_id);
            if(!task_id){
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                resolve('foo');
                }, 100);
            });
            }
            var data = {"param": [{"task_id": task_id}]};
            return app.postData(
                '/tasks/files',
                data
            ).then(function(responseJson){
                // console.log("responseJson", responseJson, responseJson["status"]);
                if(responseJson["status"] === "OK") {
                    let update={};
                    update[task_files]=responseJson["detail"];
                    self.setState(update);
                    return responseJson["detail"];
                } else if(responseJson["status"] === "NOT_FOUND") {
                    self.props.app.setState({
                        "message": responseJson["comment"],
                        "messageType": "error"
                    });
                    return [];
                } else {
                    onError(responseJson["comment"]);
                    return [];
                }
            }).catch(() => onError("Не вдалося завантажити файли завдання"));
        }
    },

    deleteTaskFile: function(self, task_files_id, onDelete) {
        /**
        POST /tasks/deletefile
        [<int>]
         */
        return function() {
            // var self = this;
            var app = self.context;
            var onError = function(errorMessage) {
                self.setState({
                    message: errorMessage,
                    messageType: "error",
                });
            };
            
            if(!task_files_id){
                return new Promise((resolve, reject) => {
                    setTimeout(() => {
                    resolve('foo');
                    }, 100);
                });
            }
            var data = {"param": [task_files_id]};
            return app.postData(
                '/tasks/deletefile',
                data
            ).then(function(responseJson){
                if(responseJson["status"] === "OK") {
                    self.props.app.setState({
                        "message": "Файл видалено",
                        "messageType": "success"
                    });
                    if(onDelete) {
                        onDelete();
                    }
                } else if(responseJson["status"] === "NOT_FOUND") {
                    self.props.app.setState({
                        "message": responseJson["comment"],
                        "messageType": "error"
                    });
                } else {
                    onError(responseJson["comment"]);
                }
            }).catch(() => onError("Не вдалося видалити файли завдання"));
        }
    },
};


export default TaskModel;