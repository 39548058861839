function customStyles(theme){
    return {
        sidebarLabel: {
          textDecoration: "none",
          display:"block",
          fontSize:theme.crm.fontSizeFilter,
          color:theme.crm.black,
          "&:hover, &:focus": {
            backgroundColor: theme.palette.background.light,
          },
        },
        sidebarList:{
        },
        greenButtonAdd:{
          backgroundColor:theme.crm.green,
          color:"white",
          width:"100%",
          "&:hover": {
            backgroundColor: theme.crm.greenLight
          }
        },
        sidebarListItem:{
          display:"block",
          "&.filter":{
            backgroundImage:"url(" + theme.crm.filterMarkerImage+ ")",
            backgroundPosition:"center right",
            backgroundRepeat:"no-repeat",
            boxShadow: theme.customShadows.widgetDark,
            borderRadius:"4px",
            backgroundColor:theme.crm.white,
            marginBottom:"4px",
            paddingBottom:"4px",
          },
          "&.button":{
            paddingRight:"0px",
            paddingLeft:"0px",
            boxShadow: "none",
          }
          
        },
        sidebarFilterText:{
          marginTop:"0px",
          "& input": {
             fontSize:theme.crm.fontSizeFilter,
          },
          "& input::placeholder": {
             fontSize:theme.crm.fontSizeFilter,
          },
        },
        sidebarFilterTextError:{
          backgroundColor:theme.crm.red,
          color:theme.crm.white,
          marginTop:"0px",
          "& input": {
             fontSize:theme.crm.fontSizeFilter,
          },
          "& input::placeholder": {
             fontSize:theme.crm.fontSizeFilter,
          },
        },
        selectOption:{
        },
        sidebarTitle:{
          fontWeight:"bold",
        },
        sidebarFilterSelect:{
          display:"block",
          fontSize:theme.crm.fontSizeFilter,
          marginBottom:"7px",
        },
        sidebarFilterButton:{
          backgroundColor:theme.crm.blue,
          color:"white",
          width:"100%",
          "&:hover":{
            backgroundColor:theme.crm.blueLight,
          }
        },
        sidebarFilterEdrpou:{
          
        },
        sidebarEdrpouBtn:{
          paddingTop:"3px",
          borderBottom:"1px solid gray",
          marginBottom:"12px",
          lineHeight:"1em"
        },
        sidebarFilterEdrpouRow:{ 
          display: "grid",
			    gridTemplateRows: "1fr",
			    gridTemplateColumns: "1fr 30px",
			    gridGap: "0",
        }
      };
}
export default customStyles;
