

function customStyles(theme) {
  return {
    breadCrumbs:{
    },
    popOver:theme.popOver,
    formButtons:theme.formButtons,
    submitButton:theme.submitButton,
    textFieldErr:theme.textFieldErr,
    textField:theme.textField,
    tableTextFieldErr:theme.tableTextFieldErr,
    tableTextField:theme.tableTextField,
    rowActionButton:theme.rowActionButton,
    rowActionButtonSattelite:theme.rowActionButtonSattelite,
    rowActionButtonRed: theme.rowActionButtonRed,
    columnAction: theme.columnAction,
    submitButtonDanger:theme.submitButtonDanger,
    submitButtonSattelite: theme.submitButtonSattelite,
    selectFieldErr: theme.selectFieldErr,
    selectField: theme.selectField,
    pageTitle:theme.pageTitle,
    topButtons:theme.topButtons,
    btnBlock:theme.btnBlock,
    formFieldLabel:theme.formFieldLabel,
    crmXTable: theme.crmXTable,
    btnGroup:theme.btnGroup,
    crmXColWidth:{
       //"& tbody td:nth-child(1)":{width:"20%", textAlign:"center",},
       //"& tbody td:nth-child(2)":{width:"15%", textAlign:"center",},
       //"& tbody td:nth-child(3)":{width:"15%", textAlign:"center",},
       //"& tbody td:nth-child(4)":{width:"15%", textAlign:"center",},
       //"& tbody td:nth-child(5)":{width:"15%", textAlign:"center",},
       //"& tbody td:nth-child(6)":{width:"20%", textAlign:"center",},
    }
  };
}

export default customStyles;