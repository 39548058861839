import React, {Component}  from "react";
import {
  Grid,
  // LinearProgress,
  // Select,
  // OutlinedInput,
  // MenuItem,
  // Button
} from "@material-ui/core";


// components
// import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
// import { Typography } from "../../components/Wrappers/Wrappers";
// import Dot from "../../components/Sidebar/components/Dot";


// context
import { AppContext } from "../../context/AppContext";

// styles
import { withStyles } from "@material-ui/core/styles";
import Themes from "../../themes";
import customStyles from "./styles";
// const theme = Themes.default;
const styles = customStyles(Themes.default);



class Report extends Component {

  static contextType = AppContext;

  constructor(props) {
    super(props);
    if(!this.state) {
      this.state = {
        
      };
    }
  }



  componentDidMount(){
    var app = this.context;
    app.setState({sidebarMenuId:"report"});
  }
  
  render(){
    // global
    // var app = this.context;
    var self = this;
    const { classes } = self.props;

    return (
      <>
      <PageTitle title="Звіти"/>
      <Grid container spacing={4}>
       <Grid item lg={6} md={6} sm={6} xs={12}>
       <div className={classes.reportCard}>
            <a href="#">
               <h1 className="reportCardTitle">Відвантаження</h1>
            </a>
       </div>
       </Grid>
       <Grid item lg={6} md={6} sm={6} xs={12}>
       <div className={classes.reportCard}>
            <a href="#">
               <h1 className="reportCardTitle">Прибуток офісу</h1>
            </a>
       </div>
       </Grid>
       <Grid item lg={3} md={4} sm={6} xs={12}>
       
       </Grid>
      </Grid>
      </>
    );
  }

}
export default withStyles(styles)(Report);

