

const ConsumerModel = {


    loadConsumer: function(self, attributeName){
        return function() {
            var app = self.context;
            var onError = function(errorMessage) {
                self.setState({
                    "message": errorMessage,
                    "messageType": "error"
                });
            };
            var data = {"param": []};
            return app.postData(
                '/consumers/get',
                data
            ).then(function(responseJson){
                // console.log("responseJson", responseJson, responseJson["status"]);
                if(responseJson["status"] === "OK") {
                    let update = {};
                    update[attributeName] = responseJson["detail"];
                    self.setState(update);
                    return responseJson["detail"];
                } else if(responseJson["status"] === "NOT_FOUND") {
                    self.props.app.setState({
                        "message": responseJson["comment"],
                        "messageType": "error"
                    });
                    return [];
                } else {
                    onError(responseJson["comment"]);
                    return [];
                }
            }).catch(() => onError("Не вдалося завантажити клієнтів"));
        };
    },
    loadConsumerMap: function(self, attributeName){
        return function() {
            var app = this.context;
            var self = this;
            var onError = function(errorMessage) {
                self.setState({
                    "message": errorMessage,
                    "messageType": "error"
                });
            };
            var data = {"param": []};
            return app.postData(
                '/consumers/get',
                data
            ).then(function(responseJson){
                // console.log("responseJson", responseJson, responseJson["status"]);
                if(responseJson["status"] === "OK") {
                    let theMap = {};
                    for(let i=0; i<responseJson["detail"].length; i++){
                        theMap[responseJson["detail"][i].id] = responseJson["detail"][i];
                    }
                    let update = {};
                    update[attributeName] = theMap;
                    self.setState(update);
                    return  theMap;
                } else if(responseJson["status"] === "NOT_FOUND") {
                    self.props.app.setState({
                    "message": responseJson["comment"],
                    "messageType": "error"
                    });
                    return {};
                } else {
                    onError(responseJson["comment"]);
                    return {};
                }
            }).catch(() => onError("Не вдалося завантажити клієнтів"));
        };
    },
    loadOneConsumer: function(self, attributeName, onError) {
        return function(consumerId) {
            // console.log("consumerId", consumerId);
            var defaultRecord={
                id:0,
                name:"",
                mfo:"",
                okpo:"",
                rr:"",
                comment:"",
                deal_letters_address:"",
                nds:false
            };

            if(!consumerId) {
                return new Promise((resolve, reject) => {
                    setTimeout(
                        function(){
                            let update={};
                            update[attributeName]=defaultRecord;
                            self.setState(update);          
                            resolve(defaultRecord);
                        }, 100);
                });
            }
            var app = self.context;
            
            var data = {"param": [{"consumer_id": parseInt(consumerId)}]};
            return app.postData(
                '/consumers/get',
                data
            ).then(function(responseJson){
                // console.log("responseJson", responseJson, responseJson["status"]);
                if(responseJson["status"] === "OK") {
                    let update={};
                    update[attributeName]=responseJson["detail"][0];
                    self.setState(update);          
                    return responseJson["detail"][0];
                } else if(responseJson["status"] === "NOT_FOUND") {
                    let update=defaultRecord;
                    update[attributeName]=defaultRecord;
                    update["message"]=responseJson["comment"];
                    update["messageType"]="error";
                    self.setState(update); 
                    return defaultRecord;
                } else {
                    onError(responseJson["comment"]);
                    return defaultRecord;
                }
            }).catch(() => onError("Не вдалося завантажити клієнта"));
        };
    },

    defaultConsumerRecord: function() {
        return {
                id:0,
                name:"",
                mfo:"",
                okpo:"",
                rr:"",
                comment:"",
                deal_letters_address:"",
                nds:false
            };
    },

};


export default ConsumerModel;