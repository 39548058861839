import React, {Component}  from "react";
import {
  Grid,
  Button,
  Popover
} from "@material-ui/core";

import {Logger} from "react-remote-logger";

// components
import MUIDataTable from "mui-datatables";


import { AppContext } from "../../context/AppContext";
import ApiModel from "../../utils/ApiModel";
import {CrmButton} from "../../components/CrmButton/CrmButton";
import MessageBlock from '../../utils/MessageBlock';

import ConsumerInfo from "./components/ConsumerInfo/ConsumerInfo";
import ConsumerFromAuction from "./components/ConsumerFromAuction/ConsumerFromAuction";
import ConsumerContracts from "./components/ConsumerContracts/ConsumerContracts";
import ConsumerContractInfo from "./components/ConsumerContractInfo/ConsumerContractInfo";
import ConsumerContractSpecs from "./components/ConsumerContractSpecs/ConsumerContractSpecs";
import Shipments from "./components/Shipments/Shipments";
import ShipmentInfo from "./components/ShipmentInfo/ShipmentInfo";
import ShipmentDetail from "./components/ShipmentDetail/ShipmentDetail";

// styles
import { withStyles } from "@material-ui/core/styles";
import Themes from "../../themes";
import customStyles from "./styles";
const theme = Themes.default;
const styles = customStyles(theme);



class Consumer extends Component {

  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.views = {
      consumers:  "consumers",                                    // список клиентов
      consumerInfo:  "consumerInfo",                              // карточка клиента + список договоров
      consumerFromAuction:  "consumerFromAuction",                // создание клиента после завершения аукциона
      consumerContracts:  "consumerContracts",                  // договора клиента
      consumerContractInfo:  "consumerContractInfo",              // карточка договора + список отгрузок
      consumerContractSpecs:  "consumerContractSpecs",            // спецификация договора
      shipments : "shipments",
      shipmentInfo: "shipment_info",
      shipment_detail: "shipment_detail",
    }


    //TODO:
    this.columns=[
      { name: "id",      label: "id",      options: { filter: false, sort: false, }   },
      { name: "name",    label: "Назва",   options: { filter: false, sort: false, }   },
      { name: "mfo",     label: "МФО",     options: { filter: false, sort: false, }   },
      { name: "okpo",    label: "ЄДРПОУ",  options: { filter: false, sort: false, }   },
      { name: "rr",      label: "Рахунок", options: { filter: false, sort: false, }   },
      { name: "comment", label: "Примітки", options: { filter: false, sort: false, }   },
      { name: "deal_letters_address", label: "Адреса для листів", options: { filter: false, sort: false, }   },
      // { name: "nds", label: "Платник ПДВ", options: { filter: false, sort: false, }   },
      { name: "nds_view", label: "Платник ПДВ", options: { filter: false, sort: false, }   },
      { name: "action",  label: "Дії",     options: { filter: false, sort: false, }   },
    ];

    if(!this.state) {
      this.state = {
          view:"consumers",
          consumers:[],
          consumerId: null,
          consumerContractId: null,
          shipmentId: null,
          //auctionId:"77777",
          auctionId:"",
          auctionProzoroId:"",

          message:"",
          messageType:"",
          
      };
    }
    this.filterHash = JSON.stringify(this.props.filter);
    this.approveShipment = ApiModel.approveShipment(this, ApiModel.onError(this));
  }

  componentDidUpdate(){
    // var app = this.context;
    this.processCmd();

    var newFilterHash = JSON.stringify(this.props.filter);
    if(newFilterHash !==  this.filterHash) {
      this.filterHash = newFilterHash;
      this.loadConsumers();
    }
  }

  componentDidMount(){
    var app = this.context;
    var self = this;
    this.loadConsumers().finally(function(){
      var popupFormVisible = self.props.cmd && self.props.cmd[0]==='add';
      app.setState({
        sidebarMenuId:"consumer",
        view: self.views.providers,
        popupFormVisible:popupFormVisible,
      });
    });
  }

processCmd(){
    var self =this;
    var app = this.context;

    if(this.props.cmd.length > 0){
       for(let i=0; i<this.props.cmd.length; i++) {
          let cmd = this.props.cmd[i];
          // console.log("cmd", cmd);
          switch(cmd.action) {
            case "consumers":
              this.setState({
                view: self.views.consumers,
              });
            break;

            case "consumer_info":
              this.setState({
                view: self.views.consumerInfo,
                consumerId: cmd.consumerId || 0,
                message: cmd.message || "",
                messageType: cmd.messageType || "success",
              });
            break;

            case "from_auction":
              this.setState({
                view: self.views.consumerFromAuction,
                auctionId: cmd.auctionId || 0,
                auctionProzoroId: cmd.auctionProzoroId || 0,
                message: cmd.message || "",
                messageType: cmd.messageType || "success",
              });
            break;

            case "contracts":
              this.setState({
                view: self.views.consumerContracts,
                consumerId: cmd.consumerId || 0,
                message: cmd.message || "",
                messageType: cmd.messageType || "success",
              });
            break;

            case "contract_info":
              this.setState({
                view: self.views.consumerContractInfo,
                consumerId: cmd.consumerId || 0,
                consumerContractId: cmd.consumerContractId || 0,
                message: cmd.message || "",
                messageType: cmd.messageType || "success",
              });
            break;

            case "contract_specs":
              this.setState({
                view: self.views.consumerContractSpecs,
                consumerId: cmd.consumerId || 0,
                consumerContractId: cmd.consumerContractId || 0,
                message: cmd.message || "",
                messageType: cmd.messageType || "success",
              });
            break;


            case "shipments":
              this.setState({
                view: self.views.Shipments,
                consumerId: cmd.consumerId || 0,
                consumerContractId: cmd.consumerContractId || 0,
                message: cmd.message || "",
                messageType: cmd.messageType || "success",
              });
            break;

            case "shipment_info":
              this.setState({
                view: self.views.shipmentInfo,
                consumerId: cmd.consumerId || 0,
                consumerContractId: cmd.consumerContractId || 0,
                shipmentId: cmd.shipmentId || 0,
                message: cmd.message || "",
                messageType: cmd.messageType || "success",
              });
            break;

            case "shipment_approve":
              this.approveShipment(cmd.shipmentId, cmd.approved);
            break;


            case "shipment_detail":
              this.setState({
                view: self.views.shipmentDetail,
                consumerId: cmd.consumerId || 0,
                consumerContractId: cmd.consumerContractId || 0,
                shipmentId: cmd.shipmentId || 0,
                message: cmd.message || "",
                messageType: cmd.messageType || "success",
              });
            break;

            case "set_view":
              this.setState({
                view:cmd.view || self.views.consumers,
                consumerId: cmd.consumerId || 0,
                consumerContractId: cmd.consumerContractId || 0,
                shipmentId: cmd.shipmentId || 0,
                auctionId: cmd.auctionId || 0,
                auctionProzoroId: cmd.auctionProzoroId || 0,
                message: cmd.message || "",
                messageType: cmd.messageType || "success",
              });
            break;

            case "refresh":
              this.loadConsumers();
            break;

            default:
            break;
          }
       }
       app.setState( { cmd_consumer:[] });
    }
  }
  

  showConsumerInfoFactory(id) {
      var self=this;
      return function(event){
        self.setState({
          consumerId: id,
          view:self.views.consumerInfo,
        });
      }
  }


  loadConsumers(){
    var app = this.context;
    var self = this;
    const { classes } = self.props;

    var onError = function(errorMessage) {
          self.setState({
            "message":errorMessage,
            "messageType":"error",
          });
        };

    var filter = this.props.filter ;
    var param = {};
    if(filter.edrpou) param["edrpou"] = filter.edrpou;
    if(filter.consumerId) param["consumer_id"] = filter.consumerId;

    if(Object.keys(param).length > 0) {
        param = [param];
    } else {
        param = [];
    }

    return app.postData(
      '/consumers/get',
      {"param": param}
    ).then(function(responseJson){
      if(responseJson["status"] === "OK") {
        self.setState({
          "consumers": responseJson["detail"],
          "message":"",
          "messageType": ""
        });
      } else {
        self.setState({
          "consumers": [],
          "message":"Вибачте, записів не знайдено, спробуйте змінити умови пошуку ",
          "messageType": "error"
        });
        // onError(responseJson["comment"]);
      }
    }).catch(() => onError("Не вдалося завантажити перелік клієнтів"));
  }


  mapTable(){
    var self=this;
    var app = this.context;
    const { classes } = self.props;
    return function(row, ind){
      var elm =  JSON.parse(JSON.stringify(row));

      elm["nds_view"] = elm.nds ? "Так" : "Ні";

      // if(self.state.company_map[elm['company_id']]){
      //   let c =  self.state.company_map[elm['company_id']];
      //   elm['company_id_view'] =  c.name;
      // }else{
      //   elm['company_id_view'] =  elm['company_id'];
      // }

      // if(self.state.type_payments_map[elm['type_auction']]) {
      //   elm['type_auction_view'] =  self.state.type_payments_map[elm['type_auction']].name;
      // } else {
      //   elm['type_auction_view'] =  elm['type_auction'];
      // }
      
      // if(self.state.type_product_map[elm['type_product']]){
      //   elm['type_product_view'] =  self.state.type_product_map[elm['type_product']].name;
      // } else {
      //   elm['type_product_view'] =  elm['type_product'];
      // }

      // if(self.state.personal_map[elm['personal_id']]){
      //   let p = self.state.personal_map[elm['personal_id']];
      //   elm['personal_id_view'] = (<div>{p.fname} {p.sname} {p.lname}</div>)
      // } else {
      //   elm['personal_id_view'] = elm['personal_id'];
      // }
      
      // if(self.state.type_task_map[elm['type_task']]){
      //   elm['type_task_view'] =  self.state.type_task_map[elm['type_task']].name;
      // } else{
      //   elm['type_task_view'] =  elm['type_task'];
      // }

      // if(self.state.auction_result_map[elm['result']]){
      //   elm['result_view'] =  self.state.auction_result_map[elm['result']].name;
      // } else{
      //   elm['result_view'] =  elm['result'];
      // }

      // elm['status_play_view'] =  elm['status_play'] ? "Так" : "Ні";

      // <CrmButton
      //     classNameMain={classes.rowActionButton}
      //     classNameSattelite={classes.rowActionButtonSattelite}
      //     size="small"
      //     onClick={() => self.setState({
      //       consumerId: elm.id,
      //       view:"contracts"
      //     })}
      //   >
      //   Договори
      // </CrmButton>   

      elm['action'] = (<>
        <div className={classes.btnGroup}>
          <CrmButton
              classNameMain={classes.rowActionButton}
              classNameSattelite={classes.rowActionButtonSattelite}
              size="small"
              onClick={() => self.setState({
                view: self.views.consumerInfo,
                consumerId: elm.id,
              })}
            >
            Переглянути
          </CrmButton>
        </div>
      </>);
      return  elm;
    }
  }

  render(){
    // global
    // var app = this.context;
    // var filter = this.props.filter ;
    // console.log("receiver filter", filter);
    var self = this;
    const { classes } = self.props;

    return (
      <>
      {self.state.view === self.views.consumers &&
        <>
          <Grid container spacing={4}>
            {self.state.message && (<Grid item xs={12}>
              <MessageBlock message={self.state.message} messageType={self.state.messageType}/>
            </Grid>)}
            <Grid item xs={12}>
                <MUIDataTable
                  data={this.state.consumers.map( self.mapTable() )}
                  columns={self.columns}
                  options={{
                    filterType: "checkbox",
                    download:false,
                    print:false,
                    search:false,
                    filter:false,
                    viewColumns:false,
                    selectableRows: "none",
                    textLabels: ApiModel.textLabels,
                  }}
                  className={classes.crmXTable + ' ' + classes.crmXColWidth}
                />
            </Grid>
          </Grid>
        </>
      }

      {self.state.view === self.views.consumerFromAuction && (
        <ConsumerFromAuction auctionId={self.state.auctionId} auctionProzoroId={self.state.auctionProzoroId} parent={this}/>
      )} 
      {self.state.view === self.views.consumerInfo && (
        <ConsumerInfo consumerId={self.state.consumerId} parent={this}/>
      )} 
      {self.state.view === self.views.consumerContracts && (
        <ConsumerContracts consumerId={self.state.consumerId} parent={this} breadcrumbs={true}/>
      )} 
      {self.state.view === self.views.consumerContractInfo && (
        <ConsumerContractInfo consumerId={self.state.consumerId} consumerContractId={self.state.consumerContractId} parent={this}/>
      )} 
      {self.state.view === self.views.consumerContractSpecs && (
        <ConsumerContractSpecs consumerId={self.state.consumerId} consumerContractId={self.state.consumerContractId} parent={this}/>
      )}

      {self.state.view === self.views.shipments && (
        <Shipments
            consumerId={self.state.consumerId} 
            consumerContractId={self.state.consumerContractId} 
            breadcrumbs={true}
            parent={this}/>
      )} 
      {self.state.view === self.views.shipmentInfo && (
        <ShipmentInfo consumerId={self.state.consumerId} 
                      consumerContractId={self.state.consumerContractId} 
                      shipmentId={self.state.shipmentId}
                      parent={this}/>
      )}
      {self.state.view === self.views.shipmentDetail && (
        <ShipmentDetail consumerId={self.state.consumerId} 
                        consumerContractId={self.state.consumerContractId} 
                        shipmentId={self.state.shipmentId} 
                        breadcrumbs={true}
                        header={true}
                        parent={this}/>
      )}
      </>
    );
  }

}
export default withStyles(styles)(Consumer);

