

const drawerWidth = 260;

function customStyles(theme){
  return {
    menuButton: {
      marginLeft: 12,
      marginRight: 36,
    },
    hide: {
      display: "none",
    },
    spacerTop:{
      height:"62px"
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
    },
    drawerOpen: {
      width: drawerWidth,
      background:"none",
      border:"none",
      paddingLeft:"4px",
      paddingRight:"16px",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),

    },
    drawerClose: {
      background:"none",
      border:"none",
      paddingLeft:"4px",
      paddingRight:"16px",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: theme.spacing(7) + 40,
      [theme.breakpoints.down("sm")]: {
        width: drawerWidth,
      },
    },
    toolbar: {
      ...theme.mixins.toolbar,
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
      [theme.breakpoints.up("sm")]: {
        minHeight: "115px",
      },
      background:"none",
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    /* sidebarList: {
      marginTop: theme.spacing(6),
    }, */
    mobileBackButton: {
      marginTop: theme.spacing(0.5),
      marginLeft: 18,
      [theme.breakpoints.only("sm")]: {
        marginTop: theme.spacing(0.625),
      },
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
  };
} 



export default customStyles;