import React, {Component}  from "react";
import {
  Grid,
  Select,
  MenuItem,
  Button,
  TextField,
  TextareaAutosize,
  Checkbox,
  Link,
} from "@material-ui/core";
import moment from 'moment';
import DateTimePicker from '@mui/lab/DateTimePicker';
import { RadioGroup, Radio, FormControlLabel, FormControl, FormLabel} from '@mui/material';

// components
// import { Typography } from "../../../../components/Wrappers";
// import MUIDataTable from "mui-datatables";
// import validators from '../../validators';
import validators   from '../../../../utils/validators';
import MessageBlock from '../../../../utils/MessageBlock';
import {CrmDateTimePicker} from '../../../../components/CrmDateTimePicker/CrmDateTimePicker';
// context
import { AppContext } from "../../../../context/AppContext";
import ApiModel from "../../../../utils/ApiModel";
import regions   from '../../../../utils/regions_ua';

// styles
import { withStyles } from "@material-ui/core/styles";
import Themes from "../../../../themes";
import customStyles from "./styles";
// import { Alarm } from "@material-ui/icons";
const theme = Themes.default;
const styles = customStyles(theme);



/**
  id                  bigint(20)
  id_prozoro          text
  url_prozoro         text
  company_id          bigint(20) => companies
  type_auction        bigint(20) => type_payments
  type_product        bigint(20) => type_product
  waiting_total_deal  float
  region              text
  propositions_up_to  datetime
  date_auction        datetime
  member_price        float
  personal_id         bigint(20)  => employes
  comments            text
  deadline            datetime
  status_play         tinyint(1)
  comment_not_play    text
  type_task           bigint(20)  => type_task
 */
class AuctionInfo extends Component {

  static contextType = AppContext;
  constructor(props) {
    super(props);

    this.defaultTaskRecord = ApiModel.defaultTaskRecord();

    if(!this.state) {
      this.state = {
        auctionId:this.props.auctionId,
        auction:ApiModel.defaultAuctionRecord(),

        errors: {},
        message:"",
        messageType:"",

        company:[],
        type_payments:[],
        type_product:[],
        personal_tech:[],
        personal_nontech:[],
        filteredPersonal_tech:[],
        filteredPersonal_nontech:[],
        type_task:[],
        auction_result:[],
        default_task_status:{},
      };
    }
    var self =this;

    var nonEmpty = function(val) {
      if((val + '').trim().length === 0 ){
          return "Будь ласка, введіть значення";
      }
      return "";
    };
    var isPositiveFloat = function(val) {
      if(validators.isPositiveFloat(val) !== true ){
        return "Будь ласка, виберіть значення";
      }
      return "";
    }
    var isPositiveInteger = function(val) {
      if(validators.isPositiveInteger(val) !== true ){
        return "Будь ласка, виберіть значення";
      }
      return "";
    }
    this.validator = {
      id_prozoro:nonEmpty,                  //  text
      url_prozoro:nonEmpty,                 //  text
      company_id:isPositiveInteger,         //  bigint(20) => companies
      type_auction:isPositiveInteger,       //  bigint(20) => type_payments
      type_product:isPositiveInteger,       //  bigint(20) => type_product
      waiting_total_deal:isPositiveFloat,   //  float
      region:nonEmpty,                      //  text
      propositions_up_to:nonEmpty,          //  datetime
      // date_auction:nonEmpty,                //  datetime
      member_price:isPositiveFloat,         //  float
      // personal_id:"",                    //  bigint(20)  => employes
      // comments:"",                       //  text
      // deadline:nonEmpty,                    //  datetime
      // status_play:nonEmpty,                 //  tinyint(1)
      // comment_not_play:"",               //  text
      // type_task:"",                      //  bigint(20)  => type_task
      contractor_name:nonEmpty,
      contractor_okpo:nonEmpty,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeFactory = ApiModel.handleChangeFactory.bind(this);
    this.handleCheckboxFactory =this.handleCheckboxFactory.bind(this);
    this.loadTaskStatusDefault = ApiModel.loadTaskStatusDefault(this, "default_task_status");

    this.validate = ApiModel.validate(this);
    this.onError = ApiModel.onError(this);

    this.loadCompany = ApiModel.loadCompany(this, "company");
    this.loadTypePayments = ApiModel.loadTypePayments(this, "type_payments");
    this.loadTypeProduct = ApiModel.loadTypeProduct(this, "type_product");
    this.loadTaskType = ApiModel.loadTaskType(this, "type_task");
    this.loadAuctionResult  = ApiModel.loadAuctionResult(this, "auction_result");
    this.loadTechPersonal = ApiModel.loadPersonal(this, "personal_tech", "filteredPersonal_tech");
    this.loadAllPersonal = ApiModel.loadPersonal(this, "personal_nontech", "filteredPersonal_nontech");
    this.loadOneAuction = ApiModel.loadOneAuction(this, "auction", this.onError);
  }

  componentDidUpdate(){
    var self = this;
    if(self.props.auctionId !==  self.state.auctionId
    || self.props.auctionProzoroId !==  self.state.auctionProzoroId) {  
      self.setState({ 
        auctionId: self.props.auctionId,
        auctionProzoroId:self.props.auctionProzoroId,
      });
      self.loadOneAuction(self.props.auctionId, self.props.auctionProzoroId);
    }
    

    if(self.state.default_task_status 
       && self.state.default_task_status.id
       && self.defaultTaskRecord.task_status != self.state.default_task_status.id
       ) {
        self.defaultTaskRecord.task_status = parseInt(self.state.default_task_status.id) || 0;
    }
    

  }

  componentDidMount(){
    // var app = this.context;
    var self = this;
    self.setState({
        auctionId: self.props.auctionId,
    });
    self.loadAuctionResult();
    self.loadOneAuction(self.props.auctionId, self.props.auctionProzoroId).finally(function(){
      self.loadCompany().finally(function(){
        self.loadTypePayments().finally(function(){
          self.loadTypeProduct().finally(function(){
            self.loadTechPersonal({is_technical:true}).finally(function(){
              self.loadAllPersonal().finally(function(){
                self.loadTaskType().finally(function(){
                  self.loadTaskStatusDefault();
                });
              });
            });
          });
        });
      });
    });
  }

  handleDateChangeFactory(attributeName) {
      var self = this;
      return function(newValue) {
        // console.log(attributeName, newValue.format('YYYY-MM-DD HH:mm:ss'));
        // deep copy of the data
        var auction = JSON.parse(JSON.stringify(self.state.auction));
        
        if(newValue) {
          auction[attributeName] = newValue.format('YYYY-MM-DD HH:mm:ss');
        } else {
          auction[attributeName] = null;
        }
        

        // var err = self.validate(auction);
      
        self.setState({
            auction: auction,
            // errors: err.errors,
            message: "Дані змінено, але не збережено",
            messageType: "warning",
        });
      }
  }

  handleCheckboxFactory(entityName ,attr, i, subAttr) {
    var self = this;
    return function(event) {
      var entity = JSON.parse(JSON.stringify(self.state[entityName]));
      entity[attr][i][subAttr] = event.target.checked;
      var update = {};
      update[entityName] = entity;
      update["message"] = "Дані змінено, але не збережено";
      update["messageType"] = "warning";
      self.setState(update);
    };
  }

  handleSubmit(event) {
        event.preventDefault();
        var self = this;
        var app = this.context;
        
        let auction = JSON.parse(JSON.stringify(this.state.auction));

        if(self.state.auction.result) {
            self.setState({
              message:"Аукціон вже завершено. Ви не зможете змінити дані",
              messageType:"error",
            });
            return;
        }

        // do validation
        var err = self.validate(auction);

        if(err.errorExists) {
            self.setState({
              errors: err.errors,
              message:"Дані не збережено",
              messageType:"error",
            });
            return;
        }

        var onError = function(errorMessage) {
            self.setState({
              "message":errorMessage,
              "messageType":"error",
            });
          };
        let defaults = ApiModel.defaultAuctionRecord();
        
        let personal_to_auction = [];
        auction.task_to_aukcion = auction.task_to_aukcion || [];
        for (let i=0; i<auction.task_to_aukcion.length; i++) {
          let task = auction.task_to_aukcion[i];
          task.end_date = auction.propositions_up_to || defaults.propositions_up_to;
          personal_to_auction.push({
            personal_id:parseInt(task.personal_id)
          });                        
        }
        let postData = {
              id:parseInt(auction.id) || 0,                                   //  bigint(20)
              id_prozoro:auction.id_prozoro || defaults.id_prozoro,                             //  text
              url_prozoro:auction.url_prozoro || defaults.url_prozoro,                           //  text
              company_id:parseInt(auction.company_id || defaults.company_id),                   //  bigint(20) => companies
              type_auction:parseInt(auction.type_auction || defaults.type_auction),               //  bigint(20) => type_payments
              type_product:parseInt(auction.type_product || defaults.type_product),               //  bigint(20) => type_product
              waiting_total_deal:parseFloat(auction.waiting_total_deal || defaults.waiting_total_deal), //  float
              region:auction.region || defaults.region,                                     //  text
              propositions_up_to:auction.propositions_up_to || defaults.propositions_up_to,             //  datetime
              date_auction:auction.date_auction || defaults.date_auction,                         //  datetime
              member_price:parseFloat(auction.member_price || defaults.member_price),             //  float
              personal_id:parseInt(auction.personal_id || defaults.personal_id),                 //  bigint(20)  => employes
              comments:auction.comments || defaults.comments,                                 //  text
              deadline:auction.propositions_up_to || defaults.propositions_up_to,                                 //  datetime
              status_play:!!parseInt(auction.status_play || defaults.status_play),                 //  tinyint(1)
              comment_not_play:auction.comment_not_play || defaults.comment_not_play,                 //  text
              type_task:parseInt(auction.type_task || defaults.type_task),                     //  bigint(20)  => type_task
              contractor_name:auction.contractor_name || defaults.contractor_name,
              contractor_okpo: auction.contractor_okpo || defaults.contractor_okpo,
              result:auction.result || defaults.result,
              task_to_aukcion: auction.task_to_aukcion,
              personal_to_auction:personal_to_auction,
        };

        if(auction.id){
          console.log('updating', postData);

          app.postData(
              '/auction/edit',
              [postData]
          ).then(function(responseJson){
            if(responseJson["status"] === "OK") {
                self.setState({
                    "message": "Дані оновлено",
                    "messageType": "success"
                });
                for (let i=0; i<auction.task_to_aukcion.length; i++) {
                  let task = auction.task_to_aukcion[i];
                  if(parseInt(task.id) > 0){
                    continue;
                  }
                  let taskPostData = {
                    id: parseInt(task.id),
                    contractor_okpo: postData.contractor_okpo,
                    task_main_id:0, //  int
                    requester_admin_id:app.state.root_id,
                    requester_id: app.state.personal_id,
                    personal_id:parseInt(task.personal_id),                           //  text
                    start_date:task.start_date ,
                    end_date:postData.propositions_up_to,
                    comments:task.comments,
                    task_status:parseInt(task.task_status) || parseInt(self.state.default_task_status.id) || 0, //  int
                    type_task:parseInt(task.type_task) ,       //  int
                  };
                  app.postData(
                    '/tasks/add',
                    [taskPostData]
                  ).then(function(responseJson){
                    // console.log("responseJson", responseJson, responseJson["status"]);
                    if(responseJson["status"] === "OK") {
                      var task_id = responseJson["detail"][0];
                      app.postData(
                        '/aukcion_task/add',
                        {"task_id":task_id, "aukcion_id":auction.id,}
                      ).catch(() => onError("Невідома помилка"));
                    } else if(responseJson["status"] === "EXPECTATION_FAILED") {
                      onError("Не вдалося зберегти дані");
                    } else {
                      onError(responseJson["comment"]);
                    }
                  }).catch(() => onError("Невідома помилка"));
                }
                app.setState({cmd_auction:[
                  {action: 'reload'},
                  {action: 'closePopup'},
                ]});
            } else if(responseJson["status"] === "EXPECTATION_FAILED") {
                onError("Не вдалося зберегти дані");
            } else {
                onError(responseJson["comment"]);
            }
          }).catch(() => onError("Невідома помилка"));
        } else {
            console.log('creating', postData);
            app.postData(
                '/auction/add',
                [postData]
            ).then(function(responseJson){
                if(responseJson["status"] === "OK") {
                    var auction = Object.assign({}, self.state.auction);
                    auction.id = responseJson["detail"][0];
                    var auctionId = responseJson["detail"][0];
                    self.setState({
                        "auction": auction,
                        "message": "Дані збережено",
                        "messageType": "success"
                    });
                    for (let i=0; i<auction.task_to_aukcion.length; i++) {
                       let task = auction.task_to_aukcion[i];
                       let taskPostData = {
                        id: parseInt(task.id),
                        contractor_okpo: postData.contractor_okpo,
                        task_main_id:0, //  int
                        requester_admin_id:app.state.root_id,
                        requester_id: app.state.personal_id,
                        personal_id:parseInt(task.personal_id),                           //  text
                        start_date:task.start_date ,
                        end_date:postData.propositions_up_to,
                        comments:task.comments,
                        task_status:parseInt(task.task_status)  || parseInt(self.state.default_task_status.id) || 0, //  int
                        type_task:parseInt(task.type_task) ,       //  int
                      };
                      app.postData(
                          '/tasks/add',
                          [taskPostData]
                      ).then(function(responseJson){
                        // console.log("responseJson", responseJson, responseJson["status"]);
                        if(responseJson["status"] === "OK") {
                          var task_id = responseJson["detail"][0];
                          app.postData(
                            '/aukcion_task/add',
                            {"task_id":task_id, "aukcion_id":auctionId,}
                          ).then(function(){
                            app.setState({cmd_auction:[
                              {action: 'reloadDelayed'},
                            ]});
                          }).catch(() => onError("Невідома помилка"));
                        } else if(responseJson["status"] === "EXPECTATION_FAILED") {
                          onError("Не вдалося зберегти дані");
                        } else {
                          onError(responseJson["comment"]);
                        }
                      }).catch(() => onError("Невідома помилка"));
                    }
                    app.setState({cmd_auction:[
                      {action: 'reloadDelayed'},
                      {action: 'closePopup'},
                    ]});
                } else if(responseJson["status"] === "EXPECTATION_FAILED") {
                    onError("Не вдалося зберегти дані");
                } else {
                    onError(responseJson["comment"]);
                }
            }).catch(() => onError("Невідома помилка"));
        }
  }

  handleTableFactory(action, entity ,attr, i, subAttr) {
    var self = this;
    if(action === "update") {
      return function(event) {
        // console.log("update", attr, subAttr);
        event.preventDefault();
        var consumer = JSON.parse(JSON.stringify(self.state[entity])) || {};
        consumer[attr] = consumer[attr] || [];
        consumer[attr][i][subAttr] = event.target.value;
        var errors = JSON.parse(JSON.stringify(self.state.errors));

        let validatorName = attr + '.' + subAttr;
        if(self.validator[validatorName]){
          errors[attr] = errors[attr] || {};
          errors[attr]["@"+i] = errors[attr]["@"+i] || {};
          errors[attr]["@"+i][subAttr] = self.validator[validatorName](consumer[attr][i][subAttr]);
        }
        let update = {
          "message": "Дані змінено, але не збережено",
          "messageType": "warning",
          "errors": errors
        };
        update[entity] = consumer;
        self.setState(update);
      };
    }
    if(action === "delete") {
      return function(event) {
        event.preventDefault();
        var consumer = JSON.parse(JSON.stringify(self.state[entity])) || {};
        consumer[attr] = consumer[attr] || [];
        consumer[attr].splice(i, 1);
        let update = {
            "message": "Дані змінено, але не збережено",
            "messageType": "warning"
        };
        update[entity] = consumer;
        self.setState(update);
      };        
    }
    if(action === "add") {
      return function(event) {
        // console.log("add", attr, subAttr);
        event.preventDefault();
        var consumer = JSON.parse(JSON.stringify(self.state[entity])) || {};
        consumer[attr] = consumer[attr] || [];
        consumer[attr].push(subAttr);
        let update = {
            "message": "Дані змінено, але не збережено",
            "messageType": "warning"
        };
        update[entity] = consumer;
        self.setState(update);
      };
    }
  }

  // handleDeleteFactory() {
  //   var self = this;
  //   var app = this.context;
  //   return function(event) {
  //       event.preventDefault();
  //       var id = self.props.consumerId;
  //       if(id) {
  //           var onError = function(errorMessage) {
  //               self.setState({
  //                   "message":errorMessage,
  //                   "messageType":"error",
  //               });
  //           };
  //           var postData = [id];
  //           // post the DELETE command
  //           app.postData(
  //                   '/consumers/delete',
  //                   postData
  //               ).then(function(responseJson){
  //                   if(responseJson["status"] === "OK") {
  //                       self.props.parent.setState({
  //                         consumerId: null,
  //                         view:self.props.parent.views.consumers,
  //                       });
  //                   } else if(responseJson["status"] === "EXPECTATION_FAILED") {
  //                       onError("Не вдалося видалити дані");
  //                   } else {
  //                       onError(responseJson["comment"]);
  //                   }
  //               }).catch(() => onError("Невідома помилка"));
  //       }
  //   }
  // }

  getError(attr, i, subAttr){
      var errors = JSON.parse(JSON.stringify(this.state.errors));
      errors = errors[attr] || {};
      errors = errors["@"+i] || {};
      return errors[subAttr];
  }


  render(){
    // global
    var app = this.context;
    var self = this;
    const { classes } = self.props;

    return (<>
      <div>
        <Grid container spacing={3}>
          {false &&
            (<Grid item xs={7}>
              <div className={classes.topButtons}>
                { app.state.userType === "admin" &&
                  <>
                  <Button
                    variant="contained"
                    color="success"
                    size="large"
                    className={classes.submitButtonDanger}
                    // onClick={self.handleDeleteFactory(ind)}
                    >
                    Видалити
                  </Button>
                  &nbsp;
                  </>
                }
              </div>
            </Grid>) }


          {!!self.state.auction.result && (<Grid item xs={12}>
          <MessageBlock message="Аукціон вже завершено. Ви не зможете змінити дані" messageType="error"/>
          </Grid>)}

          {self.state.message && (<Grid item xs={12}>
          <MessageBlock message={self.state.message} messageType={self.state.messageType}/>
          </Grid>)}

          <Grid item xs={6} md={3}>
            <div className={classes.formFieldLabel}>
              ID в prozoro
            </div>
          </Grid>
          <Grid item xs={6} md={9}>
            <TextField
              value={self.state.auction.id_prozoro}
              onChange={self.handleChangeFactory("auction", "id_prozoro", self.validate)}
              margin="dense"
              placeholder="ID в prozoro"
              type="text"
              fullWidth
              variant="standard" 
              className={self.state.errors.id_prozoro ? classes.textFieldErr : classes.textField}
              />
          </Grid>

          <Grid item xs={6} md={3}>
            <div className={classes.formFieldLabel}>
              URL в prozoro
            </div>
          </Grid>
          <Grid item xs={5} md={8}>
            <TextField
              value={self.state.auction.url_prozoro}
              onChange={self.handleChangeFactory("auction", "url_prozoro", self.validate)}
              margin="dense"
              placeholder="URL в prosoro"
              type="text"
              fullWidth
              variant="standard" 
              className={self.state.errors.url_prozoro ? classes.textFieldErr : classes.textField}
              />
          </Grid>
          <Grid item xs={1} md={1}>
            <Link href={self.state.auction.url_prozoro} target="blank">
                    Відкрити
            </Link>
          </Grid>

          <Grid item xs={6} md={3}>
            <div className={classes.formFieldLabel}>
              Компанія
            </div>
          </Grid>
          <Grid item xs={6} md={9}>
              <Select
                value={self.state.auction.company_id || ''}
                onChange={self.handleChangeFactory("auction", "company_id", self.validate)}
                className={self.state.errors.company_id ? classes.selectFieldErr : classes.selectField}
              >
                <MenuItem value="" key={'company_id_X'} >-</MenuItem>
                {self.state.company.map(function(elm, ind){
                    return <MenuItem key={'company_id_' + ind} value={elm.id}  className={classes.selectOption}>{elm.name}</MenuItem>;
                })}
              </Select>
          </Grid>

          <Grid item xs={6} md={3}>
            <div className={classes.formFieldLabel}>
              Контрагент
            </div>
          </Grid>
          <Grid item xs={6} md={9}>
            <TextField
              value={self.state.auction.contractor_name}
              onChange={self.handleChangeFactory("auction", "contractor_name", self.validate)}
              margin="dense"
              placeholder="Контрагент"
              type="text"
              fullWidth
              variant="standard" 
              className={self.state.errors.contractor_name ? classes.textFieldErr : classes.textField}
              />
          </Grid>

          <Grid item xs={6} md={3}>
            <div className={classes.formFieldLabel}>
              ЄДРПОУ контрагента
            </div>
          </Grid>
          <Grid item xs={6} md={9}>
            <TextField
              value={self.state.auction.contractor_okpo}
              onChange={self.handleChangeFactory("auction", "contractor_okpo", self.validate)}
              margin="dense"
              placeholder="ЄДРПОУ контрагента"
              type="text"
              fullWidth
              variant="standard" 
              className={self.state.errors.contractor_okpo ? classes.textFieldErr : classes.textField}
              />
          </Grid>


          <Grid item xs={6} md={3}>
            <div className={classes.formFieldLabel}>
              Вид аукціону
            </div>
          </Grid>
          <Grid item xs={6} md={9}>
              <Select
                value={self.state.auction.type_auction || ''}
                onChange={self.handleChangeFactory("auction", "type_auction", self.validate)}
                className={self.state.errors.type_auction ? classes.selectFieldErr : classes.selectField}
              >
                <MenuItem value="" key={'type_auction_X'} >-</MenuItem>
                {self.state.type_payments.map(function(elm, ind){
                    return <MenuItem key={'type_auction_' + ind} value={elm.id}  className={classes.selectOption}>{elm.name}</MenuItem>;
                })}
              </Select>
          </Grid>


          <Grid item xs={6} md={3}>
            <div className={classes.formFieldLabel}>
              Тип товару
            </div>
          </Grid>
          <Grid item xs={6} md={9}>
              <Select
                value={self.state.auction.type_product || ''}
                onChange={self.handleChangeFactory("auction", "type_product", self.validate)}
                className={self.state.errors.type_product ? classes.selectFieldErr : classes.selectField}
              >
                <MenuItem value="" key={'type_product_X'} >-</MenuItem>
                {self.state.type_product.map(function(elm, ind){
                    return <MenuItem key={'type_product_' + ind} value={elm.id}  className={classes.selectOption}>{elm.name}</MenuItem>;
                })}
              </Select>
          </Grid>

          <Grid item xs={6} md={3}>
            <div className={classes.formFieldLabel}>
              Очікувана вартість всієї закупівлі
            </div>
          </Grid>
          <Grid item xs={6} md={9}>
            <TextField
              value={self.state.auction.waiting_total_deal}
              onChange={self.handleChangeFactory("auction", "waiting_total_deal", self.validate)}
              margin="dense"
              placeholder="Очікувана сума"
              type="text"
              fullWidth
              variant="standard" 
              className={self.state.errors.waiting_total_deal ? classes.textFieldErr : classes.textField}
              />
          </Grid>

          <Grid item xs={6} md={3}>
            <div className={classes.formFieldLabel}>
              Регіон
            </div>
          </Grid>
          <Grid item xs={6} md={9}>
              <Select
                value={self.state.auction.region || ''}
                onChange={self.handleChangeFactory("auction", "region", self.validate)}
                className={self.state.errors.region ? classes.selectFieldErr : classes.selectField}
              >
                <MenuItem value="" key={'region_X'} >-</MenuItem>
                {regions.map(function(elm, ind){
                    return <MenuItem key={'region_' + ind} value={elm}  className={classes.selectOption}>{elm}</MenuItem>;
                })}
              </Select>
          </Grid>


          <Grid item xs={6} md={3}>
            <div className={classes.formFieldLabel}>
              Прийом пропозицій до
            </div>
          </Grid>
          <Grid item xs={6} md={9}>
            <CrmDateTimePicker
              renderInput={(props) => <TextField {...props} />}
              label="Прийом пропозицій до"
              value={self.state.auction.propositions_up_to}
              onChange={self.handleDateChangeFactory("propositions_up_to")}
              margin="dense"
              placeholder="Прийом пропозицій до"
              variant="standard" 
              className={self.state.errors.propositions_up_to ? classes.textFieldErr : classes.textField}
            />
          </Grid>

          <Grid item xs={6} md={3}>
            <div className={classes.formFieldLabel}>
              Дата аукціону
            </div>
          </Grid>
          <Grid item xs={6} md={9}>
            <CrmDateTimePicker
              renderInput={(props) => <TextField {...props} />}
              label="Дата аукціона"
              value={self.state.auction.date_auction}
              onChange={self.handleDateChangeFactory("date_auction")}
              margin="dense"
              placeholder="Дата аукціона"
              variant="standard" 
              className={self.state.errors.date_auction ? classes.textFieldErr : classes.textField}
            />
          </Grid>

          <Grid item xs={6} md={3}>
            <div className={classes.formFieldLabel}>
              Вартість участі
            </div>
          </Grid>
          <Grid item xs={6} md={9}>
            <TextField
              value={self.state.auction.member_price}
              onChange={self.handleChangeFactory("auction", "member_price", self.validate)}
              margin="dense"
              placeholder="Вартість участі"
              type="text"
              fullWidth
              variant="standard" 
              className={self.state.errors.member_price ? classes.textFieldErr : classes.textField}
              />
          </Grid>






          <Grid item xs={6} md={3}>
            <div className={classes.formFieldLabel}>
              Завдання
            </div>
          </Grid>

          <Grid item item xs={6} md={9}>
            <div className={classes.crmxCard}>
                <Grid container spacing={3}>
                {this.state.auction.task_to_aukcion && this.state.auction.task_to_aukcion.map(function(object, i){
                    return (<>
                      
                        <Grid item xs={1}>
                          <div className={classes.formFieldLabel}>{i+1}</div>
                        </Grid>
                        <Grid item xs={9}>
                          <FormControlLabel control={
                            <Checkbox 
                              checked={object.is_technical}
                              onChange={self.handleCheckboxFactory("auction","task_to_aukcion", i, "is_technical")}
                            />
                            } label="Завдання для технічного спеціаліста" 
                          />
                        </Grid>
                        <Grid item xs={2}>
                          {object.id === 0 &&
                            <div>
                              <button className={classes.rowActionButtonRed}
                                  onClick={self.handleTableFactory("delete", "auction","task_to_aukcion", i, "telephone")}
                              >Видалити</button>
                              <button className={classes.rowActionButtonSattelite}
                                  onClick={self.handleTableFactory("delete", "auction","task_to_aukcion", i, "telephone")}
                              >&gt;</button>
                            </div>
                          }
                        </Grid>

                        <Grid item xs={1}>&nbsp;</Grid>
                        <Grid item xs={5}>
                          <div className={classes.formFieldLabel}>
                            Завдання для співробітника
                          </div>
                        </Grid>
                        <Grid item xs={6}>
                          {object.is_technical &&
                            <Select
                              key={"task_personal_id_"+i}
                              value={object.personal_id || ''}
                              onChange={object.id === 0 ? self.handleTableFactory("update", "auction","task_to_aukcion", i, "personal_id") : () => 0}
                              className={self.getError("task_to_aukcion", i, "personal_id") ? classes.selectFieldErr : classes.selectField}
                            >
                              <MenuItem value="" key={'personal_X'} >-</MenuItem>
                              {self.state.personal_tech.map(function(elm, ind){
                                  return <MenuItem key={'personal_' + ind} value={elm.id}  className={classes.selectOption}>{elm.fname} {elm.sname} {elm.lname}</MenuItem>;
                              })}
                            </Select>
                          }
                          {!object.is_technical &&
                            <Select
                              key={"task_personal_id_"+i}
                              value={object.personal_id || ''}
                              onChange={object.id === 0 ? self.handleTableFactory("update", "auction","task_to_aukcion", i, "personal_id") : () => 0}
                              className={self.getError("task_to_aukcion", i, "personal_id") ? classes.selectFieldErr : classes.selectField}
                            >
                              <MenuItem value="" key={'personal_X'} >-</MenuItem>
                              {self.state.personal_nontech.map(function(elm, ind){
                                  return <MenuItem key={'personal_' + ind} value={elm.id}  className={classes.selectOption}>{elm.fname} {elm.sname} {elm.lname}</MenuItem>;
                              })}
                            </Select>
                          }

                        </Grid>

                        <Grid item xs={1}>
                          &nbsp;
                        </Grid>
                        <Grid item xs={5}>
                          <div className={classes.formFieldLabel}>
                            Тип завдання
                          </div>
                        </Grid>
                        <Grid item xs={6}>
                            <Select
                              key={"task_type_task_"+i}
                              value={object.type_task || ''}
                              onChange={object.id === 0 ? self.handleTableFactory("update", "auction","task_to_aukcion", i, "type_task") : () => 0}
                              className={self.getError("task_to_aukcion", i, "type_task") ? classes.selectFieldErr : classes.selectField}
                            >
                              <MenuItem value="" key={'type_task_X'} >-</MenuItem>
                              {self.state.type_task.map(function(elm, ind){
                                  return <MenuItem key={'type_task_' + ind} value={elm.id}  className={classes.selectOption}>{elm.name}</MenuItem>;
                              })}
                            </Select>
                        </Grid>


                        <Grid item xs={1}>&nbsp;</Grid>
                        <Grid item xs={5}>
                          <div className={classes.formFieldLabel}>
                            Коментар до завдання
                          </div>
                        </Grid>
                        <Grid item xs={6}>
                          <TextareaAutosize
                            minRows={3}
                            style={{width:"100%"}}
                            value={object.comments}
                            onChange={object.id === 0 ? self.handleTableFactory("update", "auction","task_to_aukcion", i, "comments") : () => 0}
                            margin="dense"
                            placeholder="Примітки"
                            type="text"
                            variant="standard" 
                            className={self.getError("task_to_aukcion", i, "comments") ? classes.textFieldErr : classes.textField}
                            />
                        </Grid>

                    </>);
                  })}

                  {!self.state.auction.result &&
                    <Grid item xs={12}>
                      <div  className={classes.columnAction}>
                        <button className={classes.rowActionButton}
                                onClick={self.handleTableFactory("add", "auction", "task_to_aukcion", null, JSON.parse(JSON.stringify(self.defaultTaskRecord)))}
                        >Додати завдання</button>
                        <button className={classes.rowActionButtonSattelite}
                                onClick={self.handleTableFactory("add", "auction", "task_to_aukcion", null,  JSON.parse(JSON.stringify(self.defaultTaskRecord)))}
                        >&gt;</button>
                      </div>
                    </Grid>
                  }
                
                </Grid>


            </div>

          </Grid>

          <Grid item xs={12}>
            <div className={classes.formButtons}>
              <Button
                variant="contained"
                color="success"
                size="large"
                className={classes.submitButtonSattelite}
                onClick={() => self.props.parent.setState({popupFormVisible:false})}
                >
                &nbsp;
              </Button>
              <Button
                variant="contained"
                color="success"
                size="large"
                className={classes.submitButton}
                onClick={() => self.props.parent.setState({popupFormVisible:false})}
                >
                Закрити
              </Button>
              
              {!self.state.auction.result &&
                <>
                  <Button
                    variant="contained"
                    color="success"
                    size="large"
                    className={classes.submitButtonSattelite}
                    onClick={self.handleSubmit}
                    >
                    &nbsp;
                  </Button>
                  <Button
                    variant="contained"
                    color="success"
                    size="large"
                    className={classes.submitButton}
                    onClick={self.handleSubmit}
                    >
                    Зберегти
                  </Button>
                </>
              }
            </div>
          </Grid>
      </Grid>
      </div>
    </>);
    
  }
}
export default withStyles(styles)(AuctionInfo);

