import React, {Component}  from "react";
import {
  Grid,
  Select,
  Button,
  TextField,
  MenuItem,
  TextareaAutosize,
  FormControlLabel,
  Checkbox,
  Link,
} from "@material-ui/core";
import moment from 'moment';

// components
// import { Typography } from "../../../../components/Wrappers";
import validators   from '../../../../utils/validators';
import MessageBlock from '../../../../utils/MessageBlock';
import { AppContext } from "../../../../context/AppContext";
import ApiModel from "../../../../utils/ApiModel";
import {CrmDateTimePicker, CrmDatePicker} from '../../../../components/CrmDateTimePicker/CrmDateTimePicker';
import Shipments from "../Shipments/Shipments";
import {CrmButton} from "../../../../components/CrmButton/CrmButton";

// styles
import { withStyles } from "@material-ui/core/styles";
import Themes from "../../../../themes";
import customStyles from "./styles";


const theme = Themes.default;
const styles = customStyles(theme);




class ConsumerContractInfo extends Component {

  static contextType = AppContext;
  constructor(props) {
    super(props);

    this.deafultRecord =  JSON.stringify(ApiModel.defaultConsumerContract());

    if(!this.state) {
      this.state = {
        consumerId:false,
        consumer:{},

        consumerContractId:false,
        consumerContract:{},
        errors:{},

        company:[],
        personal:[],
        type_product:[],

        message:"",
        messageType:"",
      };
    }
    // var self =this;

    this.validator={
      consumer_id: ApiModel.atomicValidator.isPositiveInteger,
      contract_number: ApiModel.atomicValidator.nonEmpty,
      contract_date: ApiModel.atomicValidator.nonEmpty,
      real_summary: ApiModel.atomicValidator.isPositiveFloat,
      company_id: ApiModel.atomicValidator.isPositiveInteger, // text
      personal_id: ApiModel.atomicValidator.isPositiveInteger, // text      
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeFactory = ApiModel.handleChangeFactory2(this);
    this.handleCheckboxFactory = ApiModel.handleCheckboxFactory2(this);
    this.handleDateChangeFactory = ApiModel.handleDateChangeFactory2(this);
    this.validate = ApiModel.validate(this).bind(this);
    this.onError = ApiModel.onError(this).bind(this);

    this.loadOneConsumer = ApiModel.loadOneConsumer(this, "consumer", this.onError);
    this.loadOneConsumerContract = ApiModel.loadOneConsumerContract(this, "consumerContract", this.onError);
    this.loadTypeProduct  = ApiModel.loadTypeProduct(this, "type_product");
    this.loadCompany  = ApiModel.loadCompany(this, "company");
    this.loadPersonal  = ApiModel.loadPersonal(this, "personal");
}

  componentDidUpdate(){
    if(this.props.consumerId !==  this.state.consumerId
    || this.props.consumerContractId !==  this.state.consumerContractId) {
        var self = this;
        this.setState({
            consumerId: this.props.consumerId,
            consumerContractId: this.props.consumerContractId,
        });
        self.loadOneConsumerContract(this.props.consumerId, this.props.consumerContractId).finally(function(){
            self.loadOneConsumer(this.props.consumerId);
        });
    }    
  }

  componentDidMount(){
    // var app = this.context;
    
    var self = this;
    // var app = self.context;
    // console.log(self.props);
    self.setState({
        consumerId: self.props.consumerId,
        consumerContractId: self.props.consumerContractId,
    });
    self.loadOneConsumerContract(self.props.consumerId, self.props.consumerContractId).finally(function(){
        self.loadOneConsumer(self.props.consumerId).finally(function(){
            self.loadPersonal().finally(function(){
                self.loadCompany().finally(function(){
                  self.loadTypeProduct();
                });
            });
        });
    });
  }




  handleSubmit(event) {
        event.preventDefault();
        var self = this;
        var app = this.context;
        
        let consumerContract = JSON.parse(JSON.stringify(this.state.consumerContract));

        consumerContract.consumer_id = self.props.consumerId;

        // do validation
        var err = self.validate(consumerContract);

        if(err.errorExists) {
            self.setState({
              errors: err.errors,
              message:"Дані не збережено",
              messageType:"error",
            });
            return;
        }

        var onError = ApiModel.onError(self);

        if(consumerContract.id>0){
          
          let postData = {
              id: parseInt(self.props.consumerContractId),
              auction_id: parseInt(consumerContract.auction_id) || 0,
              type_product: parseInt(consumerContract.type_product) || 0,
              consumer_id: parseInt(self.props.consumerId),
              contract_number:consumerContract.contract_number,
              contract_date:consumerContract.contract_date,
              real_summary:parseFloat(consumerContract.real_summary),
              company_id: parseInt(consumerContract.company_id),
              personal_id: parseInt(consumerContract.personal_id),
          };
          // console.log('updating consumerContract', postData);
          app.postData(
              '/consumers/contracts/edit',
              [postData]
              ).then(function(responseJson){
                // console.log("responseJson", responseJson, responseJson["status"]);
                if(responseJson["status"] === "OK") {
                    self.setState({
                        "message": "Дані оновлено",
                        "messageType": "success"
                    });
                } else if(responseJson["status"] === "EXPECTATION_FAILED") {
                    onError("Не вдалося зберегти дані. 4");
                } else {
                    onError(responseJson["comment"] || "Не вдалося зберегти дані. 5");
                }
              }).catch(() => onError("Не вдалося зберегти дані. 6"));
        } else {
            let postData = {
                id: 0,
                auction_id: parseInt(consumerContract.auction_id) || 0,
                type_product: parseInt(consumerContract.type_product) || 0,
                consumer_id: parseInt(self.props.consumerId),
                contract_number:consumerContract.contract_number,
                contract_date:consumerContract.contract_date,
                real_summary:parseFloat(consumerContract.real_summary),
                company_id: parseInt(consumerContract.company_id),
                personal_id: parseInt(consumerContract.personal_id),
            };

            // console.log('creating consumerContract', postData);
            app.postData(
                '/consumers/add',
                [postData]
            ).then(function(responseJson){
                // console.log("responseJson", responseJson, responseJson["status"]);
                if(responseJson["status"] === "OK") {
                    var consumerContract = Object.assign({}, self.state.consumerContract);
                    consumerContract.id = responseJson["detail"][0];
                    self.setState({
                        "consumerContractId": consumerContract.id,
                        "consumerContract": consumerContract,
                        "message": "Дані збережено",
                        "messageType": "success"
                    });
                } else if(responseJson["status"] === "EXPECTATION_FAILED") {
                    onError("Не вдалося зберегти дані. 1");
                } else {
                    onError(responseJson["comment"] || "Не вдалося зберегти дані. 2");
                }
            }).catch(() => onError("Не вдалося зберегти дані. 3"));
        }
  }



  render(){
    // global
    var app = this.context;
    var self = this;
    const { classes } = self.props;

    return (<>
      <div>
        <Grid container spacing={4}>

            {self.state.message && (
                <Grid item xs={12}>
                <MessageBlock message={self.state.message} messageType={self.state.messageType}/>
                </Grid>
            )}
            <Grid item xs={12}>
              <div className={classes.breadCrumbs}>
                <Link 
                  onClick={() => app.setState({cmd_consumer:[
                        {
                          action: 'consumers',
                        },
                      ]})
                  }>Клієнти</Link>
                /
                <Link 
                  onClick={() => app.setState({cmd_consumer:[
                        {
                          action: 'consumer_info', 
                          "consumerId": self.state.consumer.id,
                        },
                      ]})
                  }>{self.state.consumer.name || "Невідомий клієнт"}</Link>
                {false && 
                <>
                  /
                  <Link 
                    onClick={() => app.setState({cmd_consumer:[
                          {
                            action: 'contracts', 
                            "consumerId": self.state.consumer.id,
                          },
                        ]})
                    }>Договори</Link>
                </>
                }
                / Договір {self.state.consumerContract.contract_number} 
              </div>
            </Grid>

            <Grid item xs={4}>
                <div className={classes.formFieldLabel}>
                Номер договору
                </div>
            </Grid>
            <Grid item xs={8}>
                <TextField
                value={self.state.consumerContract.contract_number}
                onChange={self.handleChangeFactory("consumerContract","contract_number")}
                margin="dense"
                placeholder="Номер договору"
                type="text"
                fullWidth
                variant="standard" 
                className={self.state.errors.contract_number ? classes.textFieldErr : classes.textField}
                />
            </Grid>

            <Grid item xs={4}>
                <div className={classes.formFieldLabel}>
                Дата договору
                </div>
            </Grid>
            <Grid item xs={8}>
                <CrmDateTimePicker
                renderInput={(props) => <TextField {...props} />}
                // label="Дата договору"
                value={self.state.consumerContract.contract_date}
                onChange={self.handleDateChangeFactory("consumerContract", "contract_date")}
                margin="dense"
                placeholder="Дата договору"
                variant="standard" 
                className={self.state.errors.contract_date ? classes.textFieldErr : classes.textField}
                />
            </Grid>

            <Grid item xs={4}>
                <div className={classes.formFieldLabel}>
                Сума договору
                </div>
            </Grid>
            <Grid item xs={8}>
                <TextField
                value={self.state.consumerContract.real_summary}
                onChange={self.handleChangeFactory("consumerContract", "real_summary")}
                margin="dense"
                placeholder="Сума договору"
                type="text"
                fullWidth
                variant="standard" 
                className={self.state.errors.real_summary ? classes.textFieldErr : classes.textField}
                />
            </Grid>


            <Grid item xs={4}>
                <div className={classes.formFieldLabel}>
                    Тип товару
                </div>
            </Grid>
            <Grid item xs={8}>
                <Select
                    value={self.state.consumerContract.type_product || ''}
                    onChange={self.handleChangeFactory("consumerContract", "type_product")}
                    className={self.state.errors.type_product ? classes.selectFieldErr : classes.selectField}
                >
                    <MenuItem value="" key={'type_product_X'} >-</MenuItem>
                    {self.state.type_product.map(function(elm, ind){
                        return <MenuItem key={'type_product_' + ind} value={elm.id}  className={classes.selectOption}>{elm.name}</MenuItem>;
                    })}
                </Select>
            </Grid>


            <Grid item xs={4}>
                <div className={classes.formFieldLabel}>
                    Компанія
                </div>
            </Grid>
            <Grid item xs={8}>
                <Select
                    value={self.state.consumerContract.company_id || ''}
                    onChange={self.handleChangeFactory("consumerContract", "company_id")}
                    className={self.state.errors.company_id ? classes.selectFieldErr : classes.selectField}
                >
                    <MenuItem value="" key={'company_id_X'} >-</MenuItem>
                    {self.state.company.map(function(elm, ind){
                        return <MenuItem key={'company_id_' + ind} value={elm.id}  className={classes.selectOption}>{elm.name}</MenuItem>;
                    })}
                </Select>
            </Grid>

            <Grid item xs={4}>
                <div className={classes.formFieldLabel}>
                    Відповідальний менеджер
                </div>
            </Grid>
            <Grid item xs={8}>
                <Select
                    value={self.state.consumerContract.personal_id || ''}
                    onChange={self.handleChangeFactory("consumerContract", "personal_id")}
                    className={self.state.errors.personal_id ? classes.selectFieldErr : classes.selectField}
                >
                    <MenuItem value="" key={'personal_id_X'} >-</MenuItem>
                    {self.state.personal.map(function(elm, ind){
                        return <MenuItem key={'personal_id_' + ind} value={elm.id}  className={classes.selectOption}>{elm.fname} {elm.sname} {elm.lname}</MenuItem>;
                    })}
                </Select>
            </Grid>

          <Grid item xs={12}>
            <div className={classes.formButtons} style={{textAlign:"center"}}>

              <Button
                variant="contained"
                color="success"
                size="large"
                className={classes.submitButtonSattelite}
                onClick={self.handleSubmit}
                >
                &nbsp;
              </Button>
              <Button
                variant="contained"
                color="success"
                size="large"
                className={classes.submitButton}
                onClick={self.handleSubmit}
                >
                Зберегти
              </Button>
            </div>
          </Grid>
        </Grid>

        {self.state.consumerContractId > 0 &&
          <div>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <h1>Відвантаження</h1>
            </Grid>
            <Grid item xs={6}>
              <div style={{"textAlign":"right", "marginTop":"28px"}}>
                <CrmButton
                    classNameMain={classes.rowActionButton}
                    classNameSattelite={classes.rowActionButtonSattelite}
                    size="small"
                    onClick={
                      () => app.setState( { cmd_consumer:[
                            {
                              action: "shipment_info",
                              consumerId: self.props.consumerId || 0,
                              consumerContractId: self.props.consumerContractId || 0,
                              shipmentId: 0
                            }
                          ]})
                    }
                  >
                  Додати відвантаження
                </CrmButton>
              </div>
            </Grid>
          </Grid>
          <Shipments 
              consumerId={self.state.consumerId} 
              consumerContractId={self.state.consumerContractId} 
              breadcrumbs={false}
              parent={this}/>
          </div>
        }
      </div>
    </>);
    
  }
}
export default withStyles(styles)(ConsumerContractInfo);

