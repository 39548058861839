// import moment from 'moment';

const UnitsModel = {


    loadUnits : function(self, attributeName, onError){
        return function() {
            var app = self.context;
            // get list of deal_status
            return app.postData(
                '/units/get',
                {"param": []}
            ).then(function(responseJson){
            // console.log("responseJson", responseJson, responseJson["status"]);
            if(responseJson["status"] === "OK") {
                let update = {};
                update[attributeName] = responseJson["detail"];
                self.setState(update);
                return responseJson["detail"];
            } else {
                onError(responseJson["comment"]);
                return [];
            }
            }).catch(() => onError("Не вдалося завантажити одиниці виміру"));
        };
    },



};


export default UnitsModel;