// import moment from 'moment';

const PersonalModel = {

    loadPersonal : function(self, personal, filteredPersonal){
        return function(filter){
            var onError = function(errorMessage) {
                self.setState({
                    "message":errorMessage,
                    "messageType":"error",
                });
            };

            var app = self.context;
            // get list of product types
            let param;
            if(filter){
                param = [filter]
            } else {
                param = [];
            }

            return app.postData(
                '/personal/get',
                {"param": param}
            ).then(function(responseJson){
                // console.log("responseJson", responseJson, responseJson["status"]);
                if(responseJson["status"] === "OK") {
                    var items = responseJson["detail"].map(elm => 
                        ({value: elm.id, 
                        label: elm.fname + " " + elm.sname + " " + elm.lname,
                        id:elm.id,
                        fname: elm.fname,
                        sname:elm.sname,
                        lname:elm.lname,
                        }) );
                    items.sort((a, b) => a.label.localeCompare(b.label));

                    let update = {};
                    update[personal] = items;
                    update[filteredPersonal] = items;
                    self.setState(update);
                } else {
                    onError(responseJson["comment"]);
                }
            }).catch(() => onError("Невідома помилка"));
        };
    },
    loadPersonalMap : function(self, attributeName){
        return function() {
            var app = self.context;
            var onError = function(errorMessage) {
                self.setState({
                    "message": errorMessage,
                    "messageType": "error"
                });
            };
            var data = {"param": []};
            return app.postData(
                '/personal/get',
                data
            ).then(function(responseJson){
            // console.log("responseJson", responseJson, responseJson["status"]);
            if(responseJson["status"] === "OK") {
                let theMap = {};
                for(let i=0; i<responseJson["detail"].length; i++){
                    theMap[responseJson["detail"][i].id] = responseJson["detail"][i];
                }
                let update = {};
                update[attributeName] = theMap;
                self.setState(update);
                return theMap;
            } else if(responseJson["status"] === "NOT_FOUND") {
                self.props.app.setState({
                "message": responseJson["comment"],
                "messageType": "error"
                });
                return {};
            } else {
                onError(responseJson["comment"]);
                return {};
            }
            }).catch(() => onError("Не вдалося завантажити співробітників"));
        };
    },



    loadAdminMap : function(self, attributeName){
        return function() {
            var app = self.context;
            var onError = function(errorMessage) {
                self.setState({
                    "message": errorMessage,
                    "messageType": "error"
                });
            };
            var data = {"param": []};
            return app.postData(
                '/admin/get',
                data
            ).then(function(responseJson){
                // console.log("responseJson", responseJson, responseJson["status"]);
                if(responseJson["status"] === "OK") {
                    let theMap = {};
                    for(let i=0; i<responseJson["detail"].length; i++){
                        theMap[responseJson["detail"][i].id] = responseJson["detail"][i];
                    }
                    let update = {};
                    update[attributeName] = theMap;
                    self.setState(update);
                    return theMap;
                } else if(responseJson["status"] === "NOT_FOUND") {
                    self.props.app.setState({
                        "message": responseJson["comment"],
                        "messageType": "error"
                    });
                    return {};
                } else {
                    onError(responseJson["comment"]);
                    return {};
                }
            }).catch(() => onError("Не вдалося завантажити адміністраторів"));
        };
    },


};


export default PersonalModel;