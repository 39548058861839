import React, {Component}  from "react";
import {
  Grid,
  Link,
  //LinearProgress,
  //Select,
  //OutlinedInput,
  //MenuItem,
  Button,
  //TextField,
  //Popover
} from "@material-ui/core";
//import moment from 'moment';

// components
// import { Typography } from "../../../../components/Wrappers";
import MUIDataTable from "mui-datatables";

// context
import { AppContext } from "../../../../context/AppContext";

// import validators   from '../../../../utils/validators';
import MessageBlock from '../../../../utils/MessageBlock';
// styles
import { withStyles } from "@material-ui/core/styles";
import Themes from "../../../../themes";
import customStyles from "./styles";
// import { Alarm } from "@material-ui/icons";
const theme = Themes.default;
const styles = customStyles(theme);

class ContractAppendixes extends Component {

  static contextType = AppContext;

  constructor(props) {
    super(props);
    // var self = this;
    this.columns=[
      {
        name: "date_contract",
        label: "Дата",
        options: { filter: false, sort: false, }
      },
      {
        name: "summary",
        label: "Сума",
        options: { filter: false, sort: false, }
      },
      {
        name: "determinate_days",
        label: "Відстрочка платежу",
        options: { filter: false, sort: false, }
      },
      {
        name: "dop_agreement_number",
        label: "Номер",
        options: { filter: false, sort: false, }
      },
      {
        name: "deal_status_view",
        label: "Статус",
        options: { filter: false, sort: false, }
      },
      {
        name: "action",
        label: "Дії",
        options: { filter: false, sort: false, }
      },

    ];
    if(!this.state) {
      this.state = {
          providerId:"",
          provider:{},
          contract_number:"",
          contract:{},
          appendix:[],

          message:"",
          messageType:"",

          deal_status:[],
      };
    }
    this.mapTable = this.mapTable.bind(this);
  }

  componentDidUpdate(){
    console.log("componentDidUpdate()");
    if(this.props.providerId !==  this.state.providerId 
       || this.props.contract_number !==  this.state.contract_number) {
      this.setState({
        providerId: this.props.providerId,
        contract_number: this.props.contract_number,
      });
      var self = this;
      self.loadProvider().finally(function(){
        self.loadContract().finally(function(){  
          self.loadAppendix();
        });
      });
    }    
  }

  componentDidMount(){
    // var app = this.context;
    var self = this;
    this.setState({
      providerId: this.props.providerId,
      contract_number: this.props.contract_number,
    });
    self.loadProvider().finally(function(){
      self.loadContract().finally(function(){
        self.loadAppendix().finally(function(){
          self.loadDealStatus();
        });
      });
    });
  }

  loadProvider(){
    var app = this.context;
    var self = this;
    var onError = function(errorMessage) {
        self.setState({
            "message": errorMessage,
            "messageType": "error"
        });
    };
    var data = {"param": [{"provider_id": parseInt(this.props.providerId)}]};
    return app.postData(
        '/providers/get',
        data
      ).then(function(responseJson){
          // console.log("responseJson", responseJson, responseJson["status"]);
          if(responseJson["status"] === "OK") {
              let data = responseJson["detail"][0];            
              self.setState({
                  "provider": data
              });
          } else if(responseJson["status"] === "NOT_FOUND") {
              self.props.app.setState({
                  "message": responseJson["comment"],
                  "messageType": "error"
              });
          } else {
              onError(responseJson["comment"]);
          }
    }).catch(() => onError("Не вдалося завантажити постачальника"));
  }

  loadContract() {
    var app = this.context;
    var self = this;
    var onError = function(errorMessage) {
      self.setState({
        "message": errorMessage,
        "messageType": "error"
      });
    };
    var data = {"param": [{"contract_number": self.props.contract_number}]};
    return app.postData(
      '/providers_contract/get',
      data
    ).then(function(responseJson){
      // console.log("responseJson", responseJson, responseJson["status"]);
      if(responseJson["status"] === "OK") {
        let data = responseJson["detail"][0];            
        self.setState({
          "contract": data
        });
      } else if(responseJson["status"] === "NOT_FOUND") {
        self.props.app.setState({
          "message": responseJson["comment"],
          "messageType": "error"
        });
      } else {
        onError(responseJson["comment"]);
      }
    }).catch(() => onError("Не вдалося завантажити договір"));
  }

  loadAppendix(){
    var app = this.context;
    var self = this;
    var onError = function(errorMessage) {
        self.setState({
          "message":errorMessage,
          "messageType":"error",
        });
    };
    var data = {"param": [{"contract_number": self.props.contract_number}]};
    return app.postData(
      '/provider_additional_agreement/get',
      data
    ).then(function(responseJson){
      // console.log("responseJson", responseJson, responseJson["status"]);
      if(responseJson["status"] === "OK") {
        let data = responseJson["detail"];            
        self.setState({
          "appendix": data,
        });
      } else if(responseJson["status"] === "NOT_FOUND") {
        self.props.app.setState({
          "message": responseJson["comment"],
          "messageType": "error"
        });
      } else {
        onError(responseJson["comment"]);
      }
    }).catch(() => onError("Не вдалося завантажити спеціфікацію"));
  }

  loadDealStatus(){
    var app = this.context;
    var self = this;
    var onError = function(errorMessage) {
          self.setState({
            "message":errorMessage,
            "messageType":"error",
          });
        };
    return app.postData(
    '/deal_status/get',
    {"param": []}
    ).then(function(responseJson){
    // console.log("responseJson", responseJson, responseJson["status"]);
    if(responseJson["status"] === "OK") {
        var the_map = {};
        responseJson["detail"].map(function(elm){
            the_map[elm.id] = elm.name;
        })
        self.setState({
        "deal_status": the_map
        });
    } else {
        onError(responseJson["comment"]);
    }
    }).catch(() => onError("Невідома помилка"));
  }


  mapTable(){
    var self=this;
    const { classes } = self.props;
    return function(row, ind){
      var elm =  JSON.parse(JSON.stringify(row));
      elm['deal_status_view'] =  self.state.deal_status[elm['deal_status_id']] || elm['deal_status_id'];

      elm['action'] = (<div className={classes.btnGroup}>
                          <Button
                            variant="contained"
                            color="success"
                            size="large"
                            className={classes.rowActionButton}
                            onClick={() => self.props.parent.setState({
                                view:self.props.parent.views.contractAppendixInfo,
                                appendixId: elm.id
                            })}
                          >
                          Переглянути
                        </Button>
                          <Button
                            variant="contained"
                            color="success"
                            size="large"
                            className={classes.rowActionButtonSattelite}
                            onClick={() => self.props.parent.setState({
                                view:self.props.parent.views.contractAppendixInfo,
                                appendixId: elm.id
                            })}
                          >
                          &gt;
                        </Button>
                      </div>);
      return  elm;
    }
  }


  handleDeleteFactory(ind) {
      var self = this;
      var app = this.context;
      return function(event) {
          event.preventDefault();

          var id = self.state.specification[ind].id;

          if(id) {
              var onError = function(errorMessage) {
                  self.setState({
                      "message":errorMessage,
                      "messageType":"error",
                  });
              };
              var postData = [id];
              // post the DELETE command
              app.postData(
                    '/provider_contract_specification/delete',
                    postData
                  ).then(function(responseJson){
                      if(responseJson["status"] === "OK") {
                          self.setState({
                              "message": "Дані оновлено",
                              "messageType": "success"
                          });
                          self.loadSpecification(false);
                      } else if(responseJson["status"] === "EXPECTATION_FAILED") {
                          onError("Не вдалося зберегти дані");
                      } else {
                          onError(responseJson["comment"]);
                      }
                  }).catch(() => onError("Невідома помилка"));
          } else {
              var specification = JSON.parse(JSON.stringify(self.state.specification));
              specification.splice(ind, 1);
              self.setState({
                  "specification": specification
              });
          }
      }
  }

  render(){
    // global
    // var app = this.context;
    var self = this;
    const { classes } = self.props;

    // console.log(self.state.provider);
    return (
      <>

      <div>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <div className={classes.breadCrumbs}>
                  <Link href="javascript:void(0)" onClick={() => self.props.parent.setState({
                    providerId: null,
                    view:self.props.parent.views.providers,
                  })}>Постачальники</Link>
                  / 
                  <Link href="javascript:void(0)" onClick={() => self.props.parent.setState({
                    view:self.props.parent.views.providerInfo,
                  })}>{self.state.provider.name}</Link>
                  / 
                  <Link href="javascript:void(0)" onClick={() => self.props.parent.setState({
                    view:self.props.parent.views.contracts,
                  }
                  )}>Договори</Link>
                  / 
                  <Link href="javascript:void(0)" onClick={() => self.props.parent.setState({
                    view:self.props.parent.views.contractInfo,
                    contract_number :self.state.contract_number,
                  }
                  )}>{self.state.contract_number}</Link>
                  / Додаткові угоди
            </div>
          </Grid>
          <Grid item xs={7}>
            <h1 className={classes.pageTitle}>Додаткові угоди "{self.state.contract_number}"</h1>
          </Grid>
          <Grid item xs={5}>
            { false && <Button
                color="success"
                // onClick={self.handleChangeFactory("all", -1)}
                className={classes.submitButton}
                >Додати рядок</Button>
            }
          </Grid>
          <MessageBlock message={self.state.message} messageType={self.state.messageType}/>
          <Grid item xs={12}>

            <MUIDataTable
              data={this.state.appendix.map( self.mapTable() )}
              columns={self.columns}
              options={{
                filterType: "checkbox",
                download:false,
                print:false,
                search:false,
                filter:false,
                viewColumns:false,
                selectableRows: "none",
                textLabels: {
                    body: {
                      noMatch: "Вибачте, записів не знайдено",
                      toolTip: "Впорядкувати",
                      columnHeaderTooltip: column => `Впорядкувати за ${column.label}`
                    },
                    pagination: {
                      next: "Наступна сторінка",
                      previous: "Попередня сторінка",
                      rowsPerPage: "Рядків на сторінці:",
                      displayRows: "із",
                    },
                    toolbar: {
                      search: "Знайти",
                      downloadCsv: "Скачати CSV",
                      print: "Надрукувати",
                      viewColumns: "Показаи колонки",
                      filterTable: "Фільтрувати таблицю",
                    },
                    filter: {
                      all: "Всі",
                      title: "Фільтри",
                      reset: "Очистити",
                    },
                    viewColumns: {
                      title: "Показати колонки",
                      titleAria: "Показати/Приховати колонки",
                    },
                    selectedRows: {
                      text: "рідків вибрано",
                      delete: "Видалити",
                      deleteAria: "Видалити вибрані рядки",
                    },
                  }
              }}
              className={classes.crmXTable + ' ' + classes.crmXColWidth}
            />
          </Grid>
        </Grid>
      </div>


      </>
    );
  }
}
export default withStyles(styles)(ContractAppendixes);

