import React, { Component } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  Radio,
  RadioGroup,
  TextField,
  FormControlLabel,
  Fade,
  FormLabel
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
// import classnames from "classnames";

// logo
// import logo from "./logo.svg";
// import google from "../../images/google.svg";

// context
import { AppContext } from "../../context/AppContext";

// styles
import { withStyles } from "@material-ui/core/styles";
import Themes from "../../themes";
import customStyles from "./styles";
var styles = customStyles(Themes.default);



class Login extends Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    if(!this.state) {
      this.state = {
        smsIsSent: false,
        message:"",
        error:"",
        isLoading:false,
        activeTabId:0,
        loginValue:'',
        passwordValue:'',
        user_full_name:'',
      };
    }

    this.userType = {user:'user', admin: 'admin'};
    this.handleSubmitTelephone = this.handleSubmitTelephone.bind(this);
    this.handleSubmitSmsCode = this.handleSubmitSmsCode.bind(this);
  }

  setUserType(id){
    var app = this.context;
    app.setState({userType: this.userType[id]});
  }


  setLoginValue(val) {
    this.setState({loginValue: val});
  }

  setPasswordValue(val) {
    this.setState({passwordValue: val});
  }

  setIsLoading(val) {
    this.setState({isLoading: val});
  }

  
  handleSubmitTelephone(event) {
    var app = this.context;
    if(this.state.loginValue.length > 0){       
      var self = this;
      var onError = function(errorMessage) {
        self.setState({
          smsIsSent: false, 
          message: '',
          error: errorMessage,
          passwordValue:'',
        });
        app.setState({
          isAuthorized:false,
          authToken: "",
          root_id:null,
          personal_id:null,
        });
      };
      // let newState = Object.assign({}, this.state);
      // newState.smsIsSent = true;
      self.setState({message:"", error:""});

      var data = {"telephone":self.state.loginValue,"type":app.state.userType};
      app.postData(
        '/auth_personal/check_login',
        data
      ).then(function(responseJson){
        if(responseJson["status"] === "OK"){
          self.setState({
            smsIsSent: true, 
            message: responseJson["comment"],
            error: '',
          });
          app.setState({
            isAuthorized:false,
            authToken: "",
            root_id: responseJson["root_id"] || 0,
            personal_id:responseJson["personal_id"] || 0,
          });
        } else {
          onError(responseJson["comment"]);
        }
      }).catch(() => onError("Невідома помилка"));
    } else {
      //TODO: handle validation errors
    }
    event.preventDefault();
  }

  handleSubmitSmsCode(event) {
    var app = this.context;

    if(this.state.loginValue.length > 0 && this.state.passwordValue.length > 0) {

      var self = this;
      var onError = function(errorMessage) {
        self.setState({
          smsIsSent: false, 
          message: '',
          error: errorMessage,
          userFullName:'',
        });
        app.setState({
          isAuthorized:false,
          authToken: "",
          root_id:null,
          personal_id:null,
          userFullName:null,
        });
      };
      self.setState({message:"", error:""});


      var data = {"password": this.state.passwordValue,"type":app.state.userType};
      if(app.state.personal_id) {
        data["personal_id"] = app.state.personal_id;
      }
      if(app.state.root_id) {
        data["root_id"] = app.state.root_id;
      }
      app.postData(
        '/auth_personal/check_password',
        data
      ).then(function(responseJson){
        if(responseJson["status"] === "OK"){
          app.setState({
            isAuthorized:true,
            authToken: responseJson.token,
            root_id: responseJson.root_id || 0,
            personal_id:responseJson.personal_id || 0,
            userFullName: responseJson.name,
          });
        } else {
          onError(responseJson["comment"]);
        }
      }).catch(() => onError("Невідома помилка"));
      
    } else {
      //TODO: handle validation errors
    }
    event.preventDefault();
  }


  render() {
     
    // var app = this.context;
    const { classes } = this.props;
    
    var self = this;

    var app = this.context;

    return (
      <Grid container className={classes.container}>
        <div className={classes.formContainer}>
            <div className={classes.formBox}>
                { app.state.userType === "admin"
                    ? (<>
                      <Typography variant="h4" className={classes.subGreeting}>
                        Вхід адміністратора
                      </Typography>
                    </>)
                    : (<>
                      <Typography variant="h4" className={classes.subGreeting}>
                        Вхід користувача
                      </Typography>
                    </>)
                }
                <Fade in={!!self.state.error}>
                  <Typography color="secondary" className={classes.errorMessage}>
                    Не вдалося увійти. Можливо, ви вказали помилковий номер телефона або пароль
                  </Typography>
                </Fade>
                <Fade in={!!self.state.message}>
                  <Typography color="secondary" className={classes.infoMessage}>
                    {self.state.message}
                  </Typography>
                </Fade>

                {self.state.smsIsSent
                 ?(
                    <>
                      <FormLabel className={classes.loginFormLabel}>Пароль</FormLabel>
                      <TextField
                        id="password"
                        InputProps={{
                          classes: {
                            underline: classes.textFieldUnderline,
                            input: classes.textField,
                          },
                        }}
                        value={self.state.passwordValue}
                        onChange={e => self.setPasswordValue(e.target.value)}
                        margin="normal"
                        placeholder="Пароль"
                        type="password"
                        fullWidth
                      />

                      <div className={classes.formButtons}>
                        <Button
                          disabled={
                            false && self.state.passwordValue.length === 0
                          }
                          // className={classes.loginFormButton}
                          onClick={self.handleSubmitSmsCode}
                          variant="contained"
                          size="large"
                          className={classes.formButtonsLogin}
                        >
                        Увійти
                        </Button>
                      </div>
                    </>
                 )
                 :(
                  <>
                    <FormLabel className={classes.loginFormLabel}>Номер телефона</FormLabel>
                    <TextField
                      id="email"
                      InputProps={{
                        classes: {
                          underline: classes.textFieldUnderline,
                          input: classes.textField,
                        },
                      }}
                      value={self.state.loginValue}
                      onChange={e => self.setLoginValue(e.target.value)}
                      margin="normal"
                      placeholder="Номер телефона"
                      type="text"
                      fullWidth
                    />
                    <RadioGroup  className={classes.formUserTypes} value={app.state.userType}>
                      <FormControlLabel value="user" control={<Radio />}  label="Користувач" onChange={(e, id) => self.setUserType("user")}/>
                      <FormControlLabel value="admin" control={<Radio />} label="Адміністратор" onChange={(e, id) => self.setUserType("admin")}/>
                    </RadioGroup>
                    <div className={classes.formButtons}>
                      <Button
                          disabled={
                            false && self.state.loginValue.length === 0 
                          }
                          onClick={self.handleSubmitTelephone}
                          variant="contained"
                          color="success"
                          size="large"
                          className={classes.formButtonsGetPassword}
                        >
                        Отримати пароль
                      </Button>
                    </div>
                  </>
                 )
              }
              <div className={classes.formButtons}>
                {self.state.isLoading && (
                  <CircularProgress size={26} className={classes.loginLoader} />
                )}
              </div>

          </div>
        </div>
      </Grid>
    );
  }

}



export default withStyles(styles)(withRouter(Login));
