function AppStorage(_storageName) {
	
	this.storageName = _storageName;
	this.appReadStorage = function(){
    	return JSON.parse(window.localStorage.getItem(this.storageName));
	};
	this.appWriteStorage = function(newDataObject){
	    window.localStorage.setItem(this.storageName, JSON.stringify(newDataObject));
	};
}


export default AppStorage;