import React, {Component}  from "react";
import {
  Grid,
  Select,
  MenuItem,
  Button,
  // TextField,
  // TextareaAutosize,
  // Link,
} from "@material-ui/core";
import moment from 'moment';
// import DateTimePicker from '@mui/lab/DateTimePicker';
// import DatePicker from '@mui/lab/DatePicker';


// components
// import MUIDataTable from "mui-datatables";
// import validators from '../../validators';
import validators   from '../../../../utils/validators';
import MessageBlock from '../../../../utils/MessageBlock';
// context
import { AppContext } from "../../../../context/AppContext";
import ApiModel from "../../../../utils/ApiModel";
// import regions   from '../../../../utils/regions_ua';

// styles
import { withStyles } from "@material-ui/core/styles";
import Themes from "../../../../themes";
import customStyles from "./styles";
// import { Alarm } from "@material-ui/icons";
const theme = Themes.default;
const styles = customStyles(theme);



/**
id: <int>
				task_main_id:<int>
				requester_id:<int>
				personal_id:<int>
				start_date: <date>
				end_date:<datetime>
				comments:<text>
				task_status:<int>
 */
class TaskStatus extends Component {

  static contextType = AppContext;
  constructor(props) {
    super(props);
    let now = moment().format('YYYY-MM-DD HH:mm:ss');
    this.defaultRecord=JSON.stringify({
      id:0,                                   //  bigint(20)
      task_main_id:this.props.parentTaskId,   //  bigint(20)
      requester_id:0,   //  bigint(20)
      personal_id:0,                          //  bigint(20) => companies
      start_date:now,                         //  datetime
      end_date:now,                           //  datetime
      comments:"",                            //  text
      task_status:0,                          //  bigint(20) => type_payments
    });

    if(!this.state) {
      this.state = {
        taskId:this.props.taskId,
        task:{},

        errors: {},
        message:"",
        messageType:"",

        personal:[],              // list of persons
        task_status:[],           // list of task statuses
      };
    }
    // var self =this;

    // var nonEmpty = function(val) {
    //   if((val + '').trim().length === 0 ){
    //       return "Будь ласка, введіть значення";
    //   }
    //   return "";
    // };

    var isPositiveInteger = function(val) {
      if(validators.isPositiveInteger(val) !== true ){
        return "Будь ласка, виберіть значення";
      }
      return "";
    }
    this.validator = {
      // task_main_id:isPositiveInteger,
      // requester_id:isPositiveInteger,
      // personal_id:isPositiveInteger,
      // start_date:nonEmpty, 
      // end_date:nonEmpty, 
      // comments:nonEmpty, 
      task_status:isPositiveInteger,
     };

     this.handleSubmit = this.handleSubmit.bind(this);
     this.loadTaskStatus = ApiModel.loadTaskStatus(this, "task_status");
     this.handleChangeFactory = ApiModel.handleChangeFactory.bind(this);
     this.loadOneTask = ApiModel.loadOneTask(this, "task");
     this.validate = ApiModel.validate(this);
  }

  componentDidUpdate(){
    if(this.props.taskId !==  this.state.taskId) {
      var self = this;
      this.setState({ taskId: this.props.taskId,  });
      self.loadOneTask();
    }    
  }

  componentDidMount(){
    // var app = this.context;
    var self = this;
    self.setState({
        taskId: this.props.taskId,
    });
    self.loadOneTask().finally(function(){
      self.loadTaskStatus();
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    var self = this;
    var app = this.context;
    
    let task = JSON.parse(JSON.stringify(this.state.task));

    // do validation
    console.log('validate', task);
    var err = self.validate(task);

    if(err.errorExists) {
        self.setState({
          errors: err.errors,
          message:"Виправте дані",
          messageType:"error",
        });
        return;
    }

    var onError = function(errorMessage) {
        self.setState({
          "message":errorMessage,
          "messageType":"error",
        });
      };
    let defaults = JSON.parse(this.defaultRecord);
    let postData = {
      id:parseInt(task.id) || 0,                                 //  int
      task_status:parseInt(task.task_status) || defaults.task_status, //  int
    };
    if(task.id){
      console.log('updating', postData);
      app.postData(
        '/tasks/status',
        [postData]
        ).then(function(responseJson){
        // console.log("responseJson", responseJson, responseJson["status"]);
        if(responseJson["status"] === "OK") {
            self.setState({
                "message": "Дані оновлено",
                "messageType": "success"
            });
        } else if(responseJson["status"] === "EXPECTATION_FAILED") {
            onError("Не вдалося зберегти дані");
        } else {
            onError(responseJson["comment"]);
        }
        }).catch(() => onError("Невідома помилка"));
    } else {
        onError("Не знайдено завдання")
    }
  }

  render(){
    // global
    var app = this.context;
    var self = this;
    const { classes } = self.props;

    return (<>
      <div>
        <Grid container spacing={3}>

          {self.state.message && (<Grid item xs={12}>
          <MessageBlock message={self.state.message} messageType={self.state.messageType}/>
          </Grid>)}

          <Grid item xs={12}><h1>Змінити  статус завдання</h1></Grid>


          <Grid item xs={6} md={3}>
            <div className={classes.formFieldLabel}>
              Статус завдання
            </div>
          </Grid>
          <Grid item xs={6} md={9}>
              <Select
                value={self.state.task.task_status || ''}
                onChange={self.handleChangeFactory("task", "task_status", false)}
                className={self.state.errors.task_status ? classes.selectFieldErr : classes.selectField}
              >
                <MenuItem value="" key={'task_status_X'} >-</MenuItem>
                {self.state.task_status.filter(x => x.name !== "Завершено").map(function(elm, ind){
                    return <MenuItem key={'task_status_' + ind} value={elm.id}  className={classes.selectOption}>{elm.name}</MenuItem>;
                })}
              </Select>
          </Grid>



          <Grid item xs={12}>
            <div className={classes.formButtons}>
              <Button
                variant="contained"
                color="success"
                size="large"
                className={classes.submitButtonSattelite}
                onClick={() => app.setState( { cmd_task:[{action:"closePopup"}] })}
                >
                &nbsp;
              </Button>
              <Button
                variant="contained"
                color="success"
                size="large"
                className={classes.submitButton}
                onClick={() => app.setState( { cmd_task:[{action:"closePopup"}] })}
                >
                Закрити
              </Button>
              
              <Button
                variant="contained"
                color="success"
                size="large"
                className={classes.submitButtonSattelite}
                onClick={self.handleSubmit}
                >
                &nbsp;
              </Button>
              <Button
                variant="contained"
                color="success"
                size="large"
                className={classes.submitButton}
                onClick={self.handleSubmit}
                >
                Зберегти
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
    </>);
    
  }
}
export default withStyles(styles)(TaskStatus);

