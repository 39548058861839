import React, { Component } from "react";
import {
  // Collapse,
  // Divider,
  List,
  ListItem,
  // ListItemIcon,
  // ListItemText,
  // TextField,
  // FormLabel,  
  // FormControlLabel,
  // FormControl,
  // Select,
  // Typography,
  // Box,
  // Button,
  // MenuItem,
  // InputLabel,
} from "@material-ui/core";
// import {
//   Search as SearchIcon,
// } from "@material-ui/icons";

// import { Inbox as InboxIcon } from "@material-ui/icons";
// import { Link } from "react-router-dom";
// import classnames from "classnames";

import { AppContext } from "../../../../context/AppContext";

// styles
import { withStyles } from "@material-ui/core/styles";
import Themes from "../../../../themes";
import customStyles from "./styles";
var theme = Themes.default;
var styles = customStyles(theme);

class ReportFilter extends Component {

  static contextType = AppContext;

  constructor(props) {
    super(props);
    if(!this.state) {
      this.state = {
        auctionId: "",
        edrpou:"",
        auctionUrl:"",
        dateMin:"",
        dateMax:"",
        typePayments:"",
        typeProduct:"",
        type_products:[],
        type_payments:[],
      };
    }
    this.setStateProperty = this.setStateProperty.bind(this);
  }

  setStateProperty(key, val) { 
    var upd={};
    upd[key]=val;
    this.setState(upd);
    console.log(key, val, this.state);
  }

  componentDidMount(){
     var self = this;
        var onError = function(errorMessage) {
          self.setState({
            "message":errorMessage,
            "messageType":"error",
          });
        };

        var app = this.context;
        // get list of product types
        app.postData(
        '/type_products/get',
        {"param": []}
        ).then(function(responseJson){
        // console.log("responseJson", responseJson, responseJson["status"]);
        if(responseJson["status"] === "OK") {
            self.setState({
            "type_products": responseJson["detail"]
            });
        } else {
            onError(responseJson["comment"]);
        }
        }).catch(() => onError("Невідома помилка"));

        // get list of payment types

        app.postData(
        '/type_payments/get',
        {"param": []}
        ).then(function(responseJson){
        // console.log("responseJson", responseJson, responseJson["status"]);
        if(responseJson["status"] === "OK") {
            self.setState({
            "type_payments": responseJson["detail"]
            });
        } else {
            onError(responseJson["comment"]);
        }
        }).catch(() => onError("Невідома помилка"));

  }

  render(){
    var {classes} = this.props;
    // global
    // var app = this.context;
    // var self= this;


    return (
        <>
          <List className={classes.sidebarList}>

            <ListItem
                className={classes.sidebarListItem}
              >
              
              
            </ListItem>

          </List>
        </>
        );

  }

}

export default withStyles(styles)(ReportFilter);

