

const ShipmentModel = {

    loadShipments: function(self, attributeName, onError) {
        return function(consumerContractId) {
            var app = self.context;
            // const { classes } = self.props;

            var param = {};
            param["consumer_contract_id"] = consumerContractId;

            if(Object.keys(param).length > 0) {
                param = [param];
            } else {
                param = [];
            }

            return app.postData(
                '/consumers/contracts/shipments/get',
                {"param": param}
            ).then(function(responseJson){
                if(responseJson["status"] === "OK") {
                    let update={};
                    update[attributeName]=responseJson["detail"];
                    self.setState(update);   
                    return responseJson["detail"];
                } else {
                    onError(responseJson["comment"]);
                    return [];
                }
            }).catch(() => onError("Невідома помилка"));
        };
    },

    loadOneShipment: function(self, attributeName, onError) {
        return function(shipmentId) {
            var app = self.context;
            // const { classes } = self.props;

            var param = {};
            param["shipments_id"] = shipmentId;

            if(Object.keys(param).length > 0) {
                param = [param];
            } else {
                param = [];
            }

            return app.postData(
                '/consumers/contracts/shipments/get',
                {"param": param}
            ).then(function(responseJson){
                if(responseJson["status"] === "OK") {
                    let update={};
                    update[attributeName]=responseJson["detail"][0];
                    self.setState(update);
                    return responseJson["detail"][0];
                } else {
                    onError(responseJson["comment"]);
                    return {};
                }
            }).catch(() => onError("Невідома помилка"));
        };
    },


    defaultShipmentRecord: function() {
        return {
            id:0,
            consumer_id:0,
            consumer_contract_id:0,
            emplyoes_id:0,
            summary:0.0,
            create_day:"",
            approved:false
        };
    },

    loadOneShipmentDetail:  function(self, attributeName, onError) {
        return function(shipmentId){
            var app = self.context;
            var data = {"param": [{"shipment_id": shipmentId}]};

            if(!shipmentId) {
                return new Promise((resolve, reject) => {
                    setTimeout(() => {
                        let updates = {};
                        updates[attributeName] = [];
                        self.setState(updates);
                        resolve([]);
                    }, 100);
                });
            }
            return app.postData(
                '/consumers/contracts/shipments/detail/get',
                data
            ).then(function(responseJson){
                if(responseJson["status"] === "OK") {
                    let updates = {};
                    updates[attributeName] = responseJson["detail"];
                    self.setState(updates);
                    return updates[attributeName];
                } else if(responseJson["status"] === "NOT_FOUND") {
                    self.onError(responseJson["comment"]);
                    return [];
                } else {
                    self.onError(responseJson["comment"]);
                    return [];
                }
            }).catch((reason) => self.onError(reason || "Не вдалося завантажити спеціфікацію"));
        }
    },

    approveShipment: function(self, onError) {
        /**
            POST '/consumers/contracts/shipments/approve'
            [{
                id:0
                approved: true|false
            }]

            Ответ
            {
                "status": "OK"
            }
        */
        return function(shipmentId, approved){
            var app = self.context;
            var data = {"param": [{
                id: shipmentId,
                approved:approved
            }]};
            if(!shipmentId) {
                return new Promise((resolve, reject) => {
                    data['status'] = "NOT_FOUND";
                    setTimeout(() => {
                        resolve(data);
                    }, 100);
                });
            }

            return app.postData(
                '/consumers/contracts/shipments/approve',
                data
            ).then(function(responseJson){
                if(responseJson["status"] === "OK") {
                    self.setState({
                        "message": "Дані оновлено",
                        "messageType": "success"
                    });
                    data['status'] = responseJson["status"];
                    return data;
                } else if(responseJson["status"] === "NOT_FOUND") {
                    self.onError(responseJson["comment"]);
                    data['status'] = responseJson["status"];
                    return data;
                } else {
                    self.onError(responseJson["comment"]);
                    data['status'] = responseJson["status"];
                    return data;
                }
            }).catch((reason) => self.onError(reason || "Не вдалося завантажити спеціфікацію"));
        }
    },

    defaultShipmentDetailRecord: function() {
        return {
            id:0,
            shipments_id:0,
            product_id:0,
            unit_id:0,
            count_to_ship:0,
            ship_price_for_one:0,
            total_ship_price:0,
            provider_id:0,
            price_one_by_provider:0,
            total_price_provider:0,
        };
    },
};


export default ShipmentModel;