// import moment from 'moment';

const ProductsModel = {

    loadProducts : function(self, attributeName, onError){
        return function() {
            var app = self.context;
            
            return app.postData(
                '/product/get',
                {"param": []}
            ).then(function(responseJson){
            // console.log("responseJson", responseJson, responseJson["status"]);
            if(responseJson["status"] === "OK") {
                let update = {};
                update[attributeName] = responseJson["detail"];
                self.setState(update);
                return responseJson["detail"];
            } else {
                onError(responseJson["comment"]);
                return [];
            }
            }).catch(() => onError("Не вдалося завантажити товари"));
        };
    },


};


export default ProductsModel;