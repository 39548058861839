import React, {Component}  from "react";
import {
  Grid,
  Select,
  MenuItem,
  Button,
  TextField,
  Link,
} from "@material-ui/core";
// import moment from 'moment';

// components
// import MUIDataTable from "mui-datatables";
// import validators from '../../validators';
import validators   from '../../../../utils/validators';
import MessageBlock from '../../../../utils/MessageBlock';
// context
import { AppContext } from "../../../../context/AppContext";

// styles
import { withStyles } from "@material-ui/core/styles";
import Themes from "../../../../themes";
import customStyles from "./styles";
// import { Alarm } from "@material-ui/icons";
const theme = Themes.default;
const styles = customStyles(theme);




class ContractAppendixInfo extends Component {

  static contextType = AppContext;
  constructor(props) {
    super(props);
    if(!this.state) {
      this.state = {
        providerId:this.props.providerId,
        provider:{},

        contract_number: this.props.contract_number,
        contract: {},

        appendixId:this.props.appendixId,
        appendix:{},

        errors: {},
        message:"",
        messageType:"",

        deal_status:[],
      };
    }
    // var self =this;

    var nonEmpty = function(val) {
        if(val.trim().length === 0 ){
            return "Будь ласка, введіть значення";
        }
        return "";
    };

    this.validator={
      "date_contract": nonEmpty,
      "summary": function(val) {
         if(validators.isPositiveFloat(val) !== true ){
                    return "Будь ласка, виберіть значення";
                }
                return "";
      },
      "determinate_days": function(val) {
         if(validators.isPositiveInteger(val) !== true ){
                    return "Будь ласка, виберіть значення";
                }
                return "";
      },
      "dop_agreement_number": nonEmpty,
      "deal_status_id": function(val) {
         if(validators.isPositiveInteger(val) !== true ){
                    return "Будь ласка, виберіть значення";
                }
                return "";
      },

     };

     this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidUpdate(){
    if(this.props.providerId !==  this.state.providerId 
       || this.props.contract_number !==  this.state.contract_number
       || this.props.appendixId !==  this.state.appendixId) {
      var self = this;
      this.setState({
         providerId: this.props.providerId,
         contract_number: this.props.contract_number,
         appendixId: this.props.appendixId,
      });
      self.loadProvider().finally(function(){
        self.loadContract().finally(function(){
          self.loadAppendix();
        });
      });
    }    
  }

  componentDidMount(){
    // var app = this.context;
    var self = this;
    self.setState({
        providerId: this.props.providerId,
        contract_number: this.props.contract_number,
        appendixId: this.props.appendixId,
    });
    self.loadProvider().finally(function(){
      self.loadContract().finally(function(){
        self.loadAppendix().finally(function(){
          self.loadDealStatus();
        });
      });
    });
  }

  loadProvider(){
    var app = this.context;
    var self = this;
    var onError = function(errorMessage) {
        self.setState({
            "message": errorMessage,
            "messageType": "error"
        });
    };
    var data = {"param": [{"provider_id": parseInt(this.props.providerId)}]};
    return app.postData(
        '/providers/get',
        data
      ).then(function(responseJson){
          // console.log("responseJson", responseJson, responseJson["status"]);
          if(responseJson["status"] === "OK") {
              let data = responseJson["detail"][0];            
              self.setState({
                  "provider": data
              });
          } else if(responseJson["status"] === "NOT_FOUND") {
              self.props.app.setState({
                  "message": responseJson["comment"],
                  "messageType": "error"
              });
          } else {
              onError(responseJson["comment"]);
          }
    }).catch(() => onError("Не вдалося завантажити постачальника"));
  }

  loadContract() {
    var app = this.context;
    var self = this;
    var onError = function(errorMessage) {
      self.setState({
        "message": errorMessage,
        "messageType": "error"
      });
    };
    var data = {"param": [{"contract_number": self.props.contract_number}]};
    return app.postData(
      '/providers_contract/get',
      data
    ).then(function(responseJson){
      // console.log("responseJson", responseJson, responseJson["status"]);
      if(responseJson["status"] === "OK") {
        let data = responseJson["detail"][0];            
        self.setState({
          "contract": data
        });
      } else if(responseJson["status"] === "NOT_FOUND") {
        self.props.app.setState({
          "message": responseJson["comment"],
          "messageType": "error"
        });
      } else {
        onError(responseJson["comment"]);
      }
    }).catch(() => onError("Не вдалося завантажити договір"));
  }

  loadAppendix(){

    var appendixId = parseInt(this.props.appendixId);
    if (!appendixId) {
        return;
    }

    var app = this.context;
    var self = this;
    var onError = function(errorMessage) {
        self.setState({
          "message":errorMessage,
          "messageType":"error",
        });
    };
    var data = {"param": [{"provider_additional_agreement_id": appendixId}]};
    return app.postData(
      '/provider_additional_agreement/get',
      data
    ).then(function(responseJson){
      // console.log("responseJson", responseJson, responseJson["status"]);
      if(responseJson["status"] === "OK") {
        let data = responseJson["detail"];            
        self.setState({
          "appendix": data[0],
        });
      } else if(responseJson["status"] === "NOT_FOUND") {
        self.props.app.setState({
          "message": responseJson["comment"],
          "messageType": "error"
        });
      } else {
        onError(responseJson["comment"]);
      }
    }).catch(() => onError("Не вдалося завантажити спеціфікацію"));
  }

  loadDealStatus(){
    var app = this.context;
    var self = this;
    var onError = function(errorMessage) {
          self.setState({
            "message":errorMessage,
            "messageType":"error",
          });
        };
    return app.postData(
    '/deal_status/get',
    {"param": []}
    ).then(function(responseJson){
    // console.log("responseJson", responseJson, responseJson["status"]);
    if(responseJson["status"] === "OK") {
        self.setState({
        "deal_status": responseJson["detail"]
        });
    } else {
        onError(responseJson["comment"]);
    }
    }).catch(() => onError("Невідома помилка"));
  }

  validate(record){
    var self = this;
    var errors={};
    var errorExists = false;
    [
      "date_contract",
      "summary",
      "determinate_days",
      "dop_agreement_number",
      "deal_status_id",
    ].map(function(elm) {
          if(self.validator[elm]) {
            errors[elm]  = self.validator[elm](record[elm]);
            if (errors[elm]){
              errorExists = true;
            }
          } else {
            errors[elm] = "";
          }
          return elm;
    });

    return {
      errors:errors,
      errorExists: errorExists
    }
  }


  handleChangeFactory(attributeName) {
      var self = this;
      return function(event) {
        event.preventDefault();
        // deep copy of the data
        var appendix = JSON.parse(JSON.stringify(self.state.appendix));
        
        appendix[attributeName] = event.target.value;

        var err = self.validate(appendix);
      
        self.setState({
            appendix: appendix,
            errors: err.errors,
            message: "Дані змінено, але не збережено",
            messageType: "warning",
        });
      }
  }

  // handleCheckboxFactory(attributeName) {
  //   var self = this;
  //   return function(event) {
  //       var provider = JSON.parse(JSON.stringify(self.state.provider));
  //       provider[attributeName] =  event.target.checked;
  //       self.setState({
  //           "provider": provider,
  //           "message": "Дані змінено, але не збережено",
  //           "messageType": "warning"
  //       });
  //   }
  // }


  handleSubmit(event) {
        event.preventDefault();
        var self = this;
        var app = this.context;
        
        let appendix = JSON.parse(JSON.stringify(this.state.appendix));

        appendix.provider_contracts_id =  this.state.contract_number;

        // do validation
        var err = self.validate(appendix);

        if(err.errorExists) {
            self.setState({
              errors: err.errors,
              message:"Дані не збережено",
              messageType:"error",
            });
            return;
        }

        var onError = function(errorMessage) {
            self.setState({
              "message":errorMessage,
              "messageType":"error",
            });
          };

        if(appendix.id){
          let postData = {
            "id" : parseInt(appendix.id),
            "dop_agreement_number": appendix.dop_agreement_number,
            "provider_contracts_id": appendix.provider_contracts_id,
            "date_contract": appendix.date_contract,
            "summary": parseFloat(appendix.summary),
            "determinate_days": parseInt(appendix.determinate_days),
            "deal_status_id":  parseInt(appendix.deal_status_id)
          };
          console.log('updating', postData);
          app.postData(
              '/provider_additional_agreement/edit',
              [postData]
              ).then(function(responseJson){
              // console.log("responseJson", responseJson, responseJson["status"]);
              if(responseJson["status"] === "OK") {
                  self.setState({
                      "message": "Дані оновлено",
                      "messageType": "success"
                  });
              } else if(responseJson["status"] === "EXPECTATION_FAILED") {
                  onError("Не вдалося зберегти дані");
              } else {
                  onError(responseJson["comment"]);
              }
              }).catch(() => onError("Невідома помилка"));
        } else {
            let postData = {
              "id" : 0,
              "dop_agreement_number": appendix.dop_agreement_number,
              "provider_contracts_id": appendix.provider_contracts_id,
              "date_contract": appendix.date_contract,
              "summary": parseFloat(appendix.summary),
              "determinate_days": parseInt(appendix.determinate_days),
              "deal_status_id":  parseInt(appendix.deal_status_id)
            };
            console.log('creating', postData);
            app.postData(
                '/provider_additional_agreement/add',
                [postData]
            ).then(function(responseJson){
                // console.log("responseJson", responseJson, responseJson["status"]);
                if(responseJson["status"] === "OK") {
                    var appendix = Object.assign({}, self.state.appendix);
                    appendix.id = responseJson["detail"][0];
                    self.setState({
                        "appendix": appendix,
                        "message": "Дані збережено",
                        "messageType": "success"
                    });
                } else if(responseJson["status"] === "EXPECTATION_FAILED") {
                    onError("Не вдалося зберегти дані");
                } else {
                    onError(responseJson["comment"]);
                }
            }).catch(() => onError("Невідома помилка"));
        }
  }

  // handleDeleteFactory(ind) {
  //     var self = this;
  //     var app = this.context;
  //     return function(event) {
  //         event.preventDefault();
  //         var id = self.state.tabledata[ind].id;
  //         if(id) {
  //             var onError = function(errorMessage) {
  //                 self.setState({
  //                     "message":errorMessage,
  //                     "messageType":"error",
  //                 });
  //             };
  //             var postData = [id];
  //             // post the DELETE command
  //             app.postData(
  //                     '/price_product/delete',
  //                     postData
  //                 ).then(function(responseJson){
  //                     if(responseJson["status"] === "OK") {
  //                         self.setState({
  //                             "message": "Дані оновлено",
  //                             "messageType": "success"
  //                         });
  //                         self.loadTableData(false);
  //                     } else if(responseJson["status"] === "EXPECTATION_FAILED") {
  //                         onError("Не вдалося зберегти дані");
  //                     } else {
  //                         onError(responseJson["comment"]);
  //                     }
  //                 }).catch(() => onError("Невідома помилка"));
  //         } else {
  //             var tabledata = JSON.parse(JSON.stringify(self.state.tabledata));
  //             tabledata.splice(ind, 1);
  //             self.setState({
  //                 "tabledata": tabledata
  //             });
  //         }
  //     }
  // }
  // getError(attr, i, subAttr){
  //     var errors = JSON.parse(JSON.stringify(this.state.errors));
  //     errors = errors[attr] || {};
  //     errors = errors["@"+i] || {};
  //     return errors[subAttr];
  // }



  render(){
    // global
    var app = this.context;
    var self = this;
    const { classes } = self.props;

    return (<>
      <div>
        <Grid container spacing={3}>
          <Grid item xs={12}>
          <div className={classes.breadCrumbs}>
                <Link href="javascript:void(0)" onClick={() => self.props.parent.setState({
                  providerId: null,
                  view:self.props.parent.views.providers,
                })}>Постачальники</Link>
                / 
                <Link href="javascript:void(0)" onClick={() => self.props.parent.setState({
                  view:self.props.parent.views.providerInfo,
                })}>{self.state.provider.name}</Link>
                / 
                <Link href="javascript:void(0)" onClick={() => self.props.parent.setState({
                  view:self.props.parent.views.contracts,
                })}>Договори</Link>
                / 
                <Link href="javascript:void(0)" onClick={() => self.props.parent.setState({
                  view:self.props.parent.views.contractInfo,
                })}>{self.state.contract_number}</Link>
                / 
                <Link href="javascript:void(0)" onClick={() => self.props.parent.setState({
                  view:self.props.parent.views.contractAppendixes,
                })}>Додаткові угоди</Link>
                /
                {self.state.appendix.dop_agreement_number}
          </div>
          </Grid>
          <Grid item xs={5}>

            <h1 className={classes.pageTitle}>Додаткова угода "{self.state.appendix.dop_agreement_number}"</h1>
          </Grid>
          <Grid item xs={7}>
            <div className={classes.topButtons}>
              { app.state.userType === "admin" &&
                <>
                <Button
                  variant="contained"
                  color="success"
                  size="large"
                  className={classes.submitButtonDanger}
                  // onClick={self.handleDeleteFactory(ind)}
                  >
                  Видалити
                </Button>
                &nbsp;
                </>
              }

              <span className={classes.btnBlock}>
                <Button
                  variant="contained"
                  color="success"
                  size="large"
                  className={classes.submitButtonSattelite}
                  onClick={() => self.props.parent.setState({
                    view: self.props.parent.views.contractAppendixSpecs,
                    appendixId:self.props.appendixId, 
                  })}
                  >
                  &nbsp;
                </Button>
                <Button
                  variant="contained"
                  color="success"
                  size="large"
                  className={classes.submitButton}
                  onClick={() => self.props.parent.setState({
                    view: self.props.parent.views.contractAppendixSpecs,
                    appendixId:self.props.appendixId, 
                  })}
                  >
                  Специфікація
                </Button>
              </span>
            </div>
          </Grid>

          {self.state.message && (<Grid item xs={12}>
          <MessageBlock message={self.state.message} messageType={self.state.messageType}/>
          </Grid>)}

          <Grid item xs={6} md={3}>
            <div className={classes.formFieldLabel}>
              Номер додаткової угоди
            </div>
          </Grid>
          <Grid item xs={6} md={9}>
            {
                self.state.appendix.dop_agreement_number
                ? (<span className={classes.formFieldLabel}>{self.state.appendix.dop_agreement_number}</span>)
                : (<TextField
                      value={self.state.appendix.dop_agreement_number}
                      onChange={self.handleChangeFactory("dop_agreement_number")}
                      margin="normal"
                      placeholder="Номер договору"
                      type="text"
                      fullWidth
                      variant="standard" 
                      className={self.state.errors.dop_agreement_number ? classes.textFieldErr : classes.textField}
                      />)
            }
          </Grid>

          <Grid item xs={6} md={3}>
            <div className={classes.formFieldLabel}>
            Дата додаткової угоди
            </div>
          </Grid>
          <Grid item xs={6} md={9}>
            <TextField
              value={self.state.appendix.date_contract}
              onChange={self.handleChangeFactory("date_contract")}
              margin="dense"
              placeholder="Дата додаткової угоди"
              type="date"
              fullWidth
              variant="standard" 
              className={self.state.errors.date_contract ? classes.textFieldErr : classes.textField}
              />
          </Grid>

          <Grid item xs={6} md={3}>
            <div className={classes.formFieldLabel}>
              Відсрочка платежу
            </div>
          </Grid>
          <Grid item xs={6} md={9}>
            <TextField
              value={self.state.appendix.determinate_days}
              onChange={self.handleChangeFactory("determinate_days")}
              margin="dense"
              placeholder="Відсрочка платежу"
              type="text"
              fullWidth
              variant="standard" 
              className={self.state.errors.determinate_days ? classes.textFieldErr : classes.textField}
              />
          </Grid>

          <Grid item xs={6} md={3}>
            <div className={classes.formFieldLabel}>
              Сума
            </div>
          </Grid>
          <Grid item xs={6} md={9}>
            <TextField
              value={self.state.appendix.summary}
              onChange={self.handleChangeFactory("summary")}
              margin="dense"
              placeholder="Сума"
              type="text"
              fullWidth
              variant="standard" 
              className={self.state.errors.summary ? classes.textFieldErr : classes.textField}
              />
          </Grid>

          <Grid item xs={6} md={3}>
            <div className={classes.formFieldLabel}>
              Статус
            </div>
          </Grid>
          <Grid item xs={6} md={9}>
              <Select
                value={self.state.appendix.deal_status_id || ''}
                onChange={self.handleChangeFactory("deal_status_id")}
                className={self.state.errors.company_id ? classes.selectFieldErr : classes.selectField}
              >
                <MenuItem value="" key={'deal_status_id_X'} >-</MenuItem>
                {self.state.deal_status.map(function(elm, ind){
                    return <MenuItem key={'deal_status_id_' + ind} value={elm.id}  className={classes.selectOption}>{elm.name}</MenuItem>;
                })}
              </Select>
          </Grid>


          <Grid item xs={12}>
            <div className={classes.formButtons}>

              
              <Button
                variant="contained"
                color="success"
                size="large"
                className={classes.submitButtonSattelite}
                onClick={self.handleSubmit}
                >
                &nbsp;
              </Button>
              <Button
                variant="contained"
                color="success"
                size="large"
                className={classes.submitButton}
                onClick={self.handleSubmit}
                >
                Зберегти
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
    </>);
    
  }
}
export default withStyles(styles)(ContractAppendixInfo);

