import React, {Component}  from "react";
import {
  Grid,
  // Select,
  // MenuItem,
  Button,
  // TextField,
  TextareaAutosize,
  // Link,
} from "@material-ui/core";
import moment from 'moment';
// import DateTimePicker from '@mui/lab/DateTimePicker';
// import DatePicker from '@mui/lab/DatePicker';


// components
// import MUIDataTable from "mui-datatables";
// import validators   from '../../../../utils/validators';
import MessageBlock from '../../../../utils/MessageBlock';
// context
import { AppContext } from "../../../../context/AppContext";
import ApiModel from "../../../../utils/ApiModel";
// import regions   from '../../../../utils/regions_ua';

// styles
import { withStyles } from "@material-ui/core/styles";
import Themes from "../../../../themes";
import customStyles from "./styles";
// import { Alarm } from "@material-ui/icons";
const theme = Themes.default;
const styles = customStyles(theme);



class TaskComment extends Component {

  static contextType = AppContext;
  constructor(props) {
    super(props);

    if(!this.state) {
      this.state = {
        taskId:this.props.taskId,
        task:{},
        comment_to_task:[],
        newComments:"",

        personal_map:{},
        admin_map:{},

        errors: {},
        message:"",
        messageType:"",
      };
    }
    this.nonEmpty = function(val) {
      if((val + '').trim().length === 0 ){
          return "Будь ласка, введіть значення";
      }
      return "";
    };

    this.validator = {
      newComments: this.nonEmpty, 
    };
    this.handleSubmit = this.handleSubmit.bind(this);

    this.loadPersonalMap = ApiModel.loadPersonalMap(this, "personal_map");
    this.loadOneTask = ApiModel.loadOneTask(this, "task");
    this.loadAdminMap = ApiModel.loadAdminMap(this, "admin_map");
  }

  componentDidUpdate(){
    var self = this;
    if(this.props.taskId !==  this.state.taskId) {
      this.setState({
        taskId: this.props.taskId,
        newComments:"",
        task:{},
      });
      self.loadOneTask().finally(function(){
        self.loadCommentToTask();
      });
    }    
  }

  componentDidMount(){
    // var app = this.context;
    var self = this;
    self.setState({
      taskId: self.props.taskId,
      newComments:"",
      task:{},
      comment_to_task:[],
    });
    self.loadOneTask().finally(function(){
      self.loadCommentToTask().finally(function(){
        self.loadPersonalMap().finally(function(){
          self.loadAdminMap();
        });
      });
    });
  }


  loadCommentToTask(){
    var app = this.context;
    var self = this;
    var onError = function(errorMessage) {
        self.setState({
          auction:JSON.parse(self.defaultRecord),
          message: errorMessage,
          messageType: "error",
        });
    };
    var task_id = parseInt(this.props.taskId);
    
    // console.log('task_id=', task_id);
    if(!task_id){
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve('foo');
        }, 100);
      });
    }
    var data = {"param": [{"task_id": task_id}]};
    return app.postData(
        '/comment_to_task/get',
        data
    ).then(function(responseJson){
      // console.log("responseJson", responseJson, responseJson["status"]);
      if(responseJson["status"] === "OK") {
        self.setState({
          "comment_to_task": responseJson["detail"], 
        });
      } else if(responseJson["status"] === "NOT_FOUND") {
        self.props.app.setState({
          "message": responseJson["comment"],
          "messageType": "error"
        });
      } else {
        onError(responseJson["comment"]);
      }
    }).catch(() => onError("Не вдалося завантажити завдання"));
  }


  handleDeleteFactory(taskId, commentId){
    var self = this;
    var app = this.context;
    return function(event) {
      var onError = function(errorMessage) {
        self.setState({
          "message":errorMessage,
          "messageType":"error",
        });
      };
      let postData = commentId;
      // console.log('deleting', postData);
      app.postData(
        '/comment_to_task/delete',
        [postData]
      ).then(function(responseJson){
        // console.log("responseJson", responseJson, responseJson["status"]);
        if(responseJson["status"] === "OK") {
          self.setState({
            "message": "Дані оновлено",
            "messageType": "success"
          });
          ;
        } else if(responseJson["status"] === "EXPECTATION_FAILED") {
          onError("Не вдалося зберегти дані");
        } else {
          onError(responseJson["comment"]);
        }
      }).catch(
        () => onError("Невідома помилка")
      ).finally(function(){
        self.loadCommentToTask();
      });
        
    }
  }

  handleSubmit(event) {
        event.preventDefault();
        var self = this;
        var app = this.context;

        // do validation
        let task = JSON.parse(JSON.stringify(this.state.task));
        // console.log('validate', self.state);
        // var err = self.validate(self.state);

        // if(err.errorExists) {
        //     self.setState({
        //       errors: err.errors,
        //       message:"Виправте дані",
        //       messageType:"error",
        //     });
        //     return;
        // }

        var onError = function(errorMessage) {
          self.setState({
            "message":errorMessage,
            "messageType":"error",
          });
        };

        let postData = {
          task_id: parseInt(task.id) || 0,  
          author_id: parseInt(app.state.personal_id) || 0,
          author_admin_id: parseInt(app.state.root_id) || 0,
          comment: self.state.newComments,
          date: moment().format('YYYY-MM-DD HH:mm:ss'),
        };
        if(task.id){
          console.log('updating', postData);
          app.postData(
            '/comment_to_task/add',
            [postData]
          ).then(function(responseJson){
            // console.log("responseJson", responseJson, responseJson["status"]);
            if(responseJson["status"] === "OK") {
              self.setState({
                "message": "Дані оновлено",
                "messageType": "success",
                "newComments":"",
              });
              self.loadCommentToTask();
            } else if(responseJson["status"] === "EXPECTATION_FAILED") {
              onError("Не вдалося зберегти дані");
            } else {
              onError(responseJson["comment"]);
            }
          }).catch(() => onError("Невідома помилка"));
        } else {
            onError("Невідома помилка");
        }
  }

  render(){
    // global
    var app = this.context;
    var self = this;
    const { classes } = self.props;

    return (<>
      <div>
        <Grid container spacing={3}>

          {self.state.message && (<Grid item xs={12}>
          <MessageBlock message={self.state.message} messageType={self.state.messageType}/>
          </Grid>)}

          {self.state.comment_to_task.map(function(elm, ind){

            let comment_datetime = moment(elm.date).format('DD.MM.YYYY HH:mm:ss');

            let comment_author = "";
            
            let personal = self.state.personal_map[elm.author_id];
            if(personal){
              comment_author = (<span>{personal.fname} {personal.sname} {personal.lname} </span>);
            } else {
              let admin = self.state.admin_map[elm.author_admin_id];
              if(admin){
                comment_author = (<span>{admin.fname} {admin.sname} {admin.lname} </span>);
              }
            }

            return (<>
              <Grid item xs={6} md={3} key={"author_" + ind}>
                  {comment_author}<br/>
                  {comment_datetime}
                  {app.state.userType === "admin" && <>
                      <Button
                        variant="contained"
                        color="success"
                        size="small"
                        className={classes.submitButtonSattelite}
                        onClick={self.handleDeleteFactory(elm.task_id, elm.id)}
                        >
                        &nbsp;
                      </Button>
                      <Button
                        variant="contained"
                        color="success"
                        size="small"
                        className={classes.submitButton}
                        onClick={self.handleDeleteFactory(elm.task_id, elm.id)}
                        >
                        Видалити
                        </Button>
                    </>
                  }
              </Grid>
              <Grid item xs={6} md={9} key={"comment_" + ind}>
                {elm.comment}
              </Grid>
             </>);
          })}

            <Grid item xs={6} md={3}>
              <div className={classes.formFieldLabel}>
                Новий коментар
              </div>
            </Grid>
            <Grid item xs={6} md={9}>
              <TextareaAutosize
                minRows={3}
                style={{width:"100%"}}
                value={self.state.newComments}
                onChange={(event) => self.setState({ newComments: event.target.value }) }
                margin="dense"
                placeholder="Примітки"
                type="text"
                variant="standard" 
                // className={self.state.errors.comments ? classes.textFieldErr : classes.textField}
                />
            </Grid>





          <Grid item xs={12}>
            <div className={classes.formButtons}>
              <Button
                variant="contained"
                color="success"
                size="large"
                className={classes.submitButtonSattelite}
                onClick={() => app.setState( { cmd_task:[{action:"closePopup"}] })}
                >
                &nbsp;
              </Button>
              <Button
                variant="contained"
                color="success"
                size="large"
                className={classes.submitButton}
                onClick={() => app.setState( { cmd_task:[{action:"closePopup"}] })}
                >
                Закрити
              </Button>
              
              <Button
                variant="contained"
                color="success"
                size="large"
                className={classes.submitButtonSattelite}
                onClick={self.handleSubmit}
                >
                &nbsp;
              </Button>
              <Button
                variant="contained"
                color="success"
                size="large"
                className={classes.submitButton}
                onClick={self.handleSubmit}
                >
                Зберегти
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
    </>);
    
  }
}
export default withStyles(styles)(TaskComment);

