import React, {Component} from "react";

// components
import { Typography } from "../Wrappers";

import { AppContext } from "../../context/AppContext";
import { withStyles } from "@material-ui/core/styles";
import Themes from "../../themes";
import customStyles from "./styles";
var theme = Themes.default;
var styles = customStyles(theme);

class PageTitle extends Component {

  static contextType = AppContext;

  constructor(props) {
    super(props);
    if(!this.state) {
      this.state = {
        isOpen:false,
      };
    }
    // this.toggleCollapse = this.toggleCollapse.bind(this);
  }

  setIsOpen(val) { this.setState({isOpen:val}) }

  render(){
   var {classes,} = this.props;
   return (
    <div className={classes.pageTitleContainer}>
      <Typography className={classes.typo} variant="h1" size="sm">
        {this.props.title}
      </Typography>
      {this.props.button && this.props.button}
    </div>
  );
  }
}
export default withStyles (styles)(PageTitle);
