// import moment from 'moment';

const TaskStatusModel = {

    loadTaskStatus: function(self, attributeName){
        return function() {
            var app = self.context;
            var onError = function(errorMessage) {
            self.setState({
                "message": errorMessage,
                "messageType": "error"
            });
            };
            var data = {"param": []};
            return app.postData(
                '/task_status/get',
                data
            ).then(function(responseJson){
            // console.log("responseJson", responseJson, responseJson["status"]);
            if(responseJson["status"] === "OK") {
                let update = {};
                update[attributeName] = responseJson["detail"];
                self.setState(update);
                return responseJson["detail"];
            } else if(responseJson["status"] === "NOT_FOUND") {
                self.props.app.setState({
                "message": responseJson["comment"],
                "messageType": "error"
                });
                return [];
            } else {
                onError(responseJson["comment"]);
                return [];
            }
            }).catch(() => onError("Не вдалося завантажити статуси завдань"));
        };
    },


    loadTaskStatusMap: function(self, attributeName){
        return function() {
            var app = self.context;
            var onError = function(errorMessage) {
            self.setState({
                "message": errorMessage,
                "messageType": "error"
            });
            };
            var data = {"param": []};
            return app.postData(
                '/task_status/get',
                data
            ).then(function(responseJson){
            // console.log("responseJson", responseJson, responseJson["status"]);
            if(responseJson["status"] === "OK") {     
                let theMap = {};
                for(let i=0; i<responseJson["detail"].length; i++){
                    theMap[responseJson["detail"][i].id] = responseJson["detail"][i];
                }
                let update = {};
                update[attributeName] = theMap;
                self.setState(update);
                return theMap;
            } else if(responseJson["status"] === "NOT_FOUND") {
                self.props.app.setState({
                    "message": responseJson["comment"],
                    "messageType": "error"
                });
                return {};
            } else {
                onError(responseJson["comment"]);
                return {};
            }
            }).catch(() => onError("Не вдалося завантажити статуси завдань"));
        };
    },
    loadTaskStatusDefault: function(self, attributeName){
        return function() {
            var app = self.context;
            var onError = function(errorMessage) {
                self.setState({
                    "message": errorMessage,
                    "messageType": "error"
                });
            };
            var data = {"param": []};
            return app.postData(
                '/task_status/default',
                data
            ).then(function(responseJson){
                // console.log("responseJson", responseJson, responseJson["status"]);
                if(responseJson["status"] === "OK") {     
                    let update={};
                    update[attributeName]=responseJson["detail"][0];
                    self.setState(update);
                    return responseJson["detail"][0];
                } else if(responseJson["status"] === "NOT_FOUND") {
                    self.props.app.setState({
                        "message": responseJson["comment"],
                        "messageType": "error"
                    });
                    return {};
                } else {
                    onError(responseJson["comment"]);
                    return {};
                }
            }).catch(() => onError("Не вдалося завантажити статуси завдань"));
        }
    },

};


export default TaskStatusModel;