

function validators(){
    return {

        isValidTelephoneNumber:function(phoneNumber) {
            var phoneRe = /^\d{10,12}$/;
            var digits = phoneNumber.replace(/\D/g, "");
            return phoneRe.test(digits);
        },
        
        isValidEmail:function(email) {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
        },

        isPositiveFloat : function(val) {
            let floatVal = parseFloat(val);
            if(isNaN(floatVal)) {
                return false;
            }
            if(floatVal<0){
                return false;
            }
            return true;
        },
        
        isPositiveInteger : function(val) {
            let intVal = parseInt(val);
            if(isNaN(intVal)) {
                return false;
            }
            if(intVal<0){
                return false;
            }
            return true;
        },

        isInteger : function(val) {
            let intVal = parseInt(val);
            if(isNaN(intVal)) {
                return false;
            }
            return true;
        },
    };
}

export  default validators();
